import React from 'react';

const Aside3 = (aside) => {
    return (
        <>
            {aside.aside3}
        </>

    )
}

export default Aside3;