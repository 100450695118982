import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  dataElectionResults,
  indexDistrict,
  indexGetAllManageElections,
  showAllPollingDivisionsByDistrictById,
} from "../../../redux/adminone/action";
import IImage from "../../../components/image/iImage";
import PastPresidentElection from "../../../components/admin/pastPresidentElection";
import ManageTable from "../../../components/table/manageTable";
import Loader from "../../../components/loaders/loader";
import CustomChart from "../../../components/charts/customChart";
import { cApiUrlPrefix } from "../../../config/envConfig";

const override = `
    display: block; 
    margin: 0 auto;
    border-color: red;
`;
const cstomStyle = {
  menu: (provided) => ({
    ...provided,
    backgroundColor: "white",
    color: "#65676B",
    maxWidth: "100%",
    borderColor: "#65676B",
    boxShadow: "unset",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#efefef" : "white",
    color: state.isFocused ? "#65676B" : "#65676B",
    padding: 5,
    maxHeight: 30,
  }),
};

const formatNumber = (num) => {
  if (Math.floor(num) === num) {
    return num.toString();
  } else {
    return num.toFixed(2);
  }
};

const ElectionResults = ({ initialValues }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector((state) => state.adminone.loading);
  const [options0, setOptions0] = useState([]);
  const [options, setOptions] = useState([]);
  const [options1, setOptions1] = useState([]);

  const [selectedFilter, setSelectedFilter] = useState(null);
  const [isFirstSelectSelected, setIsFirstSelectSelected] = useState(false);
  const dataElectionResultsbyID = useSelector(
    (state) => state.adminone.dataElectionResultsbyID?.data
  );

  const indexDistrictsData = useSelector(
    (state) => state.adminone.indexDistrictsData?.data
  );
  const showsAllPollingDivisionsByDistrictIdData = useSelector(
    (state) => state.adminone.showsAllPollingDivisionsByDistrictIdData?.data
  );

  const [selectedDistrictId, setSelectedDistrictId] = useState(null);
  const [defaultDistrictOption, setDefaultDistrictOption] = useState(null);
  const [selectedPollingDivisionId, setSelectedPollingDivisionId] =
    useState(null);
  const [defaultPollingDivisionOption, setDefaultPollingDivisionOption] =
    useState(null);
  const indexAllManageElectionData = useSelector(
    (state) => state.adminone.indexAllManageElectionData?.data
  );
  const [selectedElectionLabel, setSelectedElectionLabel] = useState("");
  const [winnerElectionLabel, setWinnerElectionLabel] = useState("");
  const [isElectionSelected, setIsElectionSelected] = useState(false);

  const election_title_id = useMemo(() => {
    return selectedFilter?.value ?? null;
  }, [selectedFilter]);

  const [errors] = useState({
    name: "",
    total_registered_votes: initialValues?.total_registered_votes ?? 0,
    total_polled_votes: initialValues?.total_polled_votes ?? 0,
    total_rejected_votes: initialValues?.total_rejected_votes ?? 0,
    total_postal_votes: initialValues?.total_postal_votes ?? 0,
    total_valid_votes: initialValues?.total_valid_votes ?? 0,
  });

  useEffect(() => {
    dispatch(indexDistrict());
    dispatch(indexGetAllManageElections());
  }, [dispatch]);

  useEffect(() => {
    if (isElectionSelected) {
      dispatch(dataElectionResults(election_title_id));
    }
  }, [dispatch, election_title_id, isElectionSelected]);

  useEffect(() => {
    if (selectedDistrictId) {
      dispatch(showAllPollingDivisionsByDistrictById(selectedDistrictId));
    } else {
      setOptions1([]);
      setSelectedPollingDivisionId(null);
      setDefaultPollingDivisionOption(null);
    }
  }, [dispatch, selectedDistrictId]);

  useEffect(() => {
    if (showsAllPollingDivisionsByDistrictIdData) {
      const newOptions = [
        ...showsAllPollingDivisionsByDistrictIdData.map((item) => ({
          value: item.id,
          label: item.polling_division_name,
        })),
      ];
      setOptions1(newOptions);
    }
  }, [showsAllPollingDivisionsByDistrictIdData]);

  useEffect(() => {
    if (indexAllManageElectionData) {
      const newOptions = [
        ...indexAllManageElectionData.map((item) => ({
          value: item.id,
          label: item.election_title,
        })),
      ];
      setOptions0(newOptions);
    }
  }, [indexAllManageElectionData]);

  const selectChange0 = (selectedOption) => {
    setSelectedFilter(selectedOption);
    setSelectedElectionLabel(selectedOption.label || "");
    setIsElectionSelected(true);

    if (selectedOption) {
      navigate(
        `/election-results?${selectedOption.label.replace(/\s+/g, "-")}`
      );
      setWinnerElectionLabel(selectedOption.label);
    } else {
      navigate("/election-results");
    }

    const allOption = options.find((option) => option.value === "all");
    setSelectedDistrictId(allOption.value);
    setDefaultDistrictOption(allOption);

    setSelectedPollingDivisionId(null);
    setDefaultPollingDivisionOption(null);
    setOptions1([]);
  };

  const selectChange1 = (selectedOption) => {
    setSelectedPollingDivisionId(selectedOption.value);
    setDefaultPollingDivisionOption(selectedOption);

    setWinnerElectionLabel(selectedOption.label || "");

    dispatch(
      dataElectionResults(
        election_title_id,
        selectedDistrictId,
        selectedOption.value
      )
    );
  };

  const labels = [
    "Candidate Image",
    "Candidate Name",
    "Party Logo",
    "Associated Party Name",
    "Total Received Votes",
    "Total Postal Votes",
    "Vote Percentage",
  ];

  useEffect(() => {
    if (indexDistrictsData) {
      const newOptions = [
        { value: "all", label: "All" },
        ...indexDistrictsData.map((item) => ({
          value: item.id,
          label: item.district_name,
        })),
      ];
      setOptions(newOptions);
    }
  }, [indexDistrictsData]);

  const selectChange = (selectedOption) => {
    setSelectedDistrictId(selectedOption.value);
    setDefaultDistrictOption(selectedOption);
    setIsFirstSelectSelected(!!selectedOption);

    setSelectedPollingDivisionId(null);
    setDefaultPollingDivisionOption(null);
    setOptions1([]);

    setWinnerElectionLabel(selectedOption.label);

    if (selectedOption.value !== "all") {
      dispatch(dataElectionResults(election_title_id, selectedOption.value));
    } else {
      dispatch(dataElectionResults(election_title_id));
    }
  };
  const [pieArray, setPieArray] = useState([]);
  const [labelArray, setLabelArray] = useState([]);
  const [cImgArray, setCImgArray] = useState([]);
  const [perArray, setPerArray] = useState([]);
  const [voteArray, setVoteArray] = useState([]);
  const [partyArray, setPartyArray] = useState([]);

  const data = useMemo(() => {
    if (!Array.isArray(dataElectionResultsbyID?.data)) return [];

    const candidateImage = [];
    const candidateNames = [];
    const votePercentages = [];
    const votes = [];
    const partyNames = [];

    const totalVotes = dataElectionResultsbyID.data.reduce(
      (total, candidate) => {
        return (
          total +
          parseFloat(candidate.total_votes) +
          parseFloat(candidate.total_postal_votes)
        );
      },
      0
    );

    const mappedData = dataElectionResultsbyID.data.map((candidate) => {
      candidateImage.push(candidate.candidate_image);
      candidateNames.push(candidate.candidate_name);
      partyNames.push(candidate.party_name);

      const candidateTotalVotes =
        parseFloat(candidate.total_votes) +
        parseFloat(candidate.total_postal_votes);
      votes.push(candidateTotalVotes);

      const votePercentage =
        totalVotes > 0 && !isNaN(candidateTotalVotes)
          ? `${formatNumber((candidateTotalVotes / totalVotes) * 100)}%`
          : "N/A";

      const votePercentageNumber =
        votePercentage !== "N/A"
          ? parseFloat(votePercentage.replace("%", ""))
          : null;

      votePercentages.push(votePercentageNumber);

      return {
        "Candidate Image": (
          <figure className="mcLogo">
            <IImage src={candidate.candidate_image} />
          </figure>
        ),
        "Candidate Name": candidate.candidate_name,
        "Party Logo": (
          <figure className="mcLogo">
            <IImage src={candidate.party_image} />
          </figure>
        ),
        "Associated Party Name": candidate.party_name,
        "Total Received Votes": (
          <input
            type="number"
            id={`total_votes_${candidate.candidate_id}`}
            className="voteChange"
            value={candidate.total_votes}
            readOnly={true}
          />
        ),
        "Total Postal Votes": (
          <input
            type="number"
            id={`total_postal_votes_${candidate.candidate_id}`}
            className="voteChange1"
            readOnly={true}
            value={candidate.total_postal_votes}
          />
        ),
        "Vote Percentage": (
          <span className="ttlVotesPerc">{votePercentage}</span>
        ),
      };
    });

    // Set pieArray with percentage values
    setPieArray(
      dataElectionResultsbyID.data.map((candidate) => {
        const votePercentage = parseFloat(
          (
            ((parseFloat(candidate.total_votes) +
              parseFloat(candidate.total_postal_votes)) /
              totalVotes) *
            100
          ).toFixed(2)
        );

        return {
          name: candidate.candidate_name,
          y: votePercentage,
          // color: `rgba(4, 103, 151, ${(votePercentage / 100) * 2})`,
          sliced: false,
        };
      })
    );

    setLabelArray(candidateNames);
    setCImgArray(candidateImage);
    setPerArray(votePercentages);
    setVoteArray(votes);
    setPartyArray(partyNames);

    return mappedData;
  }, [dataElectionResultsbyID]);

  const [topCandidate, setTopCandidate] = useState(null);

  useEffect(() => {
    if (
      dataElectionResultsbyID?.data &&
      dataElectionResultsbyID.data.length > 0
    ) {
      const candidates = dataElectionResultsbyID.data;

      const topCandidate = candidates.reduce((prev, current) => {
        const prevTotalVotes =
          parseFloat(prev.total_votes) + parseFloat(prev.total_postal_votes);
        const currentTotalVotes =
          parseFloat(current.total_votes) +
          parseFloat(current.total_postal_votes);

        return currentTotalVotes > prevTotalVotes ? current : prev;
      }, candidates[0]);

      setTopCandidate({
        ...topCandidate,
        combined_votes:
          parseFloat(topCandidate.total_votes) +
          parseFloat(topCandidate.total_postal_votes),
      });
    } else {
      setTopCandidate(null);
    }
  }, [dataElectionResultsbyID]);

  const textInput = dataElectionResultsbyID
    ? [
      {
        label: "Registered votes",
        name: "registeredVotes",
        value: dataElectionResultsbyID?.total_registered_votes ?? 0,
        error: errors.registeredVotes,
      },
      {
        label: "Total polled votes",
        name: "totalPolledVotes",
        value: dataElectionResultsbyID?.total_polled ?? 0,
        error: errors.total_polled,
      },
      {
        label: "Total rejected votes",
        name: "totalRejectedVotes",
        value: dataElectionResultsbyID?.total_rejected_votes ?? 0,
        error: errors.total_rejected_votes,
      },
      {
        label: "Total postal votes",
        name: "totalPostalVotes",
        value: dataElectionResultsbyID?.total_postal_votes ?? 0,
        error: errors.total_postal_votes,
      },
      {
        label: "Total valid votes",
        name: "totalValidVotes",
        value: dataElectionResultsbyID?.total_valid_votes ?? 0,
        error: errors.total_valid_votes,
      },
    ]
    : [];

  useEffect(() => {
    return () => {
      dispatch({ type: "CLEAR_ELECTION_RESULTS" });
      setOptions0([]);
      setOptions([]);
      setOptions1([]);
      setSelectedFilter(null);
      setSelectedDistrictId(null);
      setSelectedPollingDivisionId(null);
      setDefaultDistrictOption(null);
      setDefaultPollingDivisionOption(null);
    };
  }, [dispatch, navigate]);

  const props = {
    title: "Past Election History",
    style: { padding: "1rem 3.5rem" },
    options0,
    options,
    options1,

    className: {
      cselectpageSize: "cselectpageSize",
    },
    cstomStyle,
    headings: {
      heading0: "Select Election",
      heading1: "Select Districts",
      heading2: "Select Polling Division",
    },
    placeholder0: "select election",
    placeholder1: "All Districts",
    value: defaultDistrictOption,
    value1: defaultPollingDivisionOption,
    selectChange0,
    selectChange,
    selectChange1,
    isFirstSelectSelected,
    isElectionSelected,
    topCandidate,
    updatedWinner: winnerElectionLabel,
  };

  const maxVote = Math.max(...voteArray);
  const maxVoteone = Math.max(...perArray);

  const getColorWithOpacity = (value) => {
    const opacity = value / maxVote;
    return `rgba(248, 141, 30, ${opacity})`;
  };

  const getColorWithOpacityone = (value) => {
    const opacity = value / maxVoteone;
    return `rgba(4, 103, 151, ${opacity})`;
  };

  const [isChecked, setIsChecked] = useState(false);

  const handleSwitchChange = () => {
    setIsChecked(!isChecked);
  };

  const columnOption = {
    chart: {
      type: "column",
      backgroundColor: "transparent",
    },
    title: {
      text: "Voting Distribution",
      style: {
        fontSize: "1.4rem",
      },
    },
    xAxis: {
      categories: labelArray,
      labels: {
        useHTML: true,
        formatter: function () {
          const index = this.pos;
          const imageUrl = cImgArray[index];
          return `<img src="${cApiUrlPrefix}${imageUrl}" alt="${this.value}" width="20" height="20" class="highChart-img" />`;
        },
      },
    },
    yAxis: {
      labels: {
        style: {
          fontSize: "1.4rem",
        },
      },
    },
    series: [
      {
        name: "Votes",
        data: voteArray,
        colorByPoint: true,
        // colors: voteArray.map(getColorWithOpacity),
      },
      // {
      //   name: "Per%",
      //   data: perArray,
      //   colorByPoint: true,
      //   colors: perArray.map(getColorWithOpacityone),
      // },
    ],
    tooltip: {
      style: {
        fontSize: "1.4rem",
      },
      formatter: function () {
        const pointIndex = this.point.index;
        const votes = voteArray[pointIndex];
        const percentage = perArray[pointIndex];
        const partyName = partyArray[pointIndex];
        return `<b>${this.x}</b><br/>Votes: ${votes}<br/>Percentage: ${percentage}%<br/>Party: ${partyName}`;
      },
    },
  };

  const pieOption = {
    chart: {
      type: "pie",
      backgroundColor: "transparent",
    },
    title: {
      text: "Percentage Distribution",
      style: {
        fontSize: "1.4rem",
      },
    },
    tooltip: {
      valueSuffix: "%",
      style: {
        fontSize: "1.4rem",
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          categories: labelArray,
          enabled: true,
          // distance: 20,
          format: "{point.name}: {point.percentage:.1f}%",
          style: {
            fontSize: "1.4rem",
            textOutline: "none",
            // opacity: 0.7,
          },
          // filter: {
          //   operator: ">",
          //   property: "percentage",
          //   value: 10,
          // },
        },
      },
    },
    series: [
      {
        name: "Percentage",
        colorByPoint: true,
        data: pieArray,
      },
    ],
  };
  return (
    <main className="eResults">
      <PastPresidentElection
        {...props}
        showEditButton={false}
        showWonButton={true}
        selectedElectionLabel={selectedElectionLabel}
      />
      <div className="PastPresidentElection2">
        <div className="svsrp-voting-blk">
          {textInput.map((item, index) => (
            <div className="d-flex right-border" key={index}>
              <span className={`sqr-box${index}`}></span>
              <div className="registered-e-div">
                <h6 className="provincial-vote">{item.value}</h6>
                <p className="registered-electors mt-1">{item.label}</p>
              </div>
            </div>
          ))}
        </div>

        {isElectionSelected ||
          selectedDistrictId ||
          selectedPollingDivisionId ? (
          <div className="form-check form-switch activeSwitch">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckChecked"
              checked={isChecked}
              onChange={handleSwitchChange}
            />
            <label
              className="form-check-label"
              htmlFor="flexSwitchCheckChecked"
            >
              {isChecked ? "table" : "chart"}
            </label>
          </div>
        ) : null}

        {isChecked ? (
          (isElectionSelected ||
            selectedDistrictId ||
            selectedPollingDivisionId) &&
            data.length > 0 ? (
            <ManageTable data={data} loading={loading} labels={labels} />
          ) : null
        ) : labelArray.length > 0 && perArray.length > 0 ? (
          <div className="ppe2-chart-block">
            <div className="ppe2-chartsubBlk">
              <CustomChart options={columnOption} />
            </div>
            <div className="ppe2-chartsubBlk">
              <CustomChart options={pieOption} />
            </div>
          </div>
        ) : null}
      </div>
      {loading ? (
        <Loader color={"#0E76A8"} loading={loading} css={override} size={75} />
      ) : null}
    </main>
  );
};

export default ElectionResults;
