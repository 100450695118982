import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import OrangeSolidBtn from "../../../components/buttons/orangeSolidBtn";
import BlueBorderBtn from "../../../components/buttons/blueBorderBtn";
import TransparentBtn from "../../../components/buttons/transparentBtn";
import Cselect from "../../../components/select/cselect";

const ProfileMain = (props) => {
    const {
        cstomStyle,
        options,
        selectChange,
        value,
        selectedDistrictOption,
        options1,
        selectChange1,
        value1,
        credentials,
        handleChange,
        childrens,
        handleSubmit,
        handleSubmit1,
        handleCancel,
        handleToggle,
        handleImageClick,
        imagePreview,
        setShowModal,
    } = props;



    return (
        <main className="profile-main">
            <Container fluid className="container-profile">
                <Form>
                    <Row className="row-profile">
                        <Col md={5} className="col-profile">
                            <h4 className="profile-head">{props.labels.label1}</h4>
                            <p className="profile-content">{props.contents.content1}</p>
                        </Col>
                        <Col md={5} className="col-profile">
                            <div className="profileimage position-relative">

                                <Image src={imagePreview} className="profile-img" />

                                <span
                                    className="position-absolute top-0 end-0 badge rounded-pill bg-danger"
                                    onClick={handleImageClick}
                                    style={{ cursor: 'pointer' }}
                                >
                                    {props.icons.icon1}
                                </span>
                            </div>
                            {props.labels.label4}
                        </Col>
                    </Row>
                    <Row className="row-profile">
                        <Col md={5} className="col-profile">
                            <h4 className="profile-head">{props.labels.label2}</h4>
                            <p className="profile-content">{props.contents.content2}</p>
                        </Col>
                        <Col md={6} className="col-profile">
                            <Row className="row-sub-profile">
                                <Col md={10} className="col-sub-profile">
                                    <FloatingLabel controlId="name" label="Name">
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="Enter your name"
                                            minLength={2}
                                            maxLength={100}
                                            name="name"
                                            value={credentials.name}
                                            onChange={handleChange}
                                        />
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <Row className="row-sub-profile">
                                <Col md={10} className="col-sub-profile">
                                    <FloatingLabel controlId="username" label="User Name">
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="enter your user name"
                                            minLength={2}
                                            maxLength={100}
                                            name="username"
                                            value={credentials.username}
                                            onChange={handleChange}
                                            disabled
                                        />
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <Row className="row-sub-profile">
                                <Col md={10} className="col-sub-profile">
                                    <FloatingLabel controlId="email" label="Email">
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="Enter your name"
                                            minLength={2}
                                            maxLength={100}
                                            name="email"
                                            value={credentials.email}
                                            onChange={handleChange}
                                            disabled
                                        />
                                        {props.icons.icon3}
                                    </FloatingLabel>
                                </Col>
                                <Col md={2} className="col-sub-profile">
                                    <TransparentBtn
                                        Children="Update"
                                        onClick={() => setShowModal(true)}
                                    ></TransparentBtn>
                                </Col>
                            </Row>
                            <Row className="row-sub-profile">
                                <Col md={10} className="col-sub-profile">
                                    <FloatingLabel controlId="mobilenumber" label="Mobile Number">
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="Enter your name"
                                            minLength={2}
                                            maxLength={10}
                                            name="mobilenumber"
                                            value={credentials.mobileNumber}
                                            onChange={handleChange}
                                            disabled
                                        />
                                        {props.icons.icon4}
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <Row className="row-sub-profile">
                                <Col md={10} className="col-sub-profile">
                                    <FloatingLabel controlId="voterid" label="Voter ID">
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="Enter your name"
                                            minLength={2}
                                            maxLength={10}
                                            name="voterid"
                                            value={credentials.voter_id}
                                            onChange={handleChange}
                                        />
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <Row className="row-sub-profile">
                                <Col md={10} className="col-sub-profile">
                                    <FloatingLabel controlId="provincename" label="Province Name">
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="Province Name"
                                            minLength={2}
                                            maxLength={10}
                                            name="provincename"
                                            value={credentials.province_name}
                                            onChange={handleChange}
                                        />
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <Row className="row-sub-profile">
                                <Col md={10} className="col-sub-profile">
                                    <FloatingLabel controlId="districtname" label="District Name">
                                        <Cselect
                                            options={options}
                                            className="cselectpageSize1"
                                            // styles={cstomStyle}
                                            onChange={selectChange}
                                            value={value}
                                            placeholder="District Name"
                                            name="districtname"
                                        />
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            {selectedDistrictOption ? (
                                <Row className="row-sub-profile">
                                    <Col md={10} className="col-sub-profile">
                                        <FloatingLabel controlId="polingdivname" label="Polling Divisions Name">
                                            <Cselect
                                                options={options1}
                                                className="cselectpageSize1"
                                                // styles={cstomStyle}
                                                onChange={selectChange1}
                                                value={value1}
                                                placeholder="Polling Divisions Name"
                                                name="polingdivname"
                                            />
                                        </FloatingLabel>
                                    </Col>
                                </Row>
                            ) : null}
                            {/* <Row className="row-sub-profile">
                                <Col md={10} className="col-sub-profile">
                                    <FloatingLabel
                                        controlId="pollingdivisionname"
                                        label="Polling Division Name"
                                    >
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="Polling Division Name"
                                            minLength={2}
                                            maxLength={10}
                                            name="pollingdivisionname"
                                            value={credentials.polling_division_name}
                                            onChange={handleChange}
                                        />
                                    </FloatingLabel>
                                </Col>
                            </Row> */}
                            <Row className="row-sub-profile">
                                <Col md={10} className="col-sub-profile">
                                    <div className="form-check form-switch activeSwitch">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            role="switch"
                                            id="flexSwitchCheckChecked"
                                            checked={credentials.activated === 1}
                                            onChange={handleToggle}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="flexSwitchCheckChecked"
                                        >
                                            {" "}
                                            {credentials.activated === 1 ? "Active" : "In Active"}
                                        </label>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="row-sub-profile">
                                <Col className="col-sub-profile">
                                    <OrangeSolidBtn
                                        children={childrens.children1}
                                        onClick={handleSubmit}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="row-profile">
                        <Col md={5} className="col-profile">
                            <h4 className="profile-head">{props.labels.label3}</h4>
                            <p className="profile-content">{props.contents.content3}</p>
                        </Col>
                        <Col md={{ span: 2, offset: 5 }} className="col-profile">
                            <OrangeSolidBtn
                                children={childrens.children2}
                                onClick={handleSubmit1}
                            />
                        </Col>
                        {/* <Col md={5} className="col-profile">
                            <FloatingLabel controlId="password" label="Password">
                                <Form.Control
                                    required
                                    type="Password"
                                    placeholder="Enter your name"
                                    minLength={2}
                                    maxLength={100}
                                    name="password"
                                    value={credentials.password}
                                    onChange={handleChange}
                                />
                            </FloatingLabel>
                            <FloatingLabel
                                controlId="password_confirmation"
                                label="Confirm Password"
                            >
                                <Form.Control
                                    required
                                    type="Password"
                                    placeholder="Enter your name"
                                    minLength={2}
                                    maxLength={100}
                                    name="password_confirmation"
                                    value={credentials.password_confirmation}
                                    onChange={handleChange}
                                />
                            </FloatingLabel>
                        </Col> */}
                    </Row>
                    <Row className="row-profile">
                        <Col md={{ span: 2, offset: 5 }} className="col-profile">
                            <OrangeSolidBtn
                                children={childrens.children2}
                                onClick={handleSubmit1}
                            />
                        </Col>
                        <Col md={2} className="col-profile">
                            <BlueBorderBtn onClick={handleCancel}>Cancel</BlueBorderBtn>
                        </Col>
                    </Row>
                </Form>
            </Container >
        </main >
    );
};

export default ProfileMain;
