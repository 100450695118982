import React from "react";
import LinkBorderBtn from "../buttons/linkBorderBtn";
import Cselect from "../select/cselect";
import SearchBtnForm from "../form/searchBtnForm";
import ManageTable from "../table/manageTable";

const ManageBlk = (props) => {
  const {
    title,
    children,
    to,
    style,
    options,
    isSearchable,
    className,
    cstomStyle,
    Placeholder,
    loading,
    error,
    labels,
    data,
  } = props;

  return (
    <div className="manageBlock">
      <div className="mangeHead">
        <h2 className="manageHeadTitle">{title}</h2>
        <LinkBorderBtn children={children} to={to} style={style} />
      </div>
      <div className="mangeHeadss">
        <div className="d-flex align-items-center">
          <label htmlFor="pageSizeSelect" className="mangeSl">
            Page size :
          </label>
          <Cselect
            id="pageSizeSelect"
            options={options}
            isSearchable={isSearchable}
            className={className.cselectpageSize}
            styles={cstomStyle}
          />
        </div>
        <SearchBtnForm
          className={className.minputSearch}
          placeholder={Placeholder}
          style={{ padding: "1rem 3.5rem" }}
        />
      </div>
      <ManageTable
        data={data}
        loading={loading}
        error={error}
        labels={labels}
      />
    </div>
  );
};

export default ManageBlk;
