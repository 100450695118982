import React from "react";
import { FormControl } from "react-bootstrap";

const Input = (props) => {
  const { className, placeholder, name, onChange } = props;

  return (
    <FormControl
      type="text"
      id={name}
      name={name}
      placeholder={placeholder}
      onChange={onChange}
      className={`${className} inp`}
    />
  );
};

export default Input;
