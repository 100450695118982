import React from "react";
// import BlackBtn from "../buttons/blackBtn";
import BlackBorderBtn from "../buttons/blackBorderBtn";
import RedBorderBtn from "../buttons/redBorderBtn";
import TextForm from "../form/textForm";

const PostBlock = (props) => {
  const {
    title,
    addSeqBtnstyle,
    save,
    cancel,
    textInput,
    handleChange,
    handleSave,
    handleCancel,
  } = props;
  return (
    <div>
      <div className="PostBlock">
        <div className="PostBlockChild">
          <h2 className="PostBlockTitle">{title}</h2>
          <div className="">
            <BlackBorderBtn
              children1={save}
              style1={addSeqBtnstyle}
              onClick={handleSave}
            />
            <RedBorderBtn
              children1={cancel}
              style1={addSeqBtnstyle}
              onClick={handleCancel}
            />
          </div>
        </div>
        <div className="mt-5">
          {textInput.map((input, index) => (
            <TextForm
              key={index}
              formLabel={input.label}
              name={input.name}
              value={input.value}
              placeholder={input.placeholder}
              onChange={handleChange}
              error={input.error}
              disabled={input.disabled}
              Lsm={2}
              Ism={6}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default PostBlock;
