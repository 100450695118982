import React, { useEffect, useState } from "react";
import PostBlockTwo from "../../../components/admin/postBlockTwo";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../components/loaders/loader";
import {
  indexGetAllManageElections,
  indexGetAllElectionDetail,
  addManageElectionByElectionTypeId,
  updateManageElectionsById,
} from "../../../redux/adminone/action";
import { useLocation, useNavigate } from "react-router-dom";
import { useSnackbar } from "react-simple-snackbar";
import { formatElectionDate } from "../../../utilities/common";

const override = `
    display: block;
    margin: 0 auto;
    border-color: red;
`;
const cstomStyle = {
  menu: (provided) => ({
    ...provided,
    backgroundColor: "white",
    color: "#65676B",
    Width: "fit-content",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#efefef" : "white",
    color: state.isFocused ? "#65676B" : "#65676B",
    padding: 5,
  }),
};

const AddElectionType = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openSnackbar] = useSnackbar();
  const location = useLocation();
  const { id, edit } = location.state || {};
  // const params = new URLSearchParams(location.search);
  // const id = params.get("id");
  // const edit = edit;

  useEffect(() => {
    dispatch(indexGetAllElectionDetail());
  }, [dispatch]);

  useEffect(() => {
    if (id) {
      dispatch(indexGetAllManageElections());
    }
  }, [dispatch, id]);

  const indexAllElectionDetailsData = useSelector(
    (state) => state.adminone.indexAllElectionDetailsData?.data
  );
  const indexAllManageElectionData = useSelector(
    (state) => state.adminone.indexAllManageElectionData?.data
  );

  const [options, setOptions] = useState([]);
  const [electionTypeId, setElectionTypeId] = useState(null);
  const [defaultElectionTypeId, setDefaultElectionTypeId] = useState(null);
  const loading = useSelector((state) => state.adminone.loading);
  const [formData, setFormData] = useState({
    election_title: "",
    poll_start_date: "",
    poll_end_date: "",
    election_start_date: "",
    election_end_date: "",
  });
  const [errors, setErrors] = useState({
    election_title: "",
    poll_start_date: "",
    poll_end_date: "",
    election_start_date: "",
    election_end_date: "",
  });

  useEffect(() => {
    if (indexAllElectionDetailsData) {
      const newOptions = indexAllElectionDetailsData.map((item) => ({
        value: item.id,
        label: item.type_of_election_name,
      }));
      setOptions(newOptions);
    }
  }, [indexAllElectionDetailsData]);

  useEffect(() => {
    if (id && indexAllManageElectionData) {
      const foundToe = indexAllManageElectionData.find(
        (toe) => toe.id === parseInt(id)
      );

      if (foundToe) {
        setFormData({
          election_title: foundToe.election_title,
          poll_start_date: foundToe.poll_start_date,
          poll_end_date: foundToe.poll_end_date,
          election_start_date: foundToe.election_start_date,
          election_end_date: foundToe.election_end_date,
        });

        setDefaultElectionTypeId({
          value: foundToe.election_type_id,
          label: foundToe.type_of_election_name,
        });

        setElectionTypeId(foundToe.election_type_id);
      }
    }
  }, [id, indexAllManageElectionData]);

  const selectChange = (selectedOption) => {
    setElectionTypeId(selectedOption.value);
    setDefaultElectionTypeId(selectedOption);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const handleDateChange = (name, date) => {
    console.log("Name:", name);
    console.log("Date:", date);

    const updatedFormData = {
      ...formData,
      [name]: date,
    };

    // Validation logic
    if (updatedFormData.election_start_date && updatedFormData.election_end_date) {
      const startDate = new Date(updatedFormData.election_start_date);
      const endDate = new Date(updatedFormData.election_end_date);

      if (startDate > endDate) {
        setErrors({
          ...errors,
          election_end_date: "Election end date must be after election start date.",
        });
        alert("Election end date must be after election start date.");
        return;
      } else {
        setErrors({
          ...errors,
          election_end_date: "",
        });
      }
    }
    if (updatedFormData.poll_start_date && updatedFormData.poll_end_date) {
      const startDate = new Date(updatedFormData.poll_start_date);
      const endDate = new Date(updatedFormData.poll_end_date);

      if (startDate > endDate) {
        setErrors({
          ...errors,
          poll_end_date: "Exit poll end date must be after start date.",
        });
        alert("Exit poll end date must be after start date.");
        return;
      } else {
        setErrors({
          ...errors,
          poll_end_date: "",
        });
      }
    }

    setFormData(updatedFormData);
  };

  const handleSave = async () => {
    const {
      election_title,
      poll_start_date,
      poll_end_date,
      election_start_date,
      election_end_date,
    } = formData;

    let valid = true;
    let newErrors = {};

    if (!election_title || election_title.length < 2) {
      newErrors.election_title = "Election title must be at least 2 characters long.";
      valid = false;
    }

    if (!poll_start_date) {
      newErrors.poll_start_date = "Poll start date is required.";
      valid = false;
    }

    if (!poll_end_date) {
      newErrors.poll_end_date = "Poll end date is required.";
      valid = false;
    }

    if (!election_start_date) {
      newErrors.election_start_date = "Election start date is required.";
      valid = false;
    }

    if (!election_end_date) {
      newErrors.election_end_date = "Election end date is required.";
      valid = false;
    }

    // Validate date range
    if (election_start_date && election_end_date) {
      const startDate = new Date(election_start_date);
      const endDate = new Date(election_end_date);

      if (startDate > endDate) {
        newErrors.election_end_date = "Election end date must be after election start date.";
        valid = false;
      }
    }

    setErrors(newErrors);

    if (!valid) return;

    const formDataElectionTypeId = new FormData();
    formDataElectionTypeId.append("election_title", election_title);
    formDataElectionTypeId.append("poll_start_date", formatElectionDate(poll_start_date));
    formDataElectionTypeId.append("poll_end_date", formatElectionDate(poll_end_date));
    formDataElectionTypeId.append("election_start_date", formatElectionDate(election_start_date));
    formDataElectionTypeId.append("election_end_date", formatElectionDate(election_end_date));
    formDataElectionTypeId.append("election_type_id", electionTypeId);

    if (id) {
      dispatch(
        updateManageElectionsById(
          formDataElectionTypeId,
          id,
          (error, response) => {
            if (error?.status === 422) {
              openSnackbar(error?.data.message.election_start_date);
            } else if (response?.status === 200) {
              openSnackbar(response?.data.message);
              navigate("/type-of-election");
            }
          }
        )
      );
    } else {
      dispatch(
        addManageElectionByElectionTypeId(
          formDataElectionTypeId,
          (error, response) => {
            if (error?.status === 422) {
              openSnackbar(error?.data.message?.election_title[0]);
            } else if (response.status === 200) {
              openSnackbar(response.data.message);
              navigate("/type-of-election");
            }
          }
        )
      );
    }

    setFormData({
      election_title: "",
      poll_start_date: "",
      poll_end_date: "",
      election_start_date: "",
      election_end_date: "",
    });
  };

  const handleCancel = () => {
    navigate("/type-of-election");
  };

  const props = {
    title: edit ? "Edit Manage Election" : "Manage Election",
    selectLable: "Election Type",
    dateLable1: "Election Date",
    dateLable2: "Poll Date",
    saveNextBtn: "Save and Next",
    addSeqBtnstyle: { padding: "1.3rem 2.8rem", marginLeft: "1.5rem" },
    save: "save",
    cancel: "cancel",
    textInput: [
      {
        label: "Election Title",
        name: "election_title",
        value: formData.election_title,
        error: errors.election_title,
        placeholder: "Enter Election Title",
      },
    ],
    className: {
      cselectpageSize: "cselectpageSize",
      cselectpageSizeLable: "cselectpageSizeLable",
    },
    options,
    cstomStyle,
    selectChange,
    handleChange,
    handleSave,
    handleCancel,
    placeholderStart1: "Enter Election Start Date",
    placeholderEnd1: "Enter Election End Date",
    startName1: "election_start_date",
    startValue1: formData.election_start_date,
    onChangeStart1: (date) => {
      handleDateChange("election_start_date", date);
    },
    endName1: "election_end_date",
    endValue1: formData.election_end_date,
    onChangeEnd1: (date) => {
      handleDateChange("election_end_date", date);
    },
    startName2: "poll_start_date",
    startValue2: formData.poll_start_date,
    onChangeStart2: (date) => {
      handleDateChange("poll_start_date", date);
    },
    endName2: "poll_end_date",
    endValue2: formData.poll_end_date,
    onChangeEnd2: (date) => {
      handleDateChange("poll_end_date", date);
    },
    value: defaultElectionTypeId,
  };

  return (
    <main className="aElection">
      <aside className="addbock">
        <PostBlockTwo {...props} />
      </aside>
      {loading ? (
        <Loader color={"#0E76A8"} loading={loading} css={override} size={75} />
      ) : null}
    </main>
  );
};

export default AddElectionType;
