import React from "react";
import BlackBorderBtn from "../buttons/blackBorderBtn";
import RedBorderBtn from "../buttons/redBorderBtn";
import TextForm from "../form/textForm";
import HtmlEditorRow from "../editor/htmlEditorRow";
import PlusButton from "../buttons/plusButton";
import MinusButton from "../buttons/minusButton";
import { Col, Row } from "react-bootstrap";

const PostBlockFive = (props) => {
    const {
        title,
        addSeqBtnstyle,
        save,
        handleSave,
        cancel,
        handleCancel,
        blocks,
        handleChange,
        addBlock,
        removeBlock,
    } = props;

    return (
        <div className="postBlock-five">
            <div className="PostBlockChild">
                <h2 className="PostBlockTitle">{title}</h2>
                <div className="">
                    <BlackBorderBtn
                        children1={save}
                        style1={addSeqBtnstyle}
                        onClick={handleSave}
                    />
                    <RedBorderBtn
                        children1={cancel}
                        style1={addSeqBtnstyle}
                        onClick={handleCancel}
                    />
                </div>
            </div>
            {blocks.map((block, index) => (
                <div
                    className="mt-5"
                    key={index}
                    id={block.id !== "null" ? block.id : "null"}
                >
                    <TextForm
                        formLabel="Title"
                        name="title"
                        value={block.title}
                        placeholder="Enter title"
                        onChange={(e) => handleChange(index, e.target.name, e.target.value)}
                        Lsm={1}
                        Ism={10}
                    />
                    <HtmlEditorRow
                        label="Content"
                        value={block.content}
                        onChange={(html) => handleChange(index, "content", html)}
                        removeBlock={() => removeBlock(index)}
                    />
                    {/* <Row className="mt-3">
                        <Col md={{ span: 1, offset: 10 }} className="text-end">
                            <MinusButton onClick={() => removeBlock(index)} />
                        </Col>
                    </Row> */}
                </div>
            ))}
            <PlusButton onClick={addBlock} />
        </div>
    );
};

export default PostBlockFive;
