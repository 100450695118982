import React from "react";
import { Button } from "react-bootstrap";

const WhiteBtnWithBorder = ({ children, style, onClick, disabled, type }) => {
  return (
    <Button
      type={type}
      disabled={disabled}
      style={style}
      onClick={onClick}
      className="whiteBorderBtn"
    >
      {children}
    </Button>
  );
};

export default WhiteBtnWithBorder;
