import React from 'react';
import { Button } from 'react-bootstrap';

const BorderBlueBtn = ({ children, style, onClick, disabled, type, className }) => {
    return (
        <Button type={type} disabled={disabled} style={style} onClick={onClick} className={`${className} borderBlueBtn`}>{children}</Button>
    )
}

export default BorderBlueBtn;
