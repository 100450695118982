import React, { useEffect } from "react";
import ImageUploading from "react-images-uploading";
import { AiOutlineUpload } from "react-icons/ai";
import { MdOutlineCancel } from "react-icons/md";
import { Col, Container, Row } from "react-bootstrap";
import { cApiUrlPrefix } from "../../config/envConfig";
import { useSnackbar } from "react-simple-snackbar"; // Import useSnackbar

const UploadImage = ({ label, style, onUpload, initialImageUrl }) => {
  const [images, setImages] = React.useState([]);
  const maxNumber = 69;
  const [openSnackbar] = useSnackbar(); // Initialize the snackbar

  useEffect(() => {
    if (initialImageUrl) {
      if (typeof initialImageUrl === "string") {
        setImages([{ data_url: `${cApiUrlPrefix}${initialImageUrl}` }]);
      } else if (initialImageUrl instanceof File) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setImages([{ data_url: reader.result }]);
        };
        reader.readAsDataURL(initialImageUrl);
      }
    }
  }, [initialImageUrl]);

  const onChange = (imageList) => {
    setImages(imageList);
    onUpload(imageList);
  };

  const handleImageRemove = (index) => {
    const newImageList = images.filter((_, i) => i !== index);
    setImages(newImageList);
    onUpload(newImageList);
  };

  return (
    <Container fluid className="px-0">
      <Row className="uploadimageRow" style={style}>
        <Col sm={2} className="uploadimageLabel mt-3">
          <label className="textformlabel" htmlFor="">
            {label}
          </label>
        </Col>
        <Col sm={3}>
          <ImageUploading
            value={images}
            onChange={onChange}
            maxNumber={maxNumber}
            dataURLKey="data_url"
          >
            {({ imageList, onImageUpload, isDragging, dragProps, errors }) => (
              <div className="upload__image-wrapper">
                <button
                  className={`upload-button ${isDragging ? "dragging" : ""}`}
                  onClick={onImageUpload}
                  {...dragProps}
                  type="button"
                >
                  <AiOutlineUpload size={20} style={{ marginRight: "8px" }} />
                  Upload
                </button>
                {errors && (
                  <div className="error-messages">
                    {errors.maxNumber && (
                      openSnackbar("Number of selected images exceeds maxNumber")
                    )}
                    {errors.acceptType && (
                      openSnackbar("Your selected file type is not allowed")
                    )}
                    {errors.maxFileSize && (
                      openSnackbar("Selected file size exceeds maxFileSize")
                    )}
                    {errors.resolution && (
                      openSnackbar(
                        "Selected file does not match your desired resolution"
                      )
                    )}
                  </div>
                )}
                <div className="image-list mt-4">
                  {imageList.map((image, index) => (
                    <div
                      key={index}
                      className="image-item"
                      onClick={() => handleImageRemove(index)}
                    >
                      <div className="image-container">
                        <img
                          src={image.data_url}
                          alt="Uploaded"
                          className="uploaded-image"
                        />
                      </div>
                      <MdOutlineCancel className="remove-icon" />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </ImageUploading>
        </Col>
      </Row>
    </Container>
  );
};

export default UploadImage;
