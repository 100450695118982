import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import SolidBlueBtn from "../../../components/buttons/solidBlueBtn";
import { Link } from "react-router-dom";
import { Container, Image } from "react-bootstrap";
import Logo from "../../../assets/images/logo.png";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { registerUser } from "../../../redux/user/action";
import Loader from "../../../components/loaders/loader";

const override = `
    display: block;
    margin: 0 auto;
    border-color: red;
`;

function Register() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [validated, setValidated] = useState(false);
  const loading = useSelector((state) => state.user.loading);
  const [credentials, setCredentials] = useState({
    name: "",
    username: "",
    email: "",
    mobileNumber: "",
    password: "",
    password_confirmation: "",
  });

  useEffect(() => {
    const handleBackButton = (event) => {
      event.preventDefault();
      window.history.pushState(null, null, window.location.pathname);
    };

    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;

    setCredentials({
      ...credentials,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      return;
    }

    const formData = new FormData();
    formData.append("name", credentials.name);
    formData.append("username", credentials.username);
    formData.append("email", credentials.email);
    formData.append("mobileNumber", credentials.mobileNumber);
    formData.append("password", credentials.password);
    formData.append("password_confirmation", credentials.password_confirmation);

    dispatch(
      registerUser(formData, (error, response) => {
        if (error && error.status === 400) {

          Object.keys(error.data.errors).forEach((key) => {
            error.data.errors[key].forEach((errorMessage) => {
              toast.error(`${errorMessage}`, {
                position: "top-center",
                style: {
                  fontSize: "1.6rem",
                },
              });
            });
          });
          navigate("/login-perefrance");
        } else {
          // handle the response

          if (response && response.status === 200) {
            localStorage.setItem("email", credentials.email);
            toast.success(response.data.message, {
              position: "top-center",
              style: {
                fontSize: "1.6rem", // Adjust the font size as needed
              },
            });
            navigate("/otp");
          }
        }
      })
    );
  };
  return (
    <main className="main-auth">
      <Container fluid="xxl">
        <div className="registerMain">
          <div className="w-100">
            <figure className="text-center ">
              <Image src={Logo} fluid />
            </figure>
            <h2 className="authHead">Registration</h2>
            <p className="authPara">
              Welcome to o'Democracy, Please enter details below
            </p>
          </div>
          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
            className="register"
          >
            <FloatingLabel
              controlId="idName"
              label="Name"
              className={validated && !credentials.name ? "is-invalid" : ""}
            >
              <Form.Control
                required
                type="text"
                placeholder="Enter your name"
                minLength={2}
                maxLength={100}
                name="name"
                value={credentials.name}
                onChange={handleChange}
                isValid={validated && credentials.name}
                isInvalid={
                  validated &&
                  (!credentials.name ||
                    credentials.name.trim().length === 0 ||
                    credentials.name.length < 2 ||
                    credentials.name.length > 100)
                }
              />
            </FloatingLabel>

            <FloatingLabel
              controlId="idUserName"
              label="Username"
              className={validated && !credentials.username ? "is-invalid" : ""}
            >
              <Form.Control
                required
                type="text"
                placeholder="Enter your username"
                name="username"
                minLength={2}
                maxLength={100}
                value={credentials.username}
                onChange={handleChange}
                isValid={validated && credentials.username}
                isInvalid={
                  validated &&
                  (!credentials.username ||
                    credentials.username.trim().length === 0 ||
                    credentials.username.length < 2 ||
                    credentials.username.length > 100)
                }
              />
            </FloatingLabel>

            <FloatingLabel
              controlId="idEmail"
              label="Email"
              className={validated && !credentials.email ? "is-invalid" : ""}
            >
              <Form.Control
                required
                type="email"
                placeholder="Enter your email"
                name="email"
                value={credentials.email}
                onChange={handleChange}
                isValid={validated && credentials.email}
                isInvalid={
                  validated &&
                  (!credentials.email ||
                    credentials.email.trim().length === 0 ||
                    !/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/.test(credentials.email))
                }
              />
            </FloatingLabel>

            <FloatingLabel
              controlId="idMnumber"
              label="Phone Number"
              className={
                validated && !credentials.mobileNumber ? "is-invalid" : ""
              }
            >
              <Form.Control
                required
                type="tel"
                placeholder="Enter your mobileNumber"
                minLength={10}
                maxLength={10}
                name="mobileNumber"
                value={credentials.mobileNumber}
                onChange={handleChange}
                isValid={validated && credentials.mobileNumber}
                isInvalid={
                  validated &&
                  (!credentials.mobileNumber ||
                    credentials.mobileNumber.trim().length === 0 ||
                    !/^\d{10}$/.test(credentials.mobileNumber))
                }
              />
            </FloatingLabel>

            <FloatingLabel
              controlId="idPassword"
              label="Password"
              className={validated && !credentials.password ? "is-invalid" : ""}
            >
              <Form.Control
                required
                type="password"
                placeholder="Enter your password"
                minLength={9}
                name="password"
                value={credentials.password}
                onChange={handleChange}
                isValid={validated && credentials.password}
                isInvalid={
                  validated &&
                  (!credentials.password ||
                    credentials.password.trim().length === 0 ||
                    !/^(?=.*[A-Za-z])(?=.*\d)(?=.*[^A-Za-z\d])[\s\S]{9,}$/.test(
                      credentials.password
                    ))
                }
              />
            </FloatingLabel>

            <FloatingLabel
              controlId="idCPassword"
              label="Confirm Password"
              className={
                validated && !credentials.password_confirmation
                  ? "is-invalid"
                  : ""
              }
            >
              <Form.Control
                required
                type="text"
                name="password_confirmation"
                placeholder="Enter your password_confirmation"
                minLength={9}
                value={credentials.password_confirmation}
                onChange={handleChange}
                isValid={validated && credentials.password_confirmation}
                isInvalid={
                  validated &&
                  (!credentials.password_confirmation ||
                    credentials.password_confirmation.trim().length === 0 ||
                    credentials.password !== credentials.password_confirmation)
                }
              />
            </FloatingLabel>

            <SolidBlueBtn
              type="submit"
              style={{
                padding: "1.3rem 6.8rem",
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "3.2rem",
              }}
            >
              Verify Email
            </SolidBlueBtn>
          </Form>
          <p className="lLinkBlk">
            Don't have an account?{" "}
            <Link to="/login" className="gLink">
              Click here
            </Link>
          </p>
        </div>
      </Container>
      {loading ? (
        <Loader color={"#0E76A8"} loading={loading} css={override} size={75} />
      ) : null}
    </main>
  );
}

export default Register;
