import React, { useEffect, useState } from "react";
import AddPartyHero from "./addPartyHero";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "react-simple-snackbar";

import {
  addParty,
  showPartyById,
  updatePartyById,
} from "../../../redux/adminone/action";
import { useNavigate, useLocation } from "react-router-dom";
import Loader from "../../../components/loaders/loader";

const AddParty = ({ initialValues }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { id, party_name, status } = location.state || {};
  const isEditing = location.pathname.includes("edit");
  // const queryParams = new URLSearchParams(location.search);
  // const id = queryParams.get("id");
  // const initialPartyName = queryParams.get("party_name") || "";
  const [openSnackbar] = useSnackbar();
  const [formData, setFormData] = useState({
    party_name: party_name,
    party_abbreviation: initialValues?.party_abbreviation ?? "",
    logo: initialValues?.logo ?? null,
    founding_date: initialValues?.founding_date ?? "",
    headquarters: initialValues?.headquarters ?? "",
    leader: initialValues?.leader ?? "",
    website: initialValues?.website ?? "",
    ideology: initialValues?.ideology ?? "",
    status: initialValues?.status ?? "",
  });

  const [errors, setErrors] = useState({
    party_name: "",
    party_abbreviation: "",
    logo: null,
    founding_date: "",
    headquarters: "",
    leader: "",
    website: "",
    ideology: "",
    status: "",
  });

  const loading = useSelector((state) => state.adminone.loading);
  const showPartyByIdData = useSelector(
    (state) => state.adminone.showPartyByIdData?.data
  );

  useEffect(() => {
    if (isEditing && id) {
      dispatch(showPartyById(id));
    }
  }, [dispatch, id, isEditing]);

  useEffect(() => {
    if (isEditing && id && showPartyByIdData) {
      setFormData({
        party_name: showPartyByIdData.party_name ?? "",
        party_abbreviation: showPartyByIdData.party_abbreviation ?? "",
        logo: showPartyByIdData.logo ?? "",
        founding_date: showPartyByIdData.founding_date ?? "",
        headquarters: showPartyByIdData.headquarters ?? "",
        leader: showPartyByIdData.leader ?? "",
        website: showPartyByIdData.website ?? "",
        ideology: showPartyByIdData.ideology ?? "",
        status: showPartyByIdData.status ?? "",
      });
    }
  }, [id, isEditing, showPartyByIdData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const handleUpload = (imageList) => {
    if (imageList.length > 0) {
      setFormData((prevData) => ({ ...prevData, logo: imageList[0].file }));
    } else {
      setFormData((prevData) => ({ ...prevData, logo: null }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        logo: "Party logo is required.",
      }));
    }
  };

  const handleClick = async () => {
    const {
      party_name,
      party_abbreviation,
      logo,
      founding_date,
      headquarters,
      leader,
      website,
      ideology,
      status,
    } = formData;

    const isLogoUpdated = showPartyByIdData?.logo !== logo;

    let valid = true;
    let newErrors = {};

    const formattedFoundingDate = new Date(founding_date);
    if (isNaN(formattedFoundingDate.getTime())) {
      return;
    }
    const isoFormattedFoundingDate = formattedFoundingDate
      .toISOString()
      .split("T")[0];

    if (!party_name || party_name.length < 2) {
      newErrors.party_name = "Party name must be at least 2 characters long.";
      valid = false;
    }
    if (!logo) {
      newErrors.logo = "Party logo is required.";
      valid = false;
    }
    if (!party_abbreviation || party_abbreviation.length < 2) {
      newErrors.party_abbreviation =
        "Party abbreviation must be at least 2 characters long.";
      valid = false;
    }
    if (!founding_date) {
      newErrors.founding_date = "Founding date is required.";
      valid = false;
    }
    if (!headquarters || headquarters.length < 2) {
      newErrors.headquarters =
        "Headquarters name must be at least 2 characters long.";
      valid = false;
    }
    if (!leader || leader.length < 2) {
      newErrors.leader = "Leader name must be at least 2 characters long.";
      valid = false;
    }
    if (!website || website.length < 2) {
      newErrors.website = "Website name must be at least 2 characters long.";
      valid = false;
    }
    if (!ideology || ideology.length < 2) {
      newErrors.ideology = "Ideology name must be at least 2 Words long.";
      valid = false;
    }

    setErrors(newErrors);

    if (!valid) return;

    const formDataCandidate = new FormData();
    formDataCandidate.append("party_name", party_name);
    formDataCandidate.append("party_abbreviation", party_abbreviation);
    formDataCandidate.append("founding_date", isoFormattedFoundingDate);

    formDataCandidate.append("headquarters", headquarters);
    formDataCandidate.append("leader", leader);
    formDataCandidate.append("website", website);
    formDataCandidate.append("ideology", ideology);
    formDataCandidate.append("status", status);

    if (isLogoUpdated) {
      formDataCandidate.append("logo", logo);
    }

    if (isEditing) {
      formDataCandidate.append("id", id);
      dispatch(
        updatePartyById(formDataCandidate, id, (error, response) => {
          if (error) {
          } else {
            if (response.status === 200) {
              openSnackbar(response.data.message);
              navigate("/manage-party");
            }
          }
        })
      );
    } else {
      dispatch(
        addParty(formDataCandidate, (error, response) => {
          if (error) {
            openSnackbar("Error occurred while adding party");
          } else {
            if (response.status === 200) {
              openSnackbar(response.data.message);
              navigate("/manage-party");
            }
          }
        })
      );
    }

    if (!isEditing) {
      setFormData({
        party_name: "",
        party_abbreviation: "",
        logo: null,
        founding_date: "",
        headquarters: "",
        leader: "",
        website: "",
        ideology: "",
        status: "",
      });
    }
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const handleDateChange = (name, date) => {
    setFormData({
      ...formData,
      [name]: date,
    });
  };

  const props = {
    title1: isEditing ? "Edit Party" : "Add Party",
    blackbtnChild: "Save and Next",
    blackBtnStyle: {
      padding: "1.3rem 2.8rem",
    },
    blackBorderBtnChild: "Save",
    blackBorderBtnTo: "",
    blackBorderBtnStyle: {
      padding: "1.3rem 2.8rem",
      marginLeft: "1.5rem",
    },
    redBorderBtnChild: "Cancel",
    redBorderBtnTo: "/manage-party",
    redBorderBtnStyle: {
      padding: "1.3rem 2.8rem",
      marginLeft: "1.5rem",
    },
    textFormLabel: isEditing ? "Edit Party Name" : "Add Party Name",
    textFormPlaceholder: "Aam aadmi party",
    textFormStyle1: {
      marginTop: "3rem",
    },
    textFormLabel1: "Party Abbreviation",
    textFormPlaceholder1: "AAP",
    uploadLabel: "Upload Image",
    uploadStyle: {
      marginTop: "3rem",
    },

    className: {
      cselectpageSize: "cselectpageSize",
      cselectpageSizeLable: "cselectpageSizeLable",
    },

    dateLable1: "Founding Date",
    startName1: "founding_date",
    startValue1: formData.founding_date,
    onChangeStart1: (date) => handleDateChange("founding_date", date),
    placeholderStart1: "Enter Founding Date",

    party_name_value: formData.party_name,

    party_abbreviation_value: formData.party_abbreviation,
    onUpload: handleUpload,
    onChange: handleChange,
    onClick: handleClick,
    handleCancel,

    textFormPlaceholder2: "leader name",
    textFormLabel2: "Leader",
    textFormStyle2: {
      marginTop: "3rem",
    },
    leader_value: formData.leader,

    textFormStyle3: {
      marginTop: "3rem",
    },
    textFormLabel3: "Headquarter",
    textFormPlaceholder3: "headquarter name",
    headquarters_value: formData.headquarters,

    textFormStyle4: {
      marginTop: "3rem",
    },
    textFormLabel4: "Website",
    textFormPlaceholder4: "website",
    website_value: formData.website,

    textFormStyle5: {
      marginTop: "3rem",
    },
    textFormLabel5: "Ideology",
    textFormPlaceholder5: "Enter Text Here",
    ideology_value: formData.ideology,

    // logo: showPartyByIdData?.logo,
    logo: isEditing ? showPartyByIdData?.logo : formData.logo,
    textFormLabel6: "Upload PDF",
  };

  return (
    <main className="aParty">
      <form className="aParty-hero">
        <AddPartyHero {...props} disabled={isEditing} />
        {loading ? (
          <Loader color={"#0E76A8"} loading={loading} size={75} />
        ) : null}
      </form>
    </main>
  );
};

export default AddParty;
