import React, { useEffect, useState } from "react";
import IImage from "../../../components/image/iImage";
import ManageBlk from "../../../components/admin/manageBlk";
import { useDispatch, useSelector } from "react-redux";
import InActive from "../../../components/labels/inActive";
import Active from "../../../components/labels/active";
import DropDown from "../../../components/dropdown/dropDown";
import DeleteCModal from "../../../components/modals/deleteCModal";
import { useSnackbar } from "react-simple-snackbar";
import {
  delPartyById,
  indexParties,
  showPartyById,
} from "../../../redux/adminone/action";
import { useNavigate } from "react-router-dom";

const options = [
  { value: 10, label: 10 },
  { value: 20, label: 20 },
  { value: 30, label: 30 },
];

const cstomStyle = {
  menu: (provided) => ({
    ...provided,
    backgroundColor: "white",
    color: "#65676B",
    maxWidth: "97px",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#efefef" : "white",
    color: state.isFocused ? "#65676B" : "#65676B",
    padding: 5,
  }),
};

const ManageParty = () => {
  const dispatch = useDispatch();
  const indexPartiesData = useSelector((state) => state.adminone.indexPartiesData?.data);
  const error = useSelector((state) => state.adminone.error);
  const [openSnackbar, closeSnackbar] = useSnackbar();

  const navigate = useNavigate();

  useEffect(() => {

    dispatch(indexParties());
  }, [dispatch]);

  const [isSearchable] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedParty, setSelectedParty] = useState(null);




  const statusMap = {
    0: <InActive />,
    1: <Active />,
  };

  const dropdownItems = (data) => [
    {
      label: "Edit",
      href: "#/edit",
      onClick: () => handleEditParty(data),
    },
    { label: "Delete", href: "#/delete", onClick: () => handleOpenModal(data) },
    {
      label: "Manage Associated Candidates",
      href: "#/action-3",
      onClick: () => handleCandidateById(data),
    },
    {
      label: "Manage party history",
      href: "#/action-4",
      onClick: () => handleHistory(data),
    },
  ];

  const handleOpenModal = (data) => {
    setSelectedParty(data);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedParty(null);
  };

  const handleConfirmDelete = () => {
    if (selectedParty) {
      const id = selectedParty.id;
      dispatch(
        delPartyById(id, (error, response) => {
          if (error) {
          } else {
            if (response.status === 200) {
              dispatch(indexParties());
              openSnackbar(response.data.message);

            }
          }
        })
      );
      handleCloseModal();

    }
  };

  const handleCandidateById = (data) => {
    navigate("/manage-associated-candidate-by-id", {
      state: {
        id: data.id,
        party_name: data.party_name,
        status: data.status
      }
    });
    // navigate(
    //   `/manage-associated-candidate-by-id?id=${data.id
    //   }&party_name=${encodeURIComponent(data.party_name)}&status=${data.status}`
    // );
  };

  const handleEditParty = (data) => {

    dispatch(showPartyById(data.id));
    navigate("/edit-party-by-id", {
      state: {
        id: data.id,
        party_name: data.party_name,
        status: data.status
      }
    });
    // navigate(
    //   `/edit-party-by-id?id=${data.id}&party_name=${encodeURIComponent(
    //     data.party_name
    //   )}&status=${data.status}`
    // );
  };

  const handleHistory = (data) => {
    navigate("/party-history", {
      state: {
        id: data.id,
        party_name: data.party_name,
        status: data.status
      }
    });

    // navigate(
    //   `/party-history?id=${data.id}&party_name=${encodeURIComponent(
    //     data.party_name
    //   )}&status=${data.status}`
    // );
  };

  const labels = [
    "Logo",
    "Party Name",
    "Party Abbreviation",
    "Party Status",
    "Action",
  ];
  const data = Array.isArray(indexPartiesData)
    ? indexPartiesData.map((permission) => ({
      Logo: (
        <figure className="mpLogo">
          <IImage src={permission.logo} className="mpLogoImg" />
        </figure>
      ),
      "Party Name": permission.party_name,
      "Party Abbreviation": permission.party_abbreviation,
      "Party Status": statusMap[permission.status],
      Action: (
        <DropDown
          Children={"Action"}
          items={dropdownItems(permission)}
          className="managPartyAction ms-auto"
        />
      ),
    }))
    : [];

  const managePartyBlkProps = {
    title: "Party Master",
    children: "Add Party",

    to: "/add-party",

    style: { padding: "1.3rem 2.8rem" },
    options,
    isSearchable,
    className: {
      cselectpageSize: "cselectpageSize",
      minputSearch: "minputSearch",
      deleteModal: "deleteModal",
    },
    cstomStyle,
    Placeholder: "Search",
    labels,
    data,
  };
  return (
    <main className="mParty">
      <aside className="pmParty">
        <ManageBlk {...managePartyBlkProps} />
        {error ? <p> 404 Not found</p> : null}
      </aside>
      <DeleteCModal
        show={showModal}
        handleClose={handleCloseModal}
        handleConfirm={handleConfirmDelete}
        className={managePartyBlkProps.className.deleteModal}
      />
    </main>
  );
};

export default ManageParty;
