import React from 'react';
import DatePicker from 'react-date-picker';

const DatePickerCustom = ({ onChange, value, name, placeholder }) => {
    return (
        <DatePicker
            format="dd MM y"
            closeCalendar
            onChange={onChange}
            value={value} 
            name={name} 
            className="datepickercustom"
            placeholder={placeholder}
            />
    )
}

export default DatePickerCustom;
