import React, { useEffect } from "react";
import LandingMain from "./landingMain";
import heroImg from "../../../assets/images/heroImg.png";
import heroImg1 from "../../../assets/images/aboutImgOne.png";
import heroImg2 from "../../../assets/images/voterImg.png";
import heroImg3 from "../../../assets/images/eduvoter.png";
import src1 from "../../../assets/svg/account.svg";
import src2 from "../../../assets/svg/election.svg";
import src3 from "../../../assets/svg/vote.svg";
import src4 from "../../../assets/svg/track.svg";
import src5 from "../../../assets/svg/arrow.svg";
import src6 from "../../../assets/svg/wavylines.svg";
import src7 from "../../../assets/svg/selection.svg";
import src8 from "../../../assets/svg/analyzing.svg";
import src9 from "../../../assets/svg/data-modelling.svg";
import src10 from "../../../assets/svg/Learn and improvement.svg";
import src11 from "../../../assets/svg/unique.svg";
import src12 from "../../../assets/svg/ai-powered-models.svg";
import src13 from "../../../assets/svg/Eye.svg";
import src14 from "../../../assets/svg/Mission.svg";
import src15 from "../../../assets/svg/Promis.svg";
import src16 from "../../../assets/svg/empowerment.svg";
import src17 from "../../../assets/svg/technique.svg";
import src18 from "../../../assets/svg/global-marketing.svg";
import src19 from "../../../assets/svg/last.svg";
import { IoCheckmarkCircleSharp } from "react-icons/io5";
import {
  MdKeyboardDoubleArrowRight,
  MdOutlineCampaign,
  MdOutlineHowToVote,
} from "react-icons/md";
import { BsArrowRight } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { indexGetAllBlogContent } from "../../../redux/adminone/action";

const LandingPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(indexGetAllBlogContent());
  }, [dispatch]);

  const handleNavigate = () => {
    navigate("/select-election");
  };

  const handleNavigate2 = () => {
    navigate("/simulator");
  };
  const handleClick = () => {
    navigate("/blogs");
  };

  const getAllBlogContent = useSelector(
    (state) => state.adminone.getAllBlogContent?.data
  );

  // const data = Array.isArray(getAllBlogContent)
  //   ? getAllBlogContent.map((categoryList) => {
  //       return {
  //         "Category Name": categoryList.category_name,
  //         created_at: categoryList.created_at,
  //         blog_image: categoryList.blog_image,
  //         "Blog Title": categoryList.blog_title,
  //         overview: categoryList.overview,
  //       };
  //     })
  //   : [];

  const props = {
    titles: {
      title1: "Welcome to O'Democracy",
      title3: "Vision Statement",
      title4: "Mission Statement",
      title5: "How It Works",
      title6: "About",
      title7:
        "Election Information: Stay Updated on Dates, Candidates, and Polling Locations",
      title8: "Voter Education",
      title9: "Predict Election using Simulator",
      title10: "LATEST BLOGS",
      title11: "Our Approach",
      title12: "Our Commitment",
      title13: "Soon, the O'Democracy will feature AI integration.",
    },
    lables: {
      lable1: "AI Integration Coming Soon",
      lable2: "About O'Democracy",
    },
    categoryName: {
      cName0: getAllBlogContent?.[0]?.category_name || "Default Category",
      cName1: getAllBlogContent?.[1]?.category_name || "Default Category",
      cName2: getAllBlogContent?.[2]?.category_name || "Default Category",
      cName3: getAllBlogContent?.[3]?.category_name || "Default Category",
      cName4: getAllBlogContent?.[4]?.category_name || "Default Category",
    },
    subtitles: {
      heading1: "Create Your Account",
      heading2: "Explore Elections",
      heading3: "Cast Your Vote",
      heading4: "Track Results",
      heading5: "Empowerment through Information:",
      heading6: "Innovative Technology:",
      heading7: "Global Reach, Local Impact:",
      heading8: getAllBlogContent?.[0]?.blog_title || "Default Title",
      heading9: getAllBlogContent?.[1]?.blog_title || "Default Title",
      heading10: getAllBlogContent?.[2]?.blog_title || "Default Title",
      heading11: "Predict and Simulate Election Outcomes",
      heading12: "AI Integration Coming Soon",
      heading13: "Explore, Learn, and Share:",
      heading14: "Why It's Unique",
      heading15: "AI-Powered Insights (Coming Soon):",
      heading16: getAllBlogContent?.[3]?.blog_title || "Default Title",
      heading17: getAllBlogContent?.[4]?.blog_title || "Default Title",
      heading18: "Inclusive Practices:",
    },
    children: {
      children1: "Cast your vote",
      children2: "Coming Soon",
      children3: "Start system tour",
      children4: "Read more",
      children5: "View All",
      children6: (
        <>
          <MdOutlineHowToVote className="vote-icon-3" />{" "}
          <span>Vote Simulator</span>{" "}
          <MdKeyboardDoubleArrowRight className="swipe-arrow" />
        </>
      ),
      children7: "Presidential Election Result -2024",
      children8: "Parliament Election Result -2024",
    },
    images: {
      image1: { heroImg },
      image2: { heroImg1 },
      image3: { heroImg2 },
      image4: { heroImg3 },
      image5: getAllBlogContent?.[0]?.blog_image ?? "Default image",
      image6: getAllBlogContent?.[1]?.blog_image ?? "Default image",
      image7: getAllBlogContent?.[2]?.blog_image ?? "Default image",
      image8: getAllBlogContent?.[3]?.blog_image ?? "Default image",
      image9: getAllBlogContent?.[4]?.blog_image ?? "Default image",
    },
    datas: {
      data1: {
        activeUserNum: "1500 +",
        activeUserTxt: "Active users",
      },
      data2: {
        activeVoterNum: "1000+",
        activeVoterTxt: "Register Voters",
      },
      data3: {
        activeUserCount: "2+",
        activeUserCountry: "Country",
      },
    },

    paras: {
      para1:
        "We envision a world where democracy thrives through the active involvement of citizens who are well-informed and fully engaged in their governance. O'Democracy strives to be a global catalyst for positive change, using technology to amplify voices and build stronger democratic institutions that serve all of humanity.",

      para2:
        "Our mission is to promote democratic participation by providing a platform that enables open dialogue, transparent data sharing, and community-driven decision-making. We aim to empower every individual with the tools and knowledge necessary to influence elections, shape policies, and contribute to a more just and equitable society.",

      para3:
        "Signing up on O'Democracy is quick and easy. In just a few minutes, you’ll join a global community where your voice shapes the future of democracy. Create your account to access all features, from exploring candidates to simulating election outcomes with our advanced tools.",
      para4:
        "Access detailed insights on political parties, their platforms, and the candidates running for office. With AI-powered tools coming soon, you’ll gain even deeper insights into political trends, voter behavior, and key issues shaping your elections. Stay informed with cutting-edge analysis that helps you make educated decisions.",
      para5:
        "Have your say by voting for the party or candidate that aligns with your values. O'Democracy allows you to take an active role in democratic simulations, and with AI integration on the horizon, your participation will drive even smarter, more precise electoral forecasting and analysis.",
      para6:
        "Stay ahead with real-time election results and comprehensive analysis. Our platform keeps you updated on the latest developments, and soon, AI-powered features will provide predictive insights and trends based on evolving data, offering you unparalleled depth in election tracking.",
      para7:
        "At O'Democracy, we are excited to announce that artificial intelligence will soon be powering many of our platform’s features. This will enhance your experience by offering predictive analytics, smarter simulations, and deeper insights into political trends and voting behaviors. Our AI tools will revolutionize how elections are explored and understood, providing users with a dynamic, data-driven approach to shaping the future of democracy.",
      para8:
        "O'democracy is a pioneering democracy polling platform dedicated to facilitating fair and transparent elections in Sri Lanka and beyond. With our user-friendly interface and cutting-edge technology, we aim to revolutionize the way people participate in the democratic process. Our platform serves as a bridge between citizens and their elected representatives, ensuring that every voice is heard and every vote counts.",
      para9:
        "Empower yourself with essential resources on democracy, voting rights, and political processes. From voter registration guides to educational articles, we provide the tools you need to become an informed citizen.",
      para10: "O'Democracy Simulator: Explore the Future of Elections",
      para11:
        "Tailor scenarios by altering voter turnout, party alliances, or voter demographic segments such as age, occupation, and regional divisions. Whether you want to see how student votes might sway an election or how alliances between political parties could change outcomes, this simulator allows you to manipulate data with precision.",
      para12: `The simulator goes beyond simple vote predictions. It gives you the ability to incorporate a range of factors simultaneously. Explore how shifts in voter behavior or campaign strategies influence the political landscape. Play out "what if" scenarios to better understand how various combinations of factors could reshape election outcomes.`,
      para13:
        "Test your predictions with real-time election data and dynamic simulation models. The simulator adapts to changes in real-world data, offering an evolving and accurate reflection of possible future outcomes. You can refine your predictions as the election approaches or as new factors emerge.",
      para14: getAllBlogContent?.[0]?.overview || "Default overview",
      para15: getAllBlogContent?.[1]?.overview || "Default overview",
      para16: getAllBlogContent?.[2]?.overview || "Default overview",
      para17: `The O'Democracy Simulator is a cutting-edge tool designed to give users unparalleled control over predicting and shaping election outcomes. Unlike anything available on other platforms, this feature allows you to dive deep into the dynamics of electoral systems and see how various factors can impact results.`,
      para19:
        "At O'Democracy, we believe that democracy is at its strongest when citizens are informed, engaged, and empowered to shape their own futures. We are dedicated to creating an inclusive digital platform where individuals from every corner of the world can participate in meaningful political discussions, access critical information, and directly contribute to the democratic process.",
      para20:
        "Our unique simulator lets you predict future election results by adjusting key factors such as voter turnout, party alliances, and demographic shifts. With AI integration in the near future, you’ll gain access to even more sophisticated simulations, allowing you to forecast multiple election scenarios with remarkable accuracy and understand how intricate variables affect democratic processes.",
      para21: `We ensure that citizens have access to reliable data and insights, enabling informed choices and a better understanding of political landscapes.`,
      para22: `Our platform leverages cutting-edge tools like the Ooraar Elections Calculator to provide dynamic election forecasts, allowing users to simulate outcomes and explore various electoral scenarios.`,
      para23: `While our scope is global, we prioritize grassroots-level engagement, connecting people to the political realities of their communities and nations.`,
      para24: `We believe that every voice matters. Our platform is built to foster inclusivity, ensuring that diverse perspectives are not only represented but valued.`,
      para25: `Use the O'Democracy Simulator to educate yourself on electoral dynamics and share your findings with others. Whether you're a voter, researcher, political enthusiast, or a party strategist, the simulator provides a powerful way to visualize and understand how elections could unfold based on various decisions.`,
      para26: `
What sets the O'Democracy Simulator apart is its user-centric design that combines advanced data analytics with an intuitive interface. It empowers everyone—from curious citizens to political analysts—to explore election dynamics with unmatched depth. As a powerful learning tool, it offers hands-on insights into how democracy works and how various forces come into play during elections. In the future, AI integration will further elevate this feature, turning election simulations into more than just predictions—they'll become powerful, data-driven forecasts.`,
      para27: `Soon, the O'Democracy Simulator will feature AI integration, enabling even more complex and accurate simulations. Our AI algorithms will analyze voting patterns, historical data, and evolving political trends, giving users an intelligent forecast of potential election results. This will allow for predictive modeling that adapts to even the smallest of changes, ensuring your insights are both informed and dynamic.`,
    },
    icons: {
      icons1: src1,
      icons2: src2,
      icons3: src3,
      icons4: src4,
      icons5: <IoCheckmarkCircleSharp />,
      icons6: <MdOutlineCampaign />,
      icons7: src5,
      icons8: src6,
      icons9: src7,
      icons10: src8,
      icons11: src9,
      icons12: <BsArrowRight />,
      icons13: src10,
      icons14: src11,
      icons15: src12,
      icons16: src13,
      icons17: src14,
      icons18: src15,
      icons19: src16,
      icons20: src17,
      icons21: src18,
      icons22: src19,
    },
    lists: {
      list1: {
        l1: "Check history of any party",
        l2: "Check history of any candidate",
        l3: "Stay updated with news",
        l4: "Election Polling System",
        l5: "Generate Simulator",
      },
    },
    dates: {
      date1: getAllBlogContent?.[0]?.created_at || "Default Date",
      date2: getAllBlogContent?.[1]?.created_at || "Default Date",
      date3: getAllBlogContent?.[2]?.created_at || "Default Date",
      date4: getAllBlogContent?.[3]?.created_at || "Default Date",
      date5: getAllBlogContent?.[4]?.created_at || "Default Date",
    },
    onClick: {
      handleNavigate: handleNavigate,
      handleNavigate2: handleNavigate2,
      handleClick,
      onClick0: () =>
        navigate("/full-blogs", { state: { id: getAllBlogContent?.[0]?.id } }),
      onClick1: () =>
        navigate("/full-blogs", { state: { id: getAllBlogContent?.[1]?.id } }),
      onClick2: () =>
        navigate("/full-blogs", { state: { id: getAllBlogContent?.[2]?.id } }),
      onClick3: () =>
        navigate("/full-blogs", { state: { id: getAllBlogContent?.[3]?.id } }),
      onClick4: () =>
        navigate("/full-blogs", { state: { id: getAllBlogContent?.[4]?.id } }),
    },
  };

  return <LandingMain {...props} />;
};

export default LandingPage;
