import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";

import Header from "../components/header/header";
// user
import ULogin from "../pages/user/auth/login";
// Admin
import ALogin from "../pages/admin/auth/login";
import ADashboard from "../pages/admin/dashboard";
import ManageParty from "../pages/admin/partyGroup/manageParty";
import SecurityLevel from "../pages/admin/secirityGroup/securityLevel";
import AddSecurity from "../pages/admin/secirityGroup/addSecurities";
import Permissions from "../pages/admin/permissionsGroup/permissions";
import AddPermission from "../pages/admin/permissionsGroup/addPermission";
import ManageProvince from "../pages/admin/province/manageProvince";
import ManageDistricts from "../pages/admin/district/manageDistricts";
import AddParty from "../pages/admin/partyGroup/addParty";
import ManageUserList from "../pages/admin/userList/manageUserList";
import AddManageProvince from "../pages/admin/province/addManageProvince";
import AddManageDistricts from "../pages/admin/district/addManageDistricts";
import ManageCandidate from "../pages/admin/candidateGroup/manageCandidate";
import AddCandidate from "../pages/admin/candidateGroup/addCandidate";
import TypeOfElectionn from "../pages/admin/typeOfElection/typeOfElection";

import AddElectionType from "../pages/admin/typeOfElection/addElectionType";
import ManageAssiciatedCandidateById from "../pages/admin/manageAssociatedCandidate/manageAssiciatedCandidateById";
import AddCandidatePartyId from "../pages/admin/manageAssociatedCandidate/addCandidatePartyId";
import ManageAssociatedDistrictById from "../pages/admin/manageAssociatedDistrict/manageAssociatedDistrictById";
import ManagePollingDivision from "../pages/admin/pollingDivision/managePollingDivision";
import AddPolingDivision from "../pages/admin/pollingDivision/addPolingDivision";
import ManageCandidateToe from "../pages/admin/typeOfElection/manageCandidateToe";
import AddCandidateToe from "../pages/admin/typeOfElection/addCandidateToe";
import ManageCandidateHistory from "../pages/admin/candidateGroup/manageCandidateHistory";
import ManagePartyHistory from "../pages/admin/partyGroup/managePartyHistory";
import EditUser from "../pages/admin/userList/editUser";
import ManagePermissionMain from "../pages/admin/secirityGroup/managePermissionMain";
import LoginPerefrance from "../pages/user/auth/loginPerefrances";
import Register from "../pages/user/auth/register";
import EsendOtp from "../pages/user/auth/eSendOtp";
import ResetPassword from "../pages/user/auth/resetPassword";
import OTP from "../pages/user/auth/otp";
import LandingPage from "../pages/user/landingPage/landingPage";
import { I18nextProvider } from "react-i18next";
import i18n from "../i18n";
import Footer from "../components/footer/footer";

import ElectionsPage from "../pages/user/elections/electionsPage";
import ProtectedRoute from "./protectedRoute";
import HeroSection from "../pages/user/presidentialElection/heroSection";
import RegisterAsVoter from "../pages/user/presidentialElection/registerAsVoter";
import DistrictsPage from "../pages/user/districts/districtsPage";
import PollingDivisionsPage from "../pages/user/pollingDivisions/pollingDivisionsPage";
import ProfilePage from "../pages/user/profilePage/profilePage";
import WinnerPage from "../pages/user/winner/winnerPage";
import PresidentElectionHistory from "../pages/admin/pastElectionHistory/presidentElectionHistory";
import CandidateHistoryPage from "../pages/user/candidates/candidateHistoryPage";
import CandidatesPage from "../pages/user/candidates/candidatesPage";
import PartiesPage from "../pages/user/parties/partiesPage";
import PartiesHistoryPage from "../pages/user/parties/partiesHistoryPage";
import ManageBlogCategoryMain from "../pages/admin/manageBlogCategory/manageBlogCategoryMain";
import AddBlogCategory from "../pages/admin/manageBlogCategory/addBlogCategory";

import ManageBlogsmain from "../pages/admin/manageBlogs/manageBlogsmain";
import AddBlogs from "../pages/admin/manageBlogs/addBlogs";
import BlogsPage from "../pages/user/blogs/blogsPage";
import SeeVoteSimulatorResultPage from "../pages/user/voteSimulatorPage/seeVoteSimulatorResultPage";
import SimulatorPage from "../pages/user/simulators/simulatorPage";
import ShowBlogs from "../pages/admin/manageBlogs/showBlogs";
import ElectionResults from "../pages/user/electionResults/electionResults";
import ManagePresidentialElectionPage from "../pages/admin/managePresidentialElection/managePresidentialElectionPage";
import PollResultsPage from "../pages/admin/managePresidentialElection/pollResultsPage";
import Reports from "../pages/user/reports/reports";
import NewLocationModal from "../pages/newLocationModal";
import PageNoteFound from "../pages/pageNoteFound";
import ParticularReport from "../pages/user/reports/particularReport";
import CandidateReports from "../pages/user/reports/candidateReports";
import { securityLevel } from "../utilities/common";
import TermsAndConditions from "../pages/user/termsConditions/termsAndConditions";
import ChooseParty from "../components/user/parliamentElection/chooseParty";
import ChoosePartyCandidate from "../components/user/parliamentElection/choosePartyCandidate";
import { updatePastElectionResultsById } from "../redux/adminone/action";
import UpdatePartyCandidateVote from "../components/user/parliamentElection/updatePartyCandidateVote";
import ParliamentSimulatorResultPage from "../pages/user/parlamentExitPollResullt/parlamentSimulatorResultPage";
import ParliamentElectionAlliancePage from "../pages/user/alliance/parliamentElectionAlliancePage";
import ManageParliamentElectionMain from "../pages/admin/manageParliamentElection/manageParliamentElectionMain";
import ManageParliamentElectionPage from "../pages/admin/manageParliamentElection/manageParliamentElectionPage";
import HeroSectionParliament from "../pages/user/parliamentElection/heroSectionParliament";
const Root = () => {
  const [lang, setLang] = useState(getInitialLanguage());

  function getInitialLanguage() {
    const navigatorLanguage = navigator.language || navigator.userLanguage;
    let initialLanguage;

    switch (navigatorLanguage) {
      case "ta":
      case "ta-IN":
        initialLanguage = "tam";
        break;
      default:
        initialLanguage = "en";
    }

    return initialLanguage;
  }

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang]);
  return (
    <I18nextProvider i18n={i18n}>
      <BrowserRouter>
        <Header lang={lang} changeLang={setLang} />
        {/* <UHeader lang={lang} changeLang={setLang} /> */}
        <Routes>
          <Route path="/" element={<LandingPage />} />

          <Route path="login-perefrance" element={<LoginPerefrance />} />
          <Route path="login" element={<ULogin />} />
          <Route path="email-send-otp" element={<EsendOtp />} />
          <Route path="otp" element={<OTP />} />
          <Route path="reset-password" element={<ResetPassword />} />
          <Route path="register" element={<Register />} />
          <Route path="/candidates" element={<CandidatesPage />} />
          <Route
            path="/candidates-history"
            element={<CandidateHistoryPage />}
          />
          <Route path="/parties" element={<PartiesPage />} />
          <Route path="/parties-history" element={<PartiesHistoryPage />} />
          {/* User Routes */}
          <Route
            path="/profile"
            element={<ProtectedRoute element={ProfilePage} />}
          />
          <Route
            path="/select-election"
            element={<ProtectedRoute element={ElectionsPage} />}
          />
          <Route
            path="/select-district"
            element={<ProtectedRoute element={DistrictsPage} />}
          />
          <Route
            path="/select-polling-division"
            element={<ProtectedRoute element={PollingDivisionsPage} />}
          />
          <Route
            path="/presidential-election-hero-section"
            element={<ProtectedRoute element={HeroSection} />}
          />
          <Route
            path="/registerasvoter"
            element={<ProtectedRoute element={RegisterAsVoter} />}
          />

          <Route
            path="/simulator"
            element={<ProtectedRoute element={SimulatorPage} />}
          />
          <Route
            path="/see-exit-poll-result"
            element={<ProtectedRoute element={SeeVoteSimulatorResultPage} />}
          />
          <Route
            path="/winner"
            element={<ProtectedRoute element={WinnerPage} />}
          />
          <Route
            path="/blogs"
            element={<ProtectedRoute element={BlogsPage} />}
          />
          <Route
            path="/full-blogs"
            element={<ProtectedRoute element={ShowBlogs} />}
          />
          <Route
            path="/election-results"
            element={<ProtectedRoute element={ElectionResults} />}
          />
          <Route
            path="/reports"
            element={<ProtectedRoute element={Reports} />}
          />
          <Route
            path="/presidentialelection-reports"
            element={<ProtectedRoute element={ParticularReport} />}
          />
          <Route
            path="/presidentialelection-report"
            element={<ProtectedRoute element={CandidateReports} />}
          />
          <Route
            path="/terms-conditions"
            element={<ProtectedRoute element={TermsAndConditions} />}
          />
          <Route
            path="/parliament-election-dashboard"
            element={<ProtectedRoute element={HeroSectionParliament} />}
          />

          <Route
            path="/parliament-election-simulator-result"
            element={<ProtectedRoute element={ParliamentSimulatorResultPage} />}
          />
          <Route
            path="/parliament-election-alliance"
            element={<ProtectedRoute element={ParliamentElectionAlliancePage} />}
          />
          <Route
            path="/parlament-election/choose-party"
            element={<ProtectedRoute element={ChooseParty} />}
          />
          <Route
            path="/choose-party-candidate"
            element={<ProtectedRoute element={ChoosePartyCandidate} />}
          />
          <Route
            path="/update-party-candidate"
            element={<ProtectedRoute element={UpdatePartyCandidateVote} />}
          />

          {/* Admin Routes */}
          <Route path="/admin/*">
            <Route path="login" element={<ALogin />} />
          </Route>
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute
                element={ADashboard}
                securityLevel={securityLevel.user}
              />
            }
          />
          <Route
            path="/manage-party"
            element={
              <ProtectedRoute
                element={ManageParty}
                securityLevel={securityLevel.user}
              />
            }
          />
          <Route
            path="/party-history"
            element={
              <ProtectedRoute
                element={ManagePartyHistory}
                securityLevel={securityLevel.user}
              />
            }
          />
          <Route
            path="/manage-associated-candidate-by-id"
            element={
              <ProtectedRoute
                element={ManageAssiciatedCandidateById}
                securityLevel={securityLevel.user}
              />
            }
          />
          <Route
            path="/add-associated-candidate-by-id"
            element={
              <ProtectedRoute
                element={AddCandidatePartyId}
                securityLevel={securityLevel.user}
              />
            }
          />
          <Route
            path="/manage-candidate"
            element={
              <ProtectedRoute
                element={ManageCandidate}
                securityLevel={securityLevel.user}
              />
            }
          />
          <Route
            path="/candidate-history"
            element={
              <ProtectedRoute
                element={ManageCandidateHistory}
                securityLevel={securityLevel.user}
              />
            }
          />
          <Route
            path="/manage-province"
            element={
              <ProtectedRoute
                element={ManageProvince}
                securityLevel={securityLevel.user}
              />
            }
          />
          <Route
            path="/manage-associated-district-by-id"
            element={
              <ProtectedRoute
                element={ManageAssociatedDistrictById}
                securityLevel={securityLevel.user}
              />
            }
          />
          <Route
            path="/add-province"
            element={
              <ProtectedRoute
                element={AddManageProvince}
                securityLevel={securityLevel.user}
              />
            }
          />
          <Route
            path="/edit-province"
            element={
              <ProtectedRoute
                element={AddManageProvince}
                securityLevel={securityLevel.user}
              />
            }
          />
          <Route
            path="/manage-districts"
            element={
              <ProtectedRoute
                element={ManageDistricts}
                securityLevel={securityLevel.user}
              />
            }
          />
          <Route
            path="/add-districts"
            element={
              <ProtectedRoute
                element={AddManageDistricts}
                securityLevel={securityLevel.user}
              />
            }
          />
          <Route
            path="/edit-districts"
            element={
              <ProtectedRoute
                element={AddManageDistricts}
                securityLevel={securityLevel.user}
              />
            }
          />
          <Route
            path="/polling-division"
            element={
              <ProtectedRoute
                element={ManagePollingDivision}
                securityLevel={securityLevel.user}
              />
            }
          />
          <Route
            path="/add-poling-division"
            element={
              <ProtectedRoute
                element={AddPolingDivision}
                securityLevel={securityLevel.user}
              />
            }
          />
          <Route
            path="/edit-poling-division"
            element={
              <ProtectedRoute
                element={AddPolingDivision}
                securityLevel={securityLevel.user}
              />
            }
          />
          <Route
            path="/add-party"
            element={
              <ProtectedRoute
                element={AddParty}
                securityLevel={securityLevel.user}
              />
            }
          />
          <Route
            path="/edit-party-by-id"
            element={
              <ProtectedRoute
                element={AddParty}
                securityLevel={securityLevel.user}
              />
            }
          />
          <Route
            path="/add-candidate"
            element={
              <ProtectedRoute
                element={AddCandidate}
                securityLevel={securityLevel.user}
              />
            }
          />
          <Route
            path="/edit-candidate-by-id"
            element={
              <ProtectedRoute
                element={AddCandidate}
                securityLevel={securityLevel.user}
              />
            }
          />
          <Route
            path="/user-list"
            element={
              <ProtectedRoute
                element={ManageUserList}
                securityLevel={securityLevel.user}
              />
            }
          />
          <Route
            path="/edit-user-by-id"
            element={
              <ProtectedRoute
                element={EditUser}
                securityLevel={securityLevel.user}
              />
            }
          />
          <Route
            path="/type-of-election"
            element={
              <ProtectedRoute
                element={TypeOfElectionn}
                securityLevel={securityLevel.user}
              />
            }
          />
          <Route
            path="/add-election-type"
            element={
              <ProtectedRoute
                element={AddElectionType}
                securityLevel={securityLevel.user}
              />
            }
          />
          <Route
            path="/edit-election-type"
            element={
              <ProtectedRoute
                element={AddElectionType}
                securityLevel={securityLevel.user}
              />
            }
          />
          <Route
            path="/type-of-election-candidate"
            element={
              <ProtectedRoute
                element={ManageCandidateToe}
                securityLevel={securityLevel.user}
              />
            }
          />
          <Route
            path="/type-of-election-add-candidate"
            element={
              <ProtectedRoute
                element={AddCandidateToe}
                securityLevel={securityLevel.user}
              />
            }
          />
          <Route
            path="/type-of-election-edit-candidate"
            element={
              <ProtectedRoute
                element={AddCandidateToe}
                securityLevel={securityLevel.user}
              />
            }
          />
          <Route
            path="/security-level"
            element={
              <ProtectedRoute
                element={SecurityLevel}
                securityLevel={securityLevel.user}
              />
            }
          />
          <Route
            path="/manage-permission"
            element={
              <ProtectedRoute
                element={ManagePermissionMain}
                securityLevel={securityLevel.user}
              />
            }
          />
          <Route
            path="/add-securities"
            element={
              <ProtectedRoute
                element={AddSecurity}
                securityLevel={securityLevel.user}
              />
            }
          />
          <Route
            path="/edit-securities"
            element={
              <ProtectedRoute
                element={AddSecurity}
                securityLevel={securityLevel.user}
              />
            }
          />
          <Route
            path="/permission"
            element={
              <ProtectedRoute
                element={Permissions}
                securityLevel={securityLevel.user}
              />
            }
          />
          <Route
            path="/add-permission"
            element={
              <ProtectedRoute
                element={AddPermission}
                securityLevel={securityLevel.user}
              />
            }
          />
          <Route
            path="/edit-permission"
            element={
              <ProtectedRoute
                element={AddPermission}
                securityLevel={securityLevel.user}
              />
            }
          />
          <Route
            path="/manage-blog-category"
            element={
              <ProtectedRoute
                element={ManageBlogCategoryMain}
                securityLevel={securityLevel.user}
              />
            }
          />
          <Route
            path="/add-blog-category"
            element={
              <ProtectedRoute
                element={AddBlogCategory}
                securityLevel={securityLevel.user}
              />
            }
          />
          <Route
            path="/edit-blog-category"
            element={
              <ProtectedRoute
                element={AddBlogCategory}
                securityLevel={securityLevel.user}
              />
            }
          />
          <Route
            path="/manage-blogs-content"
            element={
              <ProtectedRoute
                element={ManageBlogsmain}
                securityLevel={securityLevel.user}
              />
            }
          />
          <Route
            path="/add-blogs-content"
            element={
              <ProtectedRoute
                element={AddBlogs}
                securityLevel={securityLevel.user}
              />
            }
          />
          <Route
            path="/edit-blogs-content"
            element={
              <ProtectedRoute
                element={AddBlogs}
                securityLevel={securityLevel.user}
              />
            }
          />
          <Route
            path="/manage-presidential-election"
            element={
              <ProtectedRoute
                element={ManagePresidentialElectionPage}
                securityLevel={securityLevel.user}
              />
            }
          />
          <Route
            path="/manage-parliamentary-election"
            element={
              <ProtectedRoute
                element={ManageParliamentElectionPage}
                securityLevel={securityLevel.user}
              />
            }
          />
          <Route
            path="/presidential-election-poll-result"
            element={
              <ProtectedRoute
                element={PollResultsPage}
                securityLevel={securityLevel.user}
              />
            }
          />
          <Route
            path="/election-history"
            element={
              <ProtectedRoute
                element={PresidentElectionHistory}
                securityLevel={securityLevel.user}
              />
            }
          />
          <Route
            path="/edit-election-history"
            element={
              <ProtectedRoute
                element={PresidentElectionHistory}
                securityLevel={securityLevel.user}
              />
            }
          />

          <Route path="*" element={<PageNoteFound />} />
        </Routes>

        <NewLocationModal />
        <Footer lang={lang} changeLang={setLang} />
      </BrowserRouter>
    </I18nextProvider>
  );
};

export default Root;
