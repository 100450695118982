import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducer';
import { thunk } from 'redux-thunk';

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: {
            ignoredActionPaths: ['payload.config', 'payload.request', 'payload.headers'],
            ignoredPaths: ['admin.parties.config.adapter'],
        },
    }).concat(thunk),
    serializableCheck: false
});

export default store;