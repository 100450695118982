import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  addPollingDivisionsByDistrictsId,
  updatePollingDivisionsByDistrictId,
  showPollingDivisionsById,
} from "../../../redux/adminone/action";
import Loader from "../../../components/loaders/loader";
import PostBlockSix from "../../../components/admin/postBlockSix";
import { useSnackbar } from "react-simple-snackbar";

const override = `
    display: block;
    margin: 0 auto;
    border-color: red;
`;
const AddPolingDivision = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const params = new URLSearchParams(location.search);
  const id = params.get("id");
  const district_name = params.get("district_name");
  const poling_id = params.get("poling_id");
  const edit = params.get("edit") === "true";
  const loading = useSelector((state) => state.adminone.loading);

  const [formData, setFormData] = useState({
    polling_division_name: "",
    total_population: "",
    total_registered_voters: "",
    map_image: null,
  });
  const [errors, setErrors] = useState({
    polling_division_name: "",
    total_population: "",
    total_registered_voters: "",
  });

  const showsPollingDivisionsByIdData = useSelector(
    (state) => state.adminone.showsPollingDivisionsByIdData?.data?.[0]
  );

  useEffect(() => {
    if (poling_id) {
      dispatch(showPollingDivisionsById(poling_id));
    } else {
      // Reset the form when there's no poling_id (indicating "Add" mode)
      setFormData({
        polling_division_name: "",
        total_population: "",
        total_registered_voters: "",
        map_image: null,
      });
    }
  }, [dispatch, poling_id]);

  useEffect(() => {
    if (poling_id && showsPollingDivisionsByIdData) {
      setFormData({
        polling_division_name:
          showsPollingDivisionsByIdData.polling_division_name,
        total_population: showsPollingDivisionsByIdData.total_population,
        total_registered_voters:
          showsPollingDivisionsByIdData.total_registered_voters,
        map_image: showsPollingDivisionsByIdData.map_image,
      });
    }
  }, [poling_id, showsPollingDivisionsByIdData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const handleUpload = (imageList) => {
    if (imageList.length > 0) {
      setFormData((prevData) => ({
        ...prevData,
        map_image: imageList[0].file,
      }));
    } else {
      setFormData((prevData) => ({ ...prevData, map_image: null }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        map_image: "Party logo is required.",
      }));
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    const {
      polling_division_name,
      total_population,
      total_registered_voters,
      map_image,
    } = formData;
    const isLogoUpdated =
      showsPollingDivisionsByIdData?.map_image !== map_image;
    let valid = true;
    if (!polling_division_name || polling_division_name.length < 2) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        name: "Enter Security Name",
      }));
      valid = false;
    }

    if (!valid) return;

    const formDataAddPollingDivision = new FormData();
    formDataAddPollingDivision.append(
      "polling_division_name",
      formData.polling_division_name
    );
    formDataAddPollingDivision.append(
      "total_population",
      formData.total_population
    );
    formDataAddPollingDivision.append(
      "total_registered_voters",
      formData.total_registered_voters
    );
    formDataAddPollingDivision.append("district_id", id);

    if (isLogoUpdated) {
      formDataAddPollingDivision.append("map_image", formData.map_image);
    }

    if (edit) {
      dispatch(
        updatePollingDivisionsByDistrictId(
          formDataAddPollingDivision,
          poling_id,
          (error, response) => {
            if (error) {
              if (error.status === 422) {
                const errorMessages = error.data.errors?.map_image.join(" ");
                openSnackbar(errorMessages);
              }
            } else {
              if (response.status === 200) {
                openSnackbar(response.data.message);
                navigate(-1);
              }
            }
          }
        )
      );
    } else {
      dispatch(
        addPollingDivisionsByDistrictsId(
          formDataAddPollingDivision,
          id,
          (error, response) => {
            if (error) {
              if (error.status === 404) {
                openSnackbar(error.data.message);
              }
              if (error.status === 422) {
                openSnackbar(error.data.message);
              }
            } else {
              if (response.status === 200) {
                openSnackbar(response.data.message);
                navigate(-1);
              }
            }
          }
        )
      );
    }
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const props = {
    to1: "/security-level",
    title: edit ? "Edit Polling Division" : "Add Polling Division",
    saveNextBtn: "Save and Next",
    addSeqBtnstyle: { padding: "1.3rem 2.8rem", marginLeft: "1.5rem" },
    save: "save",
    cancel: "cancel",
    textInput: [
      {
        label: "Polling Division Name",
        name: "polling_division_name",
        value: formData.polling_division_name,
        error: errors.polling_division_name,
        placeholder: "Enter Poling Division Name",
      },
      {
        label: "Total Population",
        name: "total_population",
        value: formData.total_population,
        error: errors.total_population,
        placeholder: "Enter Total Population",
      },
      {
        label: "District Name",
        value: district_name,
        disabled: true,
        placeholder: "Enter Total Population",
      },
      {
        label: "Total Register Voters",
        name: "total_registered_voters",
        value: formData.total_registered_voters,
        error: errors.total_registered_voters,
        placeholder: "Enter Total Register Voters",
      },
    ],
    uploadLabel: "Upload Image",
    uploadStyle: {
      marginTop: "3rem",
    },
    onUpload: handleUpload,
    handleChange,
    handleSave,
    handleCancel,
    map_image: edit
      ? showsPollingDivisionsByIdData?.map_image
      : formData.map_image,
  };

  return (
    <main className="addPolingDivisions">
      <aside className="addbock">
        <PostBlockSix {...props} />
      </aside>
      {loading ? (
        <Loader color={"#0E76A8"} loading={loading} css={override} size={75} />
      ) : null}
    </main>
  );
};

export default AddPolingDivision;
