import React, { useEffect, useState } from "react";
import OrangYellowBtn from "../../../components/buttons/orangeSolidBtn";
import { TiLocation } from "react-icons/ti";
import VoteForYourCandidate from "../../../components/modals/voteForYourCandidate";
import ElectionTotalVotes from "./electionTotalVotes";
import ElectionPollingResults from "./electionPollingResults";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "react-simple-snackbar";
import {
  addRegisterVoterId,
  filterElectionResults,
  userDashboardData,
} from "../../../redux/user/action";
import disImg from "../../../assets/images/sreelamkaImg.png";
import { cApiUrlPrefix } from "../../../config/envConfig";
import {
  indexDistrict,
  showAllPollingDivisionsByDistrictById,
} from "../../../redux/adminone/action";
import Loader from "../../../components/loaders/loader";
import { FaLocationArrow } from "react-icons/fa6";
const override = `
    display: block;
    margin: 0 auto;
    border-color: red;
  `;
function HeroSection({ initialValues }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [username, setUsername] = useState("");
  const [openSnackbar] = useSnackbar();
  const location = useLocation();
  const {
    electionTypeId,
    title,
    district_id,
    district_name,
    polling_division_id,
    polling_division_name,
  } = location.state || {};

  console.log("title",title);
  

  const error = useSelector((state) => state.adminone.error);
  const indexCandidateData = useSelector(
    (state) => state.user.indexCandidateData?.top_three_candidates
  );

  const loadingA = useSelector((state) => state.adminone.loading);
  const loadingU = useSelector((state) => state.user.loading);
  const isLoading = loadingA || loadingU;

  const [formData, setFormData] = useState({
    voter_id: initialValues?.voter_id ?? "",
  });
  const [errors, setErrors] = useState({
    voter_id: "",
  });

  const [percentage, setPercentage] = useState(0);
  const userdashboardData = useSelector(
    (state) => state.user.userdashboardData?.data
  );
  const totalVotes = userdashboardData?.total_submitted_votes || 0;
  useEffect(() => {
    dispatch(userDashboardData(electionTypeId));
  }, [dispatch, electionTypeId]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setPercentage(70);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  const votingPercentage = {
    value: (
      (totalVotes / (userdashboardData?.total_registered_voters || 0)) *
      100
    ).toFixed(2),
    color: "",
  };

  const candidatesData = Array.isArray(userdashboardData?.top_three_candidates)
    ? userdashboardData?.top_three_candidates.map((candidate) => ({
      CandidateImage: candidate.candidate_image,
      "3 Leading candidates as a first preference": candidate.candidate_name,
      "1st Preference": candidate.preference1,
      "2nd Preference": candidate.preference2,
      "3rd Preference": candidate.preference3,
      "Total Votes": candidate.total_votes,
      "Votes Percentage": (
        (candidate.total_votes / totalVotes) *
        100
      ).toFixed(2),
    }))
    : [];

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      if (parsedUser && parsedUser.username) {
        setUsername(parsedUser.username);
      }
      if (parsedUser.user && parsedUser.user.voter_id) {
        setShowModal(false);
      }
    }
  }, []);

  const handleShowModal = () => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      const voterId = parsedUser?.voter_id;
      if (voterId) {
        navigate("/registerasvoter", {
          state: {
            electionTypeId,
            title,
            district_id,
            district_name,
            polling_division_id,
            polling_division_name,
          },
        });
        // navigate(
        //   `/registerasvoter?election_id=${electionTypeId}&district_id=${district_id}&polling_division_id=${polling_division_id} `
        // );
        return;
      } else {
      }
    } else {
    }
    setShowModal(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const handleClick = async (e) => {
    if (e) {
      e.preventDefault();
    }

    const { voter_id } = formData;
    let valid = true;
    let newErrors = {};

    if (!voter_id || voter_id.length < 2) {
      newErrors.voter_id = "Voter ID must be at least 2 characters long.";
      valid = false;
    }

    setErrors(newErrors);

    if (!valid) return;

    const formDataVoter = new FormData();
    formDataVoter.append("name", username);
    formDataVoter.append("voter_id", voter_id);

    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      try {
        const parsedUser = JSON.parse(storedUser);
        const userId = parsedUser?.id;

        if (!userId) {
          openSnackbar("User ID not found");
          return;
        }

        dispatch(
          addRegisterVoterId(formDataVoter, userId, (error, response) => {
            if (error) {
              openSnackbar("Error occurred while adding voter ID");
            } else {
              if (response.status === 200) {
                openSnackbar(response.data.message);
                parsedUser.voter_id = voter_id;
                localStorage.setItem("user", JSON.stringify(parsedUser));

                setShowModal(false);
                navigate("/registerasvoter", {
                  state: {
                    electionTypeId,
                    title,
                    district_id,
                    district_name,
                    polling_division_id,
                    polling_division_name,
                  },
                });
              }
            }
          })
        );
      } catch (error) {
        openSnackbar("User ID not found");
      }
    } else {
      openSnackbar("User ID not found");
    }
  };

  useEffect(() => {
    dispatch(
      filterElectionResults(electionTypeId, district_id, polling_division_id)
    );
  }, [dispatch, electionTypeId, district_id, polling_division_id]);

  const labels = [
    "Name of Candidate",
    "Party name",
    "1st priority",
    "2st priority",
    "3rd priority",
    "Total Vote",
    "Total Vote Percentage",
  ];

  const data = Array.isArray(indexCandidateData?.top_three_candidates)
    ? indexCandidateData?.top_three_candidates.map((candidate) => ({
      CandidateImage: candidate.candidate_image,
      "Name of Candidate": candidate.candidate_name,
      "Party name": candidate.party_name,
      "1st priority": candidate.preference1,
      "2st priority": candidate.preference2,
      "3rd priority": candidate.preference3,
      "Total Vote": candidate.total_votes,
      "Total Vote Percentage": candidate.total_votes,
    }))
    : [];

  useEffect(() => {
    dispatch(showAllPollingDivisionsByDistrictById(district_id));
  }, [dispatch, district_id]);

  const showsAllPollingDivisionsByDistrictIdData = useSelector(
    (state) => state.adminone.showsAllPollingDivisionsByDistrictIdData?.data
  );
  const datas =
    showsAllPollingDivisionsByDistrictIdData?.map((pollingDivision) => ({
      id: pollingDivision.id,
      name: pollingDivision.polling_division_name,
      image:
        pollingDivision.map_image === "0"
          ? disImg
          : cApiUrlPrefix + pollingDivision.map_image,
    })) || [];

  const indexDistrictsData = useSelector(
    (state) => state.adminone.indexDistrictsData?.data
  );
  const datas1 = Array.isArray(indexDistrictsData)
    ? indexDistrictsData.map((district) => ({
      id: district.id,
      name: district.district_name,
      image:
        district.map_image === "0"
          ? disImg
          : cApiUrlPrefix + district.map_image,
    }))
    : [];

  const [activeId, setActiveId] = useState(null);
  const [activeId1, setActiveId1] = useState(null);
  const [resultLabel, setResultLabel] = useState(null);
  const [resultLabel1, setResultLabel1] = useState(null);

  useEffect(() => {
    if (polling_division_id) {
      // setActiveId(polling_division_id);
      setResultLabel(polling_division_name);
      // } else {
      //   const user = JSON.parse(localStorage.getItem("user"));
      //   if (user && user.polling_division_id && user.polling_division_name) {
      //     setActiveId(user.polling_division_id);
      //     setResultLabel(user.polling_division_name);
      //   }
    }
  }, [polling_division_id, polling_division_name]);

  const handleClick1 = (id, name) => {
    setActiveId(id);
    // setResultLabel(name);
    const user = JSON.parse(localStorage.getItem("user"));
    if (user && user.polling_division_id && user.polling_division_name) {
      navigate(
        `/presidential-election-hero-section?election_id=${electionTypeId}&title=${encodeURIComponent(
          "title"
        )}&district_id=${district_id}&polling_division_id=${id}&district_name=${encodeURIComponent(
          district_name
        )}&polling_division_name=${encodeURIComponent(name)} `
      );
    }
  };
  const [displayData, setDisplayData] = useState("datas");
  const handleClick2 = (
    districtId,
    districtName,
    pollingDivisionId
    // pollingDivisionName
  ) => {
    setActiveId1(pollingDivisionId);

    setResultLabel1(districtName);
    setDisplayData("datas");

    const user = JSON.parse(localStorage.getItem("user"));
    if (user && user.district_id && user.district_name) {
      navigate(
        `/presidential-election-hero-section?election_id=${electionTypeId}&title=${encodeURIComponent(
          electionTypeId
        )}&district_id=${districtId}&district_name=${encodeURIComponent(
          districtName
        )}`
      );
    }
  };

  const handleOnClick = () => {
    setDisplayData("datas1");
    dispatch(indexDistrict());
  };

  const props = {
    activeId,
  };
  const props1 = {
    activeId1,
  };

  return (
    <div className="Hero-Section">
      <div className="d-flex">
        <p className="backG"></p>
        <div className="hero-disc">
          <span>Note :</span> O'Democracy polling is an exclusive platform poll,
          and only O'Democracy registered users have the cast their vote.
        </div>
      </div>
      <div className="d-lg-flex d-block justify-content-between align-items-center mt-5">
        <div className="hero-h5">
          <h5>
            {title} <span>(Poll)</span>
          </h5>
          <p>Sri Lanka, {district_name}, {polling_division_name} <FaLocationArrow className="location-i" onClick={() => { navigate(`/select-election`); }} /></p>
        </div>
        <div className="hero-btns text-end mt-lg-0 mt-4">
          <OrangYellowBtn onClick={handleShowModal}>
            Vote for your candidate
          </OrangYellowBtn>
        </div>
      </div>
      <VoteForYourCandidate
        show={showModal}
        onHide={() => setShowModal(false)}
        title="Register as a voter"
        nameLabel="Voter name"
        namePlaceholder="Enter your profile name"
        idPlaceholder="Enter your Voter ID"
        formValue={username}
        formValue1={formData.voter_id}
        handleChange={handleChange}
        handleClick={handleClick}
        voteText="Save"
      />
      <ElectionTotalVotes
        totalVotes={totalVotes}
        votingPercentage={votingPercentage}
        candidatesData={candidatesData}
      />
      {displayData === "datas" && (
        <ElectionPollingResults
          handleClick1={handleClick1}
          pollingdivitionName={polling_division_name}
          districtName={district_name}
          data={data}
          datas={datas}
          labels={labels}
          props={props}
          resultLabel={resultLabel}
          handleOnClick={handleOnClick}
          error={error}
        />
      )}
      {displayData === "datas1" && (
        <ElectionPollingResults
          handleClick1={handleClick2}
          pollingdivitionName={polling_division_name}
          districtName={district_name}
          data={data}
          datas={datas1}
          labels={labels}
          props={props1}
          // resultLabel={resultLabel1}
          handleOnClick={handleOnClick}
        // error={error}
        />
      )}
      {isLoading ? (
        <Loader
          color={"#0E76A8"}
          loading={isLoading}
          css={override}
          size={75}
        />
      ) : null}
    </div>
  );
}

export default HeroSection;
