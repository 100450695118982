import React from "react";
// import SolidBlueBtn from "../../buttons/solidBlueBtn";
import OrangeSolidBtn from "../../buttons/orangeSolidBtn";
import { Image } from "react-bootstrap";
import WhiteBtnWithBorder from "../../buttons/whiteBtnWithBorder";

const SectionVisionMission = (props) => {
  const { titles, paras, icons, onClick1, children, subtitles } = props;
  return (
    <>
      <section className="section-landing">
        {/* <aside className="text-center my-5">
          <h5 className="aboutHead">With the O'Democracy Simulator, you can:</h5>
        </aside> */}
        <div className="aboutStatement">
          <aside className="section-vmBlk">
            <div className="ba-imgblk">
              <Image src={icons.icons16} className='ba-icon' />
            </div>
            <h2 className="vmblk-heading">{titles.title3}</h2>
            <p className="vmblk-para">{paras.para1}</p>
          </aside>
          <aside className="section-vmBlk">
            <div className="ba-imgblk">
              <Image src={icons.icons17} className='ba-icon' />
            </div>
            <h2 className="vmblk-heading">{titles.title4}</h2>
            <p className="vmblk-para">{paras.para2}</p>
          </aside>
          <aside className="section-vmBlk">
            <div className="ba-imgblk">
              <Image src={icons.icons18} className='ba-icon' />
            </div>
            <h2 className="vmblk-heading">{titles.title12}</h2>
            <p className="vmblk-para">{paras.para19}</p>
          </aside>
          {/* <aside className="section-vmBlk">
        <h2 className="vmblk-heading">{titles.title11}</h2>
        <p className="vmblk-para pre-line-text">{paras.para18}</p>
      </aside> */}
        </div>
      </section>
      <div className="break-point">
        <p>
          Election Information: Stay Updated on Dates, Candidates, and Polling
          Locations
        </p>
        <WhiteBtnWithBorder
          type="submit"
          style={{ width: "calc(100% - 90%)", padding: "1.3rem" }}
        >
          Submit
        </WhiteBtnWithBorder>
      </div>
      <section className="ourApproch">
        {/* <h2 className="section-heading">{titles.title11}</h2> */}
        {/* <p className='section-para'>{paras.para10}</p> */}
        {/* <span className="aside-lable">
          {icons.icons6}
          {titles.title11}
        </span> */}

        <div className="ourApprochHead">
          <aside className="section-aside">
            <span className="aside-lable">
              {icons.icons6}
              {titles.title11}
            </span>
            <h2 className="aside-heading">{titles.title7}</h2>
            <Image src={icons.icons7} className="float-end" />
          </aside>
          <aside className="section-aside">
            <p className="aside-para">{paras.para17}</p>
            <OrangeSolidBtn onClick={onClick1} children={children.children4} />
          </aside>
        </div>
        <div className="sectionblk">
          <aside className="blk-aside">
            <div className="ba-imgblk">
              <Image src={icons.icons19} className='ba-icon' />
            </div>
            <h4 className="ba-heading">{subtitles.heading5}</h4>
            <p className="ba-para">{paras.para21}</p>
          </aside>
          <aside className="blk-aside">
            <div className="ba-imgblk">
              <Image src={icons.icons20} className='ba-icon' />
            </div>
            <h4 className="ba-heading">{subtitles.heading6}</h4>
            <p className="ba-para">{paras.para22}</p>
          </aside>
          <aside className="blk-aside">
            <div className="ba-imgblk">
              <Image src={icons.icons21} className='ba-icon' />
            </div>
            <h4 className="ba-heading">{subtitles.heading7}</h4>
            <p className="ba-para">{paras.para23}</p>
          </aside>
          <aside className="blk-aside">
            <div className="ba-imgblk">
              <Image src={icons.icons22} className='ba-icon' />
            </div>
            <h4 className="ba-heading">{subtitles.heading18}</h4>
            <p className="ba-para">{paras.para24}</p>
          </aside>
        </div>
      </section>
    </>
  );
};

export default SectionVisionMission;
