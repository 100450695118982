import ApiService from "../../config/apiService";
import {
  ADD_PERMISSION_FAILURE,
  ADD_PERMISSION_REQUEST,
  ADD_PERMISSION_SUCCESS,
  ADD_SECURITIES_FAILURE,
  ADD_SECURITIES_REQUEST,
  ADD_SECURITIES_SUCCESS,
  DELETE_CANDIDATE_FAILURE,
  DELETE_CANDIDATE_REQUEST,
  DELETE_CANDIDATE_SUCCESS,
  DELETE_PARTY_FAILURE,
  DELETE_PARTY_REQUEST,
  DELETE_PARTY_SUCCESS,
  DEL_MANAGE_DISTRICTS_BY_ID_REQUEST,
  DEL_MANAGE_PROVINCE_BY_ID_FAILURE,
  DEL_MANAGE_PROVINCE_BY_ID_REQUEST,
  DEL_MANAGE_PROVINCE_BY_ID_SUCCESS,
  DEL_POLLING_DIVISIONS_BY_DISTRICT_ID_FAILURE,
  DEL_POLLING_DIVISIONS_BY_DISTRICT_ID_REQUEST,
  DEL_POLLING_DIVISIONS_BY_DISTRICT_ID_SUCCESS,
  GET_ALL_POLLING_DIVISIONS_BY_DISTRICT_ID_FAILURE,
  GET_ALL_POLLING_DIVISIONS_BY_DISTRICT_ID_REQUEST,
  GET_ALL_POLLING_DIVISIONS_BY_DISTRICT_ID_SUCCESS,
  GET_ALL_PROVINCE_DISTRICTS_BY_ID_FAILURE,
  GET_ALL_PROVINCE_DISTRICTS_BY_ID_REQUEST,
  GET_ALL_PROVINCE_DISTRICTS_BY_ID_SUCCESS,
  GET_CANDIDATEBYID_FAILURE,
  GET_CANDIDATEBYID_REQUEST,
  GET_CANDIDATEBYID_SUCCESS,
  GET_CANDIDATE_FAILURE,
  GET_CANDIDATE_HISTORY_BY_ID_REQUEST,
  GET_CANDIDATE_HISTORY_BY_ID_SUCCESS,
  GET_CANDIDATE_REQUEST,
  GET_CANDIDATE_SUCCESS,
  GET_DASHBOARD_ADMIN_FAILURE,
  GET_DASHBOARD_ADMIN_REQUEST,
  GET_DASHBOARD_ADMIN_SUCCESS,
  GET_ELECTROAL_DISTRICTS_BY_ID_FAILURE,
  GET_ELECTROAL_DISTRICTS_BY_ID_REQUEST,
  GET_ELECTROAL_DISTRICTS_BY_ID_SUCCESS,
  GET_MANAGE_ASSOCIATED_CANDIDATE_BY_ID_FAILURE,
  GET_MANAGE_ASSOCIATED_CANDIDATE_BY_ID_REQUEST,
  GET_MANAGE_ASSOCIATED_CANDIDATE_BY_ID_SUCCESS,
  GET_MANAGE_CANDIDATE_TOE_BY_ID_FAILURE,
  GET_MANAGE_CANDIDATE_TOE_BY_ID_REQUEST,
  GET_MANAGE_CANDIDATE_TOE_BY_ID_SUCCESS,
  GET_MANAGE_DISTRICTS_FAILURE,
  GET_MANAGE_DISTRICTS_REQUEST,
  GET_MANAGE_DISTRICTS_SUCCESS,
  GET_PARTYBYID_FAILURE,
  GET_PARTYBYID_REQUEST,
  GET_PARTYBYID_SUCCESS,
  GET_PARTY_HISTORY_BY_ID_FAILURE,
  GET_PARTY_HISTORY_BY_ID_REQUEST,
  GET_PARTY_HISTORY_BY_ID_SUCCESS,
  GET_POLLING_DIVISIONS_BY_ID_FAILURE,
  GET_POLLING_DIVISIONS_BY_ID_REQUEST,
  GET_POLLING_DIVISIONS_BY_ID_SUCCESS,
  GET_SECURITY_LEVEL_DETAILS_BY_ID_FAILURE,
  GET_SECURITY_LEVEL_DETAILS_BY_ID_REQUEST,
  GET_SECURITY_LEVEL_DETAILS_BY_ID_SUCCESS,
  GET_SECURITY_LEVEL_PERMISSIONS_FAILURE,
  GET_SECURITY_LEVEL_PERMISSIONS_REQUEST,
  GET_SECURITY_LEVEL_PERMISSIONS_SUCCESS,
  GET_TYPE_OF_ELECTION_FAILURE,
  GET_TYPE_OF_ELECTION_FAILURE_ONE,
  GET_TYPE_OF_ELECTION_REQUEST,
  GET_TYPE_OF_ELECTION_REQUEST_ONE,
  GET_TYPE_OF_ELECTION_SUCCESS,
  GET_TYPE_OF_ELECTION_SUCCESS_ONE,
  LOGIN_ADMIN_FAILURE,
  LOGIN_ADMIN_REQUEST,
  LOGIN_ADMIN_SUCCESS,
  PERMISSION_FAILURE,
  PERMISSION_REQUEST,
  PERMISSION_SUCCESS,
  POST_ADD_CANDIDATE_TOE_BY_ID_FAILURE,
  POST_ADD_CANDIDATE_TOE_BY_ID_REQUEST,
  POST_ADD_CANDIDATE_TOE_BY_ID_SUCCESS,
  POST_CANDIDATE_FAILURE,
  POST_CANDIDATE_REQUEST,
  POST_CANDIDATE_SUCCESS,
  POST_MANAGEPARTIES_FAILURE,
  POST_MANAGEPARTIES_REQUEST,
  POST_MANAGEPARTIES_SUCCESS,
  POST_MANAGE_DISTRICTS_FAILURE,
  POST_MANAGE_DISTRICTS_REQUEST,
  POST_MANAGE_DISTRICTS_SUCCESS,
  POST_MANAGE_ELECTION_TYPE_ID_FAILURE,
  POST_MANAGE_ELECTION_TYPE_ID_REQUEST,
  POST_MANAGE_ELECTION_TYPE_ID_SUCCESS,
  POST_POLLING_DIVISIONS_BY_DISTRICT_ID_FAILURE,
  POST_POLLING_DIVISIONS_BY_DISTRICT_ID_REQUEST,
  POST_POLLING_DIVISIONS_BY_DISTRICT_ID_SUCCESS,
  POST_UPDATE_ELECTION_CANDIDATES_BY_ID_FAILURE,
  POST_UPDATE_ELECTION_CANDIDATES_BY_ID_REQUEST,
  POST_UPDATE_ELECTION_CANDIDATES_BY_ID_SUCCESS,
  POST_UPDATE_ELECTROL_DISTRICTS_BY_ID_FAILURE,
  POST_UPDATE_ELECTROL_DISTRICTS_BY_ID_REQUEST,
  POST_UPDATE_ELECTROL_DISTRICTS_BY_ID_SUCCESS,
  POST_UPDATE_MANAGE_ELECTIONS_BY_ID_FAILURE,
  POST_UPDATE_MANAGE_ELECTIONS_BY_ID_REQUEST,
  POST_UPDATE_MANAGE_ELECTIONS_BY_ID_SUCCESS,
  POST_UPDATE_PERMISSION_BY_ID_FAILURE,
  POST_UPDATE_PERMISSION_BY_ID_REQUEST,
  POST_UPDATE_PERMISSION_BY_ID_SUCCESS,
  POST_UPDATE_POLLING_DIVISIONS_BY_DISTRICT_ID_FAILURE,
  POST_UPDATE_POLLING_DIVISIONS_BY_DISTRICT_ID_REQUEST,
  POST_UPDATE_POLLING_DIVISIONS_BY_DISTRICT_ID_SUCCESS,
  POST_UPDATE_SECURITY_LEVEL_BY_ID_FAILURE,
  POST_UPDATE_SECURITY_LEVEL_BY_ID_REQUEST,
  POST_UPDATE_SECURITY_LEVEL_BY_ID_SUCCESS,
  SECURITIES_FAILURE,
  SECURITIES_REQUEST,
  SECURITIES_SUCCESS,
  UPDATE_CANDIDATEBYID_FAILURE,
  UPDATE_CANDIDATEBYID_REQUEST,
  UPDATE_CANDIDATEBYID_SUCCESS,
  UPDATE_CANDIDATE_HISTORY_FAILURE,
  UPDATE_CANDIDATE_HISTORY_REQUEST,
  UPDATE_CANDIDATE_HISTORY_SUCCESS,
  UPDATE_PARTY_FAILURE,
  UPDATE_PARTY_HISTORY_FAILURE,
  UPDATE_PARTY_HISTORY_REQUEST,
  UPDATE_PARTY_HISTORY_SUCCESS,
  UPDATE_PARTY_REQUEST,
  UPDATE_PARTY_SUCCESS,
  UPDATE_SECURITYLEVEL_PERMISSION_FAILURE,
  UPDATE_SECURITYLEVEL_PERMISSION_REQUEST,
  UPDATE_SECURITYLEVEL_PERMISSION_SUCCESS,
  UPDATE_SecurityLevels_REQUEST,
  UPDATE_SecurityLevels_SUCCESS,
  UPDATE_SecurityLevels_FAILURE,
  GET_USERDETAILS_BYID_REQUEST,
  GET_USERDETAILS_BYID_SUCCESS,
  GET_USERDETAILS_BYID_FAILURE,
  GET_MANAGE_USERLIST_REQUEST,
  GET_MANAGE_USERLIST_FAILURE,
  GET_MANAGE_USERLIST_SUCCESS,
  POST_UPDATE_PROVINCES_BY_PROVINCES_ID_REQUEST,
  POST_UPDATE_PROVINCES_BY_PROVINCES_ID_SUCCESS,
  POST_UPDATE_PROVINCES_BY_PROVINCES_ID_FAILURE,
  POST_MANAGE_PROVINCE_FAILURE,
  POST_MANAGE_PROVINCE_SUCCESS,
  POST_MANAGE_PROVINCE_REQUEST,
  GET_PERMISSIONS_BY_ID_REQUEST,
  GET_PERMISSIONS_BY_ID_SUCCESS,
  GET_PERMISSIONS_BY_ID_FAILURE,
  GET_PARTIES_REQUEST,
  GET_PARTIES_SUCCESS,
  GET_PARTIES_FAILURE,
  GET_CANDIDATE_HISTORY_BY_ID_FAILURE,
  // GET_MANAGE_PROVINCE_BY_ID_REQUEST,
  // GET_MANAGE_PROVINCE_BY_ID_SUCCESS,
  // GET_MANAGE_PROVINCE_BY_ID_FAILURE,
  DEL_MANAGE_DISTRICTS_BY_ID_SUCCESS,
  DEL_MANAGE_DISTRICTS_BY_ID_FAILURE,
  GET_ALL_PROVINCES_REQUEST,
  GET_ALL_PROVINCES_SUCCESS,
  GET_ALL_PROVINCES_FAILURE,
  LOGOUT_ADMIN,
  GET_DATAELECTIONRESULTS_BYID_REQUEST,
  GET_DATAELECTIONRESULTS_BYID_SUCCESS,
  GET_DATAELECTIONRESULTS_BYID_FAILURE,
  POST_UPDATE_PAST_ELECTIONRESULTS_BY_ID_REQUEST,
  POST_UPDATE_PAST_ELECTIONRESULTS_BY_ID_SUCCESS,
  POST_UPDATE_PAST_ELECTIONRESULTS_BY_ID_FAILURE,
  GET_ALL_BLOG_CATEGORY_REQUEST,
  GET_ALL_BLOG_CATEGORY_SUCCESS,
  GET_ALL_BLOG_CATEGORY_FAILURE,
  ADD_BLOG_CATEGORY_REQUEST,
  ADD_BLOG_CATEGORY_SUCCESS,
  ADD_BLOG_CATEGORY_FAILURE,
  POST_UPDATE_BLOG_CATEGORY_BY_ID_FAILURE,
  POST_UPDATE_BLOG_CATEGORY_BY_ID_SUCCESS,
  POST_UPDATE_BLOG_CATEGORY_BY_ID_REQUEST,
  GET_BLOGCATEGORY_BYID_FAILURE,
  GET_BLOGCATEGORY_BYID_SUCCESS,
  GET_BLOGCATEGORY_BYID_REQUEST,
  DELETE_BLOGCATEGORY_REQUEST,
  DELETE_BLOGCATEGORY_SUCCESS,
  DELETE_BLOGCATEGORY_FAILURE,
  GET_ALL_BLOG_CONTENT_REQUEST,
  GET_ALL_BLOG_CONTENT_SUCCESS,
  GET_ALL_BLOG_CONTENT_FAILURE,
  GET_BLOGCONTENT_BYID_REQUEST,
  GET_BLOGCONTENT_BYID_SUCCESS,
  GET_BLOGCONTENT_BYID_FAILURE,
  POST_UPDATE_BLOG_CONTENT_BY_ID_FAILURE,
  POST_UPDATE_BLOG_CONTENT_BY_ID_SUCCESS,
  POST_UPDATE_BLOG_CONTENT_BY_ID_REQUEST,
  ADD_BLOG_CONTENT_REQUEST,
  ADD_BLOG_CONTENT_SUCCESS,
  ADD_BLOG_CONTENT_FAILURE,
  DELETE_BLOGCONTENT_REQUEST,
  DELETE_BLOGCONTENT_SUCCESS,
  DELETE_BLOGCONTENT_FAILURE,
  GET_ALL_PRESIDENTIAL_ELECTION_RESULTS_BY_ID_REQUEST,
  GET_ALL_PRESIDENTIAL_ELECTION_RESULTS_BY_ID_SUCCESS,
  GET_ALL_PRESIDENTIAL_ELECTION_RESULTS_BY_ID_FAILURE,
  GET_ALL_ODEMOCARCY_POLL_RESULT_REQUEST,
  GET_ALL_ODEMOCARCY_POLL_RESULT_SUCCESS,
  GET_ALL_ODEMOCARCY_POLL_RESULT_FAILURE,
  GET_PARLIAMENT_ELECTION_RESULTS_BY_ELECTION_TITLE_ID_REQUEST,
  GET_PARLIAMENT_ELECTION_RESULTS_BY_ELECTION_TITLE_ID_SUCCESS,
  GET_PARLIAMENT_ELECTION_RESULTS_BY_ELECTION_TITLE_ID_FAILURE,
} from "./actionTypes";

// login Admin action start
export const loginAdmin = (adminFormData, callback) => async (dispatch) => {
  dispatch({ type: LOGIN_ADMIN_REQUEST });

  try {
    const response = await ApiService.post("/api/admin/login", adminFormData);

    const responsePayload = {
      data: response.data,
      status: response.status,
    };
    dispatch({ type: LOGIN_ADMIN_SUCCESS, payload: responsePayload });

    if (callback) {
      callback(null, responsePayload);
    }
  } catch (error) {
    const errorPayload = {
      // message: error.message,
      status: error.response.status,
      data: error.response.data,
    };
    dispatch({
      type: LOGIN_ADMIN_FAILURE,
      payload: errorPayload,
    });

    // Pass the error to the callback if it exists
    if (callback) {
      callback(errorPayload); // Pass the errorPayload as the first argument
    }
  }
};
// login Admin action end

// admin dashboard action start
export const adminDashboard = () => async (dispatch) => {
  dispatch({ type: GET_DASHBOARD_ADMIN_REQUEST });
  try {
    const response = await ApiService.get("/api/getalladmindashboard");

    const responsePayload = {
      data: response.data,
      status: response.status,
    };
    dispatch({ type: GET_DASHBOARD_ADMIN_SUCCESS, payload: responsePayload });
  } catch (error) {
    dispatch({
      type: GET_DASHBOARD_ADMIN_FAILURE,
      payload: error,
    });
  }
};
// admin dashboard action end
// add security action start
export const addSecurity = (securityFormdata, callback) => async (dispatch) => {
  dispatch({ type: ADD_SECURITIES_REQUEST });
  try {
    const response = await ApiService.post(
      "/api/managesecuritylevel",
      securityFormdata
    );

    dispatch({ type: ADD_SECURITIES_SUCCESS, payload: response });
    if (callback) {
      callback(null, response);
    }
  } catch (error) {
    dispatch({
      type: ADD_SECURITIES_FAILURE,
      payload: error,
    });
    if (callback) {
      callback(error, null);
    }
  }
};
// add security action end
//update security action start
export const UpdateSecurityLevelById =
  (securityFormdata, id, callback) => async (dispatch) => {
    dispatch({ type: POST_UPDATE_SECURITY_LEVEL_BY_ID_REQUEST });
    try {
      const response = await ApiService.post(
        `/api/managesecuritylevelbyId/${id}`,
        securityFormdata
      );

      dispatch({
        type: POST_UPDATE_SECURITY_LEVEL_BY_ID_SUCCESS,
        payload: response,
      });
      if (callback) {
        callback(null, response);
      }
    } catch (error) {
      dispatch({
        type: POST_UPDATE_SECURITY_LEVEL_BY_ID_FAILURE,
        payload: error,
      });
      if (callback) {
        callback(error, null);
      }
    }
  };
//update security action end
//show security level Details By Id start
export const ShowSecurityLevelById = (id) => async (dispatch) => {
  dispatch({ type: GET_SECURITY_LEVEL_DETAILS_BY_ID_REQUEST });
  try {
    const response = await ApiService.get(
      `/api/getSecurityleveldetailsById/${id}`
    );

    const responsePayload = {
      data: response.data,
      status: response.status,
    };
    dispatch({
      type: GET_SECURITY_LEVEL_DETAILS_BY_ID_SUCCESS,
      payload: responsePayload,
    });
  } catch (error) {
    const errorPayload = {
      // message: error.message,
      status: error.response.status,
      data: error.response.data,
    };
    dispatch({
      type: GET_SECURITY_LEVEL_DETAILS_BY_ID_FAILURE,
      payload: errorPayload,
    });
  }
};
//show security level Details By Id end
// index security level start
export const indexSecurityLevel = () => async (dispatch) => {
  dispatch({ type: SECURITIES_REQUEST });
  try {
    const response = await ApiService.get("/api/getallSecuritylevels");

    const responsePayload = {
      data: response.data,
      status: response.status,
    };
    dispatch({ type: SECURITIES_SUCCESS, payload: responsePayload });
  } catch (error) {
    const errorPayload = {
      // message: error.message,
      status: error.response.status,
      data: error.response.data,
    };
    dispatch({
      type: SECURITIES_FAILURE,
      payload: errorPayload,
    });
  }
};
// index security level end
// show security level permissions by id start
export const ShowSecurityLevelPermissionsById = (id) => async (dispatch) => {
  dispatch({ type: GET_SECURITY_LEVEL_PERMISSIONS_REQUEST });
  try {
    const response = await ApiService.get(
      `/api/getsecuritylevelpermissions/${id}`
    );

    const responsePayload = {
      data: response.data,
      status: response.status,
    };
    dispatch({
      type: GET_SECURITY_LEVEL_PERMISSIONS_SUCCESS,
      payload: responsePayload,
    });
  } catch (error) {
    const errorPayload = {
      // message: error.message,
      status: error.response.status,
      data: error.response.data,
    };
    dispatch({
      type: GET_SECURITY_LEVEL_PERMISSIONS_FAILURE,
      payload: errorPayload,
    });
  }
};
// show security level permissions by id end
// index prmissions start
export const indexPermission = () => async (dispatch) => {
  dispatch({ type: PERMISSION_REQUEST });
  try {
    const response = await ApiService.get("/api/getallpermissions");

    const responsePayload = {
      data: response.data,
      status: response.status,
    };
    dispatch({ type: PERMISSION_SUCCESS, payload: responsePayload });
  } catch (error) {
    const errorPayload = {
      // message: error.message,
      status: error.response.status,
      data: error.response.data,
    };
    dispatch({
      type: PERMISSION_FAILURE,
      payload: errorPayload,
    });
  }
};
// index prmissions end
//update security  level permission start
export const updateSecurityLevelPermission =
  (SecurityLevelPermissionFormData, id, callback) => async (dispatch) => {
    dispatch({ type: UPDATE_SECURITYLEVEL_PERMISSION_REQUEST });
    try {
      const response = await ApiService.post(
        `/api/managesecuritylevelpermissions/${id}`,
        SecurityLevelPermissionFormData
      );

      const responsePayload = {
        data: response.data,
        status: response.status,
      };
      dispatch({
        type: UPDATE_SECURITYLEVEL_PERMISSION_SUCCESS,
        payload: responsePayload,
      });
      if (callback) {
        callback(null, responsePayload);
      }
    } catch (error) {
      const errorPayload = {
        // message: error.message,
        status: error.response.status,
        data: error.response.data,
      };
      dispatch({
        type: UPDATE_SECURITYLEVEL_PERMISSION_FAILURE,
        payload: errorPayload,
      });
      if (callback) {
        callback(errorPayload, null);
      }
    }
  };
//update security  level permission end
//add permission start
export const addPermision =
  (formDataPermission, callback) => async (dispatch) => {
    dispatch({ type: ADD_PERMISSION_REQUEST });
    try {
      const response = await ApiService.post(
        "/api/addpermissions",
        formDataPermission
      );

      const responsePayload = {
        data: response.data,
        status: response.status,
      };
      dispatch({ type: ADD_PERMISSION_SUCCESS, payload: responsePayload });
      if (callback) {
        callback(null, responsePayload);
      }
    } catch (error) {
      const errorPayload = {
        // message: error.message,
        status: error.response.status,
        data: error.response.data,
      };
      dispatch({
        type: ADD_PERMISSION_FAILURE,
        payload: errorPayload,
      });
      if (callback) {
        callback(errorPayload, null);
      }
    }
  };
//add permission end
//show permission by id start
export const showPermissionByID = (id) => async (dispatch) => {
  dispatch({ type: GET_PERMISSIONS_BY_ID_REQUEST });
  try {
    const response = await ApiService.get(
      `/api/getpermissionsdetailsById/${id}`
    );

    dispatch({ type: GET_PERMISSIONS_BY_ID_SUCCESS, payload: response });
  } catch (error) {
    dispatch({
      type: GET_PERMISSIONS_BY_ID_FAILURE,
      payload: error,
    });
  }
};
//show permission by id end
//update permission by id start
export const updatePermissionsById =
  (formDataPermission, id, callback) => async (dispatch) => {
    dispatch({ type: POST_UPDATE_PERMISSION_BY_ID_REQUEST });
    try {
      const response = await ApiService.post(
        `/api/managepermissionsbyId/${id}`,
        formDataPermission
      );

      const responsePayload = {
        data: response.data,
        status: response.status,
      };
      dispatch({
        type: POST_UPDATE_PERMISSION_BY_ID_SUCCESS,
        payload: responsePayload,
      });
      if (callback) {
        callback(null, responsePayload);
      }
    } catch (error) {
      const errorPayload = {
        // message: error.message,
        status: error.response.status,
        data: error.response.data,
      };
      dispatch({
        type: POST_UPDATE_PERMISSION_BY_ID_FAILURE,
        payload: errorPayload,
      });
      if (callback) {
        callback(errorPayload, null);
      }
    }
  };
//update permission by id end
//show candidate by id start
export const ShowCandidateById = (id) => async (dispatch) => {
  dispatch({ type: GET_CANDIDATEBYID_REQUEST });
  try {
    const response = await ApiService.get(
      `/api/showcandidateDetailsbycandidateId/${id}`
    );

    const responsePayload = {
      data: response.data,
      status: response.status,
    };
    dispatch({ type: GET_CANDIDATEBYID_SUCCESS, payload: responsePayload });
  } catch (error) {
    const errorPayload = {
      status: error.response.status,
      data: error.response.data,
    };
    dispatch({
      type: GET_CANDIDATEBYID_FAILURE,
      payload: errorPayload,
    });
  }
};
//show candidate by id end
//index parties start
export const indexParties = () => async (dispatch) => {
  dispatch({ type: GET_PARTIES_REQUEST });
  try {
    const response = await ApiService.get("/api/getallparties");

    const responsePayload = {
      data: response.data,
      status: response.status,
    };
    dispatch({ type: GET_PARTIES_SUCCESS, payload: responsePayload });
  } catch (error) {
    dispatch({
      type: GET_PARTIES_FAILURE,
      payload: error,
    });
  }
};
//index parties end
//add candidate start
export const addCandidate =
  (formDataCandidate, callback) => async (dispatch) => {
    dispatch({ type: POST_CANDIDATE_REQUEST });
    try {
      const response = await ApiService.post(
        "/api/Managecandidate",
        formDataCandidate
      );

      const responsePayload = {
        data: response.data,
        status: response.status,
      };
      dispatch({ type: POST_CANDIDATE_SUCCESS, payload: responsePayload });
      if (callback) {
        callback(null, responsePayload);
      }
    } catch (error) {
      const errorPayload = {
        // message: error.message,
        status: error.response.status,
        data: error.response.data,
      };
      dispatch({
        type: POST_CANDIDATE_FAILURE,
        payload: errorPayload,
      });
      if (callback) {
        callback(errorPayload, null);
      }
    }
  };
//add candidate end
//update candidate by id start
export const updateCandidateById =
  (formDataCandidate, id, callback) => async (dispatch) => {
    dispatch({ type: UPDATE_CANDIDATEBYID_REQUEST });
    try {
      const response = await ApiService.post(
        `/api/managecandidatebyId/${id}`,
        formDataCandidate
      );

      dispatch({ type: UPDATE_CANDIDATEBYID_SUCCESS, payload: response });
      if (callback) {
        callback(null, response);
      }
    } catch (error) {
      dispatch({
        type: UPDATE_CANDIDATEBYID_FAILURE,
        payload: error,
      });
      if (callback) {
        callback(error, null);
      }
    }
  };
//update candidate by id end
//del candidate by id start
export const delCandidateById = (id, callback) => async (dispatch) => {
  dispatch({ type: DELETE_CANDIDATE_REQUEST });
  try {
    const response = await ApiService.delete(`/api/deletecandidatebyId/${id}`);

    const responsePayload = {
      data: response.data,
      status: response.status,
    };
    dispatch({ type: DELETE_CANDIDATE_SUCCESS, payload: responsePayload });
    if (callback) {
      callback(null, responsePayload);
    }
  } catch (error) {
    const errorPayload = {
      // message: error.message,
      status: error.response.status,
      data: error.response.data,
    };
    dispatch({ type: DELETE_CANDIDATE_FAILURE, payload: errorPayload });
    if (callback) {
      callback(errorPayload, null);
    }
  }
};
//del candidate by id end
//index candidate start
export const indexCandidate = () => async (dispatch) => {
  dispatch({ type: GET_CANDIDATE_REQUEST });
  try {
    const response = await ApiService.get("/api/getallcandidates");

    const responsePayload = {
      data: response.data,
      status: response.status,
    };
    dispatch({ type: GET_CANDIDATE_SUCCESS, payload: responsePayload });
  } catch (error) {
    const errorPayload = {
      // message: error.message,
      status: error.response.status,
      data: error.response.data,
    };
    dispatch({
      type: GET_CANDIDATE_FAILURE,
      payload: errorPayload,
    });
  }
};

//index candidate end
//show candidate history by id start
export const ShowCandidateHistoryById = (id) => async (dispatch) => {
  dispatch({ type: GET_CANDIDATE_HISTORY_BY_ID_REQUEST });
  try {
    const response = await ApiService.get(
      `/api/getallcandidatehistorybycandidateidId/${id}`
    );

    const responsePayload = {
      data: response.data,
      status: response.status,
    };
    dispatch({
      type: GET_CANDIDATE_HISTORY_BY_ID_SUCCESS,
      payload: responsePayload,
    });
  } catch (error) {
    const errorPayload = {
      // message: error.message,
      status: error.response.status,
      data: error.response.data,
    };
    dispatch({
      type: GET_CANDIDATE_HISTORY_BY_ID_FAILURE,
      payload: errorPayload,
    });
  }
};
//show candidate history by id end
//update candidate history by id start
export const updateCandidateHistoryById =
  (updateHistory, id, callback) => async (dispatch) => {
    dispatch({ type: UPDATE_CANDIDATE_HISTORY_REQUEST });
    try {
      const response = await ApiService.post(
        `/api/updatecandidatehistorybycandidateidId/${id}`,
        updateHistory
      );

      const responsePayload = {
        data: response.data,
        status: response.status,
      };
      dispatch({
        type: UPDATE_CANDIDATE_HISTORY_SUCCESS,
        payload: responsePayload,
      });
      if (callback) {
        callback(null, responsePayload);
      }
    } catch (error) {
      const errorPayload = {
        // message: error.message,
        status: error.response.status,
        data: error.response.data,
      };
      dispatch({
        type: UPDATE_CANDIDATE_HISTORY_FAILURE,
        payload: errorPayload,
      });
      if (callback) {
        callback(errorPayload, null);
      }
    }
  };
//update candidate history by id end
//index province start
export const indexProvince = () => async (dispatch) => {
  dispatch({ type: GET_ALL_PROVINCES_REQUEST });
  try {
    const response = await ApiService.get("/api/getallprovinces");

    const responsePayload = {
      data: response.data,
      status: response.status,
    };
    dispatch({ type: GET_ALL_PROVINCES_SUCCESS, payload: responsePayload });
  } catch (error) {
    const errorPayload = {
      // message: error.message,
      status: error.response.status,
      data: error.response.data,
    };
    dispatch({
      type: GET_ALL_PROVINCES_FAILURE,
      payload: errorPayload,
    });
  }
};
//index province end
//update Provinces By Province Id start
export const updateProvincesByProvinceId =
  (formDataAddProvince, id, callback) => async (dispatch) => {
    dispatch({ type: POST_UPDATE_PROVINCES_BY_PROVINCES_ID_REQUEST });
    try {
      const response = await ApiService.post(
        `/api/updateprovincesbyprovinceId/${id}`,
        formDataAddProvince
      );

      const responsePayload = {
        data: response.data,
        status: response.status,
      };
      dispatch({
        type: POST_UPDATE_PROVINCES_BY_PROVINCES_ID_SUCCESS,
        payload: responsePayload,
      });
      if (callback) {
        callback(null, responsePayload);
      }
    } catch (error) {
      const errorPayload = {
        // message: error.message,
        status: error.response.status,
        data: error.response.data,
      };
      dispatch({
        type: POST_UPDATE_PROVINCES_BY_PROVINCES_ID_FAILURE,
        payload: errorPayload,
      });
      if (callback) {
        callback(errorPayload, null);
      }
    }
  };
//update Provinces By Province Id end
//add province start
export const addProvince =
  (formDataAddProvince, callback) => async (dispatch) => {
    dispatch({ type: POST_MANAGE_PROVINCE_REQUEST });
    try {
      const response = await ApiService.post(
        "/api/addprovinces",
        formDataAddProvince
      );

      const responsePayload = {
        data: response.data,
        status: response.status,
      };
      dispatch({
        type: POST_MANAGE_PROVINCE_SUCCESS,
        payload: responsePayload,
      });
      if (callback) {
        callback(null, responsePayload);
      }
    } catch (error) {
      const errorPayload = {
        // message: error.message,
        status: error.response.status,
        data: error.response.data,
      };
      dispatch({
        type: POST_MANAGE_PROVINCE_FAILURE,
        payload: errorPayload,
      });
      if (callback) {
        callback(errorPayload, null);
      }
    }
  };
//add province end
//add district start
export const addDistricts =
  (formDataDistrict, callback) => async (dispatch) => {
    dispatch({ type: POST_MANAGE_DISTRICTS_REQUEST });
    try {
      const response = await ApiService.post(
        "/api/addelectoraldistricts",
        formDataDistrict
      );

      const responsePayload = {
        data: response.data,
        status: response.status,
      };
      dispatch({
        type: POST_MANAGE_DISTRICTS_SUCCESS,
        payload: responsePayload,
      });
      if (callback) {
        callback(null, responsePayload);
      }
    } catch (error) {
      const errorPayload = {
        // message: error.message,
        status: error.response.status,
        data: error.response.data,
      };
      dispatch({
        type: POST_MANAGE_DISTRICTS_FAILURE,
        payload: errorPayload,
      });
      if (callback) {
        callback(errorPayload, null);
      }
    }
  };
//add district end
//show get electroal district by id start
export const showElectroalDistrictById = (id) => async (dispatch) => {
  dispatch({ type: GET_ELECTROAL_DISTRICTS_BY_ID_REQUEST });
  try {
    const response = await ApiService.get(
      `/api/getelectoraldistrictsbyId/${id}`
    );

    const responsePayload = {
      data: response.data,
      status: response.status,
    };
    dispatch({
      type: GET_ELECTROAL_DISTRICTS_BY_ID_SUCCESS,
      payload: responsePayload,
    });
  } catch (error) {
    const errorPayload = {
      // message: error.message,
      status: error.response.status,
      data: error.response.data,
    };
    dispatch({
      type: GET_ELECTROAL_DISTRICTS_BY_ID_FAILURE,
      payload: errorPayload,
    });
  }
};
//show get electroal district by id end
//update electroal district by id start
export const updateElectroalDistrictById =
  (formDataDistrict, id, callback) => async (dispatch) => {
    dispatch({ type: POST_UPDATE_ELECTROL_DISTRICTS_BY_ID_REQUEST });
    try {
      const response = await ApiService.post(
        `/api/updateelectoraldistrictsbyId/${id}`,
        formDataDistrict
      );

      const responsePayload = {
        data: response.data,
        status: response.status,
      };
      dispatch({
        type: POST_UPDATE_ELECTROL_DISTRICTS_BY_ID_SUCCESS,
        payload: responsePayload,
      });
      if (callback) {
        callback(null, responsePayload);
      }
    } catch (error) {
      const errorPayload = {
        // message: error.message,
        status: error.response.status,
        data: error.response.data,
      };
      dispatch({
        type: POST_UPDATE_ELECTROL_DISTRICTS_BY_ID_FAILURE,
        payload: errorPayload,
      });
      if (callback) {
        callback(errorPayload, null);
      }
    }
  };
//update electroal district by id end
//del districts by id start
export const delDistrictById = (id, callback) => async (dispatch) => {
  dispatch({ type: DEL_MANAGE_DISTRICTS_BY_ID_REQUEST });
  try {
    const response = await ApiService.delete(
      `/api/deleteelectoraldistrictsbyId/${id}`
    );

    dispatch({ type: DEL_MANAGE_DISTRICTS_BY_ID_SUCCESS, payload: response });
    if (callback) {
      callback(null, response);
    }
  } catch (error) {
    dispatch({ type: DEL_MANAGE_DISTRICTS_BY_ID_FAILURE, payload: error });
    if (callback) {
      callback(error, null);
    }
  }
};
//del districts by id end
//index districts start
export const indexDistrict = () => async (dispatch) => {
  dispatch({ type: GET_MANAGE_DISTRICTS_REQUEST });
  try {
    const response = await ApiService.get("/api/getallelectoraldistricts");

    const responsePayload = {
      data: response.data,
      status: response.status,
    };
    dispatch({ type: GET_MANAGE_DISTRICTS_SUCCESS, payload: responsePayload });
  } catch (error) {
    const errorPayload = {
      // message: error.message,
      status: error.response?.status,
      data: error.response?.data,
    };
    dispatch({
      type: GET_MANAGE_DISTRICTS_FAILURE,
      payload: errorPayload,
    });
  }
};
//index districts end
//show manage associate candidate by id start
export const showManageAssociatedCandidateById = (id) => async (dispatch) => {
  dispatch({ type: GET_MANAGE_ASSOCIATED_CANDIDATE_BY_ID_REQUEST });
  try {
    const response = await ApiService.get(
      `/api/CandidatesearchbyPartyId/${id}`
    );

    const responsePayload = {
      data: response.data,
      status: response.status,
    };
    dispatch({
      type: GET_MANAGE_ASSOCIATED_CANDIDATE_BY_ID_SUCCESS,
      payload: responsePayload,
    });
  } catch (error) {
    const errorPayload = {
      // message: error.message,
      status: error.response.status,
      data: error.response.data,
    };

    dispatch({
      type: GET_MANAGE_ASSOCIATED_CANDIDATE_BY_ID_FAILURE,
      payload: errorPayload,
    });
  }
};
//show manage associate candidate by id end
//show All Province Districts By Id start
export const showAllProvinceDistrictsById = (id) => async (dispatch) => {
  dispatch({ type: GET_ALL_PROVINCE_DISTRICTS_BY_ID_REQUEST });
  try {
    const response = await ApiService.get(`/api/getallprovincedistricts/${id}`);

    const responsePayload = {
      data: response.data,
      status: response.status,
    };
    dispatch({
      type: GET_ALL_PROVINCE_DISTRICTS_BY_ID_SUCCESS,
      payload: responsePayload,
    });
  } catch (error) {
    const errorPayload = {
      // message: error.message,
      status: error.response.status,
      data: error.response.data,
    };
    dispatch({
      type: GET_ALL_PROVINCE_DISTRICTS_BY_ID_FAILURE,
      payload: errorPayload,
    });
  }
};
//show All Province Districts By Id end
//add manage parties start
export const addParty = (formDataCandidate, callback) => async (dispatch) => {
  dispatch({ type: POST_MANAGEPARTIES_REQUEST });
  try {
    const response = await ApiService.post(
      "/api/Manageparty",
      formDataCandidate
    );

    const responsePayload = {
      data: response.data,
      status: response.status,
    };
    dispatch({ type: POST_MANAGEPARTIES_SUCCESS, payload: responsePayload });
    if (callback) {
      callback(null, responsePayload);
    }
  } catch (error) {
    const errorPayload = {
      // message: error.message,
      status: error.response.status,
      data: error.response.data,
    };
    dispatch({
      type: POST_MANAGEPARTIES_FAILURE,
      payload: errorPayload,
    });
    if (callback) {
      callback(errorPayload);
    }
  }
};
//add manage parties end
//show party by id start
export const showPartyById = (id) => async (dispatch) => {
  dispatch({ type: GET_PARTYBYID_REQUEST });
  try {
    const response = await ApiService.get(`/api/partybyId/${id}`);

    const responsePayload = {
      data: response.data,
      status: response.status,
    };
    dispatch({ type: GET_PARTYBYID_SUCCESS, payload: responsePayload });
  } catch (error) {
    const errorPayload = {
      // message: error.message,
      status: error.response.status,
      data: error.response.data,
    };
    dispatch({
      type: GET_PARTYBYID_FAILURE,
      payload: errorPayload,
    });
  }
};
//show party by id end
//update party by id start
export const updatePartyById =
  (formDataCandidate, id, callback) => async (dispatch) => {
    dispatch({ type: UPDATE_PARTY_REQUEST });
    try {
      const response = await ApiService.post(
        `/api/updatepartybyId/${id}`,
        formDataCandidate
      );

      const responsePayload = {
        data: response.data,
        status: response.status,
      };
      dispatch({ type: UPDATE_PARTY_SUCCESS, payload: responsePayload });
      if (callback) {
        callback(null, response);
      }
    } catch (error) {
      const errorPayload = {
        // message: error.message,
        status: error.response.status,
        data: error.response.data,
      };
      dispatch({
        type: UPDATE_PARTY_FAILURE,
        payload: errorPayload,
      });
      if (callback) {
        callback(error, null);
      }
    }
  };
//update party by id end
//del party by id start
export const delPartyById = (id, callback) => async (dispatch) => {
  dispatch({ type: DELETE_PARTY_REQUEST });
  try {
    const response = await ApiService.delete(`/api/deletepartybyId/${id}`);

    const responsePayload = {
      data: response.data,
      status: response.status,
    };
    dispatch({ type: DELETE_PARTY_SUCCESS, payload: responsePayload });
    if (callback) {
      callback(null, response);
    }
  } catch (error) {
    const errorPayload = {
      // message: error.message,
      status: error.response.status,
      data: error.response.data,
    };
    dispatch({ type: DELETE_PARTY_FAILURE, payload: errorPayload });
    if (callback) {
      callback(errorPayload, null);
    }
  }
};
//del party by id end
//show party history by id start
export const showPartyHistoryById = (id) => async (dispatch) => {
  dispatch({ type: GET_PARTY_HISTORY_BY_ID_REQUEST });
  try {
    const response = await ApiService.get(
      `/api/getallpartyhistorybypartyId/${id}`
    );

    const responsePayload = {
      data: response.data,
      status: response.status,
    };
    dispatch({
      type: GET_PARTY_HISTORY_BY_ID_SUCCESS,
      payload: responsePayload,
    });
  } catch (error) {
    const errorPayload = {
      // message: error.message,
      status: error.response.status,
      data: error.response.data,
    };
    dispatch({
      type: GET_PARTY_HISTORY_BY_ID_FAILURE,
      payload: errorPayload,
    });
  }
};
//show party history by id end
//update Party history by id start
export const updatePartyHistoryById =
  (updateHistory, id, callback) => async (dispatch) => {
    dispatch({ type: UPDATE_PARTY_HISTORY_REQUEST });
    try {
      const response = await ApiService.post(
        `/api/updatepartyhistorybypartyId/${id}`,
        updateHistory
      );

      const responsePayload = {
        data: response.data,
        status: response.status,
      };
      dispatch({
        type: UPDATE_PARTY_HISTORY_SUCCESS,
        payload: responsePayload,
      });
      if (callback) {
        callback(null, responsePayload);
      }
    } catch (error) {
      const errorPayload = {
        // message: error.message,
        status: error.response.status,
        data: error.response.data,
      };
      dispatch({
        type: UPDATE_PARTY_HISTORY_FAILURE,
        payload: errorPayload,
      });
      if (callback) {
        callback(errorPayload, null);
      }
    }
  };
//update Party history by id end
//add polling divisions  by districts id start
export const addPollingDivisionsByDistrictsId =
  (formDataAddPollingDivision, id, callback) => async (dispatch) => {
    dispatch({ type: POST_POLLING_DIVISIONS_BY_DISTRICT_ID_REQUEST });
    try {
      const response = await ApiService.post(
        `/api/addpollingdivisions/${id}`,
        formDataAddPollingDivision
      );

      const responsePayload = {
        data: response.data,
        status: response.status,
      };
      dispatch({
        type: POST_POLLING_DIVISIONS_BY_DISTRICT_ID_SUCCESS,
        payload: responsePayload,
      });
      if (callback) {
        callback(null, responsePayload);
      }
    } catch (error) {
      const errorPayload = {
        // message: error.message,
        status: error.response.status,
        data: error.response.data,
      };
      dispatch({
        type: POST_POLLING_DIVISIONS_BY_DISTRICT_ID_FAILURE,
        payload: errorPayload,
      });
      if (callback) {
        callback(errorPayload, null);
      }
    }
  };
//add polling divisions  by districts id end
//update Polling Divisions By District Id Start
export const updatePollingDivisionsByDistrictId =
  (formDataAddPollingDivision, id, callback) => async (dispatch) => {
    dispatch({ type: POST_UPDATE_POLLING_DIVISIONS_BY_DISTRICT_ID_REQUEST });
    try {
      const response = await ApiService.post(
        `/api/updatepollingdivisionsbyId/${id}`,
        formDataAddPollingDivision
      );
      const responsePayload = {
        data: response.data,
        status: response.status,
      };
      dispatch({
        type: POST_UPDATE_POLLING_DIVISIONS_BY_DISTRICT_ID_SUCCESS,
        payload: responsePayload,
      });
      if (callback) {
        callback(null, responsePayload);
      }
    } catch (error) {
      const errorPayload = {
        status: error.response.status,
        data: error.response.data,
      };
      dispatch({
        type: POST_UPDATE_POLLING_DIVISIONS_BY_DISTRICT_ID_FAILURE,
        payload: errorPayload,
      });
      if (callback) {
        callback(errorPayload, null);
      }
    }
  };
//update Polling Divisions By District Id End
//show polling divisions by id start
export const showPollingDivisionsById = (id) => async (dispatch) => {
  dispatch({ type: GET_POLLING_DIVISIONS_BY_ID_REQUEST });
  try {
    const response = await ApiService.get(`/api/getpollingdivisions/${id}`);

    const responsePayload = {
      data: response.data,
      status: response.status,
    };
    dispatch({
      type: GET_POLLING_DIVISIONS_BY_ID_SUCCESS,
      payload: responsePayload,
    });
  } catch (error) {
    const errorPayload = {
      // message: error.message,
      status: error.response.status,
      data: error.response.data,
    };
    dispatch({
      type: GET_POLLING_DIVISIONS_BY_ID_FAILURE,
      payload: errorPayload,
    });
  }
};
//show polling divisions by id end
//del polling divisions by district id start
export const delPollingDivisionsByDistrictId =
  (id, callback) => async (dispatch) => {
    dispatch({ type: DEL_POLLING_DIVISIONS_BY_DISTRICT_ID_REQUEST });
    try {
      const response = await ApiService.delete(
        `/api/deletepollingdivisionsbyId/${id}`
      );

      const responsePayload = {
        data: response.data,
        status: response.status,
      };
      dispatch({
        type: DEL_POLLING_DIVISIONS_BY_DISTRICT_ID_SUCCESS,
        payload: responsePayload,
      });
      if (callback) {
        callback(null, responsePayload);
      }
    } catch (error) {
      const errorPayload = {
        // message: error.message,
        status: error.response.status,
        data: error.response.data,
      };
      dispatch({
        type: DEL_POLLING_DIVISIONS_BY_DISTRICT_ID_FAILURE,
        payload: errorPayload,
      });
      if (callback) {
        callback(errorPayload, null);
      }
    }
  };

export const showAllPollingDivisionsByDistrictById =
  (id) => async (dispatch) => {
    dispatch({ type: GET_ALL_POLLING_DIVISIONS_BY_DISTRICT_ID_REQUEST });
    try {
      const response = await ApiService.get(
        `/api/getallpollingdivisions/${id}`
      );

      const responsePayload = {
        data: response.data,
        status: response.status,
      };

      dispatch({
        type: GET_ALL_POLLING_DIVISIONS_BY_DISTRICT_ID_SUCCESS,
        payload: responsePayload,
      });
    } catch (error) {
      const errorPayload = {
        // message: error.message,
        status: error.response.status,
        data: error.response.data,
      };
      dispatch({
        type: GET_ALL_POLLING_DIVISIONS_BY_DISTRICT_ID_FAILURE,
        payload: errorPayload,
      });
    }
  };
//show all polling divisions by district id end
//del province by id start
export const delProvinceById = (id, callback) => async (dispatch) => {
  dispatch({ type: DEL_MANAGE_PROVINCE_BY_ID_REQUEST });
  try {
    const response = await ApiService.delete(
      `/api/deleteprovincesprovinceId/${id}`
    );

    const responsePayload = {
      data: response.data,
      status: response.status,
    };
    dispatch({
      type: DEL_MANAGE_PROVINCE_BY_ID_SUCCESS,
      payload: responsePayload,
    });
    if (callback) {
      callback(null, responsePayload);
    }
  } catch (error) {
    const errorPayload = {
      // message: error.message,
      status: error.response.status,
      data: error.response.data,
    };
    dispatch({
      type: DEL_MANAGE_PROVINCE_BY_ID_FAILURE,
      payload: errorPayload,
    });
    if (callback) {
      callback(errorPayload, null);
    }
  }
};
//del province by id end
//add elections add candidates by id start
export const addElectionsAddCandidatesById =
  (jsonData, id, callback) => async (dispatch) => {
    dispatch({ type: POST_ADD_CANDIDATE_TOE_BY_ID_REQUEST });
    try {
      const response = await ApiService.post(
        `/api/electionsaddcandidatesbyId/${id}`,
        jsonData
      );

      dispatch({
        type: POST_ADD_CANDIDATE_TOE_BY_ID_SUCCESS,
        payload: response,
      });
      if (callback) {
        callback(null, response);
      }
    } catch (error) {
      dispatch({
        type: POST_ADD_CANDIDATE_TOE_BY_ID_FAILURE,
        payload: error,
      });
      if (callback) {
        callback(error, null);
      }
    }
  };
//add elections add candidates by id end
//get all candidate by title id start
export const getAllCandidateByTitleId = (id) => async (dispatch) => {
  dispatch({ type: GET_MANAGE_CANDIDATE_TOE_BY_ID_REQUEST });
  try {
    const response = await ApiService.get(
      `/api/getallcandidatebytitleId/${id}`
    );

    const responsePayload = {
      data: response.data,
      status: response.status,
    };
    dispatch({
      type: GET_MANAGE_CANDIDATE_TOE_BY_ID_SUCCESS,
      payload: responsePayload,
    });
  } catch (error) {
    const errorPayload = {
      // message: error.message,
      status: error.response.status,
      data: error.response.data,
    };
    dispatch({
      type: GET_MANAGE_CANDIDATE_TOE_BY_ID_FAILURE,
      payload: errorPayload,
    });
  }
};
//get all candidate by title id end
//update post manage elections by id start
export const updatePostManageElectionsById =
  (jsonData, id, callback) => async (dispatch) => {
    dispatch({ type: POST_UPDATE_ELECTION_CANDIDATES_BY_ID_REQUEST });
    try {
      const response = await ApiService.post(
        `/api/electionsupdatecandidatesbyId/${id}`,
        jsonData
      );

      dispatch({
        type: POST_UPDATE_ELECTION_CANDIDATES_BY_ID_SUCCESS,
        payload: response,
      });
      if (callback) {
        callback(null, response);
      }
    } catch (error) {
      dispatch({
        type: POST_UPDATE_ELECTION_CANDIDATES_BY_ID_FAILURE,
        payload: error,
      });
      if (callback) {
        callback(error, null);
      }
    }
  };
//update post manage elections by id end
//index get all manage elections start
export const indexGetAllManageElections = () => async (dispatch) => {
  dispatch({ type: GET_TYPE_OF_ELECTION_REQUEST });
  try {
    const response = await ApiService.get("/api/getallmanageelections");

    const responsePayload = {
      data: response.data,
      status: response.status,
    };
    dispatch({ type: GET_TYPE_OF_ELECTION_SUCCESS, payload: responsePayload });
  } catch (error) {
    const errorPayload = {
      status: error.response.status,
      data: error.response.data,
    };
    dispatch({
      type: GET_TYPE_OF_ELECTION_FAILURE,
      payload: errorPayload,
    });
  }
};
//index get all manage elections end
//index get all election detail start
export const indexGetAllElectionDetail = () => async (dispatch) => {
  dispatch({ type: GET_TYPE_OF_ELECTION_REQUEST_ONE });
  try {
    const response = await ApiService.get("/api/getallelectionsdetail");

    const responsePayload = {
      data: response.data,
      status: response.status,
    };
    dispatch({
      type: GET_TYPE_OF_ELECTION_SUCCESS_ONE,
      payload: responsePayload,
    });
  } catch (error) {
    const errorPayload = {
      // message: error.message,
      status: error.response.status,
      data: error.response.data,
    };
    dispatch({
      type: GET_TYPE_OF_ELECTION_FAILURE_ONE,
      payload: errorPayload,
    });
  }
};
//index get all election detail start
//add manage election by election type id start
export const addManageElectionByElectionTypeId =
  (formDataElectionTypeId, callback) => async (dispatch) => {
    dispatch({ type: POST_MANAGE_ELECTION_TYPE_ID_REQUEST });
    try {
      const response = await ApiService.post(
        "/api/manageelectionbyelectiontypeId",
        formDataElectionTypeId
      );
      const responsePayload = {
        data: response.data,
        status: response.status,
      };
      dispatch({
        type: POST_MANAGE_ELECTION_TYPE_ID_SUCCESS,
        payload: responsePayload,
      });
      if (callback) {
        callback(null, responsePayload);
      }
    } catch (error) {
      const errorPayload = {
        status: error.response.status,
        data: error.response.data,
      };
      dispatch({
        type: POST_MANAGE_ELECTION_TYPE_ID_FAILURE,
        payload: errorPayload,
      });
      if (callback) {
        callback(errorPayload, null);
      }
    }
  };
//add manage election by election type id end
//update manage elections by id start
export const updateManageElectionsById =
  (formDataElectionTypeId, id, callback) => async (dispatch) => {
    dispatch({ type: POST_UPDATE_MANAGE_ELECTIONS_BY_ID_REQUEST });
    try {
      const response = await ApiService.post(
        `/api/updatemanageelectionsbyId/${id}`,
        formDataElectionTypeId
      );
      const responsePayload = {
        data: response.data,
        status: response.status,
      };
      dispatch({
        type: POST_UPDATE_MANAGE_ELECTIONS_BY_ID_SUCCESS,
        payload: response,
      });
      if (callback) {
        callback(null, responsePayload);
      }
    } catch (error) {
      const errorPayload = {
        status: error.response.status,
        data: error.response.data,
      };
      dispatch({
        type: POST_UPDATE_MANAGE_ELECTIONS_BY_ID_FAILURE,
        payload: errorPayload,
      });
      if (callback) {
        callback(errorPayload, null);
      }
    }
  };
//update manage elections by id end
//update User Security LeveL By UserId start
export const updateUserSecurityLeveLByUserId =
  (formDataUser, id, callback) => async (dispatch) => {
    dispatch({ type: UPDATE_SecurityLevels_REQUEST });
    try {
      const response = await ApiService.post(
        `/api/userssecuritylevelupdatebyuserId/${id}`,
        formDataUser
      );

      dispatch({ type: UPDATE_SecurityLevels_SUCCESS, payload: response });
      if (callback) {
        callback(null, response);
      }
    } catch (error) {
      dispatch({
        type: UPDATE_SecurityLevels_FAILURE,
        payload: error,
      });
      if (callback) {
        callback(error, null);
      }
    }
  };
//update User Security LeveL By UserId start
//show User Details By Id start
export const showUserDetailsById = (id) => async (dispatch) => {
  dispatch({ type: GET_USERDETAILS_BYID_REQUEST });
  try {
    const response = await ApiService.get(`/api/getUserdetailsById/${id}`);

    const responsePayload = {
      data: response.data,
      status: response.status,
    };
    dispatch({ type: GET_USERDETAILS_BYID_SUCCESS, payload: responsePayload });
  } catch (error) {
    const errorPayload = {
      // message: error.message,
      status: error.response.status,
      data: error.response.data,
    };
    dispatch({
      type: GET_USERDETAILS_BYID_FAILURE,
      payload: errorPayload,
    });
  }
};
//show User Details By Id end
//index get all user start
export const indexGetAllUser = () => async (dispatch) => {
  dispatch({ type: GET_MANAGE_USERLIST_REQUEST });
  try {
    const response = await ApiService.get("/api/getallUsers");

    const responsePayload = {
      data: response.data,
      status: response.status,
    };
    dispatch({ type: GET_MANAGE_USERLIST_SUCCESS, payload: responsePayload });
  } catch (error) {
    const errorPayload = {
      // message: error.message,
      status: error.response.status,
      data: error.response.data,
    };
    dispatch({
      type: GET_MANAGE_USERLIST_FAILURE,
      payload: errorPayload,
    });
  }
};

export const dataElectionResults =
  (election_title_id, selectedDistrictId, selectedPollingDivisionId) =>
    async (dispatch) => {
      dispatch({ type: GET_DATAELECTIONRESULTS_BYID_REQUEST });
      try {
        const response = await ApiService.get(
          `/api/getdataelectionresults/${election_title_id}` +
          `${selectedDistrictId ? `?district_id=${selectedDistrictId}` : ""}` +
          `${selectedPollingDivisionId
            ? `${selectedDistrictId ? "&" : "?"
            }polling_division_id=${selectedPollingDivisionId}`
            : ""
          }`
        );

        const responsePayload = {
          data: response.data,
          status: response.status,
        };
        dispatch({
          type: GET_DATAELECTIONRESULTS_BYID_SUCCESS,
          payload: responsePayload,
        });
      } catch (error) {
        const errorPayload = {
          // message: error.message,
          status: error.response.status,
          data: error.response.data,
        };
        dispatch({
          type: GET_DATAELECTIONRESULTS_BYID_FAILURE,
          payload: errorPayload,
        });
      }
    };

export const updatePastElectionResultsById =
  (
    formDataElectionResultsById,
    election_title_id,
    selectedDistrictId,
    selectedPollingDivisionId,
    callback
  ) =>
    async (dispatch) => {
      dispatch({ type: POST_UPDATE_PAST_ELECTIONRESULTS_BY_ID_REQUEST });
      try {
        const response = await ApiService.post(
          `/api/updatepastelectionresults/${election_title_id}` +
          `${selectedDistrictId ? `?district_id=${selectedDistrictId}` : ""}` +
          `${selectedPollingDivisionId
            ? `${selectedDistrictId ? "&" : "?"
            }polling_division_id=${selectedPollingDivisionId}`
            : ""
          }`,
          formDataElectionResultsById
        );

        dispatch({
          type: POST_UPDATE_PAST_ELECTIONRESULTS_BY_ID_SUCCESS,
          payload: response,
        });
        if (callback) {
          callback(null, response);
        }
      } catch (error) {
        dispatch({
          type: POST_UPDATE_PAST_ELECTIONRESULTS_BY_ID_FAILURE,
          payload: error,
        });
        if (callback) {
          callback(error, null);
        }
      }
    };

export const indexGetAllBlogCategory = () => async (dispatch) => {
  dispatch({ type: GET_ALL_BLOG_CATEGORY_REQUEST });
  try {
    const response = await ApiService.get("/api/getallblogcategory");

    const responsePayload = {
      data: response.data,
      status: response.status,
    };
    dispatch({ type: GET_ALL_BLOG_CATEGORY_SUCCESS, payload: responsePayload });
  } catch (error) {
    const errorPayload = {
      // message: error.message,
      status: error.response.status,
      data: error.response.data,
    };
    dispatch({
      type: GET_ALL_BLOG_CATEGORY_FAILURE,
      payload: errorPayload,
    });
  }
};

export const addBlogCategory =
  (formDataCategory, callback) => async (dispatch) => {
    dispatch({ type: ADD_BLOG_CATEGORY_REQUEST });
    try {
      const response = await ApiService.post(
        "/api/addblogcategory",
        formDataCategory
      );

      const responsePayload = {
        data: response.data,
        status: response.status,
      };
      dispatch({ type: ADD_BLOG_CATEGORY_SUCCESS, payload: responsePayload });
      if (callback) {
        callback(null, responsePayload);
      }
    } catch (error) {
      const errorPayload = {
        // message: error.message,
        status: error.response.status,
        data: error.response.data,
      };
      dispatch({
        type: ADD_BLOG_CATEGORY_FAILURE,
        payload: errorPayload,
      });
      if (callback) {
        callback(errorPayload, null);
      }
    }
  };

export const updateBlogCategoryById =
  (formDataCategory, id, callback) => async (dispatch) => {
    dispatch({ type: POST_UPDATE_BLOG_CATEGORY_BY_ID_REQUEST });
    try {
      const response = await ApiService.post(
        `/api/updateblogcategory/${id}`,
        formDataCategory
      );

      const responsePayload = {
        data: response.data,
        status: response.status,
      };
      dispatch({
        type: POST_UPDATE_BLOG_CATEGORY_BY_ID_SUCCESS,
        payload: responsePayload,
      });
      if (callback) {
        callback(null, responsePayload);
      }
    } catch (error) {
      const errorPayload = {
        // message: error.message,
        status: error.response.status,
        data: error.response.data,
      };
      dispatch({
        type: POST_UPDATE_BLOG_CATEGORY_BY_ID_FAILURE,
        payload: errorPayload,
      });
      if (callback) {
        callback(errorPayload, null);
      }
    }
  };
export const showBlogCategoryById = (id) => async (dispatch) => {
  dispatch({ type: GET_BLOGCATEGORY_BYID_REQUEST });
  try {
    const response = await ApiService.get(`/api/getblogcategory/${id}`);

    const responsePayload = {
      data: response.data,
      status: response.status,
    };
    dispatch({ type: GET_BLOGCATEGORY_BYID_SUCCESS, payload: responsePayload });
  } catch (error) {
    const errorPayload = {
      // message: error.message,
      status: error.response.status,
      data: error.response.data,
    };
    dispatch({
      type: GET_BLOGCATEGORY_BYID_FAILURE,
      payload: errorPayload,
    });
  }
};
export const delBlogCategoryById = (id, callback) => async (dispatch) => {
  dispatch({ type: DELETE_BLOGCATEGORY_REQUEST });
  try {
    const response = await ApiService.delete(`/api/deleteblogcategory/${id}`);

    const responsePayload = {
      data: response.data,
      status: response.status,
    };
    dispatch({ type: DELETE_BLOGCATEGORY_SUCCESS, payload: responsePayload });
    if (callback) {
      callback(null, responsePayload);
    }
  } catch (error) {
    const errorPayload = {
      // message: error.message,
      status: error.response.status,
      data: error.response.data,
    };
    dispatch({ type: DELETE_BLOGCATEGORY_FAILURE, payload: errorPayload });
    if (callback) {
      callback(errorPayload, null);
    }
  }
};

export const indexGetAllBlogContent = () => async (dispatch) => {
  dispatch({ type: GET_ALL_BLOG_CONTENT_REQUEST });
  try {
    const response = await ApiService.get("/api/getallblogcontent");

    const responsePayload = {
      data: response.data,
      status: response.status,
    };
    dispatch({ type: GET_ALL_BLOG_CONTENT_SUCCESS, payload: responsePayload });
  } catch (error) {
    const errorPayload = {
      // message: error.message,
      status: error.response.status,
      data: error.response.data,
    };
    dispatch({
      type: GET_ALL_BLOG_CONTENT_FAILURE,
      payload: errorPayload,
    });
  }
};

export const showBlogContentById = (id) => async (dispatch) => {
  dispatch({ type: GET_BLOGCONTENT_BYID_REQUEST });
  try {
    const response = await ApiService.get(`/api/getblogcontent/${id}`);
    const responsePayload = {
      data: response.data,
      status: response.status,
    };
    dispatch({ type: GET_BLOGCONTENT_BYID_SUCCESS, payload: responsePayload });
  } catch (error) {
    const errorPayload = {
      // message: error.message,
      status: error.response.status,
      data: error.response.data,
    };
    dispatch({
      type: GET_BLOGCONTENT_BYID_FAILURE,
      payload: errorPayload,
    });
  }
};

export const addBlogContent =
  (formDataContent, callback) => async (dispatch) => {
    dispatch({ type: ADD_BLOG_CONTENT_REQUEST });
    try {
      const response = await ApiService.post(
        "/api/addblogcontent",
        formDataContent
      );

      const responsePayload = {
        data: response.data,
        status: response.status,
      };
      dispatch({ type: ADD_BLOG_CONTENT_SUCCESS, payload: responsePayload });
      if (callback) {
        callback(null, responsePayload);
      }
    } catch (error) {
      const errorPayload = {
        // message: error.message,
        status: error.response.status,
        data: error.response.data,
      };
      dispatch({
        type: ADD_BLOG_CONTENT_FAILURE,
        payload: errorPayload,
      });
      if (callback) {
        callback(errorPayload, null);
      }
    }
  };

export const updateBlogContentById =
  (formDataContent, id, callback) => async (dispatch) => {
    dispatch({ type: POST_UPDATE_BLOG_CONTENT_BY_ID_REQUEST });
    try {
      const response = await ApiService.post(
        `/api/updateblogcontent/${id}`,
        formDataContent
      );

      const responsePayload = {
        data: response.data,
        status: response.status,
      };
      dispatch({
        type: POST_UPDATE_BLOG_CONTENT_BY_ID_SUCCESS,
        payload: responsePayload,
      });
      if (callback) {
        callback(null, responsePayload);
      }
    } catch (error) {
      const errorPayload = {
        // message: error.message,
        status: error.response.status,
        data: error.response.data,
      };
      dispatch({
        type: POST_UPDATE_BLOG_CONTENT_BY_ID_FAILURE,
        payload: errorPayload,
      });
      if (callback) {
        callback(errorPayload, null);
      }
    }
  };

export const delBlogContentById = (id, callback) => async (dispatch) => {
  dispatch({ type: DELETE_BLOGCONTENT_REQUEST });
  try {
    const response = await ApiService.delete(`/api/deleteblogcontent/${id}`);

    const responsePayload = {
      data: response.data,
      status: response.status,
    };
    dispatch({ type: DELETE_BLOGCONTENT_SUCCESS, payload: responsePayload });
    if (callback) {
      callback(null, responsePayload);
    }
  } catch (error) {
    const errorPayload = {
      // message: error.message,
      status: error.response.status,
      data: error.response.data,
    };
    dispatch({ type: DELETE_BLOGCONTENT_FAILURE, payload: errorPayload });
    if (callback) {
      callback(errorPayload, null);
    }
  }
};

export const getAllPresidentialElectionResultsById =
  (id) => async (dispatch) => {
    dispatch({ type: GET_ALL_PRESIDENTIAL_ELECTION_RESULTS_BY_ID_REQUEST });
    try {
      const response = await ApiService.get(
        `/api/getallpresidentialelectionresults/${id}`
      );
      const responsePayload = {
        data: response.data,
        status: response.status,
      };
      dispatch({
        type: GET_ALL_PRESIDENTIAL_ELECTION_RESULTS_BY_ID_SUCCESS,
        payload: responsePayload,
      });
    } catch (error) {
      const errorPayload = {
        status: error.response.status,
        data: error.response.data,
      };
      dispatch({
        type: GET_ALL_PRESIDENTIAL_ELECTION_RESULTS_BY_ID_FAILURE,
        payload: errorPayload,
      });
    }
  };

export const getAlloDemocarcyPollResultById = (id) => async (dispatch) => {
  dispatch({ type: GET_ALL_ODEMOCARCY_POLL_RESULT_REQUEST });
  try {
    const response = await ApiService.get(
      `/api/getallodemocarcypollresult/${id}`
    );
    const responsePayload = {
      data: response.data,
      status: response.status,
    };
    dispatch({
      type: GET_ALL_ODEMOCARCY_POLL_RESULT_SUCCESS,
      payload: responsePayload,
    });
  } catch (error) {
    const errorPayload = {
      status: error.response.status,
      data: error.response.data,
    };
    dispatch({
      type: GET_ALL_ODEMOCARCY_POLL_RESULT_FAILURE,
      payload: errorPayload,
    });
  }
};

export const getParliamentElectionResultsByETitleId =
  (etId) => async (dispatch) => {
    dispatch({
      type: GET_PARLIAMENT_ELECTION_RESULTS_BY_ELECTION_TITLE_ID_REQUEST,
    });
    try {
      const response = await ApiService.get(
        `/api/electionresultpasselctiontitleId/${etId}`
      );

      const responsePayload = {
        data: response.data,
        status: response.status,
      };
      dispatch({
        type: GET_PARLIAMENT_ELECTION_RESULTS_BY_ELECTION_TITLE_ID_SUCCESS,
        payload: responsePayload,
      });
    } catch (error) {
      const errorPayload = {
        status: error.response.status,
        data: error.response.data,
      };
      dispatch({
        type: GET_PARLIAMENT_ELECTION_RESULTS_BY_ELECTION_TITLE_ID_FAILURE,
        payload: errorPayload,
      });
    }
  };

//index get all user end
export const logoutAdmin = () => {
  localStorage.clear();
  return {
    type: LOGOUT_ADMIN,
  };
};
