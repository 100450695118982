import React, { useEffect, useState } from "react";
import ProfileMain from "./profileMain";
import { HiOutlinePencil } from "react-icons/hi";
import {
    indexDistrict,
    showAllPollingDivisionsByDistrictById,
    showUserDetailsById,
} from "../../../redux/adminone/action";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../components/loaders/loader";
import { FaUser } from "react-icons/fa6";
import { BsPatchCheckFill } from "react-icons/bs";
import { useSnackbar } from "react-simple-snackbar";
import { Badge } from "react-bootstrap";
import {
    forgotPasswordSendEmail,
    resetPasswordEmail,
    updateEditUserByUserId,
    updateEmailUserId,
} from "../../../redux/user/action";
import { useNavigate } from "react-router-dom";
import { cApiUrlPrefix } from "../../../config/envConfig";
import UpdateEmail from "../../../components/modals/updateEmail";

const override = `
    display: block;
    margin: 0 auto;
    border-color: red;
`;

const cstomStyle = {
    menu: (provided) => ({
        ...provided,
        backgroundColor: "white",
        color: "#65676B",
        Width: "fit-content",
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused ? "#efefef" : "white",
        color: state.isFocused ? "#65676B" : "#65676B",
        padding: 5,
    }),
};
const ProfilePage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = localStorage.getItem("token");
    const user = JSON.parse(localStorage.getItem("user"));
    const userId = user.id;
    const [openSnackbar] = useSnackbar();
    const [showModal, setShowModal] = useState(false);
    const [options, setOptions] = useState([]);
    const [options1, setOptions1] = useState([]);
    const [selectedDistrictOption, setSelectedDistrictOption] = useState(null);
    const [defaultDistrictOption, setDefaultDistrictOption] = useState(null);
    const [selectedPollingDivisionOption, setSelectedPollingDivisionOption] =
        useState(null);
    const [defaultPollingDivisionOption, setDefaultPollingDivisionOption] =
        useState(null);

    const [credentials, setCredentials] = useState({
        user_image: null,
        name: "",
        username: "",
        email: "",
        mobileNumber: "",
        voter_id: "",
        province_name: "",
        password: "",
        password_confirmation: "",
        activated: "",
    });

    const [updateEmail, setUpdateEmail] = useState({
        new_email: "",
    });
    const [newImage, setNewImage] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    useEffect(() => {
        if (userId) {
            dispatch(showUserDetailsById(userId));
            dispatch(indexDistrict());
        }
    }, [dispatch, userId]);
    const loadingA = useSelector((state) => state.adminone.loading);
    const loadingU = useSelector((state) => state.user.loading);
    const isLoading = loadingA || loadingU;
    const showUserDetailsByIdData = useSelector(
        (state) => state.adminone.showUserDetailsByIdData?.data
    );
    const indexDistrictsData = useSelector(
        (state) => state.adminone.indexDistrictsData?.data
    );
    useEffect(() => {
        if (indexDistrictsData) {
            const newOptions = [
                ...indexDistrictsData.map((item) => ({
                    value: item.id,
                    label: item.district_name,
                })),
            ];
            setOptions(newOptions);
        }
    }, [indexDistrictsData]);
    const selectChange = (selectedOption) => {
        setSelectedDistrictOption(selectedOption.value);
        setDefaultDistrictOption(selectedOption);
    };

    useEffect(() => {
        dispatch(showAllPollingDivisionsByDistrictById(selectedDistrictOption));
    }, [dispatch, selectedDistrictOption]);

    const showsAllPollingDivisionsByDistrictIdData = useSelector(
        (state) => state.adminone.showsAllPollingDivisionsByDistrictIdData?.data
    );

    useEffect(() => {
        if (showsAllPollingDivisionsByDistrictIdData) {
            const newOptions = [
                ...showsAllPollingDivisionsByDistrictIdData.map((item) => ({
                    value: item.id,
                    label: item.polling_division_name,
                })),
            ];

            setOptions1(newOptions);
        }
    }, [showsAllPollingDivisionsByDistrictIdData]);

    const selectChange1 = (selectedOption) => {
        setSelectedPollingDivisionOption(selectedOption.value);
        setDefaultPollingDivisionOption(selectedOption);
    };

    useEffect(() => {
        if (showUserDetailsByIdData) {
            setCredentials({
                user_image: showUserDetailsByIdData.user_image,
                name: showUserDetailsByIdData.name,
                username: showUserDetailsByIdData.username,
                email: showUserDetailsByIdData.email,
                mobileNumber: showUserDetailsByIdData.mobileNumber,
                voter_id: showUserDetailsByIdData.voter_id,
                province_name: showUserDetailsByIdData.province_name,
                activated: showUserDetailsByIdData.activated,
            });

            setImagePreview(
                showUserDetailsByIdData.user_image
                    ? cApiUrlPrefix + showUserDetailsByIdData.user_image
                    : null
            );

            setDefaultDistrictOption({
                value: showUserDetailsByIdData.district_id,
                label: showUserDetailsByIdData.district_name,
            });

            setSelectedDistrictOption(showUserDetailsByIdData.district_id);

            setDefaultPollingDivisionOption({
                value: showUserDetailsByIdData.polling_division_id,
                label: showUserDetailsByIdData.polling_division_name,
            });

            setSelectedPollingDivisionOption(
                showUserDetailsByIdData.polling_division_id
            );
        }
    }, [showUserDetailsByIdData]);

    const handleChange = (event) => {
        const { name, value } = event.target;

        setCredentials({
            ...credentials,
            [name]: value,
        });
        setUpdateEmail({
            ...updateEmail,
            [name]: value,
        });
    };

    const handleToggle = () => {
        setCredentials((prevState) => ({
            ...prevState,
            activated: prevState.activated === 1 ? 0 : 1,
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        const editFormData = new FormData();
        if (newImage) {
            editFormData.append("user_image", newImage);
        } else {
            editFormData.append("user_image", credentials.user_image);
        }
        editFormData.append("name", credentials.name);
        editFormData.append("activated", credentials.activated);
        editFormData.append("district_id", selectedDistrictOption);
        editFormData.append("polling_division_id", selectedPollingDivisionOption);

        dispatch(
            updateEditUserByUserId(editFormData, userId, (error, response) => {
                if (error && error.status === 401) {
                    openSnackbar(error.data.message);
                } else {
                    if (response && response.status === 200) {
                        openSnackbar(response.data.message);
                        navigate(-1);
                    }
                }
            })
        );
    };

    const handleSubmit1 = (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append("email", user.email);
        dispatch(
            forgotPasswordSendEmail(formData, (error, response) => {
                if (error && error.status) {
                    toast.error(error.data.message, {
                        position: "top-center",
                        style: {
                            fontSize: "1.6rem",
                        },
                    });
                } else {
                    if (response && response.status === 200) {
                        toast.success(response.message, {
                            position: "top-center",
                            style: {
                                fontSize: "1.6rem",
                            },
                        });
                    }
                }
            })
        );
    };

    const handleCancel = () => {
        navigate(-1);
    };
    const handleImageClick = () => {
        const fileInput = document.createElement("input");
        fileInput.type = "file";
        fileInput.accept = "image/*";
        fileInput.onchange = (e) => {
            if (e.target.files && e.target.files[0]) {
                const file = e.target.files[0];
                setNewImage(file);
                setImagePreview(URL.createObjectURL(file));
            }
        };
        fileInput.click();
    };
    const handleUpdateEmail = () => {
        const editEmailFormData = new FormData();
        editEmailFormData.append("new_email", updateEmail.new_email);

        dispatch(
            updateEmailUserId(editEmailFormData, userId, (error, response) => {
                if (error && error.status === 400) {
                    openSnackbar(error.data.message);
                } else {
                    if (response && response.status === 200) {
                        dispatch(showUserDetailsById(userId));
                        openSnackbar(response.data.message);
                        localStorage.setItem("new_email", updateEmail.new_email);
                        navigate("/otp");
                        setUpdateEmail({ new_email: "" });
                    }
                }
            })
        );
        setShowModal(false);
    };
    const isEmailVerified = showUserDetailsByIdData?.isEmailverified === 1;
    const isMobileNumberverified =
        showUserDetailsByIdData?.isMobileNumberverified === 1;
    const getBadge = (securityLevelId) => {
        switch (securityLevelId) {
            case 1:
                return <Badge className="sl-badge">User</Badge>;
            case 2:
                return <Badge className="sl-badge">Admin</Badge>;
            case 3:
                return <Badge className="sl-badge">Super Admin</Badge>;
            default:
                return null;
        }
    };

    const props = {
        labels: {
            label1: "Profile Avatar",
            label2: "Personal Information",
            label3: "Security",
            label4: getBadge(showUserDetailsByIdData?.security_level_id),
        },
        contents: {
            content1: "Edit your profile picture",
            content2: "Change your identity and information",
            content3: "Last change: 15 days ago",
        },
        icons: {
            icon1: <HiOutlinePencil className="pencil" />,
            icon2: <FaUser className="user" />,
            icon3: isEmailVerified ? (
                <BsPatchCheckFill className="verifyEmail" />
            ) : null,
            icon4: isMobileNumberverified ? (
                <BsPatchCheckFill className="verifyEmail" />
            ) : null,
        },
        headings: {
            heading1: "Update Email",
        },

        childrens: {
            children1: "Update",
            children2: "Update Password",
        },
        cstomStyle,
        options,
        selectChange,
        value: defaultDistrictOption,
        selectedDistrictOption,
        options1,
        selectChange1,
        value1: defaultPollingDivisionOption,

        handleChange,
        handleToggle,
        handleSubmit,
        handleSubmit1,
        handleImageClick,
        handleCancel,
        credentials,
        imagePreview,
        setShowModal,
        handleUpdateEmail,
        updateEmail,
    };

    return (
        <>
            <ProfileMain {...props} />
            {isLoading ? (
                <Loader
                    color={"#0E76A8"}
                    loading={isLoading}
                    css={override}
                    size={75}
                />
            ) : null}
            <UpdateEmail
                show={showModal}
                handleClose={() => setShowModal(false)}
                {...props}
            />
        </>
    );
};

export default ProfilePage;
