import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../../components/loaders/loader";
import {
  indexCandidate,
  indexDistrict,
  addElectionsAddCandidatesById,
  getAllCandidateByTitleId,
  updatePostManageElectionsById,
} from "../../../redux/adminone/action";
import PostBlockThree from "../../../components/admin/postBlockThree";
import { useSnackbar } from "react-simple-snackbar";

const override = `
    display: block;
    margin: 0 auto;
    border-color: red;
`;

const AddCandidateToe = () => {
  const dispatch = useDispatch();
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const id = params.get("id");
  const edit = params.get("edit") === "true";

  useEffect(() => {
    dispatch(indexCandidate());
    dispatch(indexDistrict());
    if (edit) {
      dispatch(getAllCandidateByTitleId(id));
    }
  }, [dispatch, edit, id]);

  const [options1, setOptions1] = useState([]);
  const [options2, setOptions2] = useState([]);
  const [selectedValue1, setSelectedValue1] = useState([]);
  const [selectedValue2, setSelectedValue2] = useState([]);
  const [disable, setDisable] = useState(false);


  const navigate = useNavigate();
  const loading = useSelector((state) => state.adminone.loading);

  const indexCandidateData = useSelector((state) => state.adminone.indexCandidateData?.data);
  const getAllCandidateByTitleIdData = useSelector((state) => state.adminone.getAllCandidateByTitleIdData?.data);
  const indexDistrictsData = useSelector((state) => state.adminone.indexDistrictsData?.data);

  useEffect(() => {
    if (indexCandidateData) {
      const newOptions = indexCandidateData
        .filter(
          (item) =>
            item.candidate_party_name && item.candidate_party_name.trim() !== ""
        )
        .map((item) => ({
          id: item.id,
          name: item.candidate_party_name,
        }));

      setOptions1(newOptions);


    }


    if (indexDistrictsData) {
      const newOptions = indexDistrictsData.map((item) => ({
        id: item.id,
        name: item.district_name,
      }));
      setOptions2(newOptions);
    }
  }, [indexCandidateData, indexDistrictsData]);

  useEffect(() => {

    if (edit && getAllCandidateByTitleIdData && Array.isArray(getAllCandidateByTitleIdData)) {
      const uniqueCandidates = [];
      const uniqueDistricts = [];

      getAllCandidateByTitleIdData.forEach((item) => {
        console.log("item", item);
        const candidateName = indexCandidateData?.find(candidate => candidate.id === item.candidate_id)?.candidate_party_name || item.candidate_party_name;
        if (
          !uniqueCandidates.some(
            (candidate) => candidate.id === item.candidate_id
          )
        ) {
          uniqueCandidates.push({
            id: item.candidate_id,
            name: candidateName,
          });

        }

        if (
          !uniqueDistricts.some((district) => district.id === item.district_id)
        ) {
          uniqueDistricts.push({
            id: item.district_id,
            name: item.district_name,
          });
        }
      });

      setSelectedValue1(uniqueCandidates);
      setSelectedValue2(uniqueDistricts);
    }
  }, [edit, getAllCandidateByTitleIdData]);

  const handleSelect1 = (selectedList) => {

    setSelectedValue1(selectedList);
  };

  const handleRemove1 = (selectedList) => {
    setSelectedValue1(selectedList);
  };

  const handleSelect2 = (selectedList) => {
    setSelectedValue2(selectedList);
  };

  const handleRemove2 = (selectedList) => {
    setSelectedValue2(selectedList);
  };

  const handleSave = async () => {
    const candidateIds = selectedValue1.map((item) => item.id);
    const districtIds = selectedValue2.map((item) => item.id);
    const jsonData = JSON.stringify({
      candidate_id: candidateIds,
      district_id: districtIds,
    });

    if (edit) {
      dispatch(updatePostManageElectionsById(jsonData, id, (error, response) => {
        if (error) {
          // handle the error

        } else {
          // handle the response

          if (response.status === 200) {
            openSnackbar(response.data.message);
            navigate(-1);
          }
        }
      }));
    } else {
      dispatch(addElectionsAddCandidatesById(jsonData, id, (error, response) => {
        if (error) {
          // handle the error

        } else {
          // handle the response

          if (response.status === 200) {
            openSnackbar(response.data.message);
            navigate(-1);
          }
        }
      }));
    }
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const handleCheck = () => {
    setDisable(!disable);
  };

  const props = {
    title: edit ? "Edit Polling Division" : "Add Polling Division",
    label: "Same candidate for all Districts",
    saveNextBtn: "Save and Next",
    addSeqBtnstyle: { padding: "1.3rem 2.8rem", marginLeft: "1.5rem" },
    save: "save",
    cancel: "cancel",

    className: {
      cselectpageSizeLable: "cselectpageSizeLable",
    },

    selectLable1: "Candidate",
    options1,
    selectedValue1,
    onSelect1: handleSelect1,
    onRemove1: handleRemove1,
    disable,
    selectLable2: "Districts",
    options2,
    selectedValue2,
    onSelect2: handleSelect2,
    onRemove2: handleRemove2,

    handleSave,
    handleCancel,
    handleCheck,
  };

  return (
    <main className="aDistrict">
      <aside className="addbock">
        <PostBlockThree {...props} />
      </aside>
      {loading ? (
        <Loader color={"#0E76A8"} loading={loading} css={override} size={75} />
      ) : null}
    </main>
  );
};

export default AddCandidateToe;
