import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import PastPresidentElection from "../../../components/admin/pastPresidentElection";
// import PastPresidentElection2 from "../../../components/admin/pastPresidentElection2";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../components/loaders/loader";
import IImage from "../../../components/image/iImage";
import {
  dataElectionResults,
  indexDistrict,
  indexGetAllManageElections,
  showAllPollingDivisionsByDistrictById,
  updatePastElectionResultsById,
} from "../../../redux/adminone/action";
import { useLocation, useNavigate } from "react-router-dom";
import RedBorderBtn from "../../../components/buttons/redBorderBtn";
import SolidSquareBlueBtn from "../../../components/buttons/solidSquareBlueBtn";
import ManageTable from "../../../components/table/manageTable";
import { Col, Container, Row } from "react-bootstrap";
import { useSnackbar } from "react-simple-snackbar";

const override = `
    display: block; 
    margin: 0 auto;
    border-color: red;
`;
const cstomStyle = {
  menu: (provided) => ({
    ...provided,
    backgroundColor: "white",
    color: "#65676B",
    maxWidth: "100%",
    borderColor: "#65676B",
    boxShadow: "unset",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#efefef" : "white",
    color: state.isFocused ? "#65676B" : "#65676B",
    padding: 5,
    maxHeight: 30,
  }),
};

const formatNumber = (num) => {
  if (Math.floor(num) === num) {
    return num.toString();
  } else {
    return num.toFixed(2);
  }
};

const PresidentElectionHistory = ({ initialValues }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector((state) => state.adminone.loading);
  const location = useLocation();
  const [options0, setOptions0] = useState([]);
  const [options, setOptions] = useState([]);
  const [openSnackbar] = useSnackbar();

  const [options1, setOptions1] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [isFirstSelectSelected, setIsFirstSelectSelected] = useState(false);
  const [editableVotes, setEditableVotes] = useState({});
  const [editableTotalVotes, setEditableTotalVotes] = useState({});
  const [updatedCandidates, setUpdatedCandidates] = useState({});
  const [isElectionSelected, setIsElectionSelected] = useState(false);

  const dataElectionResultsbyID = useSelector(
    (state) => state.adminone.dataElectionResultsbyID?.data
  );
  const indexDistrictsData = useSelector(
    (state) => state.adminone.indexDistrictsData?.data
  );
  const showsAllPollingDivisionsByDistrictIdData = useSelector(
    (state) => state.adminone.showsAllPollingDivisionsByDistrictIdData?.data
  );
  const [selectedDistrictId, setSelectedDistrictId] = useState(null);
  const [defaultDistrictOption, setDefaultDistrictOption] = useState(null);
  const [selectedPollingDivisionId, setSelectedPollingDivisionId] =
    useState(null);
  const [defaultPollingDivisionOption, setDefaultPollingDivisionOption] =
    useState(null);
  const indexAllManageElectionData = useSelector(
    (state) => state.adminone.indexAllManageElectionData?.data
  );
  const election_title_id = useMemo(() => {
    return selectedFilter?.value ?? null;
  }, [selectedFilter]);

  const [errors, setErrors] = useState({
    name: "",
    total_registered_votes: initialValues?.total_registered_votes ?? 0,
    total_polled_votes: initialValues?.total_polled_votes ?? 0,
    total_rejected_votes: initialValues?.total_rejected_votes ?? 0,
    total_postal_votes: initialValues?.total_postal_votes ?? 0,
    total_valid_votes: initialValues?.total_valid_votes ?? 0,
  });

  useEffect(() => {
    dispatch(indexDistrict());
    dispatch(indexGetAllManageElections());
  }, [dispatch]);

  useEffect(() => {
    if (isElectionSelected) {
      dispatch(dataElectionResults(election_title_id));
    }
  }, [dispatch, election_title_id, isElectionSelected]);

  useEffect(() => {
    if (selectedDistrictId) {
      dispatch(showAllPollingDivisionsByDistrictById(selectedDistrictId));
    } else {
      setOptions1([]);
      setSelectedPollingDivisionId(null);
      setDefaultPollingDivisionOption(null);
    }
  }, [dispatch, selectedDistrictId]);

  useEffect(() => {
    if (showsAllPollingDivisionsByDistrictIdData) {
      const newOptions = [
        ...showsAllPollingDivisionsByDistrictIdData.map((item) => ({
          value: item.id,
          label: item.polling_division_name,
        })),
      ];
      setOptions1(newOptions);
    }
  }, [showsAllPollingDivisionsByDistrictIdData]);

  useEffect(() => {
    if (indexAllManageElectionData?.then) {
      indexAllManageElectionData.then((elections) => {
        const filteredElections = elections.filter(
          (election) => election.election_type_id === 1
        );
        setOptions0([
          ...filteredElections.map((election) => ({
            value: election.id,
            label: election.election_title,
          })),
        ]);
      });
    } else if (Array.isArray(indexAllManageElectionData)) {
      const filteredElections = indexAllManageElectionData.filter(
        (election) => election.election_type_id === 1
      );
      const newOptions = [
        ...filteredElections.map((item) => ({
          value: item.id,
          label: item.election_title,
        })),
      ];
      setOptions0(newOptions);
    }
  }, [indexAllManageElectionData]);

  const selectChange0 = (selectedOption) => {
    setSelectedFilter(selectedOption);
    setIsElectionSelected(true);

    const allOption = options.find((option) => option.value === "all");
    setSelectedDistrictId(allOption.value);
    setDefaultDistrictOption(allOption);

    setSelectedPollingDivisionId(null);
    setDefaultPollingDivisionOption(null);
    setOptions1([]);
  };

  const selectChange1 = (selectedOption) => {
    setSelectedPollingDivisionId(selectedOption.value);
    setDefaultPollingDivisionOption(selectedOption);

    dispatch(
      dataElectionResults(
        election_title_id,
        selectedDistrictId,
        selectedOption.value
      )
    );
  };

  const handleVoteChange = useCallback(
    (candidateId, field, value) => {

      setEditableVotes((prev) => {
        const updatedVotes = {
          ...prev,
          [candidateId]: {
            ...prev[candidateId],
            [field]: value === "" ? "" : parseFloat(value) || 0,
          },
        };

        setUpdatedCandidates((prev) => ({
          ...prev,
          [candidateId]: {
            ...dataElectionResultsbyID.data.find(
              (candidate) => candidate.candidate_id === candidateId
            ),
            ...updatedVotes[candidateId],
          },
        }));

        return updatedVotes;
      });
    },
    [dataElectionResultsbyID]
  );

  const handleTotalVoteChange = (districtId, field, value) => {
    setEditableTotalVotes((prev) => ({
      ...prev,
      [districtId]: {
        ...prev[districtId],
        [field]: value === "" ? "" : parseFloat(value) || "",
      },
    }));
  };

  const labels = [
    "Candidate Image",
    "Candidate Name",
    "Associated Party Name",
    "Total Received Votes",
    "Total Postal Votes",
    "Vote Percentage",
  ];

  useEffect(() => {
    if (indexDistrictsData) {
      const newOptions = [
        { value: "all", label: "All" },
        ...indexDistrictsData.map((item) => ({
          value: item.id,
          label: item.district_name,
        })),
      ];
      setOptions(newOptions);
    }
  }, [indexDistrictsData]);

  const selectChange = (selectedOption) => {
    setSelectedDistrictId(selectedOption.value);
    setDefaultDistrictOption(selectedOption);
    setIsFirstSelectSelected(!!selectedOption);

    setSelectedPollingDivisionId(null);
    setDefaultPollingDivisionOption(null);
    setOptions1([]);

    if (selectedOption.value !== "all") {
      dispatch(dataElectionResults(election_title_id, selectedOption.value));
    } else {
      dispatch(dataElectionResults(election_title_id));
    }
  };

  const districtId = dataElectionResultsbyID?.district_id;

  const data = useMemo(() => {
    if (!Array.isArray(dataElectionResultsbyID?.data)) return [];

    return dataElectionResultsbyID.data.map((candidate) => {
      const candidateTotalVotes =
        parseFloat(
          editableVotes[candidate.candidate_id]?.total_votes ||
            candidate.total_votes ||
            0
        ) +
        parseFloat(
          editableVotes[candidate.candidate_id]?.total_postal_votes ||
            candidate.total_postal_votes ||
            0
        );

      const updatedElectionDataTotal = dataElectionResultsbyID.data.reduce(
        (total, candidate) => {
          const totalVotes =
            parseFloat(
              editableVotes[candidate.candidate_id]?.total_votes ||
                candidate.total_votes ||
                0
            ) +
            parseFloat(
              editableVotes[candidate.candidate_id]?.total_postal_votes ||
                candidate.total_postal_votes ||
                0
            );
          return total + totalVotes;
        },
        0
      );

      const votePercentage =
        updatedElectionDataTotal > 0 && !isNaN(candidateTotalVotes)
          ? `${formatNumber(
              (candidateTotalVotes / updatedElectionDataTotal) * 100
            )}%`
          : "N/A";

      return {
        "Candidate Image": (
          <figure className="mcLogo">
            <IImage src={candidate.candidate_image} />
          </figure>
        ),
        "Candidate Name": candidate.candidate_name,
        "Associated Party Name": candidate.party_name,
        "Total Received Votes": (
          <input
            type="number"
            id={`total_votes_${candidate.candidate_id}`}
            className="voteChange"
            value={
              editableVotes[candidate.candidate_id]?.total_votes ||
              editableVotes[candidate.candidate_id]?.total_votes === ""
                ? editableVotes[candidate.candidate_id]?.total_votes
                : candidate.total_votes || 0
            }
            readOnly={location.pathname === "/election-history"}
            onChange={(e) =>
              handleVoteChange(
                candidate.candidate_id,
                "total_votes",
                e.target.value
              )
            }
          />
        ),
        "Total Postal Votes": (
          <input
            type="number"
            id={`total_postal_votes_${candidate.candidate_id}`}
            className="voteChange1"
            readOnly={location.pathname === "/election-history"}
            value={
              editableVotes[candidate.candidate_id]?.total_postal_votes ||
              editableVotes[candidate.candidate_id]?.total_postal_votes === ""
                ? editableVotes[candidate.candidate_id]?.total_postal_votes
                : candidate.total_postal_votes || 0
            }
            onChange={(e) =>
              handleVoteChange(
                candidate.candidate_id,
                "total_postal_votes",
                e.target.value
              )
            }
          />
        ),
        "Vote Percentage": (
          <span className="ttlVotesPerc">{votePercentage}</span>
        ),
      };
    });
  }, [
    dataElectionResultsbyID,
    editableVotes,
    location.pathname,
    handleVoteChange,
  ]);

  const total_polled = useMemo(() => {
    const voteMap = new Map(
      dataElectionResultsbyID?.data?.map((candidate) => [
        candidate.candidate_id,
        parseFloat(
          editableVotes[candidate.candidate_id]?.total_votes ||
            candidate.total_votes ||
            0
        ),
      ])
    );

    const totalVotesSum = Array.from(voteMap.values()).reduce(
      (sum, votes) => sum + votes,
      0
    );

    return totalVotesSum;
  }, [dataElectionResultsbyID, editableVotes]);

  const total_postal_votes = useMemo(() => {
    const postalVoteMap = new Map(
      dataElectionResultsbyID?.data?.map((candidate) => [
        candidate.candidate_id,
        parseFloat(
          editableVotes[candidate.candidate_id]?.total_postal_votes ||
            candidate.total_postal_votes ||
            0
        ),
      ])
    );

    const totalPostalVotesSum = Array.from(postalVoteMap.values()).reduce(
      (sum, votes) => sum + votes,
      0
    );

    return totalPostalVotesSum;
  }, [dataElectionResultsbyID, editableVotes]);

  const total_valid_votes = useMemo(() => {
    return total_polled + total_postal_votes;
  }, [total_polled, total_postal_votes]);

  const textInput = dataElectionResultsbyID
    ? [
        {
          label: "Registered votes",
          name: "registeredVotes",
          value: dataElectionResultsbyID?.total_registered_votes ?? 0,
          error: errors.registeredVotes,
        },
        {
          label: "Total polled votes",
          name: "totalPolledVotes",
          value: total_polled ?? 0,
          error: errors.total_polled,
        },
        {
          label: "Total rejected votes",
          name: "totalRejectedVotes",
          value: dataElectionResultsbyID?.total_rejected_votes ?? 0,
          error: errors.total_rejected_votes,
        },
        {
          label: "Total postal votes",
          name: "totalPostalVotes",
          value: total_postal_votes ?? 0,
          error: errors.total_postal_votes,
        },
        {
          label: "Total valid votes",
          name: "totalValidVotes",
          value: total_valid_votes ?? 0,
          error: errors.total_valid_votes,
        },
      ]
    : [];

  const handleClick1 = () => {
    navigate("/edit-election-history");
  };

  const handleCancel = () => {
    navigate("/dashboard");
  };

  const handleSubmit = async (e) => {
 
    if (e) {
      e.preventDefault();
    }

    let valid = true;
    let newErrors = {};

    Object.keys(editableVotes).forEach((candidateId) => {
      const { total_votes, total_postal_votes } = editableVotes[candidateId];

      if (!total_votes || total_votes.length < 1) {
        newErrors[`total_votes_${candidateId}`] =
          "Total Received Votes must be at least 1 character long.";
        valid = false;
      }

      if (!total_postal_votes || total_postal_votes.length < 1) {
        newErrors[`total_postal_votes_${candidateId}`] =
          "Total Postal Votes must be at least 1 character long.";
        valid = false;
      }
    });

    setErrors(newErrors);

    if (!valid) return;

    if (!selectedFilter || !selectedDistrictId || !selectedPollingDivisionId) {
      openSnackbar("Please fill all required fields", { variant: "error" });
      return;
    }

    if (!election_title_id) {
      console.error("Election title ID is missing");
      return;
    }
    if (!selectedDistrictId) {
      console.error("District ID is missing");
      return;
    }
    if (!selectedPollingDivisionId) {
      console.error("Polling Division ID is missing");
      return;
    }

    if (
      !dataElectionResultsbyID ||
      !Array.isArray(dataElectionResultsbyID.data)
    ) {
      console.error(
        "dataElectionResultsbyID is not properly structured:",
        dataElectionResultsbyID
      );
      return;
    }

    const combinedCandidates =
      dataElectionResultsbyID?.data.map((candidate) => ({
        candidate_id: candidate.candidate_id,
        party_id: candidate.party_id,
        total_received_votes:
          editableVotes[candidate.candidate_id]?.total_votes ??
          candidate.total_votes,
        total_postal_votes:
          editableVotes[candidate.candidate_id]?.total_postal_votes ??
          candidate.total_postal_votes,
      })) || [];

    const formDataElectionResultsById = new FormData();
    formDataElectionResultsById.append("district_id", selectedDistrictId);
    formDataElectionResultsById.append(
      "polling_division_id",
      selectedPollingDivisionId
    );
    formDataElectionResultsById.append(
      "total_population",
      dataElectionResultsbyID?.total_population ?? 0
    );
    formDataElectionResultsById.append(
      "total_registered_votes",
      dataElectionResultsbyID?.total_registered_votes ?? 0
    );
    formDataElectionResultsById.append("total_polled", total_polled);

    const totalRejectedVotes =
      editableTotalVotes[districtId]?.totalRejectedVotes !== undefined
        ? editableTotalVotes[districtId]?.totalRejectedVotes
        : dataElectionResultsbyID?.total_rejected_votes ?? 0;

    formDataElectionResultsById.append(
      "total_rejected_votes",
      totalRejectedVotes
    );
    formDataElectionResultsById.append(
      "total_postal_votes",
      total_postal_votes
    );
    formDataElectionResultsById.append("total_valid_votes", total_valid_votes);

    combinedCandidates.forEach((candidate) => {
      Object.keys(candidate).forEach((key) => {
        if (key !== "original") {
          formDataElectionResultsById.append(
            `data[${candidate.candidate_id}][${key}]`,
            candidate[key] ?? 0
          );
        }
      });
    });

    dispatch(
      updatePastElectionResultsById(
        formDataElectionResultsById,
        election_title_id,
        selectedDistrictId,
        selectedPollingDivisionId,
        (error, response) => {
          if (error) {
            openSnackbar("Error updating election results:");
          } else {
            if (response.status === 200) {
              openSnackbar(response.data.message);
              setEditableVotes({});
              setEditableTotalVotes({});
              setErrors({});
              navigate("/edit-election-history");
              dispatch(
                dataElectionResults(
                  election_title_id,
                  selectedDistrictId,
                  selectedPollingDivisionId
                )
              );
            }
          }
        }
      )
    );
  };

  useEffect(() => {
    return () => {
      if (location.pathname !== "/edit-election-history") return;

      dispatch({ type: "CLEAR_ELECTION_RESULTS" });
      setOptions0([]);
      setOptions([]);
      setOptions1([]);
      setSelectedFilter(null);
      setSelectedDistrictId(null);
      setSelectedPollingDivisionId(null);
      setDefaultDistrictOption(null);
      setDefaultPollingDivisionOption(null);
    };
  }, [dispatch, navigate, location.pathname]);

  const props = {
    title: `Past Election History >> ${selectedFilter?.label || ""}`,
    style: { padding: "1rem 3.5rem" },
    options0,
    options,
    options1,

    className: {
      cselectpageSize: "cselectpageSize",
    },
    cstomStyle,
    headings: {
      heading0: "Select Election",
      heading1: "Select Districts",
      heading2: "Select Polling Division",
    },
    placeholder0: "select election",
    placeholder1: "All Districts",
    handleClick1,
    value: defaultDistrictOption,
    value1: defaultPollingDivisionOption,
    selectChange0,
    selectChange,
    selectChange1,
    isFirstSelectSelected,
    isElectionSelected,
  };

  return (
    <main className="aSecurity">
      <PastPresidentElection
        {...props}
        showEditButton={true}
        showWonButton={false}
      />
      <div className="PastPresidentElection2">
        <Container fluid className="px-0">
          <Row>
            {textInput.map((input, index) => (
              <Col key={index} className="d-flex flex-column textForm mb-3 g-5">
                <label htmlFor={input.name} className="textformlabel mb-2">
                  {input.label}
                </label>
                <input
                  type="number"
                  id={`${input.name}_${districtId}`}
                  className="textformtext"
                  value={
                    editableTotalVotes[districtId]?.[input.name] !== undefined
                      ? editableTotalVotes[districtId][input.name]
                      : input.value
                  }
                  readOnly={
                    input.name === "registeredVotes" ||
                    input.name === "totalPolledVotes" ||
                    input.name === "totalPostalVotes" ||
                    input.name === "totalValidVotes" ||
                    location.pathname === "/election-history"
                  }
                  onChange={(e) =>
                    handleTotalVoteChange(
                      districtId,
                      input.name,
                      e.target.value
                    )
                  }
                />
              </Col>
            ))}
          </Row>
        </Container>
        <ManageTable data={data} loading={loading} labels={labels} />
      </div>
      {loading ? (
        <Loader color={"#0E76A8"} loading={loading} css={override} size={75} />
      ) : null}
      <div className="saveAndcancel text-end">
        <RedBorderBtn
          onClick={handleCancel}
          children1="Cancel"
          style1={{
            padding: "1rem 3.5rem",
          }}
        />
        <SolidSquareBlueBtn
          style={{ padding: "1rem 3.5rem", marginLeft: "1.5rem" }}
          onClick={handleSubmit}
          disabled={
            true
              ? location.pathname === "/election-history"
              : location.pathname === "/edit-election-history"
          }
        >
          Save
        </SolidSquareBlueBtn>
      </div>
    </main>
  );
};

PresidentElectionHistory.propTypes = {
  initialValues: PropTypes.shape({
    total_registered_votes: PropTypes.number,
    total_polled_votes: PropTypes.number,
    total_rejected_votes: PropTypes.number,
    total_postal_votes: PropTypes.number,
    total_valid_votes: PropTypes.number,
  }),
};

export default PresidentElectionHistory;
