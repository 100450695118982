import React from "react";
import OrangeSolidBtn from "../components/buttons/orangeSolidBtn";
import { useNavigate } from "react-router-dom";

const PageNoteFound = () => {
    const navigate = useNavigate();
    const onClick = () => {
        navigate("/");
    };
    return (
        <main className="pageNotFound-main">
            <section className="pageNotFound-section">
                <h1 className="pnf-code">404</h1>
                <h3 className="pnf-text">Page Not Found</h3>
                <OrangeSolidBtn onClick={onClick} children="Go to home page" />
            </section>
        </main>
    );
};

export default PageNoteFound;
