import React, { useEffect, useState } from "react";
import ParliamentSimulatorResultMain from "./parlamentSimulatorResultMain";
import { useLocation, useNavigate } from "react-router-dom";
import { calculateVotingPercentage } from "../../../utilities/common";
import IImage from "../../../components/image/iImage";
import { Table } from "react-bootstrap";
import OffCanvas from "../../../components/offCanvas/offCanvas";
import ManageTable from "../../../components/table/manageTable";
import { cApiUrlPrefix } from "../../../config/envConfig";

const ParliamentSimulatorResultPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { updateData1, selectedOption } = location.state || {};

    const user = JSON.parse(localStorage.getItem("user"));
    const [seatsTable, setSeatTable] = useState();
    const [filterData, setFilterData] = useState(null);

    const [show, setShow] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleSwitchChange = () => {
        setIsChecked(!isChecked);
    };
    const resultJson = {
        simulators: {},
    };
    if (updateData1 && updateData1.simulators) {
        Object.keys(updateData1.simulators).forEach((districtKey) => {
            const district = updateData1.simulators[districtKey];
            resultJson.simulators[districtKey] = {
                district_name: district.district_name,
                simulators: {},
            };
            const allVotes = [];

            Object.keys(district.simulators).forEach((partyKey) => {
                const party = district.simulators[partyKey];
                resultJson.simulators[districtKey].simulators[partyKey] = {
                    party_name: party.party_name,
                    votes: [],
                };

                if (party.party_total_votes > 0) {
                    for (let i = 0; i < district.district_total_seats; i++) {
                        const voteCount =
                            i === 0
                                ? party.party_total_votes
                                : Math.abs(party.party_total_votes / (i + 1));
                        resultJson.simulators[districtKey].simulators[partyKey].votes.push(
                            voteCount
                        );
                        allVotes.push(voteCount);
                    }
                }
            });

            const sortedVotes = allVotes.sort((a, b) => b - a);
            const topVotes = sortedVotes.slice(0, district.district_total_seats);

            let totalAssignedSeats = 0;

            Object.keys(district.simulators).forEach((partyKey) => {
                const originalVotes =
                    resultJson.simulators[districtKey].simulators[partyKey].votes;


                const filteredVotes = originalVotes.filter((vote) =>
                    topVotes.includes(vote)
                );


                const seatsToAssign = Math.min(
                    filteredVotes.length,
                    district.district_total_seats - totalAssignedSeats
                );


                resultJson.simulators[districtKey].simulators[partyKey].votes =
                    filteredVotes.slice(0, seatsToAssign);


                totalAssignedSeats += seatsToAssign;
            });
        });
    }

    const partyData = {};

    if (updateData1 && updateData1.simulators) {
        for (const districtId in updateData1.simulators) {
            const district = updateData1.simulators[districtId];

            if (district.simulators) {
                for (const partyId in district.simulators) {
                    const party = district.simulators[partyId];

                    if (partyData[partyId]) {
                        partyData[partyId].party_votes += party.party_total_votes;
                        partyData[partyId].party_seats +=
                            resultJson.simulators[districtId].simulators[
                                partyId
                            ].votes.length;
                    } else {
                        partyData[partyId] = {
                            party_name: party.party_name,
                            party_votes: party.party_total_votes,
                            party_seats:
                                resultJson.simulators[districtId].simulators[partyId].votes
                                    .length,
                            party_logo: party.party_logo,
                            national_seats: "",
                            simulator: [],
                        };
                    }

                    const candidates = party.simulators.map((candidate) => ({
                        candidate_id: candidate.candidate_id,
                        candidate_name: candidate.candidate_name,
                        candidate_image: candidate.candidate_image,
                        candidate_vote: candidate.candidate_vote,
                        candidate_seats: candidate.candidate_seats,
                        district_id: districtId,
                        district_name: district.district_name,
                    }));

                    const sortedCandidates = candidates.sort(
                        (a, b) => b.candidate_vote - a.candidate_vote
                    );

                    partyData[partyId].simulator.push(...sortedCandidates);

                    let totalNationalSeats = updateData1?.total_national_seats;
                    let totalCalculatedSeats = 0;

                    if (updateData1.all_district_total_votes) {
                        Object.keys(partyData).forEach((partyId) => {
                            let calculatedSeats = Math.round(
                                (((100 * partyData[partyId].party_seats) /
                                    updateData1.total_seats) *
                                    totalNationalSeats) / 100
                            );
                            totalCalculatedSeats += calculatedSeats;
                            partyData[partyId].national_seats = calculatedSeats;
                        });


                        if (totalCalculatedSeats > totalNationalSeats) {
                            let excessSeats = totalCalculatedSeats - totalNationalSeats;


                            Object.keys(partyData).forEach((partyId) => {
                                if (excessSeats <= 0) return;

                                let reduction = Math.min(partyData[partyId].national_seats, excessSeats);
                                partyData[partyId].national_seats -= reduction;
                                excessSeats -= reduction;
                            });
                        }


                        if (totalCalculatedSeats < totalNationalSeats) {
                            let deficitSeats = totalNationalSeats - totalCalculatedSeats;


                            Object.keys(partyData).forEach((partyId) => {
                                let availableSeats = totalNationalSeats - partyData[partyId].national_seats;
                                let addition = Math.min(availableSeats, deficitSeats);

                                partyData[partyId].national_seats += addition;
                                deficitSeats -= addition;

                                if (deficitSeats <= 0) return;
                            });
                        }
                    }
                }
            }
        }
    }
    const tableData = {};

    if (updateData1 && updateData1.simulators) {
        Object.keys(updateData1.simulators).forEach((districtKey) => {
            const district = updateData1.simulators[districtKey];

            Object.keys(district.simulators).forEach((partyKey) => {
                const party = district.simulators[partyKey];

                if (!tableData[partyKey]) {
                    tableData[partyKey] = {
                        party_name: party.party_name,
                        all_district_party_seats: 0,
                    };
                }

                const filteredVotes =
                    resultJson.simulators[districtKey].simulators[partyKey].votes || [];

                tableData[partyKey][districtKey] = {
                    district_name: district.district_name,
                    party_seats: filteredVotes.length,
                };

                tableData[partyKey].all_district_party_seats += filteredVotes.length;
            });
        });
    }

    for (const partyId in partyData) {
        const party = partyData[partyId];
        if (tableData[partyId]) {
            for (const districtId in tableData[partyId]) {
                if (
                    districtId === "party_name" ||
                    districtId === "all_district_party_seats"
                ) {
                    continue;
                }
                const partySeatsInDistrict = tableData[partyId][districtId].party_seats;
                const candidatesInDistrict = party.simulator.filter(
                    (candidate) => candidate.district_id === districtId
                );
                const sortedCandidates = candidatesInDistrict.sort(
                    (a, b) => b.candidate_vote - a.candidate_vote
                );
                for (
                    let i = 0;
                    i < Math.min(partySeatsInDistrict, sortedCandidates.length);
                    i++
                ) {
                    sortedCandidates[i].candidate_seats = 1;
                }
            }
        }
    }

    const labels = [
        "Logo",
        "Name",
        "Votes",
        "Seats Percentage",
        "Seats",
        "National Seats",
    ];
    const data = Object.entries(partyData).map(([, party]) => ({
        Logo: (
            <figure className="mpLogo">
                <IImage src={party.party_logo} className="mpLogoImg" />
            </figure>
        ),
        Name: party.party_name,
        Votes: party.party_votes,
        "Seats Percentage":
            Math.round(
                ((party.party_seats + party.national_seats) * 100) /
                (updateData1.total_seats + 29)
            ) + "%",

        Seats: party.party_seats,
        "National Seats": party.national_seats,
    }));
    const extractPercentage = (percentageString) => {
        return parseInt(percentageString, 10);
    };
    let leadingParty = data.reduce((prev, current) => {
        const prevPercentage = extractPercentage(prev["Seats Percentage"]);
        const currentPercentage = extractPercentage(current["Seats Percentage"]);

        return currentPercentage > prevPercentage ? current : prev;
    });

    const getRandomColor = () => {
        const randomColor = "#" + Math.floor(Math.random() * 16777215).toString(16);
        return randomColor;
    };
    const pImgArray = Object.keys(partyData).map(
        (party) => partyData[party].party_logo
    );
    const pNameArray = Object.keys(partyData).map(
        (party) => partyData[party].party_name
    );
    const pSeatsArray = Object.keys(partyData).map(
        (party) => partyData[party].party_seats
    );

    const nSeatsArray = Object.keys(partyData).map(
        (party) => partyData[party].national_seats
    );

    const pVoteArray = Object.keys(partyData).map(
        (party) => partyData[party].party_votes
    );

    const totalSeatsArray = Object.keys(partyData).map(
        (party) => partyData[party].party_seats + partyData[party].national_seats
    );

    const partySeatsOptions = {
        chart: {
            type: "column",
        },
        title: {
            text: "Party Seats",
            style: {
                fontSize: "1.4rem",
            },
        },
        xAxis: {
            categories: pImgArray,
            labels: {
                useHTML: true,
                formatter: function () {
                    const index = this.pos;
                    const imageUrl = pImgArray[index];
                    return `<img src="${cApiUrlPrefix}${imageUrl}" alt="${this.value}" class="highChart-img" />`;
                },
            },
        },
        yAxis: {
            labels: {
                style: {
                    fontSize: "1.4rem",
                },
            },
        },
        series: [
            {
                name: "Seats",
                data: totalSeatsArray,
                colorByPoint: true,
                // colors: totalSeatsArray.map(getColorWithOpacity),
            },
            // {
            //   name: "Per%",
            //   data: perArray,
            //   colorByPoint: true,
            //   colors: perArray.map(getColorWithOpacityone),
            // },
        ],
        tooltip: {
            style: {
                fontSize: "1.4rem",
            },

            colorByPoint: true,
            formatter: function () {
                const pointIndex = this.point.index;
                const votes = pVoteArray[pointIndex];
                const partyName = pNameArray[pointIndex];
                const nSeats = nSeatsArray[pointIndex];
                const pSeats = pSeatsArray[pointIndex];
                return `<b>Party: ${partyName}</b><br/>Votes: ${votes}<br/>Party Seats: ${pSeats}<br/>National Seats: ${nSeats}<br/> `;
            },
        },
        exporting: {
            buttons: {
                contextButton: {
                    menuItems: ["viewFullscreen"],
                },
            },
        },
    };
    const parliamentArray = Object.entries(partyData).map(([, party]) => [
        party.party_name,
        party.party_seats + party.national_seats,
        getRandomColor(),
    ]);
    const parliamentChartOptions = {
        chart: {
            type: "item",
        },
        title: {
            text: "Parliament of seats",
            style: {
                fontSize: "1.4rem",
            },
        },
        // subtitle: {
        //     text: 'Bundestag election 2021. Source: ' +
        //         '<a href="https://www.bundeswahlleiter.de/en/bundeswahlleiter.html" target="_blank">Bundeswahlleiter</a>',
        // },
        legend: {
            labelFormat: '{name} <span style="opacity: 0.8">{y}</span>',
            itemStyle: {
                fontSize: "1.4rem",
            },
        },
        series: [
            {
                name: "Representatives",
                keys: ["name", "y", "color"],
                data: parliamentArray,
                dataLabels: {
                    enabled: false,
                    format: "{point.label}",
                },
                center: ["50%", "88%"],
                size: "170%",
                startAngle: -100,
                endAngle: 100,
            },
        ],
        tooltip: {
            style: {
                fontSize: "1.4rem",
            },

            colorByPoint: true,
            // formatter: function () {
            //     const pointIndex = this.point.index;
            //     const votes = voteArray[pointIndex];
            //     // const candidate = cNameArray[pointIndex];
            //     const partyName = partyArray[pointIndex];
            //     return `<b>${this.x}</b><br/>Votes: ${votes}<br/>Party: ${partyName}`;
            // },
        },
        exporting: {
            buttons: {
                contextButton: {
                    menuItems: ["viewFullscreen"],
                },
            },
        },
        responsive: {
            rules: [
                {
                    condition: {
                        maxWidth: 600,
                    },
                    chartOptions: {
                        series: [
                            {
                                dataLabels: {
                                    distance: -30,
                                },
                            },
                        ],
                    },
                },
            ],
        },
    };
    const pieArray = Object.keys(partyData).map((partyId) => {
        const party = partyData[partyId];

        const percentageString = calculateVotingPercentage(
            party.party_seats + party.national_seats,
            updateData1.total_seats + 29
        );
        const percentageValue = parseFloat(percentageString.replace("%", ""));
        return {
            name: party.party_name,
            y: percentageValue,
        };
    });

    const parliamentpieOptions = {
        chart: {
            type: "pie",
        },
        title: {
            text: "Seats Distribution",
            style: {
                fontSize: "1.4rem",
            },
        },
        tooltip: {
            valueSuffix: "%",
            style: {
                fontSize: "1.4rem",
            },
            // formatter: function () {
            //     const pointIndex = this.point.index;
            //     const votes = pVoteArray[pointIndex];
            //     const partyName = pNameArray[pointIndex];
            //     const nSeats = nSeatsArray[pointIndex];
            //     const pSeats = pSeatsArray[pointIndex];
            //     return `<b>Party: ${partyName}</b><br/>Votes: ${votes}<br/>Party Seats: ${pSeats}<br/>National Seats: ${nSeats}<br/> `;
            // },
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: "pointer",
                dataLabels: {
                    categories: pNameArray,
                    enabled: true,
                    distance: 20,
                    format: "{point.name}: {point.percentage:.1f}%",
                    style: {
                        fontSize: "1.4rem",
                        textOutline: "none",
                    },
                    // filter: {
                    //     operator: ">",
                    //     property: "percentage",
                    //     value: 10,
                    // },
                },
            },
        },
        series: [
            {
                name: "Percentage",
                colorByPoint: true,
                data: pieArray,
            },
        ],
        exporting: {
            buttons: {
                contextButton: {
                    menuItems: ["viewFullscreen"],
                },
            },
        },
    };

    const districtNames = new Set();

    Object.values(tableData).forEach((party) => {
        Object.values(party).forEach((district) => {
            if (district.district_name) {
                districtNames.add(district.district_name);
            }
        });
    });

    const uniqueDistrictNames = [...districtNames];
    const partiesArray = [];
    const districtNamesArray = Array.from(districtNames);
    Object.values(tableData).forEach((party) => {
        const partyData = {
            name: party.party_name,
            data: districtNamesArray.map((districtName) => {
                const district = Object.values(party).find(
                    (district) => district.district_name === districtName
                );
                return district ? district.party_seats : 0;
            }),
        };

        partiesArray.push(partyData);
    });

    const partyDistrictDataOptions = {
        chart: {
            type: "column",
        },
        title: {
            text: "The seat of the district is according to the party",
            align: "left",
        },
        xAxis: {
            categories: uniqueDistrictNames,
            labels: {
                style: {
                    fontSize: "1.4rem",
                },
            },
        },
        yAxis: {
            min: 0,
            title: {
                text: "Seats",
                style: {
                    fontSize: "1.4rem",
                },
            },
            labels: {
                style: {
                    fontSize: "1.4rem",
                },
            },
            stackLabels: {
                enabled: true,
                style: {
                    fontSize: "1.4rem",
                },
            },
        },
        legend: {
            align: "center",
            x: 0,
            verticalAlign: "top",
            y: 0,
            floating: true,
            borderColor: "#CCC",
            borderWidth: 1,
            shadow: false,
            itemStyle: {
                fontSize: "1.4rem",
            },
        },
        tooltip: {
            style: {
                fontSize: "1.4rem",
            },
            headerFormat: "<b>{point.x}</b><br/>",
            pointFormat:
                "{series.name}: {point.y}<br/>Total Seats : {point.stackTotal}",
        },
        plotOptions: {
            column: {
                stacking: "normal",
                dataLabels: {
                    enabled: true,
                    style: {
                        fontSize: "1.4rem",
                    },
                },
            },
        },

        series: partiesArray,
        exporting: {
            buttons: {
                contextButton: {
                    menuItems: ["viewFullscreen"],
                },
            },
        },
    };

    const handleClick = () => {
        navigate("/parliament-election-alliance", {
            state: {
                partyData,
                registerVoter: updateData1.totalRegisteredVoters,
                totalVotes: updateData1.all_district_total_votes,
                totalPer: calculateVotingPercentage(
                    updateData1.all_district_total_votes,
                    updateData1.totalRegisteredVoters
                ),
                totalDistrict: updateData1.totalDistrictsCount,
                totalPollingDivisions: updateData1.totalPollingDivisions,
                selectedOption,
                totalSeats: updateData1.total_seats + 29,
            },
        });
    };

    useEffect(() => {
        if (!tableData || Object.keys(tableData).length === 0) {
            return;
        }
        const districtMap = {};

        Object.entries(tableData).forEach(([partyId, partyData]) => {
            Object.entries(partyData).forEach(([districtId, districtInfo]) => {
                if (!["party_name", "all_district_party_seats"].includes(districtId)) {
                    if (!districtMap[districtId]) {
                        districtMap[districtId] = districtInfo.district_name;
                    }
                }
            });
        });

        const districtIds = Object.keys(districtMap);

        const tableContent = (
            <Table responsive className="manageTable1">
                <thead>
                    <tr>
                        <th className="sticky-column-left">Parties</th>
                        {districtIds.map((districtId) => (
                            <th key={districtId}>{districtMap[districtId]}</th>
                        ))}
                        <th className="sticky-column-right">Total Seats</th>
                    </tr>
                </thead>
                <tbody className="scrollable-table">
                    {Object.entries(tableData).map(([partyId, partyData]) => (
                        <tr key={partyId}>
                            <td className="sticky-column-left">{partyData.party_name}</td>
                            {districtIds.map((districtId) => {
                                const seats = partyData[districtId]?.party_seats || 0;
                                return (
                                    <td key={districtId}>
                                        <div
                                            className="clickCell"
                                            onClick={() => onCellClick(partyId, districtId)}
                                        >
                                            {seats}
                                        </div>
                                    </td>
                                );
                            })}
                            <td className="sticky-column-right">
                                <div
                                    className="clickCell"
                                    onClick={() => onCellClick(partyId, null)}
                                >
                                    {partyData.all_district_party_seats}
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        );

        if (JSON.stringify(seatsTable) !== JSON.stringify(tableContent)) {
            setSeatTable(tableContent);
        }
    }, [tableData, seatsTable, setSeatTable]);

    const newPartyData = partyData;

    const onCellClick = (partyId, districtId) => {
        const selectedParty = newPartyData[partyId];

        if (!selectedParty) {
            return;
        }
        const filteredCandidates = selectedParty.simulator.filter(
            (candidate) => !districtId || candidate.district_id === districtId
        );
        const filteredPartyData = {
            party_name: selectedParty.party_name,
            party_votes: selectedParty.party_votes,
            party_seats: selectedParty.party_seats,
            national_seats: selectedParty.national_seats,
            party_logo: selectedParty.party_logo,
            candidates: filteredCandidates,
        };

        setFilterData(filteredPartyData);

        handleShow();
    };

    const OffCanvasHeader = (
        <div className="off-canvas-header">
            <figure className="mpLogo1">
                <IImage src={filterData?.party_logo} className="mpLogoImg" />
            </figure>
            <div className="flex-grow-1 me-auto">
                <h2 className="off-canvas-ptitle">{filterData?.party_name}</h2>
            </div>
            <div className="d-flex right-border">
                <span className="sqr-box0"></span>
                <div className="registered-e-div">
                    <h6 className="provincial-vote">{filterData?.party_votes}</h6>
                    <p className="registered-electors">Party Votes</p>
                </div>
            </div>
            <div className="d-flex right-border">
                <span className="sqr-box1"></span>
                <div className="registered-e-div">
                    <h6 className="provincial-vote">{filterData?.party_seats}</h6>
                    <p className="registered-electors">Party Seats</p>
                </div>
            </div>
            <div className="d-flex right-border">
                <span className="sqr-box2"></span>
                <div className="registered-e-div">
                    <h6 className="provincial-vote">{filterData?.national_seats}</h6>
                    <p className="registered-electors">National Seats</p>
                </div>
            </div>
        </div>
    );
    const labels1 = [
        "Candidate Image",
        "Candidate Name",
        "District Name",
        "Votes",
        "Seat",
    ];

    const data1 =
        filterData?.candidates?.map((candidate) => ({
            "Candidate Image": (
                <figure className="mpLogo">
                    <IImage src={candidate.candidate_image} className="mpLogoImg" />
                </figure>
            ),
            "Candidate Name": candidate.candidate_name,
            "District Name": candidate.district_name,
            Votes: candidate.candidate_vote,
            Seat: candidate.candidate_seats,
        })) || [];

    const provincialDetailsData = [
        { label: "Registered Electors", value: updateData1.totalRegisteredVoters },
        { label: "Total Votes", value: updateData1.all_district_total_votes },
        {
            label: "Total Voting Percentage",
            value: calculateVotingPercentage(
                updateData1.all_district_total_votes,
                updateData1.totalRegisteredVoters
            ),
        },
        { label: "Total Districts", value: updateData1.totalDistrictsCount },
        {
            label: "Total Polling Divisions",
            value: updateData1.totalPollingDivisions,
        },
        {
            label: "Total Seats",
            value: updateData1.total_seats,
        },
        {
            label: "National Seats",
            value: 29,
        },
    ];
    const props = {
        isChecked,
        handleSwitchChange,
        selectedOption,
        locationDistrict: user.district_name,
        locationPollingDivision: user.polling_division_name,
        provincialDetailsData,
        labels,
        data,
        leadingParty,
        partySeatsOptions,
        parliamentChartOptions,
        parliamentpieOptions,
        partyDistrictDataOptions,
        childrens: {
            children1: "Click here",
        },
        handleClick,

        components: {
            component1: seatsTable,
        },
        OffCanvasHeader: OffCanvasHeader,
        OffCanvasBody: (
            <ManageTable labels={labels1} data={data1} className="manageTable1" />
        ),
    };

    return (
        <>
            <ParliamentSimulatorResultMain {...props} />

            <OffCanvas
                OffCanvasHeader={props.OffCanvasHeader}
                OffCanvasBody={props.OffCanvasBody}
                show={show}
                handleClose={handleClose}
                placement="end"
            />
        </>
    );
};

export default ParliamentSimulatorResultPage;
