// validation start
// email start
export const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
    return emailRegex.test(email);
};

export const validatePassword = (password) => {
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[^A-Za-z\d])[\s\S]{9,}$/;
    return passwordRegex.test(password);
};
// email end
// validation end
export const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("en-us", { month: "short" });
    const year = date.getFullYear();

    let daySuffix = "th";
    if (day === 1 || day === 21 || day === 31) {
        daySuffix = "st";
    } else if (day === 2 || day === 22) {
        daySuffix = "nd";
    } else if (day === 3 || day === 23) {
        daySuffix = "rd";
    }

    return (<span>{day}<sup>{daySuffix}</sup> {month} {year}</span>);
};

export function formatElectionDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
}


export const calculateVotingPercentage = (totalVotes, totalRegisteredVoters) => {
    if (totalRegisteredVoters === 0) return "0%";
    return `${((totalVotes / totalRegisteredVoters) * 100).toFixed(2)}%`;
};

export const securityLevel = {
    user: 1,
    admin: 2,
    super_admin: 3,
};
