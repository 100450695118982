import React from 'react';
import { Button } from 'react-bootstrap';

const SolidSquareBlueBtn = (props) => {
  const { type, disabled, style, onClick, children } = props
  return (
    <Button type={type} disabled={disabled} style={style} onClick={onClick} className='solidsquarebluebtn'>{children}</Button>
  )
}

export default SolidSquareBlueBtn;
