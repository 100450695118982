import React from 'react';
import { Button } from 'react-bootstrap';

const GreySolidBtn = (props) => {
    const { children, onClick } = props
    return (
        <Button className="greysolidBtn" onClick={onClick}>
            {children}
        </Button>
    )
}

export default GreySolidBtn;
