import React from "react";
import { Image } from "react-bootstrap";
import OrangeSolidBtn from "../../buttons/orangeSolidBtn";
import { useNavigate } from "react-router-dom";

const SectionAbout = (props) => {
  // const { titles, paras, icons, datas, images, lists, } = props;
  const { children, paras, titles, lables, icons } = props;
  const navigate = useNavigate();

  const onClick = () => {
    navigate("/");
  };

  return (
    <section className="section-landing">
      {/* <Container fluid>
                <Row>
                    <Col md={3}>
                        <div className="userBlock">
                            <aside className="userAside">
                                <div className="userTxtBlk">
                                    <h2 className="userNum">{datas.data1.activeUserNum}</h2>
                                    <h5 className="userTxt">{datas.data1.activeUserTxt}</h5>
                                </div>
                                <div className="userTxtBlk">
                                    <h2 className="userNum">{datas.data2.activeVoterNum}</h2>
                                    <h5 className="userTxt">{datas.data2.activeVoterTxt}</h5>
                                </div>
                                <div className="userTxtBlk">
                                    <h2 className="userNum">{datas.data3.activeUserCount}</h2>
                                    <h5 className="userTxt">{datas.data3.activeUserCountry}</h5>
                                </div>
                            </aside>
                            <aside className="userAside">
                                <Image src={images.image2.heroImg1} className="userside-img" />
                            </aside>
                        </div>
                    </Col>
                    <Col md={3}>
                        <Image src={images.image3.heroImg2} fluid />
                    </Col>
                    <Col md={6}>
                        <aside className="section-aside">
                            <h2 className="aside-title">{titles.title6}</h2>
                            <p className="aside-para">{paras.para1}</p>
                            <ul className="aside-list">
                                <li className="aside-listItems"><span className="listitemsIcons">{icons.icons5}</span>{lists.list1.l1}</li>
                                <li className="aside-listItems"><span className="listitemsIcons">{icons.icons5}</span>{lists.list1.l2}</li>
                                <li className="aside-listItems"><span className="listitemsIcons">{icons.icons5}</span>{lists.list1.l3}</li>
                                <li className="aside-listItems"><span className="listitemsIcons">{icons.icons5}</span>{lists.list1.l4}</li>
                                <li className="aside-listItems"><span className="listitemsIcons">{icons.icons5}</span>{lists.list1.l5}</li>
                            </ul>
                        </aside>
                    </Col>
                </Row>
            </Container> */}
      {/* <section className="section-landing"> */}
      <aside className="section-aside">
        <span className="aside-lable">
          {icons.icons6}
          {lables.lable2}
        </span>
        <h2 className="aside-heading">{titles.title7}</h2>
        <Image src={icons.icons7} className="float-end" />
      </aside>
      <aside className="section-aside">
        <p className="aside-para">{paras.para17}</p>
        <OrangeSolidBtn onClick={onClick} children={children.children4} />
      </aside>
      {/* </section> */}
    </section>
  );
};

export default SectionAbout;
