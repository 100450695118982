import React from "react";
import Select from "react-select";

const Cselect = (props) => {
  const {
    options,
    isSearchable,
    className,
    styles,
    onChange,
    value,
    isMulti,
    isDisabled,
    placeholder,
  } = props;


  const defaultStyles = {
    menu: (provided) => ({
      ...provided,
      width: "100%",
    }),
    ...styles,
  };

  return (
    <Select
      className={className}
      value={value}
      isSearchable={isSearchable}
      onChange={onChange}
      options={options}
      styles={defaultStyles}
      isMulti={isMulti}
      isDisabled={isDisabled}
      placeholder={placeholder}
    />
  );
};

export default Cselect;
