import React, { useState } from "react";
import { MdOutlineHowToVote } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { LiaGreaterThanSolid } from "react-icons/lia";
import {
  Container,
  Row,
  Col,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";

const electionTypess = [
  { name: "Presidential Election", path: "/simulator", election_type_id: 1 },
  { name: "Parliament Election", path: "/simulator", election_type_id: 2 },
];

function VoteSimulator(props) {
  const navigate = useNavigate();
  const { uniqueElections } = props;
  const [showVoteSimulator, setShowVoteSimulator] = useState(true);
  const token = localStorage.getItem("token");
  const isLoggedIn = !!token;

  const electionMap = uniqueElections.reduce((acc, election) => {
    acc[election.type_of_election_name] = election.election_type_id;
    return acc;
  }, {});

  const handleDropdownClick = () => {
    if (!isLoggedIn) {
      navigate("/login-perefrance");
      setShowVoteSimulator(false);
    }
  };

  const electionTypes = [
    "Presidential Election",
    "Parlament Election",
    "Provincial Council Elections",
    "Local Authorities Elections",
    "Referendum",
  ];

  if (!showVoteSimulator) {
    return null;
  }

  return (
    <div className="voteWrapper">
      <div className="voteSimulator">
        <Container fluid>
          <Row md={2} xs={1} className="voteSimulator-row">
            <Col className="pe-md-5">
              <div className="electionType d-flex align-items-center">
                <MdOutlineHowToVote className="vote-icon" />
                <h6 className="h-electionType ms-3">Election Type</h6>
              </div>
              <div className="my-4">
                {electionTypes.map((electionType, index) => {
                  const electionTypeId = electionMap[electionType];
                  return (
                    <Link
                      key={index}
                      to={`/select-election`}
                      state={{ electionTypeId }}
                      className="text-decoration-none h-votesimulator-body"
                      onClick={() => setShowVoteSimulator(false)} // Close on link click
                    >
                      <LiaGreaterThanSolid />{" "}
                      <span className="ms-3">{electionType}</span>
                    </Link>
                  );
                })}
              </div>
            </Col>
            <Col className="ps-md-5">
              <div className="electionType-2 d-flex align-items-center">
                <MdOutlineHowToVote className="vote-icon-2" />
                <h6 className="h-electionType ms-3">Results</h6>
              </div>
              <div className="my-4">
                {electionTypes.map((electionType, index) => (
                  <Link
                    key={index}
                    to={`/results/${electionType
                      .toLowerCase()
                      .replace(/\s+/g, "-")}`}
                    className="text-decoration-none h-votesimulator-body"
                    onClick={() => setShowVoteSimulator(false)} // Close on link click
                  >
                    <LiaGreaterThanSolid />{" "}
                    <span className="ms-3">{electionType}</span>
                  </Link>
                ))}
              </div>
            </Col>
          </Row>
          <DropdownButton
            className="voteSimu-btnblk"
            id="dropdown-basic-button"
            title={
              <>
                <aside className="">
                  <MdOutlineHowToVote className="vote-icon-3" />
                  <span className="">Vote Simulator</span>
                </aside>
                <aside>
                  <MdKeyboardDoubleArrowRight className="swipe-arrow" />
                </aside>
              </>
            }
            onClick={handleDropdownClick}
          >
            {isLoggedIn &&
              electionTypess.map((election) => (
                <Dropdown.Item
                  key={election.election_type_id}
                  onClick={() => {
                    navigate(election.path, {
                      state: { election_type_id: election.election_type_id },
                    });
                    setShowVoteSimulator(false); // Close on dropdown item click
                  }}
                  className="mUlist1"
                >
                  {election.name}
                </Dropdown.Item>
              ))}
          </DropdownButton>
        </Container>
      </div>
    </div>
  );
}

export default VoteSimulator;
