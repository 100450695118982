import React, { useEffect, useMemo, useRef, useState } from "react";
import OrangYellowBtn from "../../../components/buttons/orangeSolidBtn";
import VoteForYourCandidate from "../../../components/modals/voteForYourCandidate";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "react-simple-snackbar";
import {
  addRegisterVoterId,
  getParliamentElectionResultsByPIdCid,
  getParliamentSeatAllocationByElectionTitleId,
  getParliamentSeatCandidateAllocationByETitleId,
} from "../../../redux/user/action";
import { cApiUrlPrefix } from "../../../config/envConfig";
import disImg from "../../../assets/images/sreelamkaImg.png";
import { GoArrowUpRight } from "react-icons/go";
import { Link } from "react-router-dom";
import Loader from "../../../components/loaders/loader";
import { FaLocationArrow } from "react-icons/fa6";
import HeroResults from "../../../components/user/parliamentElection/heroResults";
// import CardSix from "../../../components/cards/cardSix";
import { Card } from "react-bootstrap";
import { indexDistrict, indexParties } from "../../../redux/adminone/action";

const override = `
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

const cstomStyle = {
  menu: (provided) => ({
    ...provided,
    backgroundColor: "white",
    color: "#65676B",
    Width: "fit-content",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#efefef" : "white",
    color: state.isFocused ? "#65676B" : "#65676B",
    padding: 5,
  }),
};

function HeroSectionParliament() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const location = useLocation();
  // const {
  //   electionTypeId,
  //   electionTitleId,
  //   title,
  //   poll_start_date,
  //   poll_end_date,
  //   election_start_date,
  //   election_end_date,
  //   // party_image,
  //   // party_name,
  //   // id,
  // } = location.state || {};
  // const [electionResults, setElectionResults] = useState(null);

  // console.log("electionTitleId:", electionTitleId);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pageData, setPageData] = useState(() => {
    const storedData = localStorage.getItem("pageData");
    return storedData ? JSON.parse(storedData) : location.state || {};
  });
  const [showModal, setShowModal] = useState(false);
  const [username, setUsername] = useState("");
  // const [userData, setUserData] = useState(null);
  const [openSnackbar] = useSnackbar();
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [options, setOptions] = useState([]);
  const [activeTab, setActiveTab] = useState(null);
  const [selectedParty, setSelectedParty] = useState(null);
  const [selectedPartyData, setSelectedPartyData] = useState(null);

  const loadingA = useSelector((state) => state.adminone.loading);
  const loadingU = useSelector((state) => state.user.loading);
  const isLoading = loadingA || loadingU;

  useEffect(() => {
    if (location.state) {
      setPageData(location.state);
      localStorage.setItem("pageData", JSON.stringify(location.state));
    }
  }, [location.state]);

  useEffect(() => {
    if (location.state?.title) {
      localStorage.setItem("title", location.state.title);
    } else {
      const storedTitle = localStorage.getItem("title");
      if (storedTitle) {
        setPageData((prevData) => ({ ...prevData, title: storedTitle }));
      }
    }
  }, [location.state?.title]);

  const {
    electionTypeId,
    electionTitleId,
    title = pageData.title,
    poll_start_date,
    poll_end_date,
    election_start_date,
    election_end_date,
  } = pageData;

  useEffect(() => {
    if (location.state) {
      setPageData(location.state);
      localStorage.setItem("pageData", JSON.stringify(location.state));
    }
  }, [location.state]);

  const [formData, setFormData] = useState({
    voter_id: "",
  });
  const [errors, setErrors] = useState({
    voter_id: "",
  });

  useEffect(() => {
    dispatch(indexDistrict());
  }, [dispatch]);

  useEffect(() => {
    if (electionTitleId) {
      dispatch(getParliamentSeatAllocationByElectionTitleId(electionTitleId));
      dispatch(getParliamentSeatCandidateAllocationByETitleId(electionTitleId));
    }
  }, [dispatch, electionTitleId]);

  const [userData, setUserData] = useState(() => {
    const storedUser = localStorage.getItem("user");
    return storedUser ? JSON.parse(storedUser) : null;
  });

  const indexDistrictsData = useSelector(
    (state) => state.adminone.indexDistrictsData?.data
  );
  const getSeatAllocationData = useSelector(
    (state) => state.user.getParliamentSeatAllocationByEtidData?.data
  );
  const getSeatCandidateAllocationData = useSelector(
    (state) => state.user.getParliamentSeatCandidateAllocationByEtidData?.data
  );

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      setUserData(parsedUser);
    }
  }, []);

  console.log("Selected Party Data:", selectedPartyData);

  useEffect(() => {
    dispatch(indexParties());
  }, [dispatch]);

  const indexPartiesData = useSelector(
    (state) => state.adminone.indexPartiesData?.data
  );

  // const partyData =
  //   indexPartiesData?.map((party) => ({
  //     id: party.id,
  //     name: party.party_name,
  //     image: party.logo === "0" ? disImg : cApiUrlPrefix + party.logo,
  //   })) || [];

  useEffect(() => {
    if (indexDistrictsData) {
      const newOptions = indexDistrictsData.map((district) => ({
        id: district.id,
        label: district.district_name,
      }));
      setOptions(newOptions);

      const storedUser = localStorage.getItem("user");
      if (storedUser) {
        const parsedUser = JSON.parse(storedUser);
        const userDistrictId = parsedUser.district_id;
        const defaultOption = newOptions.find(
          (option) => option.id === userDistrictId
        );
        setActiveTab(defaultOption || newOptions[0]);
      }
    }
  }, [indexDistrictsData]);

  const handleFilterChange = (selectedOption) => {
    setSelectedFilter(selectedOption);
    // console.log("Selected Option:", selectedOption);
  };

  const filteredDistricts = useMemo(() => {
    return getSeatAllocationData?.districts
      ? getSeatAllocationData.districts.filter(
          (district) => activeTab && district.district_id === activeTab.id
        )
      : [];
  }, [getSeatAllocationData, activeTab]);

  const filteredSeatCandidateData = useMemo(() => {
    return getSeatCandidateAllocationData && filteredDistricts
      ? getSeatCandidateAllocationData.filter((candidateData) =>
          filteredDistricts.some(
            (district) => district.district_id === candidateData.district_id
          )
        )
      : [];
  }, [getSeatCandidateAllocationData, filteredDistricts]);

  useEffect(() => {
    if (filteredSeatCandidateData && filteredSeatCandidateData.length > 0) {
      const firstParty =
        filteredSeatCandidateData[0].polling_divisions[0]?.party[0];
      if (firstParty) {
        setSelectedParty(firstParty);
        setSelectedPartyData(firstParty.candidates);
      }
    }
  }, [filteredSeatCandidateData]);

  const handlePartySelect = (party) => {
    setSelectedParty(party);
    const selectedPartyData = filteredSeatCandidateData.flatMap((district) =>
      district.polling_divisions.flatMap((division) =>
        division.party.filter(
          (partyData) => partyData.party_id === party.party_id
        )
      )
    )[0];
    setSelectedPartyData(
      selectedPartyData ? selectedPartyData.candidates : null
    );
  };

  const handleShowModal = () => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      if (parsedUser.voter_id) {
        navigate("/parlament-election/choose-party", {
          state: {
            electionTypeId: electionTypeId,
            electionTitleId: electionTitleId,
            title: title || "Election Title",
            district_id: parsedUser.district_id,
            district_name: parsedUser.district_name,
            polling_division_id: parsedUser.polling_division_id,
            polling_division_name: parsedUser.polling_division_name,
            voter_id: parsedUser.voter_id,
          },
        });
      } else {
        setShowModal(true);
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const handleClick = async (e) => {
    if (e) {
      e.preventDefault();
    }

    const { voter_id } = formData;
    let valid = true;
    let newErrors = {};

    if (!voter_id || voter_id.length < 2) {
      newErrors.voter_id = "Voter ID must be at least 2 characters long.";
      valid = false;
    }

    setErrors(newErrors);

    if (!valid) return;

    const formDataVoter = new FormData();
    formDataVoter.append("name", username);
    formDataVoter.append("voter_id", voter_id);

    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      try {
        const parsedUser = JSON.parse(storedUser);
        const userId = parsedUser.id;

        if (!userId) {
          openSnackbar("User ID not found");
          return;
        }

        dispatch(
          addRegisterVoterId(formDataVoter, userId, (error, response) => {
            if (error) {
              console.error("Error:", error);
              openSnackbar("Error occurred while adding voter ID");
            } else {
              if (response.status === 200) {
                openSnackbar(response.data.message);
                parsedUser.voter_id = voter_id;
                localStorage.setItem("user", JSON.stringify(parsedUser));
                setShowModal(false);
                navigate("/parlament-election/choose-party", {
                  state: {
                    electionTypeId: electionTypeId,
                    title: title || "Election Title",
                    district_id: parsedUser.district_id,
                    district_name: parsedUser.district_name,
                    polling_division_id: parsedUser.polling_division_id,
                    polling_division_name: parsedUser.polling_division_name,
                  },
                });
              } else {
                console.error("Response error:", response);
                openSnackbar("Authorization failed.");
              }
            }
          })
        );
      } catch (error) {
        openSnackbar("User ID not found");
      }
    } else {
      openSnackbar("User ID not found");
    }
  };
  const hasFetchedResults = useRef(false);

  useEffect(() => {
    if (electionTitleId && userData && !hasFetchedResults.current) {
      // console.log("Fetching election results for:", electionTitleId);
      dispatch(
        getParliamentElectionResultsByPIdCid(
          electionTitleId,
          userData.id,
          (error, response) => {
            if (error?.status === 404) {
              // console.log("No entries found for the given election.");
            } else if (error) {
              console.error("Error fetching election data:", error);
            } else {
              // console.log("Election data retrieved successfully:", response);
              hasFetchedResults.current = true;
            }
          }
        )
      );
    }
  }, [dispatch, electionTitleId, userData]);

  const electionResults = useSelector(
    (state) => state.user.getParliamentElectionResultById?.data
  );
  // console.log("electionResults", electionResults);

  const filteredParties =
    electionResults?.candidates && indexPartiesData
      ? [electionResults.candidates[0]]
          .map((candidate) => {
            const party = indexPartiesData.find(
              (party) => party.id === electionResults.party_id
            );

            return party
              ? {
                  id: party.id,
                  name: party.party_name,
                  image:
                    party.logo === "0" ? disImg : cApiUrlPrefix + party.logo,
                }
              : null;
          })
          .filter((party) => party !== null)
      : [];

  const contentTabs = {
    provincialDetailsData: [
      { label: "Registered Electors", value: `52,000` },
      { label: "Total Valid Votes", value: `30,467` },
      { label: "Total Rejected Votes", value: `1,448` },
    ],
  };
  const totalVotes = 100;
  const votesReceived = 88;
  const percentage = (votesReceived / totalVotes) * 100;

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date.toISOString().split("T")[0];
  };

  // const text = {
  //   title: "Sri Lanka",
  //   parties: "4 Leading parties",
  //   seats: "196 seats",
  // };

  // const data = [
  //   { party: "Muslim Minority Politics", votes: 200 },
  //   { party: "National Party", votes: 150 },
  //   { party: "Bhartiya Janta Party", votes: 125 },
  //   { party: "Minority Cast Party", votes: 80 },
  // ];

  const isPollEnded = new Date() > new Date(poll_end_date);

  const disData = {
    getSeatAllocationData,
  };
  const voteData = {
    electionResults,
  };
  // console.log("disData:", getSeatAllocationData);
  // console.log("filteredDistricts", filteredDistricts);

  const props = {
    cstomStyle,
    value: selectedFilter,
    selectChange: handleFilterChange,
    filteredDistricts: filteredDistricts,
    options,
    contentTabs,
    percentage: parseFloat(percentage),
    strokeColor: "orange",
    disData,
    voteData,
    setActiveTab,
    activeTab,
  };

  const handleChangeClick = (selectedPartyId) => {
    // console.log("Selected partyId:", selectedPartyId);

    const selectedParty = filteredParties.find(
      (party) => party.id === selectedPartyId
    );

    if (!selectedParty) {
      console.error("Selected party not found!");
      return;
    }

    // console.log("partyImage:", selectedParty.image);

    navigate("/update-party-candidate", {
      state: {
        title: title || "Election Title",
        electionTypeId,
        electionTitleId,
        id: selectedParty.id,
        party_name: selectedParty.name,
        party_image: selectedParty.image,
      },
    });
  };

  // console.log("activeTab", activeTab.id);

  return (
    <>
      {/* {isLoading ? (
        <Loader />
      ) : ( */}
      <div className="Hero-Section-parliament mb-5">
        <div className="d-flex mb-4">
          <p className="backG"></p>
          <div className="hero-disc">
            <p>
              <span>Note :</span> O'Democracy polling is an exclusive platform
              poll, and only O'Democracy registered users have the cast their
              vote.
            </p>
          </div>
        </div>
        <div className="d-flex mb-5">
          <p className="backG"></p>
          <div className="hero-disc d-lg-block d-none">
            <span>Election start date :</span> {formatDate(election_start_date)}
            &nbsp;
            <span>end date :</span> {formatDate(election_end_date)}
            <span className="mx-5">||</span>
            <span>Poll start date :</span> {formatDate(poll_start_date)} &nbsp;
            <span>end date :</span> {formatDate(poll_end_date)}
          </div>
          <div className="hero-disc d-lg-none d-block">
            <table style={{ borderCollapse: "collapse", width: "100%" }}>
              <tbody>
                <tr>
                  <td className="electionDate">
                    <span>Election start date :</span>{" "}
                    {formatDate(election_start_date)}
                  </td>
                  <td>
                    <span>end date :</span> {formatDate(election_end_date)}
                  </td>
                </tr>
                <tr>
                  <td className="pollDate">
                    <span>Poll start date :</span> {formatDate(poll_start_date)}
                  </td>
                  <td>
                    <span>end date :</span> {formatDate(poll_end_date)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="first-div">
          <div className="hero-h5">
            <h5>
              {title || "Election Title"} <span>(Poll)</span>
            </h5>
            <p>
              Sri Lanka, {userData?.district_name},{" "}
              {userData?.polling_division_name}{" "}
              <FaLocationArrow
                className="location-i"
                onClick={() => {
                  navigate(`/select-election`);
                }}
              />
            </p>
          </div>
          <div className="hero-btns text-end mt-lg-0 mt-4">
            {electionResults &&
            electionResults.candidates &&
            electionResults.candidates.length > 0 ? (
              <div className="updateViewBtn">
                {filteredParties.map((party) => (
                  <OrangYellowBtn
                    key={party.id}
                    onClick={() => handleChangeClick(party.id)}
                    disabled={isLoading}
                  >
                    Update Vote for candidate
                  </OrangYellowBtn>
                ))}
                <aside className="ms-3">
                  <OrangYellowBtn
                    onClick={() => navigate("/parliamentresult")}
                    disabled={isLoading}
                  >
                    View Exit Poll Result
                  </OrangYellowBtn>
                </aside>
              </div>
            ) : !isPollEnded ? (
              <OrangYellowBtn onClick={handleShowModal} disabled={isLoading}>
                Vote for your candidate
              </OrangYellowBtn>
            ) : (
              <OrangYellowBtn
                onClick={() => navigate("/parliamentresult")}
                disabled={isLoading}
              >
                View Exit Poll Result
              </OrangYellowBtn>
            )}
          </div>
        </div>
        <VoteForYourCandidate
          show={showModal}
          onHide={() => setShowModal(false)}
          title="Register as a voter"
          nameLabel="Voter name"
          namePlaceholder="Enter your profile name"
          idPlaceholder="Enter your Voter ID"
          formValue={username}
          formValue1={formData.voter_id}
          handleChange={handleChange}
          handleClick={handleClick}
          voteText="Save"
        />
        <HeroResults {...props} />
        {isLoading ? (
          <Loader />
        ) : (
          <section className="disCards">
            {filteredDistricts?.length > 0 ? (
              <aside className="results-container">
                <div className="winner-row">
                  {filteredDistricts.map((district) =>
                    district.allocated_seats.map((seat, seatIndex) => {
                      return (
                        <div key={seatIndex} className="winner-col">
                          <Card
                            className={`winner-card ${
                              selectedParty &&
                              selectedParty.party_id === seat.party_id
                                ? "selected"
                                : ""
                            }`}
                          >
                            <Link
                              to="#"
                              className="text-decoration-none d-flex align-items-center"
                              onClick={() => handlePartySelect(seat)}
                            >
                              <div className="winner-blok-des">
                                <div className="winner-blok-design">
                                  <div className="winner-imgBlk">
                                    {seat.party_logo && (
                                      <Card.Img
                                        src={cApiUrlPrefix + seat.party_logo}
                                        className="winner-img"
                                        alt={`${seat.party_name} logo`}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                              <Card.Body className="d-flex flex-column">
                                <aside className="winner-votes order-md-first order-last">
                                  <h3 className="winnerVotes">
                                    {seat.votes}
                                    <span className="winner-votesTxt">
                                      Votes
                                    </span>
                                  </h3>
                                  <h3 className="pt-1">
                                    {seat.vote_percentage}%
                                    <span className="winner-votesTxt">
                                      Vote Percentage
                                    </span>
                                  </h3>
                                </aside>
                                <aside className="winner-name pt-1 order-md-last order-first">
                                  {seat.party_name}{" "}
                                  <GoArrowUpRight className="winner-arw" />
                                </aside>
                              </Card.Body>
                            </Link>
                          </Card>
                        </div>
                      );
                    })
                  )}
                </div>

                {/* Conditionally render selected party data */}
                {selectedPartyData && selectedPartyData.length > 0 ? (
                  <div className="candidate-div">
                    {selectedPartyData.map((candidate) => (
                      <div key={candidate.candidate_id} className="winner-col">
                        <Card className="winner-card">
                          <div className="winner-blok-des">
                            <div className="winner-blok-design">
                              <div className="winner-imgBlk">
                                {candidate.candidate_image && (
                                  <Card.Img
                                    src={
                                      cApiUrlPrefix + candidate.candidate_image
                                    }
                                    alt={`${candidate.candidate_name} logo`}
                                    className="selected-party-logo"
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                          <Card.Body>
                            <aside className="winner-votes">
                              <h3>{candidate.candidate_name}</h3>
                            </aside>
                            <aside className="winner-name pt-2">
                              <p>Votes: {candidate.votes}</p>
                            </aside>
                          </Card.Body>
                        </Card>
                      </div>
                    ))}
                  </div>
                ) : (
                  <p>Select a party to view candidate details.</p>
                )}
              </aside>
            ) : (
              <aside className="notFoundError">
                <span className="notFoundErrorTxt">Data Not Available</span>
              </aside>
            )}
          </section>
        )}
        {/* <Container fluid className="cards-result">
          <Row md={2} sm={1}>
            <Col>
              <CardSix text={text} data={data} />
            </Col>
            <Col>
              <CardSix text={text} data={data} />
            </Col>
          </Row>
        </Container> */}
        {isLoading ? (
          <Loader
            color={"#0E76A8"}
            loading={isLoading}
            css={override}
            size={75}
          />
        ) : null}
      </div>
      {/* )} */}
    </>
  );
}

export default HeroSectionParliament;
