import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row } from "react-bootstrap";
import { SlOptionsVertical } from "react-icons/sl";
import Cselect from "../../components/select/cselect";
import DropDownOne from "../../components/dropdown/dropDownOne";
import ManageTable from "../../components/table/manageTable";
import Cancel from "../../components/labels/cancel";
import Check from "../../components/labels/check";
import { adminDashboard } from "../../redux/adminone/action";
import Loader from "../../components/loaders/loader";

const override = `
    display: block;
    margin: 0 auto;
    border-color: red;
`;

const options = [
  { value: 10, label: "Sri Lanka" },
  { value: 20, label: "India" },
  { value: 30, label: 30 },
];

const cstomStyle = {
  menu: (provided) => ({
    ...provided,
    backgroundColor: "white",
    color: "#65676B",
    maxWidth: "100%",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#efefef" : "white",
    color: state.isFocused ? "#65676B" : "#65676B",
    padding: 5,
  }),
};

const Dashboard = () => {
  const [showAll, setShowAll] = useState(false);
  const dispatch = useDispatch();
  const dashboardData = useSelector(
    (state) => state.adminone.dashboardData?.data
  );
  const loading = useSelector((state) => state.adminone.loading);
  const error = useSelector((state) => state.adminone.error);

  useEffect(() => {
    dispatch(adminDashboard());
  }, [dispatch]);

  const activeStatusMap = {
    0: <Cancel />,
    1: <Check />,
  };

  const data2 = Array.isArray(dashboardData?.manageelections)
    ? dashboardData.manageelections.map((data) => ({
        election_title: data.election_title,
        election_start_date: data.election_start_date,
        election_end_date: data.election_end_date,
      }))
    : [];

  const handleViewAllClick = () => {
    setShowAll(!showAll);
  };

  const labels = [
    "No.",
    "Registered Users",
    "User ID",
    "Email",
    "Mobile Number",
  ];

  const data = Array.isArray(dashboardData?.users)
    ? dashboardData.users.map((data, index) => ({
        "No.": index + 1,
        "Registered Users": data.name,
        "User ID": data.username,
        Email: (
          <>
            {data.email} {activeStatusMap[data.isEmailverified]}
          </>
        ),
        "Mobile Number": (
          <>
            {data.mobileNumber} {activeStatusMap[data.isMobileNumberverified]}
          </>
        ),
      }))
    : [];

  const labels1 = [
    "User name",
    "Voter ID",
    "party_name",
    "preference1",
    "preference2",
    "preference3",
  ];

  const data1 = Array.isArray(dashboardData?.topCandidates)
    ? dashboardData.topCandidates.map((data) => ({
        "User name": data.user_name,
        "Voter ID": data.voter_id,
        party_name: data.party_name,
        preference1: data.preference1,
        preference2: data.preference2,
        preference3: data.preference3,
      }))
    : [];

  const props = {
    totalData: [
      { title: "Total Province", value: dashboardData?.totalProvinces },
      {
        title: "Total Province Seats",
        value: dashboardData?.totalProvinceSeats,
      },
      { title: "Total Districts Seats", value: dashboardData?.totalSeats },
      { title: "Total Districts", value: dashboardData?.totalDistricts },
      {
        title: "Total Polling Division",
        value: dashboardData?.totalPollingDivisions,
      },
      { title: "Total Population", value: dashboardData?.totalPopulation },
      {
        title: "Total Registered Voters",
        value: dashboardData?.totalRegisteredVoters,
      },
      { title: "Total Candidates", value: dashboardData?.totalCandidates },
      { title: "Total Parties", value: dashboardData?.totalParties },
      { title: "O'Democracy Users", value: dashboardData?.totalUsers },
    ],
    data,
    labels,
    labels1,
    data1,
  };

  return (
    <main className="aDashbordMain">
      <section className="aDashbordMainSection">
        <div className="headingbar">
          <h2 className="headingtxt">Dashboard</h2>
          <Cselect
            styles={cstomStyle}
            options={options}
            className="cselectpageSize"
          />
        </div>
        <div className="newsblock">
          {data2.length > 3 && (
            <div className="newsviewbtnblock d-flex justify-content-end">
              <div className="viewallbtn" onClick={handleViewAllClick}>
                {showAll ? "View Less" : "View All"}
              </div>
            </div>
          )}
          {data2.slice(0, showAll ? data2.length : 3).map((item, index) => (
            <div className="announcement" key={index}>
              O'Democracy has announced the dates for the{" "}
              <span className="election-name">{item.election_title}</span> in{" "}
              <span className="electionCountry">Sri Lanka</span> from{" "}
              <span className="electionStartDate">
                {item.election_start_date}
              </span>{" "}
              to{" "}
              <span className="electionEndDate">{item.election_end_date}.</span>
            </div>
          ))}
        </div>
        <Container fluid className="totalContainer">
          <Row className="g-5" xl={4} lg={3} md={2} xs={1}>
            {props.totalData.map((item, index) => (
              <Col key={index} className="totalCol">
                <div className="totalBlks">
                  <h4 className="totalBlksHead">{item.title}</h4>
                  <p className="totalBlksPara">{item.value}</p>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
      <section className="aDashbordMainSection">
        <Container fluid>
          <Row className="NewRegisterRow">
            <Col md={12} className="NewRegisterColom">
              <h4 className="NewRegisterColomHeading">
                New Registered user in O'Democracy Platform
              </h4>
              <DropDownOne
                key="start"
                id="dropdown-button-drop-start"
                drop="start"
                title={<SlOptionsVertical />}
                className="LeftDropDOwm"
              />
            </Col>
          </Row>
          <Row>
            <Col md={12} className="NewRegisterTableColum">
              <ManageTable
                data={props.data}
                loading={loading}
                error={error}
                labels={props.labels}
              />
            </Col>
            <Col md={5}></Col>
          </Row>
        </Container>
      </section>
      <section className="aDashbordMainSection">
        <Container fluid>
          <Row className="NewRegisterRow">
            <Col md={12} className="NewRegisterColom">
              <h4 className="NewRegisterColomHeading">Submited Votes</h4>
              <DropDownOne
                key="start"
                id="dropdown-button-drop-start"
                drop="start"
                title={<SlOptionsVertical />}
                className="LeftDropDOwm"
              />
            </Col>
          </Row>
          <Row>
            <Col md={12} className="SubmittedVotesTableColum">
              <h4 className="SubmittedVotesTableHeading">
                Presidential election (15th May to 15th June)
              </h4>
              <ManageTable
                data={props.data1}
                loading={loading}
                error={error}
                labels={props.labels1}
              />
            </Col>
            <Col md={5}></Col>
          </Row>
        </Container>
      </section>
      {loading ? (
        <Loader color={"#0E76A8"} loading={loading} css={override} size={75} />
      ) : null}
    </main>
  );
};

export default Dashboard;
