import React from "react";
import PropTypes from "prop-types";
import { IoSearch } from "react-icons/io5";

const SearchInput = ({ placeholder }) => {
  return (
    <div className="search-input">
      <IoSearch className="search-icon" />
      <input type="text" placeholder={placeholder} />
    </div>
  );
};

SearchInput.propTypes = {
  placeholder: PropTypes.string,
};

export default SearchInput;
