import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import IImage from "../../../components/image/iImage";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { showBlogContentById } from "../../../redux/adminone/action";

const ShowBlogs = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const id = location.state?.id;

  const showBlogContentByIdData = useSelector(
    (state) => state.adminone.showBlogContentByIdData?.data
  );

  useEffect(() => {
    if (!id) {
      navigate("/blogs");
    } else {
      dispatch(showBlogContentById(id));
    }
  }, [id, dispatch, navigate]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [showBlogContentByIdData]);

  if (!showBlogContentByIdData) {
    return <div>Loading...</div>;
  }

  const {
    category_name,
    blog_title,
    blog_image,
    overview,
    created_at,
    content,
  } = showBlogContentByIdData;

  return (
    <section className="section-showBlogs d-flex align-items-center justify-content-center">
      <Container fluid className="px-0 section-container">
        <Row className="section-row justify-content-center">
          <Col md={8} xs={10} className="section-col">
            <div className="card col-card">
              {blog_image && (
                <IImage
                  src={blog_image}
                  className="card-img-top"
                  alt="Blog Image"
                />
              )}
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="date">{created_at}</div>
                  <span className="card-cName">{category_name}</span>
                </div>
                <h5 className="card-title mt-3">{blog_title}</h5>
                <p className="card-text">{overview}</p>
                <div className="card-content">
                  <p className="card-text">{content}</p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ShowBlogs;
