import React from "react";
import PropTypes from 'prop-types';
import Multiselect from "multiselect-react-dropdown";
const CMultiSelect = (props) => {
  const { options, selectedValue, onSelect, onRemove,  disable } = props;



  return (
    <Multiselect
      options={options} 
      selectedValues={selectedValue} 
      onSelect={onSelect} 
      onRemove={onRemove} 
      displayValue="name" 
      disable={disable}
      className="cmultiselect"
      showCheckbox
    />
  );
};

CMultiSelect.propTypes = {
  options: PropTypes.array.isRequired,
  selectedValue: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  disable: PropTypes.bool,
};

export default CMultiSelect;
