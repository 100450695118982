import React from "react";
import { useLocation } from "react-router-dom";
import Aheader from "./aHeader";
import Uheader from "./uHeader";
import DefaultHeader from "./defaultHeader";

const Header = ({ lang, setLang }) => {
  const location = useLocation();

  const renderHeader = () => {
    switch (location.pathname) {
      case "/dashboard":
      case "/manage-party":
      case "/party-history":
      case "/manage-associated-candidate-by-id":
      case "/add-associated-candidate-by-id":
      case "/manage-candidate":
      case "/candidate-history":
      case "/manage-province":
      case "/manage-associated-district-by-id":
      case "/add-province":
      case "/edit-province":
      case "/manage-districts":
      case "/add-districts":
      case "/edit-districts":
      case "/polling-division":
      case "/add-poling-division":
      case "/edit-poling-division":
      case "/add-party":
      case "/edit-party-by-id":
      case "/add-candidate":
      case "/edit-candidate-by-id":
      case "/user-list":
      case "/edit-user-by-id":
      case "/type-of-election":
      case "/add-election-type":
      case "/edit-election-type":
      case "/type-of-election-candidate":
      case "/type-of-election-add-candidate":
      case "/type-of-election-edit-candidate":
      case "/security-level":
      case "/manage-permission":
      case "/add-securities":
      case "/edit-securities":
      case "/permission":
      case "/manage-blog-category":
      case "/add-blog-category":
      case "/edit-blog-category":
      case "/manage-blogs-content":
      case "/add-blogs-content":
      case "/edit-blogs-content":
      case "/add-permission":
      case "/edit-permission":
      case "/election-history":
      case "/edit-election-history":
      case "/manage-presidential-election":
      case "/manage-parliamentary-election":
      case "/presidential-election-poll-result":
        return <Aheader />;

      case "/profile":
      case "/presidential-election-hero-section":
      case "/select-election":
      case "/select-district":
      case "/select-polling-division":
      case "/vote-simulator":
      case "/simulator":
      case "/registerasvoter":
      case "/winner":
      case "/test":
      case "/parlament-election/choose-party":
      case "/parliament-election-dashboard":
      case "/choose-party-candidate":
      case "/update-party-candidate":
      case "/parliament-election-simulator-result":
      case "/parliament-election-alliance":
        return <Uheader lang={lang} changeLang={setLang} />;
      default:
        return <DefaultHeader />;
    }
  };

  return <>{renderHeader()}</>;
};

export default Header;
