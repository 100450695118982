import React from 'react';
import { Button } from 'react-bootstrap';
import { FaMinus } from "react-icons/fa";

const MinusButton = ({onClick}) => {
    return (
        <Button className='minusButton' onClick={onClick}><FaMinus /></Button>
    )
}

export default MinusButton;
