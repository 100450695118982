import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Table } from "react-bootstrap";
import ParliamentElectionAllianceMain from "./parliamentElectionAllianceMain";
import { calculateVotingPercentage } from "../../../utilities/common";
import IImage from "../../../components/image/iImage";
import { CiSquareMinus, CiSquarePlus } from "react-icons/ci";
import TransparentBtn from "../../../components/buttons/transparentBtn";
import CustomChart from "../../../components/charts/customChart";
import OffCanvas from "../../../components/offCanvas/offCanvas";
import ManageTable from "../../../components/table/manageTable";
const ParliamentElectionAlliancePage = () => {
  const location = useLocation();
  const {
    partyData,
    selectedOption,
    totalSeats,
    totalPer,
    registerVoter,
    totalVotes,
    totalPollingDivisions,
    totalDistrict,
  } = location.state || {};

  const [allianceOne, setAllianceOne] = useState(partyData);
  const [tSeatsOne, setTSeatsOne] = useState(0);
  const [tPerOne, setTPerOne] = useState(100);
  const [allianceTwo, setAllianceTwo] = useState({});
  const [tSeatsTwo, setTSeatsTwo] = useState(0);
  const [tPerTwo, setTPerTwo] = useState(0);
  const [listOfCandidate, setListOfCandidate] = useState();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const calculateTotals = () => {
    let totalSeatsCalculatedOne = 0;
    let totalSeatsCalculatedTwo = 0;
    Object.values(allianceOne).forEach((party) => {
      totalSeatsCalculatedOne += party.party_seats + party.national_seats;
    });
    const percentageOne = totalSeats
      ? ((totalSeatsCalculatedOne / totalSeats) * 100).toFixed(2)
      : "0.00";
    setTSeatsOne(totalSeatsCalculatedOne);
    setTPerOne(percentageOne);

    Object.values(allianceTwo).forEach((party) => {
      totalSeatsCalculatedTwo += party.party_seats + party.national_seats;
    });
    const percentageTwo = totalSeats
      ? ((totalSeatsCalculatedTwo / totalSeats) * 100).toFixed(2)
      : "0.00";
    setTSeatsTwo(totalSeatsCalculatedTwo);
    setTPerTwo(percentageTwo);
  };

  useEffect(() => {
    calculateTotals();
  }, [allianceOne, allianceTwo]);

  const handleParty = (partyId, plus, minus) => {
    const partyToAdd = Object.keys(allianceOne).find((key) => key === partyId);
    if (plus === true) {
      if (partyToAdd) {
        setAllianceTwo((prev) => ({
          ...prev,
          [partyToAdd]: allianceOne[partyToAdd],
        }));
        setAllianceOne((prev) => {
          const { [partyToAdd]: _, ...newAllianceOne } = prev;
          return newAllianceOne;
        });
      } else {
        console.log("Party not found in allianceOne");
      }
    }
    const partyToRemove = Object.keys(allianceTwo).find(
      (key) => key === partyId
    );
    if (minus === false) {
      if (partyToRemove) {
        setAllianceOne((prev) => ({
          ...prev,
          [partyToRemove]: allianceTwo[partyToRemove],
        }));
        setAllianceTwo((prev) => {
          const { [partyToRemove]: _, ...newAllianceTwo } = prev;
          return newAllianceTwo;
        });
      } else {
        console.log("Party not found in allianceOne");
      }
    }
  };

  const handleClick = (allianceOne, allianceTwo) => {
    console.log("allianceOne", allianceOne);

    const candidates = [];
    for (const partyKey in allianceOne) {
      const party = allianceOne[partyKey];
      if (party) {
        const partyName = party.party_name;
        const partyLogo = party.party_logo;

        party.simulator.forEach((candidate) => {
          candidates.push({
            ...candidate,
            party_name: partyName,
            party_logo: partyLogo,
          });
        });
      }
    }

    for (const partyKey in allianceTwo) {
      const party = allianceTwo[partyKey];
      if (party) {
        const partyName = party.party_name;
        const partyLogo = party.party_logo;

        party.simulator.forEach((candidate) => {
          candidates.push({
            ...candidate,
            party_name: partyName,
            party_logo: partyLogo,
          });
        });
      }
    }

    const filteredCandidates = candidates.filter(
      (candidate) => candidate.candidate_seats > 0
    );

    setListOfCandidate(filteredCandidates);
    handleShow();
  };

  const labels = ["Candidate", "party", "District Name", "Votes", "Seat"];
  const data =
    listOfCandidate?.map((candidate) => ({
      Candidate: (
        <div className="d-flex align-items-center">
          <figure className="mpLogo mx-3">
            <IImage src={candidate.candidate_image} className="mpLogoImg " />
          </figure>
          {candidate.candidate_name}
        </div>
      ),
      party: (
        <div div className="d-flex align-items-center">
          <figure className="mpLogo mx-0 me-3">
            <IImage src={candidate.party_logo} className="mpLogoImg " />
          </figure>
          {candidate.party_name}
        </div>
      ),
      "District Name": candidate.district_name,
      Votes: candidate.candidate_vote,
      Seat: candidate.candidate_seats,
    })) || [];

  const provincialDetailsDataOne = [
    { label: "Total Seats", value: tSeatsOne, onClick: handleClick },
    { label: "Percentage", value: `${tPerOne} %` },
  ];

  const Alliance1 = () => {
    return (
      <div className="parliament_alliance">
        <div className="parl_all_head_blk">
          <h2 className="pahb_heading">
            {tSeatsOne > totalSeats / 2 ? "Ruling Party" : "Opposition Party"}
          </h2>
          <div className="parlament-ex-voting-blk">
            {provincialDetailsDataOne.map((item, index) => (
              <div
                className="d-flex right-border pe-4 cursor-pointer"
                key={index}
                onClick={() => index === 0 && item.onClick(allianceOne, null)}
              >
                <span className={`sqr-box${index}`}></span>
                <div className="registered-e-div">
                  <h6 className="provincial-vote">{item.value}</h6>
                  <p className="registered-electors">{item.label}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="ao-table-blk">
          <Table className="table-borderless ao-table">
            <thead>
              <tr>
                <td>Party</td>
                <td className="align-middle text-center">Seat %</td>
                <td className="align-middle text-center">Seats</td>
                <td className="align-middle text-center">National Seats</td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              {Object.keys(allianceOne).map((partyKey) => {
                const party = allianceOne[partyKey];
                return (
                  <tr key={partyKey}>
                    <td className="d-flex align-items-center">
                      <figure className="mpLogo">
                        <IImage src={party.party_logo} className="mpLogoImg" />
                      </figure>

                      {party.party_name}
                    </td>
                    <td className="align-middle text-center">
                      {calculateVotingPercentage(
                        party.party_seats + party.national_seats,
                        totalSeats
                      )}
                    </td>
                    <td className="align-middle text-center">
                      {party.party_seats}
                    </td>
                    <td className="align-middle text-center">
                      {party.national_seats}
                    </td>
                    <td className="align-middle text-center">
                      <TransparentBtn
                        icon={<CiSquarePlus />}
                        onClick={() => handleParty(partyKey, true, null)}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
    );
  };

  const provincialDetailsDataTwo = [
    { label: "Total Seats", value: tSeatsTwo, onClick: handleClick },
    { label: "Percentage", value: `${tPerTwo} %` },
  ];
  const provincialDetailsData = [
    { label: "Registered Electors", value: registerVoter },
    { label: "Total Votes", value: totalVotes },
    {
      label: "Total Voting Percentage",
      value: totalPer,
    },
    { label: "Total Districts", value: totalDistrict },
    {
      label: "Total Polling Divisions",
      value: totalPollingDivisions,
    },
    {
      label: "Total Seats",
      value: (tSeatsOne + tSeatsTwo) - 29,
    },
    {
      label: "National Seats",
      value: 29,
    },
  ];
  const Alliance2 = () => {
    return (
      <div className="parliament_alliance">
        <div className="parl_all_head_blk">
          <h2 className="pahb_heading">
            {tSeatsTwo > totalSeats / 2 ? "Ruling Party" : "Opposition Party"}
          </h2>
          <div className="parlament-ex-voting-blk">
            {provincialDetailsDataTwo.map((item, index) => (
              <div
                className="d-flex right-border pe-4 cursor-pointer"
                key={index}
                onClick={() => index === 0 && item.onClick(null, allianceTwo)}
              >
                <span className={`sqr-box${index}`}></span>
                <div className="registered-e-div">
                  <h6 className="provincial-vote">{item.value}</h6>
                  <p className="registered-electors">{item.label}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="ao-table-blk">
          <Table className="table-borderless ao-table">
            <thead>
              <tr>
                <td>Party</td>
                <td className="align-middle text-center">Seat %</td>
                <td className="align-middle text-center">Seats</td>
                <td className="align-middle text-center">National Seats</td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              {Object.keys(allianceTwo).map((partyKey) => {
                const party = allianceTwo[partyKey];

                return (
                  <tr key={partyKey}>
                    <td className="d-flex align-items-center">
                      <figure className="mpLogo">
                        <IImage src={party.party_logo} className="mpLogoImg" />
                      </figure>

                      {party.party_name}
                    </td>
                    <td className="align-middle text-center">
                      {calculateVotingPercentage(
                        party.party_seats + party.national_seats,
                        totalSeats
                      )}
                    </td>
                    <td className="align-middle text-center">
                      {party.party_seats}
                    </td>
                    <td className="align-middle text-center">
                      {party.national_seats}
                    </td>
                    <td className="align-middle text-center">
                      <TransparentBtn
                        icon={<CiSquareMinus />}
                        onClick={() => handleParty(partyKey, null, false)}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
    );
  };

  const options3 = {
    chart: {
      type: "item",
    },
    title: {
      text: "Parliament of seats",
      style: {
        fontSize: "1.4rem",
      },
    },
    legend: {
      labelFormat: '{name} <span style="opacity: 0.8">{y}</span>',
      itemStyle: {
        fontSize: "1.4rem",
      },
    },
    series: [
      {
        name: "Representatives",
        keys: ["name", "y", "color"],
        data: [
          [
            `${tSeatsOne > totalSeats / 2 ? "Ruling Party" : "Opposition Party"
            }`,
            tSeatsOne,
            "#0e76a8",
          ],
          [
            `${tSeatsOne < totalSeats / 2 ? "Ruling Party" : "Opposition Party"
            }`,
            tSeatsTwo,
            "#f07e0c",
          ],
        ],
        dataLabels: {
          enabled: false,
          format: "{point.label}",
        },
        center: ["50%", "88%"],
        size: "170%",
        startAngle: -100,
        endAngle: 100,
      },
    ],
    tooltip: {
      style: {
        fontSize: "1.4rem",
      },

      colorByPoint: true,
      formatter: function () {
        let partyNames = [];
        let partySeats = [];
        let color = this.point.color;
        let label =
          this.point.y > totalSeats / 2 ? "Ruling Party" : "Opposition Party";

        if (this.point.y === tSeatsOne) {
          for (const key in allianceOne) {
            if (allianceOne[key]) {
              partyNames.push(allianceOne[key].party_name);
              partySeats.push(allianceOne[key].party_seats);
            }
          }
        } else if (this.point.y === tSeatsTwo) {
          for (const key in allianceTwo) {
            if (allianceTwo[key]) {
              partyNames.push(allianceTwo[key].party_name);
              partySeats.push(allianceTwo[key].party_seats);
            }
          }
        }

        let partyInfo = partyNames
          .map((name, index) => `<b>${name}</b>- seats : ${partySeats[index]}`)
          .join("<br/>");
        return `<span><b style="color: ${color}; text-decoration: underline;">${label}</b><br/><br/>Total Seats: ${this.point.y}<br/><br/>${partyInfo}</span>`;
      },
    },
    exporting: {
      buttons: {
        contextButton: {
          menuItems: ["viewFullscreen"],
        },
      },
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 600,
          },
          chartOptions: {
            series: [
              {
                dataLabels: {
                  distance: -30,
                },
              },
            ],
          },
        },
      ],
    },
  };

  const props = {
    title: `Result alliance of ${selectedOption}`,
    Components: {
      Component1: <Alliance1 />,
      Component2: <Alliance2 />,
      Component3: <CustomChart options={options3} />,
    },
    provincialDetailsData,
    OffCanvasBody: (
      <ManageTable labels={labels} data={data} className="manageTable1" />
    ),
    // formattedResults,
  };

  return (
    <div className="xyz">
      <ParliamentElectionAllianceMain {...props} />
      <OffCanvas
        OffCanvasHeader={props.OffCanvasHeader}
        OffCanvasBody={props.OffCanvasBody}
        show={show}
        handleClose={handleClose}
        placement="end"
      />
    </div>
  );
};

export default ParliamentElectionAlliancePage;
