import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DropDown from "../../../components/dropdown/dropDown";
import DeleteCModal from "../../../components/modals/deleteCModal";

import { useLocation, useNavigate } from "react-router-dom";
import ManageBlkThree from "../../../components/admin/manageBlkThree";

import {
  delPollingDivisionsByDistrictId,
  showAllPollingDivisionsByDistrictById,
} from "../../../redux/adminone/action";
import { useSnackbar } from "react-simple-snackbar";

const options = [
  { value: 10, label: 10 },
  { value: 20, label: 20 },
  { value: 30, label: 30 },
];
const cstomStyle = {
  menu: (provided) => ({
    ...provided,
    backgroundColor: "white",
    color: "#65676B",
    maxWidth: "97px",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#efefef" : "white",
    color: state.isFocused ? "#65676B" : "#65676B",
    padding: 5,
  }),
};
const ManagePollingDivision = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const location = useLocation();
  const { id, district_name, } = location.state || {};
  // const params = new URLSearchParams(location.search);
  // const id = params.get("id");
  // const district_name = params.get("district_name");

  useEffect(() => {
    if (id) {
      dispatch(showAllPollingDivisionsByDistrictById(id));
    }
  }, [dispatch, id]);

  const showsAllPollingDivisionsByDistrictIdData = useSelector(
    (state) => state.adminone.showsAllPollingDivisionsByDistrictIdData?.data
  );
  const loading = useSelector((state) => state.adminone.loading);
  const error = useSelector((state) => state.adminone.error);
  const [isSearchable] = useState(false);
  const [pollingDivision, setPollingDivision] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const dropdownItems = (data) => [
    { label: "Edit", href: "#/edit", onClick: () => handleEdit(data) },
    {
      label: "Delete",
      href: "#/delete",

      onClick: () => {
        handleOpenModal(data);
      },
    },
  ];

  const handleEdit = (data) => {
    navigate(
      `/edit-poling-division?id=${id}&poling_id=${data.id
      }&polling_division_name=${encodeURIComponent(
        data.polling_division_name
      )}&district_name=${encodeURIComponent(district_name)}&edit=true`
    );
  };

  const handleOpenModal = (data) => {
    setPollingDivision(data);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setPollingDivision(null);
  };

  const handleConfirmDelete = () => {
    if (pollingDivision) {
      const id = pollingDivision.id;

      dispatch(delPollingDivisionsByDistrictId(id, (error, response) => {
        if (error) {
          // handle the error

        } else {
          // handle the response

          if (response.status === 200) {
            dispatch(showAllPollingDivisionsByDistrictById(id));
            openSnackbar(response.data.message);
          }
        }
      }));
      handleCloseModal();
    }
  };

  const handleAddPollingDivision = () => {
    navigate(
      `/add-poling-division?id=${id}&district_name=${encodeURIComponent(
        district_name
      )}&add=true`
    );
  };

  const labels = [
    "Polling Devision",
    "Total Population",
    "Total Register Voter",
    "Action",
  ];
  const data = Array.isArray(showsAllPollingDivisionsByDistrictIdData)
    ? showsAllPollingDivisionsByDistrictIdData.map((pollingDivision) => ({
      "Polling Devision": pollingDivision.polling_division_name,
      "Total Population": pollingDivision.total_population,
      "Total Register Voter": pollingDivision.total_registered_voters,
      Action: (
        <DropDown
          className="sequritiesPartyAction ms-auto"
          Children={"Action"}
          items={dropdownItems(pollingDivision)}
        />
      ),
    }))
    : [];
  const Props = {
    title: `${district_name} >> Polling Divisions`,
    children: "Add Polling Devision",
    style: { padding: "1.3rem 2.8rem" },
    options,
    isSearchable,
    className: {
      cselectpageSize: "cselectpageSize",
      minputSearch: "minputSearch",
      deleteModal: "deleteModal",
    },
    handleAddPollingDivision,
    cstomStyle,
    Placeholder: "Search",
    labels,
    data,
    error,
  };
  return (
    <main className="pollingDiv">
      <aside className="pollingDivaside">
        <ManageBlkThree {...Props} />
      </aside>
      <DeleteCModal
        show={showModal}
        handleClose={handleCloseModal}
        handleConfirm={handleConfirmDelete}
        className={Props.className.deleteModal}
      />
    </main>
  );
};

export default ManagePollingDivision;
