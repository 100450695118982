import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getParliamentElectionResultsByETitleId,
  indexDistrict,
} from "../../../redux/adminone/action";
import { useLocation, useNavigate } from "react-router-dom";
import ManageParliamentElectionMain from "./manageParliamentElectionMain";
import IImage from "../../../components/image/iImage";

const options = [
  { value: 10, label: 10 },
  { value: 20, label: 20 },
  { value: 30, label: 30 },
];

const cstomStyle = {
  menu: (provided) => ({
    ...provided,
    backgroundColor: "white",
    color: "#65676B",
    maxWidth: "97px",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#efefef" : "white",
    color: state.isFocused ? "#65676B" : "#65676B",
    padding: 5,
  }),
};
const cstomStyle1 = {
  menu: (provided) => ({
    ...provided,
    backgroundColor: "white",
    color: "#65676B",
    maxWidth: "250px",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#efefef" : "white",
    color: state.isFocused ? "#65676B" : "#65676B",
    padding: 5,
  }),
};
const cstomStyle2 = {
  menu: (provided) => ({
    ...provided,
    backgroundColor: "white",
    color: "#65676B",
    maxWidth: "97px",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#efefef" : "white",
    color: state.isFocused ? "#65676B" : "#65676B",
    padding: 5,
  }),
};

const ManageParliamentElectionPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [isSearchable] = useState(false);
  const [options1, setOptions1] = useState([]);
  const [selectedDistrictId, setSelectedDistrictId] = useState(null);

  const { electionTitleId } = location.state || {};
  const { electionTitle } = location.state || {};

  console.log("electionTypeId:", electionTitleId);

  useEffect(() => {
    dispatch(getParliamentElectionResultsByETitleId(electionTitleId));
    dispatch(indexDistrict());
  }, [dispatch, electionTitleId]);

  const getParliamentElectionResultsByEtidData = useSelector(
    (state) => state.adminone.getParliamentElectionResultsByEtidData?.data
  );

  const indexDistrictsData = useSelector(
    (state) => state.adminone.indexDistrictsData?.data
  );

  useEffect(() => {
    if (indexDistrictsData) {
      const newOptions = indexDistrictsData.map((item) => ({
        value: item.id,
        label: item.district_name,
      }));
      setOptions1(newOptions);
    }
  }, [indexDistrictsData]);

  const selectChange = (selectedOption) => {
    setSelectedDistrictId(selectedOption.value);
  };

  const filteredData = Array.isArray(getParliamentElectionResultsByEtidData)
    ? getParliamentElectionResultsByEtidData
        .filter((item) =>
          selectedDistrictId
            ? item.results[0]?.district_id === selectedDistrictId
            : true
        )
        .map((presiResData) => ({
          "User Name": presiResData.user_name,
          "Voter Id": presiResData.voter_id,
          "District Name": presiResData.results[0]?.district_name || "",
          "Party Name": (
            <span className="d-flex flex-row">
              <figure className="mpLogo">
                <IImage
                  src={presiResData.results[0]?.party_logo}
                  className="mpLogoImg"
                />
              </figure>
              {presiResData.results[0]?.party_name || ""}
            </span>
          ),
          "1st Preference": (
            <span className="d-flex flex-row">
              <figure className="mpLogo">
                <IImage
                  src={presiResData.results[0]?.candidate_image}
                  className="mpLogoImg"
                />
              </figure>
              {presiResData.results[0]?.candidate_name || ""}
            </span>
          ),
          "2nd Preference": (
            <span className="d-flex flex-row">
              <figure className="mpLogo">
                <IImage
                  src={presiResData.results[1]?.candidate_image}
                  className="mpLogoImg"
                />
              </figure>
              {presiResData.results[1]?.candidate_name || ""}
            </span>
          ),
          "3rd Preference": (
            <span className="d-flex flex-row">
              <figure className="mpLogo">
                <IImage
                  src={presiResData.results[2]?.candidate_image}
                  className="mpLogoImg"
                />
              </figure>
              {presiResData.results[2]?.candidate_name || ""}
            </span>
          ),
        }))
    : [];

  const handleClick = () => {
    navigate("/presidential-election-poll-result", {
      state: { electionTitleId },
    });
  };

  const props = {
    pageTitles: {
      pageTitle1: electionTitle,
    },
    childrens: {
      children1: "O'Democracy Poll Result",
    },
    className: {
      cselectpageSize: "cselectpageSize",
      cselectpageSize2: "cselectpageSize2",
      minputSearch: "minputSearch",
      deleteModal: "deleteModal",
    },
    label: {
      lable1: "",
      lable2: "",
      lable3: "",
    },
    details: {
      detail1: "",
      detail2: "",
      detail3: "",
    },
    Placeholder: {
      Placeholder1: "Search",
      Placeholder2: "Districts",
      Placeholder3: "Polling Divisions",
    },
    options,
    options1,
    isSearchable,
    cstomStyle,
    cstomStyle1,
    cstomStyle2,
    handleClick,
    labels: [
      "User Name",
      "District Name",
      "Voter Id",
      "Party Name",
      "1st Preference",
      "2nd Preference",
      "3rd Preference",
    ],
    data: filteredData,
    loading: useSelector((state) => state.adminone.loading),
    error: useSelector((state) => state.adminone.error),
    selectChange,
    showSelect2: false,
  };

  return (
    <main className="parliamentElection-page">
      <ManageParliamentElectionMain {...props} />
    </main>
  );
};

export default ManageParliamentElectionPage;
