import React from "react";
import { Modal } from "react-bootstrap";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import GreySolidBtn from "../buttons/greySolidBtn";
import SolidBlueBtn from "../buttons/solidBlueBtn";
const UpdateEmail = (props) => {
    const {
        credentials,
        updateEmail,
        show,
        handleClose,
        headings,
        handleChange,
        handleUpdateEmail,
    } = props;
    return (
        <Modal
            size="md"
            show={show}
            onHide={handleClose}
            centered
            className="emailUpdate"
        >
            <Modal.Header>
                <h4 className="ueheading">{headings.heading1}</h4>
            </Modal.Header>
            <Modal.Body>
                <FloatingLabel controlId="email" label="Email" className="mb-5">
                    <Form.Control
                        required
                        type="text"
                        placeholder="Enter your name"
                        minLength={2}
                        maxLength={100}
                        name="email"
                        value={credentials.email}
                    // onChange={handleChange}
                    />
                    {props.icons.icon3}
                </FloatingLabel>
                <FloatingLabel controlId="new_email" label="new_email" >
                    <Form.Control
                        required
                        type="text"
                        placeholder="Enter your new email"
                        minLength={2}
                        maxLength={100}
                        name="new_email"
                        value={updateEmail.new_email}
                        onChange={handleChange}
                    />
                </FloatingLabel>
            </Modal.Body>
            <Modal.Footer>
                <SolidBlueBtn onClick={handleUpdateEmail}>Change Email</SolidBlueBtn>
            </Modal.Footer>
        </Modal>
    );
};

export default UpdateEmail;
