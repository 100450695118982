import React from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ element: Element, securityLevel }) => {
    const isAuthenticated = localStorage.getItem("token");
    const user = JSON.parse(localStorage.getItem("user"));

    if (!isAuthenticated) {
        return <Navigate to="/login-perefrance" />;
    }
    if (user && user.security_level_id === securityLevel) {

        return <Navigate to="/" />;
    }

    return <Element />;
};

export default ProtectedRoute;
