import React, { useEffect, useState } from "react";
import DropdownWithModal from "../components/modals/dropdownWithModal";
import {
  indexDistrict,
  showAllPollingDivisionsByDistrictById,
} from "../redux/adminone/action";
import { useDispatch, useSelector } from "react-redux";
import { addUsersLocationByUserId } from "../redux/user/action";
import { useSnackbar } from "react-simple-snackbar";
import { useLocation } from "react-router-dom";

const options2 = [{ value: "Srilanka", label: "Srilanka" }];
const cstomStyle = {
  menu: (provided) => ({
    ...provided,
    backgroundColor: "white",
    color: "#65676B",
    Width: "fit-content",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#efefef" : "white",
    color: state.isFocused ? "#65676B" : "#65676B",
    padding: 5,
  }),
};

const NewLocationModal = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [options, setOptions] = useState([]);
  const [options1, setOptions1] = useState([]);
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const [selectedDistrictId, setSelectedDistrictId] = useState(null);
  const [selectedPollingDivisionId, setSelectedPollingDivisionId] =
    useState(null);
  const [defaultDistrictOption, setDefaultDistrictOption] = useState(null);
  const [defaultPollingDivisionOption, setDefaultPollingDivisionOption] =
    useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      const user = JSON.parse(localStorage.getItem("user"));
      if (user) {
        if (
          user.district_id === null ||
          user.district_name === null ||
          user.polling_division_id === null ||
          user.polling_division_name === null
        ) {
          setShowModal(true);
          dispatch(indexDistrict());
        }
      }
    }
  }, [dispatch, location.pathname]);

  const indexDistrictsData = useSelector(
    (state) => state.adminone.indexDistrictsData?.data
  );
  useEffect(() => {
    if (indexDistrictsData) {
      const newOptions = [
        ...indexDistrictsData.map((item) => ({
          value: item.id,
          label: item.district_name,
        })),
      ];
      setOptions(newOptions);
    }
  }, [indexDistrictsData]);
  const showsAllPollingDivisionsByDistrictIdData = useSelector(
    (state) => state.adminone.showsAllPollingDivisionsByDistrictIdData?.data
  );
  const selectChange = (selectedOption) => {
    setSelectedDistrictId(selectedOption.value);
    dispatch(showAllPollingDivisionsByDistrictById(selectedOption.value));
    setDefaultDistrictOption(selectedOption);
  };

  useEffect(() => {
    if (showsAllPollingDivisionsByDistrictIdData) {
      const newOptions = [
        ...showsAllPollingDivisionsByDistrictIdData.map((item) => ({
          value: item.id,
          label: item.polling_division_name,
        })),
      ];
      setOptions1(newOptions);
    }
  }, [showsAllPollingDivisionsByDistrictIdData]);
  const selectChange1 = (selectedOption) => {
    setSelectedPollingDivisionId(selectedOption.value);
    setDefaultPollingDivisionOption(selectedOption);
  };

  const handleClick = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      const updatedUser = {
        ...user,
        district_id: selectedDistrictId,
        polling_division_id: selectedPollingDivisionId,
        district_name: options.find(
          (option) => option.value === selectedDistrictId
        )?.label,
        polling_division_name: options1.find(
          (option) => option.value === selectedPollingDivisionId
        )?.label,
      };
      localStorage.setItem("user", JSON.stringify(updatedUser));

      const formData = new FormData();
      formData.append("district_id", selectedDistrictId);
      formData.append("polling_division_id", selectedPollingDivisionId);
      dispatch(
        addUsersLocationByUserId(
          formData,
          updatedUser.id,
          (error, response) => {
            if (error) {
            } else {
              if (response.status === 200) {
                openSnackbar(response.data.message);

                setShowModal(false);
              }
            }
          }
        )
      );
    }
  };

  const props = {
    title: "Add New location",
    options: options,
    options1: options1,
    legendText1: "",
    systemTourText: "Save Location",
    voteText: "Go",
    legendText: "Country name",
    className: {
      cselectpageSize1: "cselectpageSize1",
      cselectpageSizeLable1: "cselectpageSizeLable1",
    },
    isSearchable: "",
    cstomStyle,
    selectChange,
    handleClick,
    placeholder: "Select District",
    value: defaultDistrictOption,
    value1: defaultPollingDivisionOption,
    placeholder1: "Select Polling Division",
    selectChange1,
    options2: options2,
    value2: options2[0],
    placeholder2: "Select Country",
    show: showModal,
    onHide: () => setShowModal(false),
  };
  return (
    <div>
      <DropdownWithModal {...props} />
    </div>
  );
};

export default NewLocationModal;
