import React, { useEffect, useState } from "react";
import {  Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { cApiUrlPrefix } from "../../../config/envConfig";
import { GoArrowUpRight } from "react-icons/go";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaLongArrowAltRight, FaLongArrowAltLeft } from "react-icons/fa";
import { debounce } from "lodash";

const HeroResults = (props) => {
  const {
    options,
    // selectChange,
    // value,
    // cstomStyle,
    // contentTabs,
    disData,
    voteData,
    activeTab,
    setActiveTab,
    filteredDistricts,
  } = props;
  const [currentIndex, setCurrentIndex] = useState(0);
  const [slidesToShow, setSlidesToShow] = useState(7);
  const [isSingleSlide, setIsSingleSlide] = useState(false);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: Math.min(slidesToShow, options.length),
    slidesToScroll: Math.min(slidesToShow, options.length),
    autoplay: false,
    arrows: false,
    responsive: [
      { breakpoint: 1025, settings: { slidesToShow: 5, slidesToScroll: 5 } },
      { breakpoint: 992, settings: { slidesToShow: 4, slidesToScroll: 4 } },
      { breakpoint: 769, settings: { slidesToShow: 3, slidesToScroll: 3 } },
      { breakpoint: 542, settings: { slidesToShow: 2, slidesToScroll: 2 } },
      { breakpoint: 460, settings: { slidesToShow: 1, slidesToScroll: 1 } },
    ],
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    const handleResize = debounce(() => {
      setCurrentIndex(0);
    }, 300);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const handleResize = debounce(() => {
      const width = window.innerWidth;
      if (width < 460) {
        setSlidesToShow(1);
        setIsSingleSlide(true);
      } else if (width < 542) {
        setSlidesToShow(2);
        setIsSingleSlide(false); 
      } else if (width < 769) {
        setSlidesToShow(3);
        setIsSingleSlide(false); 
      } else if (width < 992) {
        setSlidesToShow(4);
        setIsSingleSlide(false); 
      } else if (width < 1025) {
        setSlidesToShow(5);
        setIsSingleSlide(false); 
      } else {
        setSlidesToShow(7);
        setIsSingleSlide(false); 
      }
    }, 300);

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (isSingleSlide && activeTab) {
      const activeTabIndex = options.findIndex((tab) => tab.id === activeTab.id);
      if (activeTabIndex !== -1) {
        setCurrentIndex(Math.max(0, activeTabIndex - Math.floor(slidesToShow / 2)));
      }
    }
  }, [activeTab, slidesToShow, options, isSingleSlide]);

  const handleNext = () => {
    if (currentIndex + slidesToShow < options.length) {
      setCurrentIndex(currentIndex + slidesToShow);
    }
  };

  const handlePrev = () => {
    if (currentIndex - slidesToShow >= 0) {
      setCurrentIndex(currentIndex - slidesToShow);
    }
  };

  const displayedOptions = options.slice(
    currentIndex,
    currentIndex + slidesToShow
  );


  return (
    <section className="hero-results">
      {voteData?.electionResults?.candidates?.length > 0 && (
        <aside>
          <h5 className="island-header">Submitted Votes</h5>
          <div className="winner-div">
            {voteData.electionResults.candidates.map((district, index) => (
              <div key={index} className="winner-col">
                <Card className="winner-card">
                  <div className="winner-blok-des">
                    <div className="winner-blok-design">
                      <div className="winner-imgBlk">
                        {district.candidate_image && (
                          <Card.Img
                            src={cApiUrlPrefix + district.candidate_image}
                            className="winner-img"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <Card.Body className="">
                    <aside className="winner-votes">
                      <h3>{district.candidate_name}</h3>
                    </aside>
                    <aside className="winner-name pt-2">
                      {/* <Link to={"/"} className="text-decoration-none"> */}
                      {district.party_name}{" "}
                      {/* <span>
                          <GoArrowUpRight className="winner-arw" />
                        </span> */}
                      {/* </Link> */}
                    </aside>
                  </Card.Body>
                </Card>
              </div>
            ))}
          </div>
        </aside>
      )}

      <aside>
        <h5 className="island-header">All island</h5>
        {disData.getSeatAllocationData?.total_seats_by_party?.length > 0 ? (
          disData.getSeatAllocationData.total_seats_by_party.length === 1 ? (
            // Render single card if there's only one party
            <div className="winner-div">
              <div className="winner-col">
                <Card className="winner-card">
                  <div className="winner-blok-des">
                    <div className="winner-blok-design">
                      <div className="winner-imgBlk">
                        {disData.getSeatAllocationData.total_seats_by_party[0]
                          .party_logo && (
                          <Card.Img
                            src={
                              cApiUrlPrefix +
                              disData.getSeatAllocationData
                                .total_seats_by_party[0].party_logo
                            }
                            className="winner-img"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <Card.Body>
                    <aside className="winner-votes">
                      <h3>
                        {
                          disData.getSeatAllocationData.total_seats_by_party[0]
                            .votes
                        }
                        <span className="winner-votesTxt">Votes</span>
                      </h3>
                      <h3 className="pt-1">
                        {
                          disData.getSeatAllocationData.total_seats_by_party[0]
                            .vote_percentage
                        }
                        %
                        <span className="winner-votesTxt">Vote Percentage</span>
                      </h3>
                    </aside>
                    <aside className="winner-name pt-1">
                      <Link to={"/"} className="text-decoration-none">
                        {
                          disData.getSeatAllocationData.total_seats_by_party[0]
                            .party_name
                        }{" "}
                        <span>
                          <GoArrowUpRight className="winner-arw" />
                        </span>
                      </Link>
                    </aside>
                  </Card.Body>
                </Card>
              </div>
            </div>
          ) : disData.getSeatAllocationData.total_seats_by_party.length >= 3 ? (
            // Render slider if there are three or more parties
            <Slider className="winner-row" {...sliderSettings}>
              {disData.getSeatAllocationData.total_seats_by_party.map(
                (district, index) => (
                  <div key={index} className="winner-col">
                    <Card className="winner-card">
                      <div className="winner-blok-des">
                        <div className="winner-blok-design">
                          <div className="winner-imgBlk">
                            {district.party_logo && (
                              <Card.Img
                                src={cApiUrlPrefix + district.party_logo}
                                className="winner-img"
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      <Card.Body>
                        <aside className="winner-votes">
                          <h3>
                            {district.votes}
                            <span className="winner-votesTxt">Votes</span>
                          </h3>
                          <h3 className="pt-1">
                            {district.vote_percentage}%
                            <span className="winner-votesTxt">
                              Vote Percentage
                            </span>
                          </h3>
                        </aside>
                        <aside className="winner-name pt-1">
                          <Link to={"/"} className="text-decoration-none">
                            {district.party_name}{" "}
                            <span>
                              <GoArrowUpRight className="winner-arw" />
                            </span>
                          </Link>
                        </aside>
                      </Card.Body>
                    </Card>
                  </div>
                )
              )}
            </Slider>
          ) : (
            // Render cards if there are two parties
            <div className="winner-row d-flex align-items-center">
              {disData.getSeatAllocationData.total_seats_by_party.map(
                (district, index) => (
                  <div key={index} className="winner-col">
                    <Card className="winner-card">
                      <div className="winner-blok-des">
                        <div className="winner-blok-design">
                          <div className="winner-imgBlk">
                            {district.party_logo && (
                              <Card.Img
                                src={cApiUrlPrefix + district.party_logo}
                                className="winner-img"
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      <Card.Body>
                        <aside className="winner-votes">
                          <h3>
                            {district.votes}
                            <span className="winner-votesTxt">Votes</span>
                          </h3>
                          <h3 className="pt-1">
                            {district.vote_percentage}%
                            <span className="winner-votesTxt">
                              Vote Percentage
                            </span>
                          </h3>
                        </aside>
                        <aside className="winner-name pt-1">
                          <Link to={"/"} className="text-decoration-none">
                            {district.party_name}{" "}
                            <span>
                              <GoArrowUpRight className="winner-arw" />
                            </span>
                          </Link>
                        </aside>
                      </Card.Body>
                    </Card>
                  </div>
                )
              )}
            </div>
          )
        ) : (
          <p>No data available</p>
        )}
      </aside>

      <div className="header-tabs my-5">
        <div className="d-flex justify-content-between align-items-center position-relative">
          <FaLongArrowAltLeft
            className={`arrow-icon ${currentIndex === 0 ? "disabled" : ""}`}
            onClick={handlePrev}
            style={{ cursor: currentIndex === 0 ? "not-allowed" : "pointer" }}
          />
          <FaLongArrowAltRight
            className={`arrow-icon1 ${
              currentIndex + 7 >= options.length ? "disabled" : ""
            }`}
            onClick={handleNext}
            style={{
              cursor:
                currentIndex + 7 >= options.length ? "not-allowed" : "pointer",
            }}
          />
        </div>
        <Slider {...settings}>
          {displayedOptions.map((tab) => (
            <div className="tab-item" key={tab.id}>
              <button
                className={`tab-button ${
                  activeTab && activeTab.id === tab.id ? "active" : ""
                }`}
                onClick={() => setActiveTab(tab)}
              >
                <h4>{tab.label}</h4>
              </button>
            </div>
          ))}
        </Slider>

        <div className="d-flex mb-5">
          <p className="backG"></p>
          <div className="hero-disc d-md-block d-none">
            <span>Total Valid Users :</span>{" "}
            {filteredDistricts[0]?.total_valid_users}
            <span className="mx-5">||</span>
            <span>Total Submitted Votes :</span>{" "}
            {filteredDistricts[0]?.total_submitted_votes}
          </div>
          <div className="hero-disc d-md-none d-block">
            <aside>
              <span>Total Valid Users :</span>{" "}
              {filteredDistricts[0]?.total_valid_users}
            </aside>
            <aside>
              <span>Total Submitted Votes :</span>{" "}
              {filteredDistricts[0]?.total_submitted_votes}
            </aside>
          </div>
        </div>
        {/* <div className="tab-content">
          {activeTab && (
            <div>
              <h2>{activeTab.label}</h2>
              <div>
                {filteredDistricts.map((district) => (
                  <h4>{district.total_valid_users}</h4>
                ))}
              </div>
            </div>
          )}
        </div> */}
      </div>

      {/* <Container fluid className="px-0">
        <Row md={3} className="mt-5">
          <Col className="header">
            <h2>Parliament Election Results</h2>
            <Cselect
              options={options}
              className="cselectpageSize1"
              styles={cstomStyle}
              onChange={selectChange}
              value={value}
              placeholder="Select district"
              name="parliamentresults"
            />
          </Col>

          <Col className="align-middle">
            <div className="provincial-details">
              {contentTabs.provincialDetailsData.map((item, index) => (
                <div className="d-flex right-border" key={index}>
                  <span className={`sqr-box${index}`}></span>
                  <div className="registered-e-div">
                    <h6 className="provincial-vote">{item.value}</h6>
                    <p className="registered-electors mt-1">{item.label}</p>
                  </div>
                </div>
              ))}
            </div>
          </Col>

          <Col className="vPercentage">
            <aside>
              <NewProgressBar />
            </aside>
            <aside>
              <p>Total votes percentage</p>
              <Link to={"/"}>
                View Detailed Result
                <span className="arrow">
                  <ImArrowUpRight2 />
                </span>
              </Link>
            </aside>
          </Col>
        </Row>
      </Container> */}
    </section>
  );
};

export default HeroResults;
