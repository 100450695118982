import React from "react";
import { Button, Col, Container, Image, Row, Table } from "react-bootstrap";
import CardOne from "../../../components/cards/cardOne";
import { cApiUrlPrefix } from "../../../config/envConfig";
// import { Link } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import Ploader from "../../../components/loaders/pLoader";
import { useSelector } from "react-redux";

const override = `
    display: block;
    margin: 0 auto;
  `;

const ElectionPollingResults = ({
  data,
  labels,
  datas,
  districtName,
  pollingdivitionName,
  handleClick1,
  props,
  to1,
  resultLabel,
  handleOnClick,
  error,
}) => {
  const loading = useSelector((state) => state.adminone.loading);
  return (
    <div className="electionPollingResults">
      <Container fluid className="layOut-one-container px-0">
        <Row className="layOut-one-row mb-5">
          <Col className="layOut-one-col ">
            <h5 className="layOut-one-heading">
              <Button
                onClick={() => handleOnClick()}
                className="layOut-one-heading text-decoration-none bg-transparent border-0 shadow-none"
              >
                {districtName}
              </Button>
              <span>
                {" "}
                &nbsp;
                <IoIosArrowForward />
                <IoIosArrowForward /> &nbsp;{pollingdivitionName}
              </span>
            </h5>
          </Col>
        </Row>

        {datas.length === 0 ? (
          <p className="notFoundError">
            <span className="notFoundErrorTxt">Data Not Found</span>
          </p>
        ) : (
          <>
            <Row className="layOut-one-row" xl={2} lg={6} md={3} sm={2} xs={1}>
              {datas.map((data) => (
                <Col key={data.id} className="layOut-one-col ">
                  <CardOne
                    className={`card-one ${
                      data.id === props.activeId ? "active" : ""
                    }`}
                    image={data.image}
                    title={data.name}
                    onClick={() => handleClick1(data.id, data.name)}
                  />
                </Col>
              ))}
            </Row>
            {loading ? (
              <Ploader
                color={"#0E76A8"}
                override={override}
                loading={loading}
                size={10}
              />
            ) : null}
            {error ? (
              <p className="notFoundError">
                <span className="notFoundErrorTxt">Data Not found</span>
              </p>
            ) : null}
          </>
        )}
      </Container>
      {/* <DistrictColumn
        props={props}
        handleClick1={handleClick1}
        datas={datas}
        heading1={heading1}
      /> */}
      <div>
        <p className="mb-5">
          Parliament Election Polling Result - {resultLabel}
        </p>
        <div className="pollingResults">
          {loading ? (
            <Ploader
              color={"#0E76A8"}
              override={override}
              loading={loading}
              size={10}
            />
          ) : data.length === 0 ? (
            <p className="notFoundError">
              <span className="notFoundErrorTxt">Data Not found</span>
            </p>
          ) : (
            <Table responsive className="leadingCandidates-Table mb-0">
              <thead>
                <tr>
                  {labels.map((label) => (
                    <td key={label}>{label}</td>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => (
                  <tr key={index} className="align-middle">
                    {labels.map((label, i) => (
                      <td key={i}>
                        {label === "Name of Candidate" ? (
                          <>
                            <Image
                              src={`${cApiUrlPrefix}${item.CandidateImage}`}
                              alt="Candidate"
                              width={30}
                              height={30}
                              className="me-3"
                            />
                            {item[label]}
                          </>
                        ) : (
                          item[label]
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </div>
      </div>
    </div>
  );
};

export default ElectionPollingResults;
