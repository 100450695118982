import React, { useEffect, useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { allPresidentialElectionResults } from "../../../redux/user/action";
import Cselect from "../../../components/select/cselect";
import IImage from "../../../components/image/iImage";
import {
  indexDistrict,
  indexGetAllManageElections,
} from "../../../redux/adminone/action";
import { IoArrowBack } from "react-icons/io5";
import BlueBorderBtn from "../../../components/buttons/blueBorderBtn";
import Loader from "../../../components/loaders/loader";

const override = `
    display: block; 
    margin: 0 auto;
    border-color: red;
`;
const cstomStyle = {
  menu: (provided) => ({
    ...provided,
    backgroundColor: "white",
    color: "#65676B",
    maxWidth: "100%",
    borderColor: "#65676B",
    boxShadow: "unset",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#efefef" : "white",
    color: state.isFocused ? "#65676B" : "#65676B",
    padding: 5,
    maxHeight: 30,
  }),
};

const CandidateReports = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [options, setOptions] = useState([]);
  const [options1, setOptions1] = useState([]);
  const [currentTitle, setCurrentTitle] = useState("");
  const [hasFetched, setHasFetched] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const election_title_id = searchParams.get("election_title_id");
  const district_id = searchParams.get("district_id");
  const [defaultDistrictOption, setDefaultDistrictOption] = useState(null);
  const [defaultDistrictOption1, setDefaultDistrictOption1] = useState(null);
  const [selectedPollingDivisionId, setSelectedPollingDivisionId] =
    useState(null);
  const [selectedPollingDivisionId1, setSelectedPollingDivisionId1] =
    useState(null);

  const getAllPresedentialElectionResultData = useSelector(
    (state) => state.user.getAllPresedentialElectionResultData
  );
  const indexDistrictsData = useSelector(
    (state) => state.adminone.indexDistrictsData?.data
  );
  const indexAllManageElectionData = useSelector(
    (state) => state.adminone.indexAllManageElectionData?.data
  );

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await dispatch(indexGetAllManageElections());
      await dispatch(indexDistrict());
      setLoading(false);
      setHasFetched(true);
    };

    if (!hasFetched) {
      fetchData();
    }
  }, [dispatch, hasFetched]);

  useEffect(() => {
    const idToPass = selectedPollingDivisionId || election_title_id;
    const districtIdToPass = selectedPollingDivisionId1 || district_id;

    if (idToPass && districtIdToPass) {
      setLoading(true);
      dispatch(
        allPresidentialElectionResults(idToPass, districtIdToPass)
      ).finally(() => setLoading(false));
    } else if (idToPass) {
      setLoading(true);
      dispatch(allPresidentialElectionResults(idToPass)).finally(() =>
        setLoading(false)
      );
    }
  }, [
    dispatch,
    election_title_id,
    selectedPollingDivisionId,
    selectedPollingDivisionId1,
    district_id,
  ]);

  useEffect(() => {
    if (getAllPresedentialElectionResultData?.data) {
      const electionTitle = election_title_id
        ? getAllPresedentialElectionResultData.data.election_title_name ||
          "Election Result"
        : "Presidential Election Sri Lanka Result";

      const selectedDistrict = indexDistrictsData?.find(
        (district) => district.id === parseInt(district_id)
      )?.district_name;

      setCurrentTitle(
        electionTitle && selectedDistrict
          ? `${electionTitle} - ${selectedDistrict}`
          : electionTitle
      );
    }
  }, [
    getAllPresedentialElectionResultData,
    election_title_id,
    district_id,
    indexDistrictsData,
  ]);

  let candidates = [];

  // const isSpecificRoute =
  //   location.pathname === "/presidentialelection-report" &&
  //   election_title_id &&
  //   district_id;

  if (election_title_id && district_id) {
    const electionInfo = getAllPresedentialElectionResultData?.data;
    const electionTitleId = electionInfo?.election_title_id || "";
    const electionTitleName = electionInfo?.election_title_name || "";
    const pollingDivisions = electionInfo?.polling_divisions || [];

    candidates =
      pollingDivisions?.flatMap(
        (division) =>
          (division?.candidates || []).map((item) => ({
            polling_division_name: division?.polling_division_name || "",
            polling_division_id: division?.polling_division_id || "",
            candidate_image: item?.candidate_image || "",
            candidate_name: item?.candidate_name || "",
            party_logo: item?.party_logo || "",
            party_name: item?.party_name || "",
            voteDetails: [
              {
                label: "Total Received Votes",
                value: item?.total_received_votes || "N/A",
              },
              {
                label: "Total Postal Votes",
                value: item?.total_postal_votes || "N/A",
              },
              {
                label: "Total Votes",
                value: item?.total_votes || "N/A",
              },
              {
                label: "Percentage Vote",
                value: `${
                  item?.percentage_votes
                    ? parseFloat(item?.percentage_votes).toFixed(
                        item?.percentage_votes % 1 === 0 ? 0 : 2
                      )
                    : "N/A"
                }%`,
              },
            ],
            is_winner: item?.is_winner || false,
            election_title_id: electionTitleId,
            election_title_name: electionTitleName,
          })) || []
      ) || [];
  } else if (election_title_id) {
    const electionInfo = getAllPresedentialElectionResultData?.data;
    const electionTitleId = electionInfo?.election_title_id || "";
    const electionTitleName = electionInfo?.election_title_name || "";
    candidates =
      getAllPresedentialElectionResultData?.data?.data?.map((item) => ({
        district_name: item.district_name || "",
        district_id: item.district_id || "",
        candidate_image: item.candidate_image || "",
        candidate_name: item.candidate_name || "",
        party_logo: item.party_logo || "",
        party_name: item.party_name || "",
        is_winner: item?.is_winner || false,
        voteDetails: [
          {
            label: "Total Received Votes",
            value: item.total_received_votes || "N/A",
          },
          {
            label: "Total Postal Votes",
            value: item.total_postal_votes || "N/A",
          },
          { label: "Total Votes", value: item.total_votes || "N/A" },
          {
            label: "Percentage Vote",
            value: `${
              parseFloat(item.percentage_votes).toFixed(
                item.percentage_votes % 1 === 0 ? 0 : 2
              ) || "N/A"
            }%`,
          },
        ],
        election_title_id: electionTitleId,
        election_title_name: electionTitleName,
      })) || [];
  }

  const voteDetailsByDistrict = candidates.reduce((acc, candidate) => {
    const { district_id, candidate_name, voteDetails } = candidate;
    if (!acc[district_id]) {
      acc[district_id] = {};
    }
    acc[district_id][candidate_name] = voteDetails;
    return acc;
  }, {});

  console.log("voteDetailsByDistrict", voteDetailsByDistrict);

  const voteDetailsByPollingDivision = candidates.reduce((acc, candidate) => {
    const { polling_division_id, candidate_name, voteDetails } = candidate;

    if (!acc[polling_division_id]) {
      acc[polling_division_id] = {};
    }

    acc[polling_division_id][candidate_name] = voteDetails;

    return acc;
  }, {});

  useEffect(() => {
    if (indexAllManageElectionData) {
      const filteredOptions = indexAllManageElectionData
        .filter((item) => item.election_type_id === 1)
        .map((item) => ({
          value: item.id,
          label: item.election_title,
        }));

      setOptions(filteredOptions);

      if (election_title_id) {
        const selectedOption = filteredOptions.find(
          (option) => option.value === parseInt(election_title_id)
        );
        if (selectedOption) {
          setDefaultDistrictOption(selectedOption);
          setCurrentTitle(selectedOption.label);
        }
      } else if (filteredOptions.length > 0) {
        setDefaultDistrictOption(filteredOptions[0]);
        setCurrentTitle(filteredOptions[0].label);
      }
    }
  }, [indexAllManageElectionData, election_title_id]);

  useEffect(() => {
    if (indexDistrictsData) {
      const filteredOptions = indexDistrictsData.map((item) => ({
        value: item.id,
        label: item.district_name,
      }));

      setOptions1(filteredOptions);

      if (district_id) {
        const selectedOption = filteredOptions.find(
          (option) => option.value === parseInt(district_id)
        );
        if (selectedOption) {
          setDefaultDistrictOption1(selectedOption);
        }
      } else if (filteredOptions.length > 0) {
        setDefaultDistrictOption1(filteredOptions[0]);
      }
    }
  }, [indexDistrictsData, district_id]);

  const selectChange = (selectedOption) => {
    setSelectedPollingDivisionId(selectedOption.value);
    setDefaultDistrictOption(selectedOption);
    const districtValue = selectedPollingDivisionId1 || district_id;

    const newUrl = `/presidentialelection-report?election_title_id=${
      selectedOption.value
    }${districtValue ? `&district_id=${districtValue}` : ""}`;
    navigate(newUrl);
  };

  const selectChange1 = (selectedOption) => {
    setSelectedPollingDivisionId1(selectedOption.value);
    setDefaultDistrictOption1(selectedOption);

    const electionValue = selectedPollingDivisionId || election_title_id;

    const newUrl = `/presidentialelection-report?election_title_id=${electionValue}${
      selectedOption.value ? `&district_id=${selectedOption.value}` : ""
    }`;
    navigate(newUrl);
  };

  const districtNames =
    election_title_id && district_id
      ? [
          ...new Set(
            candidates.map((candidate) => candidate.polling_division_name)
          ),
        ]
      : election_title_id
      ? [...new Set(candidates.map((candidate) => candidate.district_name))]
      : [];

  const onClick = () => {
    navigate("/presidentialelection-reports");
  };

  return (
    <main className="pReports">
      {loading ? (
        <Loader color={"#0E76A8"} loading={loading} css={override} size={75} />
      ) : (
        <>
          <Container className="pReports-header" fluid>
            <Row className="align-items-center">
              <Col
                className="d-flex align-items-center"
                md={district_id ? 12 : 6}
              >
                <BlueBorderBtn onClick={onClick}>
                  <IoArrowBack />
                </BlueBorderBtn>
                <h2 className="section-heading ps-2">{currentTitle}</h2>
              </Col>

              {election_title_id && (
                <Col
                  md={district_id ? 4 : 6}
                  sm={12}
                  xs={12}
                  className={district_id ? "mt-3" : ""}
                >
                  <Cselect
                    id="electionSelect"
                    options={options}
                    className="cselectpageSize"
                    styles={cstomStyle}
                    onChange={selectChange}
                    value={defaultDistrictOption}
                    placeholder="Select Option"
                  />
                </Col>
              )}

              {election_title_id && district_id && (
                <Col md={4} sm={12} xs={12} className="mt-3">
                  <Cselect
                    id="districtSelect"
                    options={options1}
                    className="cselectpageSize"
                    styles={cstomStyle}
                    onChange={selectChange1}
                    value={defaultDistrictOption1}
                    placeholder="Select Option"
                  />
                </Col>
              )}
            </Row>
          </Container>

          <div className="cReportsTable">
            <Table responsive>
              <thead>
                <tr>
                  <th>Candidate</th>
                  <th>Party</th>
                  {districtNames.map((district, index) => {
                    const districtCandidate = candidates.find(
                      (c) => c.district_name === district
                    );

                    const isClickable = !(election_title_id && district_id);

                    return (
                      <th key={index}>
                        {district ? (
                          isClickable ? (
                            <Link
                              className="text-decoration-none"
                              to={{
                                pathname: "/presidentialelection-report",
                                search: `?election_title_id=${districtCandidate?.election_title_id}&district_id=${districtCandidate?.district_id}`,
                              }}
                            >
                              {district}
                            </Link>
                          ) : (
                            <span>{district}</span>
                          )
                        ) : (
                          `Heading ${index + 1}`
                        )}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {Array.isArray(candidates) && candidates.length > 0 ? (
                  [
                    ...new Set(
                      candidates.map((candidate) => candidate.candidate_name)
                    ),
                  ].map((name, rowIndex) => {
                    const candidate = candidates.find(
                      (c) => c.candidate_name === name
                    );
                    return (
                      <tr key={rowIndex} className="align-middle">
                        <td className="firstTd">
                          <div className="detailCandidate">
                            <Link
                              className="text-decoration-none"
                              to={`/district/${""}`}
                            >
                              <figure className="mcLogo">
                                <IImage src={candidate.candidate_image} />
                              </figure>
                              <h5>
                                {candidate.candidate_name ||
                                  `Heading ${rowIndex + 1}`}
                              </h5>
                            </Link>
                          </div>
                        </td>
                        <td className="firstTd">
                          <div className="detailCandidate">
                            <Link
                              className="text-decoration-none"
                              to={`/district/${""}`}
                            >
                              <figure className="mcLogo">
                                <IImage src={candidate.party_logo} />
                              </figure>
                              <h5>
                                {candidate.party_name ||
                                  `Heading ${rowIndex + 1}`}
                              </h5>
                            </Link>
                          </div>
                        </td>
                        {districtNames.map((districtName, colIndex) => {
                          if (district_id) {
                            const districtCandidate = candidates.find(
                              (c) => c.polling_division_name === districtName
                            );

                            const pollingId =
                              districtCandidate?.polling_division_id;
                            let voteDetails =
                              voteDetailsByPollingDivision[pollingId]?.[
                                candidate.candidate_name
                              ] || [];

                            const isWinner = candidates.some(
                              (c) =>
                                c.candidate_name === candidate.candidate_name &&
                                c.polling_division_id === pollingId &&
                                c.is_winner
                            );
                            const tdClassName = isWinner ? "winnerCell" : "";

                            return (
                              <td key={colIndex} className={tdClassName}>
                                <Container fluid className="ercTotal px-0">
                                  <Table striped bordered hover>
                                    <tbody>
                                      {voteDetails.length > 0 ? (
                                        voteDetails.map((detail, index) => (
                                          <tr key={index}>
                                            <td className="pText">
                                              {detail.label}
                                            </td>
                                            <td className="pText pTextValue">
                                              {detail.value}
                                            </td>
                                          </tr>
                                        ))
                                      ) : (
                                        <tr className="nTr">
                                          <td
                                            colSpan={2}
                                            className="nText text-center"
                                          >
                                            No data available
                                          </td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </Table>
                                </Container>
                              </td>
                            );
                          } else {
                            const districtId = candidates.find(
                              (c) => c.district_name === districtName
                            )?.district_id;

                            let voteDetails =
                              voteDetailsByDistrict[districtId]?.[
                                candidate.candidate_name
                              ] || [];

                            const isWinner = candidates.some(
                              (c) =>
                                c.candidate_name === candidate.candidate_name &&
                                c.district_id === districtId &&
                                c.is_winner
                            );
                            const tdClassName = isWinner ? "winnerCell" : "";

                            return (
                              <td key={colIndex} className={tdClassName}>
                                <Container fluid className="ercTotal px-0">
                                  <Table striped bordered hover>
                                    <tbody>
                                      {voteDetails.length > 0 ? (
                                        voteDetails.map((detail, index) => (
                                          <tr key={index}>
                                            <td className="pText">
                                              {detail.label}
                                            </td>
                                            <td className="pText pTextValue">
                                              {detail.value}
                                            </td>
                                          </tr>
                                        ))
                                      ) : (
                                        <tr className="nTr">
                                          <td
                                            colSpan={2}
                                            className="nText text-center"
                                          >
                                            No data available
                                          </td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </Table>
                                </Container>
                              </td>
                            );
                          }
                        })}
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={12}>No candidates available</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </>
      )}
    </main>
  );
};

export default CandidateReports;
