import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/loaders/loader";
import PostBlockThree from "../../../components/admin/postBlockThree";
const override = `
    display: block;
    margin: 0 auto;
    border-color: red;
`;
const cstomStyle = {
  menu: (provided) => ({
    ...provided,
    backgroundColor: "white",
    color: "#65676B",
    Width: "fit-content",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#efefef" : "white",
    color: state.isFocused ? "#65676B" : "#65676B",
    padding: 5,
  }),
};


const AddCandidatePartyId = () => {
  const dispatch = useDispatch();
  const fetchAttemptedRef = useRef(false);

  const navigate = useNavigate();
  const loading = useSelector((state) => state.admin.loading);

  return (
    <div>

    </div>
  )
}

export default AddCandidatePartyId;
