import React, { useState, useEffect, useRef } from "react";

const CustomSelect = ({ options, placeholder, onChange, value, index }) => {
  const [opened, setOpened] = useState(false);
  const [selectedValue, setSelectedValue] = useState(value || "");
  const selectRef = useRef(null);

  const handleSelect = (value) => {
    setSelectedValue(value);
    setOpened(false);

    if (onChange) {
      onChange(index, value);
    }
  };

  const handleClickOutside = (event) => {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      setOpened(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  return (
    <div className={`custom-select ${opened ? "opened" : ""}`} ref={selectRef}>
      <span
        className="custom-select-trigger"
        onClick={() => setOpened(!opened)}
      >
        {selectedValue ? options.find(option => option.value === selectedValue)?.label : placeholder}
      </span>
      {opened && (
        <div className="custom-options">
          {options.map((option) => (
            <span
              key={option.value}
              className={`custom-option ${selectedValue === option.value ? "selection" : ""}`}
              data-value={option.value}
              onClick={() => handleSelect(option.value)}
            >
              {option.label}
            </span>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomSelect;
