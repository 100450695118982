import React from 'react';
import Card from "react-bootstrap/Card";
const CardOne = ({ image, title, onClick, className }) => {
    return (
        <Card className={className} onClick={onClick}>
            <Card.Img variant="top" src={image} />
            <Card.Body>
                <Card.Title>{title}</Card.Title>
            </Card.Body>
        </Card>
    )
}

export default CardOne;
