import React, { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { CiSquarePlus, CiSquareMinus } from "react-icons/ci";

const Accordians = ({ accordianData, className }) => {
    const [activeKey, setActiveKey] = useState('0');

    const handleToggle = (key) => {
        setActiveKey(activeKey === key ? null : key);
    };

    return (
        <Accordion activeKey={activeKey} className={className}>
            {accordianData.map((item, index) => (
                <Accordion.Item eventKey={index.toString()} key={index}>
                    <Accordion.Header onClick={() => handleToggle(index.toString())}>
                        <div className='acc-header-blk'>
                            <span className='acc-header'>{item.header}</span>
                        </div>
                        <span className='acc-icons'>
                            {activeKey === index.toString() ? <CiSquareMinus /> : <CiSquarePlus />}
                        </span>
                    </Accordion.Header>
                    <Accordion.Body>
                        {item.body}
                    </Accordion.Body>
                </Accordion.Item>
            ))}
        </Accordion>
    );
};

export default Accordians;
