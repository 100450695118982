import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  ShowCandidateById,
  indexParties,
  addCandidate,
  updateCandidateById,
} from "../../../redux/adminone/action";

import { useLocation, useNavigate } from "react-router-dom";
import AddCandidateHero from "./addCandidateHero";
import Loader from "../../../components/loaders/loader";
import { useSnackbar } from "react-simple-snackbar";

const cstomStyle = {
  menu: (provided) => ({
    ...provided,
    backgroundColor: "white",
    color: "#65676B",
    Width: "fit-content",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#efefef" : "white",
    color: state.isFocused ? "#65676B" : "#65676B",
    padding: 5,
  }),
};

const AddCandidate = ({ initialValues }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { id, candidate_name, status } = location.state || {};
  const isEditing = location.pathname.includes("edit");

  const initialCandidateName = candidate_name || "";
  useEffect(() => {
    dispatch(indexParties());
  }, [dispatch]);
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const [formData, setFormData] = useState({
    candidate_name: initialCandidateName,
    candidate_image: initialValues?.candidate_image ?? null,
    date_of_birth: initialValues?.date_of_birth ?? "",
    position: initialValues?.position ?? "",
    end_date: initialValues?.end_date ?? "",
    start_date: initialValues?.start_date ?? "",
    bio: initialValues?.bio ?? "",
    status: initialValues?.status ?? "",
  });

  const loading = useSelector((state) => state.adminone.loading);
  const indexPartiesData = useSelector(
    (state) => state.adminone.indexPartiesData?.data
  );
  const ShowCandidateByIdData = useSelector(
    (state) => state.adminone.ShowCandidateByIdData?.data
  );

  const [options, setOptions] = useState([]);
  const [selectedCandidateId, setSelectedCandidateId] = useState(null);
  const [defaultPartyoptions, setDefaultPartyoptions] = useState(null);

  const [errors, setErrors] = useState({
    candidate_name: "",
    candidate_image: null,
    date_of_birth: "",
    position: "",
    end_date: "",
    start_date: "",
    bio: "",
  });

  useEffect(() => {
    if (isEditing && id) {
      dispatch(ShowCandidateById(id));
    }
  }, [dispatch, id, isEditing]);

  useEffect(() => {
    if (isEditing && id && ShowCandidateByIdData) {
      setFormData({
        candidate_name: ShowCandidateByIdData.candidate_name ?? "",
        candidate_image: ShowCandidateByIdData.candidate_image ?? null,
        date_of_birth: ShowCandidateByIdData.date_of_birth ?? "",
        position: ShowCandidateByIdData.position ?? "",
        end_date: ShowCandidateByIdData.end_date ?? "",
        start_date: ShowCandidateByIdData.start_date ?? "",
        bio: ShowCandidateByIdData.bio ?? "",
        status: ShowCandidateByIdData.status ?? "",
      });
      setDefaultPartyoptions({
        value: ShowCandidateByIdData.party_id,
        label: ShowCandidateByIdData.party_name,
      });
      setSelectedCandidateId(ShowCandidateByIdData.party_id);
    }
  }, [id, isEditing, ShowCandidateByIdData]);

  useEffect(() => {
    if (indexPartiesData) {
      const newOptions = indexPartiesData.map((item) => ({
        value: item.id,
        label: item.party_name,
      }));
      setOptions(newOptions);
    }
  }, [indexPartiesData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const handleDateChange = (name, date) => {
    setFormData({
      ...formData,
      [name]: date,
    });
  };

  const handleUpload = (imageList) => {
    if (imageList.length > 0) {
      // const imageFile = imageList[0].file;

      setFormData((prevData) => ({
        ...prevData,
        candidate_image: imageList[0].file,
      }));
      setErrors((prevErrors) => ({ ...prevErrors, candidate_image: "" }));
    } else {
      setFormData((prevData) => ({ ...prevData, candidate_image: null }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        candidate_image: "Candidate image is required.",
      }));
    }
  };

  const handleClick = async (e) => {
    e.preventDefault();
    const {
      candidate_name,
      candidate_image,
      date_of_birth,
      position,
      end_date,
      start_date,
      bio,
      status,
    } = formData;

    const isLogoUpdated =
      ShowCandidateByIdData?.candidate_image !== candidate_image;

    let valid = true;
    let newErrors = {};

    const validateAndFormatDate = (date, fieldName) => {
      const formattedDate = new Date(date);
      if (isNaN(formattedDate.getTime())) {
        newErrors[fieldName] = `${fieldName.replace(/_/g, " ")} is invalid.`;
        valid = false;
        return null;
      }
      return formattedDate.toISOString().split("T")[0];
    };

    const isoFormattedDateofbirth = validateAndFormatDate(
      date_of_birth,
      "date_of_birth"
    );
    const isoFormattedEnddate = validateAndFormatDate(end_date, "end_date");
    const isoFormattedStartdate = validateAndFormatDate(
      start_date,
      "start_date"
    );

    if (!candidate_name || candidate_name.length < 2) {
      newErrors.candidate_name =
        "Candidate name must be at least 2 characters long.";
      valid = false;
    }

    if (!candidate_image) {
      newErrors.candidate_image = "Candidate image is required.";
      valid = false;
    }
    if (!date_of_birth) {
      newErrors.date_of_birth = "Candidate date of birth is required.";
    }
    if (!position || position.length < 2) {
      newErrors.position = "Position must be at least 2 characters long.";
      valid = false;
    }
    if (!end_date) {
      newErrors.end_date = "End date is required.";
    }
    if (!start_date) {
      newErrors.start_date = "Start date is required.";
    }
    if (!bio || bio.length < 2) {
      newErrors.bio = "bio must be at least 2 characters long.";
      valid = false;
    }

    setErrors(newErrors);

    if (!valid) return;

    const formDataCandidate = new FormData();
    formDataCandidate.append("candidate_name", candidate_name);
    // formDataCandidate.append("candidate_image", candidate_image);
    formDataCandidate.append("date_of_birth", isoFormattedDateofbirth);
    formDataCandidate.append("position", position);
    formDataCandidate.append("end_date", isoFormattedEnddate);
    formDataCandidate.append("start_date", isoFormattedStartdate);
    formDataCandidate.append("bio", bio);
    formDataCandidate.append("party_id", selectedCandidateId);
    formDataCandidate.append("status", status);

    if (isLogoUpdated) {
      formDataCandidate.append("candidate_image", candidate_image);
    }

    // dispatch(addCandidate(formDataCandidate));

    if (isEditing) {
      formDataCandidate.append("id", id);
      dispatch(
        updateCandidateById(formDataCandidate, id, (error, response) => {
          if (error) {
            // handle the error
            if (response.status === 400) {
              openSnackbar(error.data.message);
            }
          } else {
            // handle the response

            if (response.status === 200) {
              openSnackbar(response.data.message);
              navigate("/manage-candidate");
            }
          }
        })
      );
    } else {
      dispatch(
        addCandidate(formDataCandidate, (error, response) => {
          if (error) {
            // handle the error
            if (error.status === 400) {
              openSnackbar(error.data.message);
            }
          } else {
            // handle the response

            if (response.status === 200) {
              openSnackbar(response.data.message);
              navigate("/manage-candidate");
            }
          }
        })
      );
    }

    if (!isEditing) {
      setFormData({
        candidate_name: "",
        candidate_image: null,
        date_of_birth: "",
        position: "",
        end_date: "",
        start_date: "",
        bio: "",
        status: "",
      });
    }
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const selectChange = (selectedOption) => {
    setSelectedCandidateId(selectedOption.value);
    setDefaultPartyoptions(selectedOption);
  };

  const props = {
    title1: isEditing ? "Edit Candidate" : "Add Candidate",
    blackBorderBtnChild: "Save",
    blackBorderBtnTo: "",
    blackBorderBtnStyle: {
      padding: "1.3rem 2.8rem",
      marginLeft: "1.5rem",
    },
    redBorderBtnChild: "Cancel",
    redBorderBtnStyle: {
      padding: "1.3rem 2.8rem",
      marginLeft: "1.5rem",
    },
    textFormLabel: isEditing ? "Edit Candidate Name" : "Candidate Name",
    textFormPlaceholder: "Mayur Saksena",
    className: {
      cselectpageSize: "cselectpageSize",
      cselectpageSizeLable: "cselectpageSizeLable",
    },
    options,
    cstomStyle,
    uploadLabel: "Upload Image",
    uploadStyle: {
      marginTop: "3rem",
    },

    candidate_name_value: formData.candidate_name,

    dateLable1: "Date of Birth",
    startName1: "date_of_birth",
    startValue1: formData.date_of_birth,
    onChangeStart1: (date) => handleDateChange("date_of_birth", date),
    placeholderStart1: "Enter DateofBirth Date",

    textFormStyle1: {
      marginTop: "3rem",
    },
    textFormLabel1: "Position",
    textFormPlaceholder1: "Top",
    position_value: formData.position,

    dateLable3: "Start Date",
    startName3: "start_date",
    startValue3: formData.start_date,
    onChangeStart3: (date) => handleDateChange("start_date", date),
    placeholderStart3: "Enter Start Date",

    dateLable2: "End Date",
    startName2: "end_date",
    startValue2: formData.end_date,
    onChangeStart2: (date) => handleDateChange("end_date", date),
    placeholderStart2: "Enter End Date",

    textFormStyle3: {
      marginTop: "3rem",
    },
    textFormLabel3: "Bio",
    textFormPlaceholder3: "text here",
    bio_value: formData.bio,

    onUpload: handleUpload,
    onChange: handleChange,
    handleClick,
    // handleSaveAndNext: handleSaveAndNext,
    handleCancel,
    selectChange,
    image: isEditing
      ? ShowCandidateByIdData?.candidate_image
      : formData.candidate_image,
    value: defaultPartyoptions,
  };

  return (
    <main className="aParty cCandidate">
      <form className="aParty-hero aCandidate-hero">
        <AddCandidateHero {...props} disabled={isEditing} />
      </form>
      {loading ? (
        <Loader color={"#0E76A8"} loading={loading} size={75} />
      ) : null}
    </main>
  );
};

export default AddCandidate;
