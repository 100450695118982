import React from "react";
// import OrangeSolidBtn from "../../buttons/orangeSolidBtn";
import { Image } from "react-bootstrap";
import WhiteBtnWithBorder from "../../buttons/whiteBtnWithBorder";

const SectionAnnouncements = (props) => {
  const { children, paras, titles, lables, icons } = props;
  return (
    <section className="section-landing">
      {/* <aside className="section-aside">
        <span className="aside-lable">
          {icons.icons6}
          {lables.lable1}
        </span>
        <h2 className="aside-heading">{titles.title13}</h2>
        <Image src={icons.icons7} className="float-end" />
      </aside>
      <aside className="section-aside">
        <p className="aside-para">{paras.para7}</p>
        <OrangeSolidBtn children={children.children2} />
      </aside> */}
      <aside className="section-aside">
        <div className="eduvoter-block">
          {/* <div className="eduvoterimgblk">
                        <Image src={images.image4.heroImg3} className="userside-img" />
                    </div> */}
          <h2 className="eduvotertitle">{lables.lable1}</h2>
          {/* <Image className="userside-img" /> */}
          <Image src={icons.icons8} className="eduvoterwavyline" />
          <p className="eduvoterpara">{paras.para7}</p>
          {/* <OrangeSolidBtn children={children.children3} /> */}
          <WhiteBtnWithBorder
            type="submit"
            style={{ width: "calc(100% - 85%)", padding: "1.3rem" }}
          >
            {children.children2}
          </WhiteBtnWithBorder>
        </div>
      </aside>
    </section>
  );
};

export default SectionAnnouncements;
