import React from 'react'
import { Button } from 'react-bootstrap'

const RedBorderBtnn = ({ children, onClick }) => {
    return (
        <Button onClick={onClick} className='redBorderBtnn'>
            {children}
        </Button>
    )
}

export default RedBorderBtnn
