import React, { useEffect } from "react";
import disImg from "../../../assets/images/sreelamkaImg.png";
import { useDispatch, useSelector } from "react-redux";
import { indexDistrict } from "../../../redux/adminone/action";
import { cApiUrlPrefix } from "../../../config/envConfig";
import LayOutOne from "../../../components/layOut/layOutOne";
import Loader from "../../../components/loaders/loader";
import { useLocation, useNavigate } from "react-router-dom";
const override = `
    display: block;
    margin: 0 auto;
    border-color: red;
`;
const DistrictsPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        dispatch(indexDistrict());
    }, [dispatch]);
    const indexDistrictsData = useSelector(
        (state) => state.adminone.indexDistrictsData?.data
    );
    const loading = useSelector((state) => state.adminone.loading);
    const data =
        indexDistrictsData?.map((district) => ({
            id: district.id,
            name: district.district_name,
            image:
                district.map_image === "0"
                    ? disImg
                    : cApiUrlPrefix + district.map_image,
        })) || [];

    const handleClick = (district_id, district_name) => {
        const { electionTypeId, title } = location.state || {};
        navigate("/select-polling-division", {
            state: { electionTypeId, title, district_id, district_name },
        });
    };
    const user = JSON.parse(localStorage.getItem("user"));
    const activeId = user?.district_id;

    const props = {
        headings: {
            heading1: "DISTRICTS",
        },

        children: {
            children1: "Enter into O'Democracy Dashboard",
        },
        data,
        handleClick,
        activeId,
    };
    return (
        <>
            <LayOutOne {...props} />;
            {loading ? (
                <Loader color={"#0E76A8"} loading={loading} css={override} size={75} />
            ) : null}
        </>
    );
};

export default DistrictsPage;
