import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import en from "./components/locals/en/translate.json";
import tam from "./components/locals/tam/translate.json";

const resources = {
  en: {
    translation: en,
  },
  tam: {
    translation: tam,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: "en",
    fallbackLng: "en",
    nonExplicitSupportedLanguages: true,
    returnNull: false,
    interpolation: {
      escapeValue: false,
    },
  });

i18n.addResourceBundle("en", "common", {
  searchPlaceholder: "Search",
});

i18n.addResourceBundle("tam", "common", {
  searchPlaceholder: "Buscar",
});

export default i18n;
