import React from 'react';
import BlueBorderBtn from '../buttons/blueBorderBtn';
import Cselect from '../select/cselect';
import SearchBtnForm from '../form/searchBtnForm';
import ManageTable from '../table/manageTable';

const ManageBlkFour = (props) => {
    const { children, children1, title, options1, options2, isSearchable, className, cstomStyle1, cstomStyle2, Placeholder, data, loading, error, labels, handleAddManageCandidate, handleEdit, handleFilterChange, selectedFilter } = props;
    return (
        <div className="manageBlock-four">
            <div className="mangeHead">
                <h2 className="manageHeadTitle">{title}</h2>
                <BlueBorderBtn children={children} onClick={handleAddManageCandidate} />
                <BlueBorderBtn children={children1} onClick={handleEdit} />
            </div>
            <div className="mangeHeadss">
                <div className="d-flex align-items-center">
                    <label htmlFor="pageSizeSelect" className="mangeSl">
                        Page size:
                    </label>
                    <Cselect
                        id="pageSizeSelect"
                        options={options1}
                        isSearchable={isSearchable}
                        className={className.cselectpageSize}
                        styles={cstomStyle1}
                    />
                </div>
                <div className="d-flex align-items-center">
                    <label htmlFor="districtSelect" className="mangeSl">
                        Districts:
                    </label>
                    <Cselect
                        id="districtSelect"
                        options={options2}
                        className={className.cselectpageSize2}
                        styles={cstomStyle2}
                        onChange={handleFilterChange}
                        value={selectedFilter}
                    />
                </div>
                <SearchBtnForm
                    className={className.minputSearch}
                    placeholder={Placeholder}
                    style={{ padding: "1rem 3.5rem" }}
                />
            </div>
            <ManageTable
                data={data}
                loading={loading}
                error={error}
                labels={labels}
            />
        </div>
    )
}

export default ManageBlkFour;
