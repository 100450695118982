import React from "react";
import ManageBlkFive from "../../../components/admin/manageBlkFive";

const ManagePresidentialElectionMain = (props) => {
  return (
    <main className="manage-presidential-election-main">
      <ManageBlkFive {...props} />
    </main>
  );
};

export default ManagePresidentialElectionMain;
