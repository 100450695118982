import React from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas';
const OffCanvas = ({ OffCanvasHeader, OffCanvasBody, show, handleClose, placement }) => {

    return (
        <Offcanvas show={show} onHide={handleClose} placement={placement} className="off-canvas" >
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>{OffCanvasHeader}</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                {OffCanvasBody}
            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default OffCanvas;
