import React from "react";
import { Image } from "react-bootstrap";
import OrangeSolidBtn from "../../buttons/orangeSolidBtn";

const SectionSimulator = (props) => {
  const { icons, titles, paras, subtitles, onClick, children } = props;
  return (
    <section className="section-landing">
      {/* <h2 className="section-heading">{titles.title9}</h2>
      <p className="section-para">{paras.para10}</p> */}
      <div className="ourApprochHead">
        <aside className="section-aside">
          <span className="aside-lable">
            {icons.icons6}
            {titles.title9}
          </span>
          <h2 className="aside-heading">{paras.para10}</h2>
          <Image src={icons.icons7} className="float-end" />
        </aside>
        <aside className="section-aside">
          <p className="aside-para">{paras.para17}</p>
          <OrangeSolidBtn onClick={onClick} children={children.children4} />
        </aside>
      </div>
      <section className="section-simulator">
        <aside className="section-vmBlk">
          <div className="d-flex align-items-center pb-4">
            <Image src={icons.icons9} className="ba-icon" />
            <h2 className="vmblk-heading ps-3">{subtitles.heading5}</h2>
          </div>
          <p className="vmblk-para">{paras.para11}</p>
        </aside>
        <aside className="section-vmBlk">
          <div className="d-flex align-items-center pb-4">
            <Image src={icons.icons10} className="ba-icon" />
            <h2 className="vmblk-heading ps-3">{subtitles.heading6}</h2>
          </div>
          <p className="vmblk-para">{paras.para12}</p>
        </aside>
        <aside className="section-vmBlk">
          <div className="d-flex align-items-center pb-4">
            <Image src={icons.icons11} className="ba-icon" />
            <h2 className="vmblk-heading ps-3">{subtitles.heading7}</h2>
          </div>
          <p className="vmblk-para">{paras.para13}</p>
        </aside>

        <aside className="section-vmBlk">
          <div className="d-flex align-items-center pb-4">
            <Image src={icons.icons13} className="ba-icon" />
            <h2 className="vmblk-heading ps-3">{subtitles.heading13}</h2>
          </div>
          <p className="vmblk-para">{paras.para25}</p>
        </aside>

        <aside className="section-vmBlk">
          <div className="d-flex align-items-center pb-4">
            <Image src={icons.icons14} className="ba-icon" />
            <h2 className="vmblk-heading ps-3">{subtitles.heading14}</h2>
          </div>
          <p className="vmblk-para">{paras.para26}</p>
        </aside>
        <aside className="section-vmBlk">
          <div className="d-flex align-items-center pb-4">
            <Image src={icons.icons15} className="ba-icon" />
            <h2 className="vmblk-heading ps-3">{subtitles.heading15}</h2>
          </div>
          <p className="vmblk-para">{paras.para27}</p>
        </aside>
      </section>
      {/* <div className="sectionblk">
        <aside className="blk-aside">
          <div className="ba-imgblk">
            <Image src={icons.icons9} className="ba-icon" />
          </div>
          <h4 className="ba-heading">{subtitles.heading5}</h4>
          <p className="ba-para">{paras.para11}</p>
        </aside>
        <aside className="blk-aside">
          <div className="ba-imgblk">
            <Image src={icons.icons10} className="ba-icon" />
          </div>
          <h4 className="ba-heading">{subtitles.heading6}</h4>
          <p className="ba-para">{paras.para12}</p>
        </aside>
        <aside className="blk-aside">
          <div className="ba-imgblk">
            <Image src={icons.icons11} className="ba-icon" />
          </div>
          <h4 className="ba-heading">{subtitles.heading7}</h4>
          <p className="ba-para">{paras.para13}</p>
        </aside>
      </div> */}
    </section>
  );
};

export default SectionSimulator;
