import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { showAllProvinceDistrictsById } from "../../../redux/adminone/action";
import ManageBlkTwo from "../../../components/admin/manageBlkTwo";
import DeleteCModal from "../../../components/modals/deleteCModal";

const options = [
  { value: 10, label: 10 },
  { value: 20, label: 20 },
  { value: 30, label: 30 },
];

const cstomStyle = {
  menu: (provided) => ({
    ...provided,
    backgroundColor: "white",
    color: "#65676B",
    maxWidth: "97px",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#efefef" : "white",
    color: state.isFocused ? "#65676B" : "#65676B",
    padding: 5,
  }),
};
const ManageAssociatedDistrictById = () => {
  const location = useLocation();
  const { id, province_name, } = location.state || {};
  // const params = new URLSearchParams(location.search);
  // const id = params.get("id");
  // const province_name = params.get("province_name");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(showAllProvinceDistrictsById(id));
  }, [dispatch, id]);
  const navigate = useNavigate();
  const [isSearchable] = useState(false);
  const showAllProvinceDistrictsByIdData = useSelector(
    (state) => state.adminone.showAllProvinceDistrictsByIdData?.data
  );
  const error = useSelector((state) => state.adminone.error);




  const labels = [
    "District name",
    "Seats",
    "Population",
    "Registered Voter",
  ];
  const data = Array.isArray(showAllProvinceDistrictsByIdData)
    ? showAllProvinceDistrictsByIdData.map((data) => ({
      "District name": data.district_name,
      Seats: data.province_seats,
      Population: data.province_population,
      "Registered Voter": data.province_registered_voters,

    }))
    : [];

  const props = {
    title: `${province_name} >> Districts`,
    style: { padding: "1.3rem 2.8rem", marginLeft: "1.5rem" },
    options,
    isSearchable,
    className: {
      cselectpageSize: "cselectpageSize",
      minputSearch: "minputSearch",
      deleteModal: "deleteModal",
    },
    cstomStyle,
    Placeholder: "Search",
    labels,
    data,
  };

  return (
    <main className="madbi">
      <aside className="madbiParty">
        <ManageBlkTwo {...props} />
      </aside>
      <DeleteCModal
        // show={showModal}
        // handleClose={handleCloseModal}
        // handleConfirm={handleConfirmDelete}
        className={props.className.deleteModal}
      />
    </main>
  );
};

export default ManageAssociatedDistrictById;
