import React from "react";
import Card from "react-bootstrap/Card";
import Table from 'react-bootstrap/Table';

const CardFour = (cardProps) => {
    return (
        <Card className="card-four">
            <div className="cfour-imgblk">
                <Card.Img variant="top" src={cardProps.images.image1} className="cfour-img" />
            </div>
            <Card.Title>
                {cardProps.titles.title1}
            </Card.Title>
            <Card.Body>
                <Card.Text>
                    {/* Some quick example text to build on the card title and make up the
                    bulk of the card's content. */}
                </Card.Text>
            </Card.Body>
            <Card.Title>
                {cardProps.titles.title2}
            </Card.Title>
            <Card.Body>
                <Table>
                    <tbody>
                        <tr>
                            <th>{cardProps.subHeadings.subheading1}</th>

                            <td>{cardProps.subDetails.subDetail1}</td>
                        </tr>
                        <tr>
                            <th>{cardProps.subHeadings.subheading2}</th>

                            <td>{cardProps.subDetails.subDetail2}</td>
                        </tr>
                    </tbody>
                </Table>
            </Card.Body>

        </Card>
    );
};

export default CardFour;
