import React, { useEffect, useState } from "react";
import PostBlockFive from "../../../components/admin/postBlockFive";
import Loader from "../../../components/loaders/loader";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "react-simple-snackbar";
import {
  showPartyHistoryById,
  updatePartyHistoryById,
} from "../../../redux/adminone/action";

const override = `
    display: block;
    margin: 0 auto;
    border-color: red;
`;

const ManagePartyHistory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const loading = useSelector((state) => state.adminone.loading);
  const showGetAllPartyHistoryByPartyIdData = useSelector(
    (state) => state.adminone.showGetAllPartyHistoryByPartyIdData?.data
  );
  const { id, party_name, status } = location.state || {};

  const party_id = id;


  useEffect(() => {
    if (party_id) {
      dispatch(showPartyHistoryById(party_id));
    }
  }, [dispatch, party_id]);
  const [blocks, setBlocks] = useState([
    {
      id: "null",
      title: "",
      content: "",
    },
  ]);
  useEffect(() => {
    if (showGetAllPartyHistoryByPartyIdData?.length > 0) {
      const initialBlocks = showGetAllPartyHistoryByPartyIdData.map((item) => ({
        id: item.id,
        title: item.title,
        content: item.content,
      }));
      setBlocks(initialBlocks);
    }
  }, [showGetAllPartyHistoryByPartyIdData]);

  const handleChange = (index, name, value) => {
    const newBlocks = blocks.map((block, i) =>
      i === index ? { ...block, [name]: value } : block
    );
    setBlocks(newBlocks);
  };

  const handleSave = () => {
    const updateHistory = blocks.map((block) => ({
      id: block.id !== "null" ? block.id : "null",
      title: block.title,
      content: block.content,
    }));

    dispatch(
      updatePartyHistoryById(updateHistory, party_id, (error, response) => {
        if (error) {
          // handle the error

        } else {
          // handle the response

          if (response.data.status === 200) {
            openSnackbar(response.data.message);
            navigate("/manage-party");
          }
        }
      })
    );

  };

  const handleCancel = () => {
    navigate("/manage-party");
  };

  const addBlock = () => {
    setBlocks([...blocks, { id: "null", title: "", content: "" }]);
  };

  const removeBlock = (index) => {
    if (blocks.length > 1) {
      const block = blocks[index];
      if (!block.title && !block.content) {
        setBlocks(blocks.filter((_, i) => i !== index));
      } else {

      }
    } else {

    }
  };

  const props = {
    title: `${party_name} >> Party History`,
    addSeqBtnstyle: { padding: "1.3rem 2.8rem", marginLeft: "1.5rem" },
    save: "save",
    handleSave,
    cancel: "cancel",
    handleCancel,
    blocks,
    handleChange,
    addBlock,
    removeBlock,
  };

  return (
    <main className="mpHistory">
      <aside className="addbock">
        <PostBlockFive {...props} />
      </aside>
      {loading ? (
        <Loader color={"#0E76A8"} loading={loading} css={override} size={75} />
      ) : null}
    </main>
  );
};

export default ManagePartyHistory;
