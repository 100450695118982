import { combineReducers } from "redux";
// User
import UserReducer from "./user/reducer";

// Admin
import adminOneReducer from "./adminone/reducer";

const rootReducer = combineReducers({
  // User
  user: UserReducer,
  //admin
  adminone: adminOneReducer,
});

export default rootReducer;
