import React from 'react';
import { Button } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';

const PlusButton = ({ onClick }) => {
    return (
        <Button className='plusbtn' onClick={onClick}><FaPlus /></Button>
    )
}

export default PlusButton;
