import React from "react";
import { Button } from "react-bootstrap";

const BlackBorderBtn = ({ children1, to1, style1, onClick }) => {
  return (
    <Button to={to1} className="linkblackBorderbtn" style={style1} onClick={onClick}>
      {children1}
    </Button>
  );
};

export default BlackBorderBtn;
