import React from "react";
import PropTypes from 'prop-types';
import BlackBorderBtn from "../../../components/buttons/blackBorderBtn";
import RedBorderBtn from "../../../components/buttons/redBorderBtn";
// import TextForm from "../../../components/form/textForm";
import { Col, Row } from "react-bootstrap";
import CMultiSelect from "../../../components/select/cMultiSelect";

const ManagePermissions = (props) => {
  const {
    title,
    addSeqBtnstyle,
    save,
    cancel,
    // handleChange,
    handleSave,
    handleCancel,

    disable,
    className,
    selectLable1,
    options1,
    selectedValue1,
    onSelect1,
    onRemove1,

    // label1,
    // name1,
    // value1,
    // placeholder1,
    // error1,
    // disabled1,
  } = props;
  const safeOptions1 = Array.isArray(options1) ? options1 : [];
  return (
    <div className="PostBlock">
      <div className="PostBlockChild">
        <h2 className="PostBlockTitle">{title}</h2>
        <div className="">
          <BlackBorderBtn
            children1={save}
            style1={addSeqBtnstyle}
            onClick={handleSave}
          />
          <RedBorderBtn
            children1={cancel}
            style1={addSeqBtnstyle}
            onClick={handleCancel}
          />
        </div>
      </div>
      {/* <div className="mt-5">
        <TextForm
          formLabel={label1}
          name={name1}
          value={value1}
          placeholder={placeholder1}
          onChange={handleChange}
          error={error1}
          disabled={disabled1}
        />
      </div> */}
      <div className="mt-5">
        <Row className="mb-3">
          <Col
            sm={2}
            className="d-flex justify-content-start align-items-center"
          >
            <label className={className.cselectpageSizeLable}>
              {selectLable1}
            </label>
          </Col>
          <Col sm={6}>
            <CMultiSelect
              options={safeOptions1}
              selectedValue={selectedValue1}
              onSelect={onSelect1}
              onRemove={onRemove1}
              disable={disable}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

ManagePermissions.propTypes = {
  title: PropTypes.string.isRequired,
  addSeqBtnstyle: PropTypes.object.isRequired,
  save: PropTypes.string.isRequired,
  cancel: PropTypes.string.isRequired,
  handleSave: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  disable: PropTypes.bool,
  className: PropTypes.object.isRequired,
  selectLable1: PropTypes.string.isRequired,
  options1: PropTypes.array.isRequired,
  selectedValue1: PropTypes.array.isRequired,
  onSelect1: PropTypes.func.isRequired,
  onRemove1: PropTypes.func.isRequired,
};

export default ManagePermissions;
