import React from "react";
import Image from "react-bootstrap/Image";
import { cApiUrlPrefix } from "../../config/envConfig";

const IImage = ({ roundedCircle, src, alt, className }) => {


  const endpoint = src;
  const imageUrl = `${cApiUrlPrefix}${endpoint}`;

  return (
    <Image
      fluid
      roundedCircle={roundedCircle}
      src={imageUrl}
      alt={alt || "Image"}

      className={className}
    />
  );
};

export default IImage;
