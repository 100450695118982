import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../../redux/user/action";
import { Link, useNavigate } from "react-router-dom";
import { Image, Form, Container } from "react-bootstrap";
import { FloatingLabel } from "react-bootstrap";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import SolidBlueBtn from "../../../components/buttons/solidBlueBtn";
import Loader from "../../../components/loaders/loader";
import Logo from "../../../assets/images/logo.png";
import CustomTooltip from "../../../components/tooltips/ctoolTip";
import { toast } from "react-toastify";

const override = `
    display: block;
    margin: 0 auto;
    border-color: red;
`;


const ULogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [validated, setValidated] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const loading = useSelector((state) => state.user.loading);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    const handleBackButton = (event) => {
      event.preventDefault();
      window.history.pushState(null, null, window.location.pathname);
    };

    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);

    if (/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/.test(formData.email)) {
    } else {
      toast.error("Invalid email");
      return;
    }
    if (
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[^A-Za-z\d])[\s\S]{9,}$/.test(
        formData.password
      )
    ) {
    } else {
      toast.error("Invalid password");
      return;
    }
    const formDataToSend = new FormData();
    formDataToSend.append("email", formData.email);
    formDataToSend.append("password", formData.password);

    dispatch(
      loginUser(formDataToSend, (error, response) => {
        if (error && error.status === 401) {

          toast.error(error.data.message, {
            position: "top-center",
            style: {
              fontSize: "1.6rem",
            },
          });
          navigate("/login");
        } else {
          // handle the response

          if (response && response.status === 200) {
            localStorage.setItem("token", response.data.token.token);
            localStorage.setItem("user", JSON.stringify(response.data.user));
            toast.success(response.data.message, {
              position: "top-center",
              style: {
                fontSize: "1.6rem",
              },
            });
            navigate("/", { state: { showDropdownModal: true } });
          }
        }
      })
    );
  };
  return (
    <main className="main-auth">
      <Container fluid="xxl">
        <div className="loginMain">
          <figure>
            <Image src={Logo} fluid />
          </figure>
          <div className="authCenterBlk">
            <h2 className="authHead">Welcome</h2>
            <p className="authPara">
              Please enter your ID and Password for login.
            </p>
            <Form className="lForm" onSubmit={handleSubmit}>
              <CustomTooltip text="The user's email address.">
                <FloatingLabel
                  controlId="idEmail"
                  label="Email"
                  className={
                    validated
                      ? formData.email
                        ? "is-valid"
                        : "is-invalid"
                      : ""
                  }
                >
                  <Form.Control
                    required
                    type="text"
                    placeholder="Enter your email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    isValid={validated && formData.email}
                    isInvalid={
                      validated &&
                      (!formData.email ||
                        formData.email.trim().length === 0 ||
                        !/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/.test(formData.email))
                    }
                  />
                </FloatingLabel>
              </CustomTooltip>
              <CustomTooltip text="Enter a valid password">
                <FloatingLabel
                  controlId="idPassword"
                  label="Password"
                  className={
                    validated
                      ? formData.password
                        ? "is-valid"
                        : "is-invalid"
                      : ""
                  }
                >
                  <Form.Control
                    required
                    type={showPassword ? "text" : "password"}
                    placeholder="Enter your password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    minLength={9}
                    isValid={validated && formData.password}
                    isInvalid={
                      validated &&
                      (!formData.password ||
                        formData.password.trim().length === 0 ||
                        !/^(?=.*[A-Za-z])(?=.*\d)(?=.*[^A-Za-z\d])[\s\S]{9,}$/.test(
                          formData.password
                        ))
                    }
                  />
                  <span
                    onClick={togglePasswordVisibility}
                    className="password-toggle-icon"
                  >
                    {showPassword ? <FaEye /> : <FaEyeSlash />}
                  </span>
                </FloatingLabel>
              </CustomTooltip>
              <Form.Group className="mb-3 text-end">
                <Link to="/email-send-otp" className="fpLink">
                  Forgot Password?
                </Link>
              </Form.Group>
              <div className="text-center lFormBtn">
                <SolidBlueBtn
                  type="submit"
                  style={{ width: "calc(100% - 40%)", padding: "1.3rem" }}
                >
                  Login
                </SolidBlueBtn>
                {loading ? (
                  <Loader
                    color={"#0E76A8"}
                    loading={loading}
                    css={override}
                    size={75}
                  />
                ) : null}
              </div>
            </Form>
          </div>
          <p className="lLinkBlk">
            Don't have an account?{" "}
            <Link to="/register" className="gLink">
              Click here
            </Link>
          </p>
        </div>
      </Container>
      {loading ? (
        <Loader color={"#0E76A8"} loading={loading} css={override} size={75} />
      ) : null}
    </main>
  );
};

export default ULogin;
