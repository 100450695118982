import React from 'react';
import { Link } from 'react-router-dom';

const LinkBorderBtn = (props) => {
    const { children, to, style, onClick } = props
    return (
        <Link to={to} className='linkborderbtn' style={style} onClick={onClick} >
            {children}
        </Link>
    )
}

export default LinkBorderBtn;
