import React, { useEffect } from "react";
import ReportsPage from "../../../components/reports/reportsPage";
import src1 from "../../../assets/svg/account.svg";
// import src2 from "../../../assets/svg/election.svg";
// import src3 from "../../../assets/svg/vote.svg";
import src4 from "../../../assets/svg/track.svg";
// import src5 from "../../../assets/svg/arrow.svg";

const Reports = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const props = {
    titles: { title1: "Election Reports" },
    sections: [
      {
        icon: src1,
        heading: "Presidential Election",
        para: "Create your account on o'democracy. It only takes a few minutes!",
        link: "/presidentialelection-reports",
      },
      // {
      //   icon: src2,
      //   heading: "Districts",
      //   para: "Learn about the various political parties and their platforms.",
      //   link: "/",
      // },
      // {
      //   icon: src3,
      //   heading: "Polling Division",
      //   para: "Express your opinion by voting for your preferred party.",
      //   link: "/",
      // },
      {
        icon: src4,
        heading: "Candidates",
        para: "Stay updated with real-time election results and analysis.",
        link: "/",
      },
      {
        icon: src4,
        heading: "Parties",
        para: "Stay updated with real-time election results and analysis.",
        link: "/",
      },
    ],
  };
  return (
    <main className="eResults">
      <ReportsPage {...props}></ReportsPage>
    </main>
  );
};

export default Reports;
