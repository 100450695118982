import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Image, Form, FloatingLabel, Container } from "react-bootstrap";
import Logo from "../../../assets/images/logo.png";
import { useLocation, useNavigate } from "react-router-dom";
import CustomTooltip from "../../../components/tooltips/ctoolTip";
import { toast } from "react-toastify";
import SolidBlueBtn from "../../../components/buttons/solidBlueBtn";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { resetPasswordEmail } from "../../../redux/user/action";
import Loader from "../../../components/loaders/loader";
const override = `
    display: block;
    margin: 0 auto;
    border-color: red;
  `;
const ResetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const token = params.get("token");
  const user = JSON.parse(localStorage.getItem("user"));
  const [credentials, setCredentials] = useState({
    newPassword: "",
    newPassword_confirmation: "",
  });
  const [validated, setValidated] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);

  const loading = useSelector((state) => state.user.loading);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleCPasswordVisibility = () => {
    setShowCPassword(!showCPassword);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setCredentials({
      ...credentials,
      [name]: value,
    });
  };
  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    const { newPassword, newPassword_confirmation } = credentials;
    if (
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[^A-Za-z\d])[\s\S]{9,}$/.test(newPassword)
    ) {

    } else {

      toast.error("Invalid password");
      return;
    }

    if (newPassword === newPassword_confirmation) {

    } else {

      toast.error("Passwords do not match");
      return;
    }

    const formData = new FormData();
    formData.append("token", token);
    formData.append("password", newPassword);
    formData.append("password_confirmation", newPassword_confirmation);

    dispatch(
      resetPasswordEmail(formData, (error, response) => {
        if (error && error.status === 400) {


          toast.error(error.data.message, {
            position: "top-center",
            style: {
              fontSize: "1.6rem",
            },
          });
        } else {


          if (response && response.status === 200) {
            toast.success(response.data.message, {
              position: "top-center",
              style: {
                fontSize: "1.6rem",
              },
            });
            localStorage.removeItem("userEmail")
            navigate("/login")

          }
        }
      })
    );
  };

  return (
    <main className="main-auth">
      <Container fluid="xxl">
        <div className="resetMain">
          <figure>
            <Image src={Logo} fluid />
          </figure>
          <div className="authCenterBlk">
            <h2 className="authHead">Reset Password</h2>
            <p className="authPara">Please enter your reset password </p>
            <Form className="resetForm" onSubmit={handleSubmit}>
              <CustomTooltip text="Enter a valid password">
                <FloatingLabel
                  controlId="idNewPassword"
                  label="New Password"
                  className={
                    validated
                      ? credentials.newPassword
                        ? "is-valid"
                        : "is-invalid"
                      : ""
                  }
                >
                  <Form.Control
                    required
                    type={showPassword ? "text" : "password"}
                    placeholder="Enter your password"
                    name="newPassword"
                    minLength={9}
                    value={credentials.newPassword}
                    onChange={handleChange}
                    isValid={validated && credentials.newPassword}
                    isInvalid={
                      validated &&
                      (!credentials.newPassword ||
                        credentials.newPassword.trim().length === 0 ||
                        !/^(?=.*[A-Za-z])(?=.*\d)(?=.*[^A-Za-z\d])[\s\S]{9,}$/.test(
                          credentials.newPassword
                        ))
                    }
                  />
                  <span
                    onClick={togglePasswordVisibility}
                    className="password-toggle-icon"
                  >
                    {showPassword ? <FaEye /> : <FaEyeSlash />}
                  </span>
                </FloatingLabel>
              </CustomTooltip>
              <CustomTooltip text="enter same password">
                <FloatingLabel
                  controlId="idCNewPassword"
                  label="Confirm New Password"
                  className={
                    validated
                      ? credentials.newPassword_confirmation
                        ? "is-valid"
                        : "is-invalid"
                      : ""
                  }
                >
                  <Form.Control
                    required
                    type={showCPassword ? "text" : "password"} // Use showCPassword state for type
                    name="newPassword_confirmation"
                    placeholder="Enter your newPassword_confirmation"
                    minLength={9}
                    value={credentials.newPassword_confirmation}
                    onChange={handleChange}
                    isValid={validated && credentials.newPassword_confirmation}
                    isInvalid={
                      validated &&
                      (!credentials.newPassword_confirmation ||
                        credentials.newPassword_confirmation.trim().length ===
                        0 ||
                        credentials.newPassword !==
                        credentials.newPassword_confirmation)
                    }
                  />
                  <span
                    onClick={toggleCPasswordVisibility}
                    className="password-toggle-icon"
                  >
                    {showCPassword ? <FaEye /> : <FaEyeSlash />}
                  </span>
                </FloatingLabel>
              </CustomTooltip>

              <div className="text-center lFormBtn">
                <SolidBlueBtn
                  type="submit"
                  style={{ width: "calc(100% - 40%)", padding: "1.3rem" }}
                >
                  Reset
                </SolidBlueBtn>
              </div>
            </Form>
          </div>
        </div>
      </Container>
      {loading ? (
        <Loader color={"#0E76A8"} loading={loading} css={override} size={75} />
      ) : null}
    </main>
  );
};

export default ResetPassword;
