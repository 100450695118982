import React, { useEffect, useState } from "react";
import ManagePermissions from "./managePermissions";
import Loader from "../../../components/loaders/loader";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ShowSecurityLevelById,
  ShowSecurityLevelPermissionsById,
  indexPermission,
  updateSecurityLevelPermission,
} from "../../../redux/adminone/action";
import { useSnackbar } from "react-simple-snackbar";

const override = `
    display: block;
    margin: 0 auto;
    border-color: red;
`;

const ManagePermissionMain = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const params = new URLSearchParams(location.search);
  const id = params.get("id");

  const [formData, setFormData] = useState({
    securitylevel_name: "",
    permission_id: [],
  });

  const navigate = useNavigate();
  const loading = useSelector((state) => state.adminone.loading);

  const [selectedValue1, setSelectedValue1] = useState([]);
  const indexPermissionData = useSelector(
    (state) => state.adminone.indexPermissionData?.data
  );
  const ShowSecurityLevelByIdData = useSelector(
    (state) => state.adminone.ShowSecurityLevelByIdData?.data
  );
  const ShowSecurityLevelPermissionsByIdData = useSelector(
    (state) => state.adminone.ShowSecurityLevelPermissionsByIdData?.data
  );

  useEffect(() => {
    if (id) {
      dispatch(indexPermission());
      dispatch(ShowSecurityLevelById(id));
      dispatch(ShowSecurityLevelPermissionsById(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (id && ShowSecurityLevelByIdData && ShowSecurityLevelPermissionsByIdData) {
      setFormData({
        securitylevel_name:
          ShowSecurityLevelByIdData?.security_level?.securitylevel_name ?? "",
        permission_id: ShowSecurityLevelPermissionsByIdData.map(
          (permission) => permission.permission_id
        ),
      });
    }
  }, [id, ShowSecurityLevelByIdData, ShowSecurityLevelPermissionsByIdData]);

  useEffect(() => {
    if (
      ShowSecurityLevelPermissionsByIdData?.length > 0 &&
      indexPermissionData?.length > 0
    ) {
      const selectedPermissions = indexPermissionData.filter((permission) =>
        ShowSecurityLevelPermissionsByIdData.some(
          (securityPermission) =>
            securityPermission.permission_id === permission.id
        )
      );
      setSelectedValue1(selectedPermissions);
    }
  }, [ShowSecurityLevelPermissionsByIdData, indexPermissionData]);

  const handleSelect1 = (selectedList) => {
    setSelectedValue1(selectedList);
    setFormData({
      ...formData,
      permission_id: selectedList.map((item) => item.id),
    });
  };

  const handleRemove1 = (selectedList) => {
    setSelectedValue1(selectedList);
  };

  const handleSave = async (e) => {
    e.preventDefault();

    const SecurityLevelPermissionFormData = {
      security_level_name: formData.securitylevel_name,
      permission_id: selectedValue1.map((item) => item.id),
      id: id,
    };

    dispatch(
      updateSecurityLevelPermission(
        SecurityLevelPermissionFormData,
        id,
        (error, response) => {
          if (error) {


            openSnackbar("Error occurred while adding party");
          } else {


            if (response.status === 200) {
              openSnackbar(response.data.message);
              navigate("/security-level");
            }
          }
        }
      )
    );
    navigate(-1);
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const props = {
    title: "Manage Permission",
    addSeqBtnstyle: { padding: "1.3rem 2.8rem", marginLeft: "1.5rem" },
    save: "save",
    cancel: "cancel",
    className: {
      cselectpageSizeLable: "cselectpageSizeLable",
    },
    selectLable1: "Permission",
    options1: Array.isArray(indexPermissionData) ? indexPermissionData : [],
    selectedValue1,
    onSelect1: handleSelect1,
    handleSave,
    handleCancel,
    onRemove1: handleRemove1,
    security_level_name: formData.securitylevel_name,
  };



  return (
    <main className="aDistrict">
      <aside className="addbock">
        <ManagePermissions {...props} />
      </aside>
      {loading ? (
        <Loader color={"#0E76A8"} loading={loading} css={override} size={75} />
      ) : null}
    </main>
  );
};

export default ManagePermissionMain;
