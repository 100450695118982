import React, { useEffect } from "react";
import ManageBlkFive from "../../../components/admin/manageBlkFive";

const ManageParliamentElectionMain = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <main className="manage-presidential-election-main">
      <ManageBlkFive {...props} />
    </main>
  );
};

export default ManageParliamentElectionMain;
