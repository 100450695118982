import React from "react";
import { Container, Image } from "react-bootstrap";
import TransparentBtn from "../../buttons/transparentBtn";
import IImage from "../../image/iImage";
import OrangeSolidBtn from "../../buttons/orangeSolidBtn";
import Loader from "../../loaders/loader";
import { useSelector } from "react-redux";
import Slider from "react-slick";

const override = `
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const SectionNews = (props) => {
  const {
    titles,
    images,
    dates,
    subtitles,
    paras,
    children,
    icons,
    categoryName,
    onClick,
    someId,
  } = props;
  const loading = useSelector((state) => state.user.loading);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="section-landing">
      {/* <h2 className="section-heading">{titles.title10}</h2> */}
      <div className="ourApprochHead">
        <aside className="section-aside">
          <span className="aside-lable">
            {icons.icons6}
            {titles.title10}
          </span>
          <h2 className="aside-heading">{paras.para10}</h2>
          <Image src={icons.icons7} className="float-end" />
        </aside>
        <aside className="section-aside">
          <p className="aside-para">{paras.para17}</p>
          <OrangeSolidBtn
            children={children.children5}
            onClick={onClick.handleClick}
          />
        </aside>
      </div>

      <Container fluid className="section-container">
        <Slider {...sliderSettings}>
          <div className="section-col">
            <div
              className="card col-card"
              onClick={() => onClick.onClick0(someId)}
            >
              <IImage src={images.image5} className="card-img-top" />
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="date">{dates.date1}</div>
                  <span className="card-cName">{categoryName.cName0}</span>
                </div>
                <h5 className="card-title">{subtitles.heading8}</h5>

                <TransparentBtn
                  Children={children.children4}
                  icon={icons.icons12}
                  onClick={(e) => {
                    e.stopPropagation();
                    onClick.onClick0(someId);
                  }}
                />
              </div>
            </div>
          </div>

          <div className="section-col">
            <div
              className="card col-card"
              onClick={() => onClick.onClick1(someId)}
            >
              <IImage src={images.image6} className="card-img-top" />
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="date">{dates.date2}</div>
                  <span className="card-cName">{categoryName.cName1}</span>
                </div>
                <h5 className="card-title">{subtitles.heading9}</h5>

                {/* <p className="card-text">{paras.para15}</p> */}
                <TransparentBtn
                  Children={children.children4}
                  icon={icons.icons12}
                  onClick={(e) => {
                    e.stopPropagation();
                    onClick.onClick1(someId);
                  }}
                />
              </div>
            </div>
          </div>

          <div className="section-col">
            <div
              className="card col-card"
              onClick={() => onClick.onClick2(someId)}
            >
              <IImage src={images.image7} className="card-img-top" />
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="date">{dates.date3}</div>
                  <span className="card-cName">{categoryName.cName2}</span>
                </div>
                <h5 className="card-title">{subtitles.heading10}</h5>

                {/* <p className="card-text">{paras.para16}</p> */}
                <TransparentBtn
                  Children={children.children4}
                  icon={icons.icons12}
                  onClick={(e) => {
                    e.stopPropagation();
                    onClick.onClick2(someId);
                  }}
                />
              </div>
            </div>
          </div>

          <div className="section-col">
            <div
              className="card col-card"
              onClick={() => onClick.onClick3(someId)}
            >
              <IImage src={images.image8} className="card-img-top" />
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="date">{dates.date4}</div>
                  <span className="card-cName">{categoryName.cName3}</span>
                </div>
                <h5 className="card-title">{subtitles.heading16}</h5>

                {/* <p className="card-text">{paras.para16}</p> */}
                <TransparentBtn
                  Children={children.children4}
                  icon={icons.icons12}
                  onClick={(e) => {
                    e.stopPropagation();
                    onClick.onClick3(someId);
                  }}
                />
              </div>
            </div>
          </div>

          <div className="section-col">
            <div
              className="card col-card"
              onClick={() => onClick.onClick4(someId)}
            >
              <IImage src={images.image9} className="card-img-top" />
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="date">{dates.date5}</div>
                  <span className="card-cName">{categoryName.cName4}</span>
                </div>
                <h5 className="card-title">{subtitles.heading17}</h5>
                {/* <p className="card-text">{paras.para16}</p> */}
                <TransparentBtn
                  Children={children.children4}
                  icon={icons.icons12}
                  onClick={(e) => {
                    e.stopPropagation();
                    onClick.onClick4(someId);
                  }}
                />
              </div>
            </div>
          </div>
        </Slider>

        {/* <div className="text-center mt-3">
          <OrangeSolidBtn
            children={children.children5}
            onClick={onClick.handleClick}
          />
        </div> */}
      </Container>

      {loading ? (
        <Loader color={"#0E76A8"} loading={loading} css={override} size={75} />
      ) : null}
    </section>
  );
};

export default SectionNews;
