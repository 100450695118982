import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { FaLocationArrow } from "react-icons/fa6";
import { Col, Container, Row } from "react-bootstrap";
import { getAllCandidateByTitleId } from "../../../redux/adminone/action";
import { cApiUrlPrefix } from "../../../config/envConfig";
import disImg from "../../../assets/images/sreelamkaImg.png";
import Loader from "../../loaders/loader";
import SolidBlueBtn from "../../buttons/solidBlueBtn";
import { HiArrowNarrowRight } from "react-icons/hi";
import CardSeven from "../../cards/cardSeven";
import BlueBorderBtn from "../../buttons/blueBorderBtn";
import { IoArrowBack } from "react-icons/io5";

const override = `
    display: block;
    margin: 0 auto;
    border-color: red;
`;

const ChooseParty = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [userData, setUserData] = useState(null);
  const [activeCardId, setActiveCardId] = useState(null);
  const { electionTypeId, electionTitleId, title } = location.state || {};
  // const electionTitleId = 10;

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      setUserData(parsedUser);
    }
  }, []);

  useEffect(() => {
    dispatch(getAllCandidateByTitleId(electionTitleId));
  }, [dispatch, electionTitleId]);

  const getAllCandidateByTitleIdData = useSelector(
    (state) => state.adminone.getAllCandidateByTitleIdData?.data
  );

  const loading = useSelector((state) => state.adminone.loading);

  const filtered = getAllCandidateByTitleIdData?.filter(
    (candidate) => candidate.district_id === userData?.district_id
  );

  const uniquePartyIds = new Set();

  const data =
    filtered
      ?.filter((party) => {
        if (uniquePartyIds.has(party.party_id)) {
          return false;
        }
        uniquePartyIds.add(party.party_id);
        return true;
      })
      ?.map((party) => ({
        id: party.party_id,
        name: party.party_name,
        image:
          party.party_image === "0"
            ? disImg
            : cApiUrlPrefix + party.party_image,
      })) || [];

  console.log("data:", data);

  const handleCardClick = (id) => {
    setActiveCardId(id);
  };

  const handleButtonClick = () => {
    if (activeCardId) {
      const selectedParty = data.find((p) => p.id === activeCardId);
      console.log("selectedParty", selectedParty);

      if (selectedParty) {
        navigate("/choose-party-candidate", {
          state: {
            electionTypeId,
            electionTitleId,
            title,
            id: selectedParty.id,
            party_name: selectedParty.name,
            party_image: selectedParty.image,
          },
        });
      }
    } else {
      alert("Please select a party before proceeding.");
    }
  };

  const onClick = () => {
    navigate(-1);
  };

  return (
    <div className="choose-party-parliament">
      <div className="d-lg-flex d-block justify-content-between align-items-center first-div">
        <div className="hero-h5">
          <aside>
            <BlueBorderBtn onClick={onClick}>
              <IoArrowBack />
            </BlueBorderBtn>
          </aside>
          <aside className="ps-3">
            <h5>
              Choose party for {location.state?.title || "Election Title"}
              <span className="ps-2">(Poll)</span>
            </h5>
            <p>
              Sri Lanka, {userData?.district_name},{" "}
              {userData?.polling_division_name}{" "}
              <FaLocationArrow
                className="location-i"
                onClick={() => {
                  navigate(`/select-election`);
                }}
              />
            </p>
          </aside>
        </div>
        <div className="button-wrapper">
          <SolidBlueBtn
            type="submit"
            className="solidBlueBtn"
            onClick={handleButtonClick}
          >
            Choose Party Candidate
            <span className="arrowBtn">
              <HiArrowNarrowRight />
            </span>
          </SolidBlueBtn>
        </div>
      </div>
      <Container fluid className="layOut-one-container">
        <div className="border-container">
          <Row className="layOut-one-row" md={5}>
            {data.map((data) => (
              <Col key={data.id} className="layOut-one-col">
                <CardSeven
                  className={`card-seven ${
                    data.id === activeCardId ? "active-card" : ""
                  }`}
                  image={data.image}
                  title={data.name}
                  onClick={() => handleCardClick(data.id)}
                  isActive={data.id === activeCardId}
                />
              </Col>
            ))}
          </Row>
        </div>
      </Container>
      {loading ? (
        <Loader color={"#0E76A8"} loading={loading} css={override} size={75} />
      ) : null}
    </div>
  );
};

export default ChooseParty;
