import React from "react";
import BlackBorderBtn from "../buttons/blackBorderBtn";
import RedBorderBtn from "../buttons/redBorderBtn";
import TextForm from "../form/textForm";
import { Col, Container, Row } from "react-bootstrap";

const PostBlockWithBtn = (props) => {
  const {
    title,
    addSeqBtnstyle,
    save,
    cancel,
    textInput,
    handleChange,
    handleSave,
    handleCancel,
    formData,
    handleToggle,
    formLabel,
  } = props;
  return (
    <div>
      <div className="PostBlock">
        <div className="PostBlockChild">
          <h2 className="PostBlockTitle">{title}</h2>
          <div className="">
            <BlackBorderBtn
              children1={save}
              style1={addSeqBtnstyle}
              onClick={handleSave}
            />
            <RedBorderBtn
              children1={cancel}
              style1={addSeqBtnstyle}
              onClick={handleCancel}
            />
          </div>
        </div>
        <div className="mt-5">
          {textInput.map((input, index) => (
            <TextForm
              key={index}
              formLabel={input.label}
              name={input.name}
              value={input.value}
              placeholder={input.placeholder}
              onChange={handleChange}
              error={input.error}
              disabled={input.disabled}
              Lsm={2}
              Ism={6}
            />
          ))}
        </div>
        <Container fluid className="my-5 px-0">
          <Row className="row-sub-profile d-flex align-items-center textForm">
            <Col>
              <label
                htmlFor="labelForButton"
                className="textformlabel"
                column
                sm={2}
              >
                {formLabel}
              </label>
            </Col>
            <Col md={10} className="col-sub-profile">
              <div className="form-check form-switch activeSwitch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="flexSwitchCheckChecked"
                  checked={formData.is_active === 1}
                  onChange={handleToggle}
                />
                <label
                  className="form-check-label"
                  htmlFor="flexSwitchCheckChecked"
                >
                  {formData.is_active === 1 ? "Active" : "In Active"}
                </label>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default PostBlockWithBtn;
