import React, { useEffect, useState } from "react";
import PostBlockFive from "../../../components/admin/postBlockFive";
import Loader from "../../../components/loaders/loader";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
// import {
//     getCandidateHistoryById,
//     updateCandidateHistory,
// } from "../../../redux/admin/action";
import {
    ShowCandidateHistoryById,
    updateCandidateHistoryById,
} from "../../../redux/adminone/action";
import { useSnackbar } from "react-simple-snackbar";

const override = `
    display: block;
    margin: 0 auto;
    border-color: red;
`;

const ManageCandidateHistory = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [openSnackbar, closeSnackbar] = useSnackbar();
    const loading = useSelector((state) => state.adminone.loading);
    const showCandidateHistoryByIdData = useSelector(
        (state) => state.adminone.showCandidateHistoryByIdData?.data
    );
    const { id, candidate_name } = location.state || {};

    const candidate_id = id;

    useEffect(() => {
        if (candidate_id) {
            dispatch(ShowCandidateHistoryById(candidate_id));
        }
    }, [dispatch, candidate_id]);

    const [blocks, setBlocks] = useState([
        {
            id: "null",
            title: "",
            content: "",
        },
    ]);

    useEffect(() => {
        if (Array.isArray(showCandidateHistoryByIdData) && showCandidateHistoryByIdData.length > 0) {
            const initialBlocks = showCandidateHistoryByIdData.map((item) => ({
                id: item.id,
                title: item.title,
                content: item.content,
            }));
            setBlocks(initialBlocks);
        }
    }, [showCandidateHistoryByIdData]);

    const handleChange = (index, name, value) => {
        const newBlocks = blocks.map((block, i) =>
            i === index ? { ...block, [name]: value } : block
        );
        setBlocks(newBlocks);
    };

    const handleSave = () => {
        const updateHistory = blocks.map((block) => ({
            id: block.id !== "null" ? block.id : "null",
            title: block.title,
            content: block.content,
        }));

        dispatch(updateCandidateHistoryById(updateHistory, candidate_id, (error, response) => {
            if (error) {
                // handle the error

            } else {

                if (response.data.status === 200) {
                    openSnackbar(response.data.message);
                    navigate("/manage-candidate");
                }
            }
        }));

    };

    const handleCancel = () => {
        navigate("/manage-candidate");
    };

    const addBlock = () => {
        setBlocks([...blocks, { id: "null", title: "", content: "" }]);
    };

    const removeBlock = (index) => {
        if (blocks.length > 1) {
            const block = blocks[index];
            if (!block.title && !block.content) {
                setBlocks(blocks.filter((_, i) => i !== index));
            } else {

            }
        } else {

        }
    };

    const props = {
        title: `${candidate_name} >> Candidate History`,
        addSeqBtnstyle: { padding: "1.3rem 2.8rem", marginLeft: "1.5rem" },
        save: "save",
        handleSave,
        cancel: "cancel",
        handleCancel,
        blocks,
        handleChange,
        addBlock,
        removeBlock,
    };

    return (
        <main className="mcHistory">
            <aside className="addbock">
                <PostBlockFive {...props} />
            </aside>
            {loading ? (
                <Loader color={"#0E76A8"} loading={loading} css={override} size={75} />
            ) : null}
        </main>
    );
};

export default ManageCandidateHistory;
