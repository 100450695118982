import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const TermsAndConditions = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <main className="tandcPage">
      <h2 className="text-center header">O'Democracy User Agreement</h2>
      <div>
        <h5>1. Acceptance of Terms</h5>
        <br />
        <p>
          By accessing, registering, or using the O'Democracy platform
          ("Platform"), you, the user ("User"), agree to be bound by the terms
          and conditions set forth in this <b>User Agreement</b>. This
          Agreement, including any future modifications, governs the use of all
          services, tools, features, and content provided by O'Democracy. If you
          do not agree to these terms, you must discontinue the use of the
          Platform immediately.
        </p>
        <br />
        <p>
          O'Democracy reserves the right to modify this Agreement at any time.
          Users will be notified of any material changes, and continued use of
          the Platform following such notice constitutes acceptance of the
          updated terms.
        </p>
        <br />
        <hr />
      </div>
      <div>
        <h5>2. User Registration and Identity Verification</h5>
        <br />
        <p>
          To ensure the integrity and transparency of the democratic processes
          simulated and supported by O'Democracy, Users are required to register
          with their <b>Unique Identification Numbers</b> ("UIDs"), such as
          government-issued identification cards, during the voter registration
          process.
        </p>
        <br />
        <p>
          <ul>
            <li>
              <strong>Accurate Information:</strong> Users must provide accurate
              and complete information during the registration process.
              Misrepresentation of identity, falsification of documents, or the
              submission of inaccurate information is strictly prohibited and
              may result in account suspension or permanent termination of
              access to the Platform.
            </li>
            <br />
            <li>
              <strong>Prevention of Fraud and Unauthorized Access:</strong> The
              Platform employs advanced security measures to prevent fraudulent
              activities, unauthorized access, and manipulation of voting data.
              Users agree not to engage in activities designed to compromise the
              security or integrity of the Platform.
            </li>
            <br />
            <li>
              <strong>Privacy Protections:</strong> O'Democracy is bound by
              strict <b>Data Protection</b> regulations, ensuring that personal
              identification information is not shared with any external
              entities or government organizations, except when mandated by a
              legal obligation in compliance with local laws and international
              standards.
            </li>
          </ul>
        </p>
        <br />
        <hr />
      </div>
      <div>
        <h5>3. Privacy and Data Protection</h5>
        <br />
        <p>
          O'Democracy is fully committed to safeguarding the privacy of all
          Users and adheres to the highest standards of data protection as
          prescribed by the <b>Data Protection Act</b> and other applicable
          international privacy laws. We recognize the importance of maintaining
          the confidentiality and integrity of personal information and voting
          data.
        </p>
        <br />
        <p>
          <ul>
            <li>
              <strong>Confidentiality of Vote Data:</strong> Upon casting a vote
              through the Platform, User voting data remains entirely
              confidential. Under no circumstances will O'Democracy share,
              disclose, or manipulate voting information. All results are based
              solely on data generated by Users and remain secure, ensuring that
              no third parties, including government entities, have access to
              such data.
            </li>
            <br />
            <li>
              <strong>Personal Data Usage:</strong> Users acknowledge that
              personal data may be collected during the registration process,
              including UIDs, voting patterns, and demographic information. This
              data is strictly for internal Platform purposes, such as ensuring
              user authentication, enhancing user experience, and providing
              analytical insights. O'Democracy will never sell, transfer, or
              disclose such information to third parties or governments unless
              compelled by court order or legal mandate within specific
              jurisdictions.
            </li>
            <br />
            <li>
              <strong>Compliance with Jurisdictional Laws:</strong> Users are
              responsible for ensuring that their use of the Platform complies
              with the laws and regulations of their jurisdiction, particularly
              concerning participation in democratic simulations and the
              disclosure of identification numbers. O'Democracy disclaims all
              liability for any legal consequences that may arise from the use
              of the Platform in violation of local or national laws.
            </li>
          </ul>
        </p>
        <br />
        <hr />
      </div>
      <div>
        <h5>4. Voting Process Integrity and Democratic Rights</h5>
        <br />
        <p>
          O'Democracy is designed to maintain and protect the fundamental
          principles of democracy, ensuring that each User's vote is treated
          with respect, integrity, and privacy. The Platform provides Users with
          tools for casting votes as part of the democratic simulation process,
          where results are generated based solely on User participation.
        </p>
        <br />
        <p>
          <ul>
            <li>
              <strong>No Alteration of Votes:</strong> O'Democracy guarantees
              that no vote cast by a User will be altered, manipulated, or
              interfered with by any party, including the Platform
              administrators, third-party organizations, or government bodies.
              The results displayed on the Platform are direct reflections of
              User input and cannot be subject to external influence.
            </li>
            <br />
            <li>
              <strong>Neutrality and Non-Partisan Operations:</strong> The
              Platform operates in a non-partisan manner. O'Democracy is not
              affiliated with any political parties, organizations, or
              campaigns. The Platform’s tools and results are impartial and
              based solely on data-driven algorithms. We do not support,
              endorse, or oppose any political entity.
            </li>
            <br />
            <li>
              <strong>Disclaimer of Results Accuracy:</strong> While O'Democracy
              employs sophisticated algorithms to simulate election outcomes,
              Users acknowledge that these results are predictive models based
              on data input and assumptions. Actual election outcomes may vary
              due to external factors not accounted for by the simulation.
              O'Democracy is not liable for any discrepancies between simulated
              outcomes and real-world results.
            </li>
          </ul>
        </p>
        <br />
        <hr />

        <h5>5. Data Security and Integrity</h5>
        <br />
        <p>
          O'Democracy employs advanced encryption techniques and robust security
          protocols to protect the personal information and voting data of all
          Users. Data integrity is our highest priority, and all information
          processed through the Platform is handled in accordance with the best
          industry practices for data security.
        </p>
        <br />
        <p>
          <ul>
            <li>
              <strong>Data Encryption:</strong> All data transferred through the
              Platform, including User registration information and voting data,
              is encrypted using advanced encryption protocols to ensure it
              remains protected from unauthorized access or data breaches.
            </li>
            <br />
            <li>
              <strong>Data Leak Prevention:</strong> O'Democracy implements data
              leak prevention measures, ensuring that no sensitive information
              is inadvertently exposed or accessed by unauthorized individuals.
              This includes monitoring and security measures to detect and
              respond to potential threats.
            </li>
            <br />
            <li>
              <strong>Non-Disclosure:</strong> O'Democracy will not disclose
              User data to any external entity, including governments,
              third-party organizations, or other Users, without the User's
              explicit consent, except in compliance with legal obligations that
              may apply in specific jurisdictions. In the event of legal
              enforcement requests, O'Democracy will notify the affected User to
              the extent permitted by law.
            </li>
          </ul>
        </p>
        <br />
        <hr />

        <h5>6. User Responsibilities and Conduct</h5>
        <br />
        <p>
          Users of O'Democracy agree to act responsibly and in compliance with
          all applicable laws and regulations while using the Platform. Misuse
          or misconduct may result in termination of the User’s account and
          legal consequences.
        </p>
        <br />
        <p>
          <ul>
            <li>
              <strong>Prohibited Activities:</strong> Users are strictly
              prohibited from:
              <ul>
                <li>
                  Falsifying personal information or impersonating others.
                </li>
                <li>
                  Engaging in fraudulent activities, including casting false
                  votes or manipulating data.
                </li>
                <li>
                  Interfering with the operation of the Platform, including
                  attempts to access or exploit security vulnerabilities.
                </li>
                <li>
                  Uploading or distributing harmful software (e.g., malware,
                  viruses) or engaging in activities that harm the integrity of
                  the Platform.
                </li>
              </ul>
            </li>
            <br />
            <li>
              <strong>Jurisdictional Awareness:</strong> Users must ensure that
              their participation in the voting process and use of their
              personal information complies with local laws, including any
              restrictions on the use of unique identification numbers for
              online platforms. Users are solely responsible for understanding
              and adhering to the legal framework of their jurisdiction, and
              O'Democracy disclaims liability for any legal disputes arising
              from such violations.
            </li>
          </ul>
        </p>
        <br />
        <hr />

        <h5>7. Intellectual Property Rights</h5>
        <br />
        <p>
          O'Democracy holds all intellectual property rights related to the
          Platform, including but not limited to the O'Democracy Simulator,
          algorithms, content, branding, and user interface. By accessing the
          Platform, Users acknowledge that they are granted a limited license to
          use the Platform's services for personal, non-commercial purposes.
        </p>
        <br />
        <p>
          <ul>
            <li>
              <strong>User-Generated Content:</strong> Users retain ownership of
              any content they post or share on the Platform but grant
              O'Democracy a non-exclusive, royalty-free license to use, modify,
              and display such content in connection with Platform operations.
            </li>
            <br />
            <li>
              <strong>Prohibited Reuse:</strong> Users are prohibited from
              reproducing, distributing, or altering any of O'Democracy’s
              proprietary tools, content, or features for commercial purposes
              without express written consent.
            </li>
          </ul>
        </p>
        <br />
        <hr />

        <h5>8. Advertising and Promotional Content</h5>
        <br />
        <p>
          O'Democracy may, from time to time, display advertisements or
          promotional content on the Platform. It is important to note that any
          advertisements, sponsored posts, or promotional items featured on the
          Platform are <b>paid promotions</b> and are not directly affiliated
          with O'Democracy’s endorsement or involvement.
        </p>
        <br />
        <p>
          <ul>
            <li>
              <strong>Third-Party Advertisements:</strong> O'Democracy does not
              have control over the content of third-party advertisements or
              promotional materials displayed on the Platform. These
              advertisements are provided by external advertisers and sponsors.
              O'Democracy does not endorse, support, or validate the claims,
              products, or services featured in these advertisements.
            </li>
            <br />
            <li>
              <strong>Disclosure of Paid Promotions:</strong> Users should be
              aware that any advertisement or promotional item displayed on the
              Platform is considered a paid promotion, and O'Democracy is not
              responsible for the accuracy, legality, or quality of the
              advertised content. Users are encouraged to exercise discretion
              when engaging with third-party advertisers and to review any
              applicable terms and conditions related to the promotion.
            </li>
            <br />
            <li>
              <strong>Liability Disclaimer:</strong> O'Democracy disclaims any
              liability for damages or losses incurred by Users as a result of
              interactions with third-party advertisers or reliance on
              advertised content. All transactions or agreements between Users
              and advertisers are conducted independently of O'Democracy, and
              the Platform is not responsible for the outcome of such
              interactions.
            </li>
          </ul>
        </p>
        <br />
        <hr />

        <h5>9. Limitation of Liability and Disclaimer of Warranties</h5>
        <br />
        <p>
          To the fullest extent permitted by law, O'Democracy, its affiliates,
          officers, directors, and agents shall not be liable for any indirect,
          incidental, special, consequential, or punitive damages arising from:
        </p>
        <br />
        <p>
          <ul>
            <li>
              <strong>Loss of Data:</strong> O'Democracy disclaims liability for
              any loss, unauthorized access, or compromise of User data,
              including personal information and voting results, arising from
              events beyond the Platform’s control, including cyber-attacks,
              force majeure events, or third-party data breaches.
            </li>
            <br />
            <li>
              <strong>Accuracy of Simulations:</strong> O'Democracy provides
              election predictions and simulations based on available data. The
              Platform does not guarantee the accuracy of any predictions and
              disclaims responsibility for any discrepancies between simulated
              and actual election results.
            </li>
          </ul>
        </p>
        <br />
        <hr />

        <h5>10. Governing Law and Dispute Resolution</h5>
        <br />
        <p>
          This Agreement is governed by and construed in accordance with the
          laws of <b>Province of Ontario, Canada.</b> Users agree to resolve any
          disputes arising from this Agreement exclusively through the courts
          located in <b>the applicable jurisdiction.</b>
        </p>
        <br />
        <p>
          <ul>
            <li>
              <strong>Jurisdictional Considerations:</strong> Users acknowledge
              that the legal use of the Platform may vary based on local laws
              and regulations, including restrictions on the collection of
              personal data, participation in democratic simulations, and the
              use of identification numbers. Users are solely responsible for
              compliance with their local laws.
            </li>
          </ul>
        </p>
        <br />
        <hr />

        <h5>11. Amendments and Modifications</h5>
        <br />
        <p>
          O'Democracy reserves the right to modify or update this User Agreement
          at its sole discretion. Users will be notified of significant changes
          through email or platform notifications. Continued use of the Platform
          after such modifications constitutes acceptance of the revised terms.
        </p>
        <br />
        <hr />

        <h5>12. Contact Information</h5>
        <br />
        <p>
          For inquiries related to this Agreement, Users may contact O'Democracy
          at{" "}
          <strong>
            <Link
              className="text-decoration-none"
              to={"mailto:info@odemocracy.com"}
            >
              info@odemocracy.com
            </Link>
            .
          </strong>
        </p>
      </div>
    </main>
  );
};

export default TermsAndConditions;
