import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { ShowCandidateHistoryById } from "../../../redux/adminone/action";
import Loader from "../../../components/loaders/loader";
import LayOutTwo from "../../../components/layOut/layOutTwo";
import { cApiUrlPrefix } from "../../../config/envConfig";
import { formatDate } from "../../../utilities/common";
import Aside1 from "../../../components/user/history/aside1";
import Aside2 from "../../../components/user/history/aside2";
import Aside3 from "../../../components/user/history/aside3";
import CardFour from "../../../components/cards/cardFour";
const override = `
    display: block;
    margin: 0 auto;
    border-color: red;
`;
const CandidateHistoryPage = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { id } = location.state || {};

    console.log("id123:", id);
    

    useEffect(() => {
        dispatch(ShowCandidateHistoryById(id));
    }, [dispatch, id]);

    const showCandidateHistoryByIdData = useSelector(
        (state) => state.adminone.showCandidateHistoryByIdData?.data
    );

    const histories = showCandidateHistoryByIdData?.histories || [];


    const loading = useSelector((state) => state.adminone.loading);
    const articleRefs = useRef({});
    const scrollToArticle = (id) => {
        if (articleRefs.current[id]) {
            articleRefs.current[id].scrollIntoView({ behavior: "smooth", block: "center" });
        }
    };
    const cardProps = {
        images: {
            image1:
                cApiUrlPrefix + showCandidateHistoryByIdData?.candidate.candidate_image,
        },
        titles: {
            title1: showCandidateHistoryByIdData?.candidate.position,
            title2: "Personal details",
        },
        subHeadings: {
            subheading1: "Born",
            subheading2: "Party Name",
        },
        subDetails: {
            subDetail1: formatDate(showCandidateHistoryByIdData?.candidate.date_of_birth),
            subDetail2: showCandidateHistoryByIdData?.candidate.party_name,
        },
    };

    const aside = {
        aside1: "",
        aside2: {
            heading: showCandidateHistoryByIdData?.candidate.candidate_name,
            para: showCandidateHistoryByIdData?.candidate.bio,
        },
        aside3: <CardFour {...cardProps} />,
        histories,
        scrollToArticle,
        articleRefs,
    };
    const props = {
        components: {
            component1: <Aside1 {...aside} />,
            component2: <Aside2 {...aside} />,
            component3: <Aside3 {...aside} />,
        },
    };

    return (
        <main className="candidate-history-page">
            <LayOutTwo {...props} />
            {loading ? (
                <Loader color={"#0E76A8"} loading={loading} css={override} size={75} />
            ) : null}
        </main>
    );
};

export default CandidateHistoryPage;
