import React, { useEffect } from "react";
import Blogs from "./blogs";
import { BsArrowRight } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { indexGetAllBlogContent } from "../../../redux/adminone/action";
import { useNavigate } from "react-router-dom";

const BlogsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(indexGetAllBlogContent());
  }, [dispatch]);

  const getAllBlogContent = useSelector(
    (state) => state.adminone.getAllBlogContent?.data
  );

  const data = Array.isArray(getAllBlogContent)
    ? getAllBlogContent.map((categoryList) => {
        return {
          categoryName: categoryList.category_name,
          dates: categoryList.created_at,
          images: categoryList.blog_image,
          subtitles: categoryList.blog_title,
          parag: categoryList.overview,
          children: "Read more",
          icons: <BsArrowRight />,
          onClick: () =>
            navigate("/full-blogs", { state: { id: categoryList.id } }),
        };
      })
    : [];
  return (
    <main className="mb-5">
      <Blogs titles="Blogs" blogData={data} />
    </main>
  );
};

export default BlogsPage;
