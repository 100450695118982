import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { loginAdmin } from "../../../redux/adminone/action";
import { Link, useNavigate } from "react-router-dom";
import { Image, Form, Container } from "react-bootstrap";
import { FloatingLabel } from "react-bootstrap";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import SolidBlueBtn from "../../../components/buttons/solidBlueBtn";
import Loader from "../../../components/loaders/loader";
import Logo from "../../../assets/images/logo.png";
import CustomTooltip from "../../../components/tooltips/ctoolTip";
import { toast } from "react-toastify";
const override = `
    display: block;
    margin: 0 auto;
    border-color: red;
`;
const ALogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [validated, setValidated] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    const handleBackButton = (event) => {
      event.preventDefault();
      window.history.pushState(null, null, window.location.pathname);
    };

    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, []);
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;

    setValidated(true);

    if (form.checkValidity() === false) {
      return;
    }

    if (!/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/.test(formData.email)) {
      toast.error("Invalid email");
      return;
    }

    if (
      !/^(?=.*[A-Za-z])(?=.*\d)(?=.*[^A-Za-z\d])[\s\S]{9,}$/.test(
        formData.password
      )
    ) {
      toast.error("Invalid password");
      return;
    }

    const adminFormData = new FormData();
    adminFormData.append("email", formData.email);
    adminFormData.append("password", formData.password);

    dispatch(
      loginAdmin(adminFormData, (error, response) => {
        if (error && error.status === 401) {
          // handle the error

          toast.error(error.data.message, {
            position: "top-center",
            style: {
              fontSize: "1.6rem", // Adjust the font size as needed
            },
          });
          navigate("/admin/login");
        } else {
          // handle the response

          if (response && response.status === 200) {
            if (response.data.user.security_level_id !== 1) {
              localStorage.setItem("token", response.data.token);
              localStorage.setItem("user", JSON.stringify(response.data.user));
              toast.success(response.data.message, {
                position: "top-center",
                style: {
                  fontSize: "1.6rem", // Adjust the font size as needed
                },
              });
              navigate("/dashboard");
            }
          }
        }
      })
    );
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <main className="main-auth">
      <Container fluid="xxl">
        <div className="AloginMain">
          <figure>
            <Image src={Logo} fluid />
          </figure>
          <div className="authCenterBlk">
            <h2 className="authHead">Welcome</h2>
            <p className="authPara">
              Please enter your ID and Password for login.
            </p>
            <Form
              className="AlForm"
              onSubmit={handleSubmit}
              noValidate
              validated={validated}
            >
              <CustomTooltip text="The user's email address.">
                <FloatingLabel
                  controlId="idEmail"
                  label="Email"
                  className={validated && !formData.email ? "is-invalid" : ""}
                >
                  <Form.Control
                    required
                    type="email"
                    placeholder="Enter your email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid email.
                  </Form.Control.Feedback>
                </FloatingLabel>
              </CustomTooltip>
              <CustomTooltip text="Enter a valid password">
                <FloatingLabel
                  controlId="idPassword"
                  label="Password"
                  className={
                    validated && !formData.password ? "is-invalid" : ""
                  }
                >
                  <Form.Control
                    required
                    type={showPassword ? "text" : "password"}
                    placeholder="Enter your password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    minLength={9}
                  />
                  <span
                    onClick={togglePasswordVisibility}
                    className="password-toggle-icon"
                  >
                    {showPassword ? <FaEye /> : <FaEyeSlash />}
                  </span>
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid password.
                  </Form.Control.Feedback>
                </FloatingLabel>
              </CustomTooltip>
              <Form.Group className="mb-3 text-end">
                <Link to="/email-send-otp" className="AfpLink">
                  Forgot Password?
                </Link>
              </Form.Group>
              <div className="text-center AlFormBtn">
                <SolidBlueBtn
                  type="submit"
                  style={{ width: "calc(100% - 40%)", padding: "1.3rem" }}
                >
                  Login
                </SolidBlueBtn>
              </div>
            </Form>
          </div>
        </div>
      </Container>
    </main>
  );
};

export default ALogin;
