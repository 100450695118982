import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import SemiCircleProgressBar from "react-progressbar-semicircle";

const ProgressBar = ({ percentage, color, size }) => {
  const ref = useRef();

  useEffect(() => {
    if (ref.current) {
      const circles = ref.current.querySelectorAll("circle");
      circles.forEach((circle) => {
        circle.style.stroke = color;
      });
    }
  }, [color]);

  return (
    <div ref={ref} className="progress-container">
      <SemiCircleProgressBar
        percentage={percentage}
        strokeWidth={7}
        strokeColor={color}
        diameter={size}
        className="progress-bar"
        style={{ width: size, height: size }}
      />
      <h4 className="progress-percentage">{percentage}%</h4>
    </div>
  );
};

ProgressBar.propTypes = {
  percentage: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
};

export default ProgressBar;
