import React, { useEffect, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';

const HtmlEditor = ({ initialValue, onChange }) => {
    const [editorContent, setEditorContent] = useState(initialValue);

    useEffect(() => {
        setEditorContent(initialValue);
    }, [initialValue]);

    const handleEditorChange = (content, editor) => {
        setEditorContent(content);
        onChange(content); 
    };

    return (
        <Editor
            apiKey='ffbvajm6utbwo7o9z0huvofx4gfno8co5n9vrb9jnioreg9n'
            initialValue={initialValue}
            init={{
                height: 250,
                menubar: true,
                plugins: [
                    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                    'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                    'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                ],
                toolbar: 'undo redo | blocks | ' +
                    'bold italic forecolor | alignleft aligncenter ' +
                    'alignright alignjustify | bullist numlist outdent indent | ' +
                    'removeformat | help',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'

            }}
            onEditorChange={handleEditorChange}
        />
    );
};

export default HtmlEditor;
