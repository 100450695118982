import React from "react";
import PulseLoader from "react-spinners/PulseLoader";
const Ploader = ({ loading, size, override, color }) => {
  return (
    <div className="pluse-container">
      <PulseLoader
        color={color}
        override={override}
        loading={loading}
        size={size}
      />
    </div>
  );
};

export default Ploader;
