import React from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

const Tabss = ({ tabContents }) => {
    return (
        <Tabs
            defaultActiveKey="sri-lanka"
            id="uncontrolled-tab-example">
            {tabContents.map((tab, index) => (
                <Tab eventKey={tab.eventKey} title={tab.title} key={index} className="tabs-content">
                    {tab.content}
                </Tab>
            ))}
        </Tabs>
    );
};

export default Tabss;
