import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";

const TextArea = ({
  placeholder,
  formLabel,
  style,
  onChange,
  name,
  value,
  uniqueId,
  error,
}) => {
  return (
    <Container fluid className="px-0">
      <Form.Group
        as={Row}
        className="mb-3 d-flex align-items-center textForm"
        style={style}
      >
        <Form.Label htmlFor={uniqueId} className="textformlabel" column sm={2}>
          {formLabel}
        </Form.Label>
        <Col sm={6}>
          <Form.Control
            className="textformtext"
            as="textarea"
            placeholder={placeholder}
            onChange={onChange}
            name={name}
            value={value}
            id={uniqueId}
          />
          {error && (
            <Form.Control.Feedback type="invalid">
              {error}
            </Form.Control.Feedback>
          )}
        </Col>
      </Form.Group>
    </Container>
  );
};

export default TextArea;
