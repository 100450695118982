import React from "react";
import { FaLocationArrow } from "react-icons/fa";
import { GoTrophy, GoArrowUpRight } from "react-icons/go";
import ManageTable from "../../../components/table/manageTable";
import IImage from "../../../components/image/iImage";
import CustomChart from "../../../components/charts/customChart";

const SeeVoteSimulatorResultMain = ({
    selectedOption,
    columnOption,
    pieOption,
    provincialDetailsData,
    data,
    tableLabels,
    topCandidate,
    totalPartyVotes,
    buttons,
    handleSwitchChange,
    isChecked, locationDistrict, locationPollingDivision
}) => {
    return (
        <main className="svsrp-main">
            <section className="svsrp-section">
                <div>
                    <h2 className="svsrp-heading">
                        {selectedOption}{" "}
                        <span className="svsrp-heading-lbl">(Simulator)</span>
                    </h2>
                    <p className="svsrp-location">
                        Sri Lanka ,
                        {locationDistrict}, {locationPollingDivision} {" "}
                        <FaLocationArrow className="location-i" />
                    </p>
                </div>
                <div className="svsrp-wcv-Blk">
                    <div className="svsrp-wcv-subBlk">
                        <div className="svsrp-trophy">
                            <GoTrophy className="trophy" />
                        </div>
                        <div className="svsrp-details">
                            <h4 className="svsrp-deta-head">
                                <span className="text-decoration-underline">
                                    {topCandidate.candidate_name || "N/A"}
                                </span>
                                <span>
                                    <GoArrowUpRight className="svsrp-arw" />
                                </span>
                            </h4>
                            <div className="svsrp-deta-votes">
                                Winning Candidate Votes - {topCandidate.total_votes || "N/A"}
                            </div>
                        </div>
                    </div>
                    <div className="svsrp-wcv-subBlk">
                        <div className="mpLogo">
                            <IImage src={topCandidate.party_logo} className="mpLogoImg" />
                        </div>
                        <div className="svsrp-details">
                            <h4 className="svsrp-deta-head">
                                <span className="text-decoration-underline">
                                    {topCandidate.party_name || "N/A"}
                                </span>
                                <span>
                                    <GoArrowUpRight className="svsrp-arw" />
                                </span>
                            </h4>
                            <div className="svsrp-deta-votes">
                                Winning Party Votes - {totalPartyVotes}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="svsrp-section">
                <h5 className="svsrp-voting-heading">Simulator voting details</h5>
                <div className="svsrp-voting-blk">
                    {provincialDetailsData.map((item, index) => (
                        <div className="d-flex right-border" key={index}>
                            <span className={`sqr-box${index}`}></span>
                            <div className="registered-e-div">
                                <h6 className="provincial-vote">{item.value}</h6>
                                <p className="registered-electors mt-1">{item.label}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
            <div className="form-check form-switch activeSwitch">
                <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="flexSwitchCheckChecked"
                    checked={isChecked}
                    onChange={handleSwitchChange}
                />
                <label
                    className="form-check-label"
                    htmlFor="flexSwitchCheckChecked"
                >
                    {isChecked ? 'table' : 'chart'}
                </label>
            </div>
            {
                isChecked ? (<section className="svsrp-section-table">
                    <ManageTable data={data} labels={tableLabels} />
                </section>) : (<section className="svsrp-section-chart ">
                    <div className="ppe2-chart-block">
                        <div className="ppe2-chartsubBlk">
                            <CustomChart options={columnOption} />
                        </div>
                        <div className="ppe2-chartsubBlk">
                            <CustomChart options={pieOption} />
                        </div>
                    </div>
                </section>)
            }
            {buttons}
        </main>
    );
};

export default SeeVoteSimulatorResultMain;
