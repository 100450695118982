import React, { useEffect, useMemo, useState } from "react";
import { FaLocationArrow } from "react-icons/fa";
import { Col, Container, Image, Row } from "react-bootstrap";
import { MdOutlineHowToVote } from "react-icons/md";
import SearchInput from "../../../components/searchbar/searchInput";
import OrangeSolidBtn from "../../../components/buttons/orangeSolidBtn";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getAllCandidateByTitleId } from "../../../redux/adminone/action";
import ManageTable from "../../../components/table/manageTable";
import {
  addPresidentialElectionResultsByDIdUid,
  getElectionUserIdElectionID,
  updatePresidentialElectionResultsByDIdUid,
} from "../../../redux/user/action";
import { useSnackbar } from "react-simple-snackbar";
import VoteSubmited from "../../../components/modals/voteSubmited";
import { cApiUrlPrefix } from "../../../config/envConfig";
import Loader from "../../../components/loaders/loader";
import Cselect from "../../../components/select/cselect";
import IImage from "../../../components/image/iImage";
const override = `
    display: block;
    margin: 0 auto;
    border-color: red;
  `;
const options = [
  { value: 1, label: "1st Preference" },
  { value: 2, label: "2nd Preference" },
  { value: 3, label: "3rd Preference" },
];

function RegisterAsVoter() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const [showModal, setShowModal] = useState(false);

  const {
    electionTypeId,
    title,
    district_id,
    district_name,
    polling_division_id,
    polling_division_name,
  } = location.state || {};

  const user = JSON.parse(localStorage.getItem("user"));
  const userId = user.id;

  const dId = user.district_id;
  const pdId = user.polling_division_id;

  useEffect(() => {
    if (electionTypeId) {
      dispatch(getAllCandidateByTitleId(electionTypeId));
      if (userId) {
        dispatch(getElectionUserIdElectionID(userId, electionTypeId));
      }
    }
  }, [dispatch, electionTypeId, userId]);

  const getAllCandidateByTitleIdData = useSelector(
    (state) => state.adminone.getAllCandidateByTitleIdData?.data
  );
  const getElectionUiEiData = useSelector(
    (state) => state.user.getElectionUiEiData?.data
  );

  const loadingA = useSelector((state) => state.adminone.loading);
  const loadingU = useSelector((state) => state.user.loading);
  const isLoading = loadingA || loadingU;

  const data = useMemo(() => {
    if (!getAllCandidateByTitleIdData || !district_id) {
      return [];
    }
    return getAllCandidateByTitleIdData.filter(
      (candidate) => candidate.district_id === district_id
    );
  }, [getAllCandidateByTitleIdData, district_id]);

  const [updateData, setUpdateData] = useState(data);

  useEffect(() => {
    if (data) {
      setUpdateData((prevData) => {
        let newData = data;
        if (getElectionUiEiData?.results?.length > 0) {
          newData = data.map((candidate) => {
            const matchedResult = getElectionUiEiData.results.find(
              (result) => result.candidate_id === candidate.candidate_id
            );
            if (matchedResult) {
              return { ...candidate, preference: matchedResult.preference };
            }
            return candidate;
          });
        }

        return newData;
      });
    }
  }, [data, getElectionUiEiData]);

  const handlePreferenceChange = (selectedOption, candidateId) => {
    setUpdateData((prevData) => {
      const candidateWithExistingPreference = prevData.find(
        (candidate) => candidate.preference === selectedOption.value
      );

      const updatedCandidates = prevData.map((candidate) => {
        if (
          candidateWithExistingPreference &&
          candidate.candidate_id ===
            candidateWithExistingPreference.candidate_id
        ) {
          const { preference, ...restCandidate } = candidate;
          return restCandidate;
        }
        if (candidate.candidate_id === candidateId) {
          openSnackbar(
            `Candidate ${candidate.candidate_name} selected as ${selectedOption.label}`
          );
          return { ...candidate, preference: selectedOption.value };
        }

        return candidate;
      });

      return updatedCandidates;
    });
  };

  const labels = [
    "Candidate Image",
    "Candidate Name",
    "Party Logo",
    "Party Name",
    "Prefrences",
  ];

  const dataa = Array.isArray(updateData)
    ? updateData.map((candidate) => ({
        "Candidate Image": (
          <figure className="regiserCandidate-figur">
            <IImage
              src={candidate.candidate_image}
              className="regiserCandidate-img"
            />
          </figure>
        ),
        "Candidate Name": candidate.candidate_name,
        "Party Logo": (
          <figure className="regiserCandidate-figur">
            <IImage
              src={candidate.party_image}
              className="regiserCandidate-img"
            />
          </figure>
        ),
        "Party Name": candidate.party_name,
        Prefrences: (
          <div className="d-flex justify-content-center align-items-center">
            <Cselect
              options={options}
              className="cselectpageSize"
              isSearchable={false}
              onChange={(selectedOption) =>
                handlePreferenceChange(selectedOption, candidate.candidate_id)
              }
              value={
                options.find(
                  (option) => option.value === candidate.preference
                ) || ""
              }
            />
          </div>
        ),
      }))
    : [];

  const submitClick = () => {
    const candidateMap = new Map();

    updateData.forEach((candidate) => {
      if ([1, 2, 3].includes(candidate.preference)) {
        const preference = candidate.preference.toString();

        const candidateIdString = candidate.candidate_id.toString();

        if (
          !candidateMap.has(candidateIdString) ||
          preference < candidateMap.get(candidateIdString)
        ) {
          candidateMap.set(candidateIdString, preference);
        }
      }
    });

    const resultsData = {
      results: Array.from(candidateMap, ([candidate_id, preference]) => ({
        candidate_id,
        preference,
      })),
    };

    if (getElectionUiEiData?.results?.length > 0) {
      dispatch(
        updatePresidentialElectionResultsByDIdUid(
          resultsData,
          userId,
          electionTypeId,
          (error, response) => {
            if (error) {
              if (error.status === 400) {
                alert(error.data.message);
                openSnackbar(error.data.message);
              } else if (error.status === 401) {
                openSnackbar(
                  error.data.message || "Unauthorized: Please log in."
                );
              } else {
                openSnackbar(
                  error.data?.message || "An unexpected error occurred."
                );
              }
            } else if (response) {
              if (response.status === 200) {
                openSnackbar(response.data.message);
                navigate("/winner", {
                  state: {
                    electionTypeId,
                    title,
                    district_id,
                    district_name,
                    polling_division_id,
                    polling_division_name,
                  },
                });
              } else {
                openSnackbar("An unexpected error occurred.");
              }
            } else {
              openSnackbar("An unexpected error occurred.");
            }
          }
        )
      );
    } else {
      dispatch(
        addPresidentialElectionResultsByDIdUid(
          resultsData,
          userId,
          electionTypeId,
          (error, response) => {
            if (error) {
              if (error.status === 400) {
                alert(error.data.message);
                openSnackbar(error.data.message);
              } else if (error.status === 401) {
                openSnackbar(
                  error.data.message || "Unauthorized: Please log in."
                );
              } else {
                openSnackbar(
                  error.data?.message || "An unexpected error occurred."
                );
              }
            } else if (response) {
              if (response.status === 200) {
                openSnackbar(response.data.message);
                handleOpenModal();
              } else {
                openSnackbar("An unexpected error occurred.");
              }
            } else {
              openSnackbar("An unexpected error occurred.");
            }
          }
        )
      );
    }
    dispatch(getAllCandidateByTitleId(electionTypeId));
    dispatch(getElectionUserIdElectionID(userId, electionTypeId));
  };
  const handleOpenModal = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleChangeClick = () => {
    setShowModal(false);
  };
  const handleChangeClick1 = () => {
    setShowModal(false);
    navigate("/winner", {
      state: {
        electionTypeId,
        title,
        district_id,
        district_name,
        polling_division_id,
        polling_division_name,
      },
    });
  };
  const candidate1 = updateData.find((candidate) => candidate.preference === 1);
  const candidate2 = updateData.find((candidate) => candidate.preference === 2);
  const candidate3 = updateData.find((candidate) => candidate.preference === 3);

  const props = {
    headings: {
      heading1: title,
    },
    locations: {
      location1: `Sri Lanka, ${district_name}, ${polling_division_name}`,
    },
    childrens: {
      children1:
        getElectionUiEiData !== undefined
          ? "See Results"
          : "Vote for your candidate",
    },
    lables: {
      lable1: candidate1?.candidate_name || "Choose 1st Preference",
      lable2: candidate2?.candidate_name || "Choose 2nd Preference",
      lable3: candidate3?.candidate_name || "Choose 3rd Preference",
    },

    icons: {
      icon1: <MdOutlineHowToVote />,
    },
  };

  return (
    <main className="main-registreAsVoter">
      <Container fluid className="registreAsVoter-container">
        <Row className="registreAsVoter-row">
          <Col className="registreAsVoter-col" md={6}>
            <h2 className="registreAsVoter-heading">
              {props.headings.heading1} <span>(Poll)</span>
            </h2>
            <p className="registreAsVoter-location">
              {props.locations.location1}{" "}
              <FaLocationArrow
                className={`location-i ${
                  district_id !== dId && polling_division_id !== pdId
                    ? "blink"
                    : ""
                }`}
                onClick={() => {
                  navigate(`/select-election`);
                }}
              />
            </p>
          </Col>
          <Col md={{ span: 4, offset: 2 }} className="registreAsVoter-col">
            <SearchInput placeholder="Search Candidate or Party" />
          </Col>
        </Row>
        <Row className="registreAsVoter-row">
          {district_id === dId && polling_division_id === pdId ? (
            <>
              <Col className="registreAsVoter-col position-relative" md={2}>
                <div className="image-block position-relative">
                  {candidate1 && (
                    <div className="imgBlk">
                      <Image
                        className="cimage"
                        fluid
                        src={cApiUrlPrefix + candidate1.candidate_image}
                      />
                      <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill">
                        <sup>
                          1<sup>st</sup>
                        </sup>
                      </span>
                    </div>
                  )}
                </div>
                <h5 className="image-lable">{props.lables.lable1}</h5>
              </Col>
              <Col className="registreAsVoter-col position-relative" md={2}>
                <div className="image-block position-relative">
                  {candidate2 && (
                    <div className="imgBlk">
                      <Image
                        className="cimage"
                        fluid
                        src={cApiUrlPrefix + candidate2.candidate_image}
                      />
                      <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill">
                        <sup>
                          2<sup>nd</sup>
                        </sup>
                      </span>
                    </div>
                  )}
                </div>
                <h5 className="image-lable">{props.lables.lable2}</h5>
              </Col>
              <Col className="registreAsVoter-col position-relative" md={2}>
                <div className="image-block position-relative">
                  {candidate3 && (
                    <div className="imgBlk">
                      <Image
                        className="cimage"
                        fluid
                        src={cApiUrlPrefix + candidate3.candidate_image}
                      />
                      <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill">
                        <sup>
                          3<sup>rd</sup>
                        </sup>
                      </span>
                    </div>
                  )}
                </div>
                <h5 className="image-lable">{props.lables.lable3}</h5>
              </Col>
              <Col className="registreAsVoter-col" md={{ span: 4, offset: 2 }}>
                <OrangeSolidBtn onClick={submitClick}>
                  {props.childrens.children1} <MdOutlineHowToVote />
                </OrangeSolidBtn>
              </Col>
            </>
          ) : (
            <h4 className="location-message">
              {" "}
              You can only vote in the location specified in your profile.{" "}
            </h4>
          )}
        </Row>

        <Row className="registreAsVoter-row">
          <Col className="registreAsVoter-col">
            <ManageTable data={dataa} labels={labels} />
          </Col>
        </Row>
      </Container>
      <VoteSubmited
        district={district_name}
        pd={polling_division_name}
        show={showModal}
        handleClose={handleCloseModal}
        handleChangeClick={handleChangeClick}
        handleChangeClick1={handleChangeClick1}
      />
      {isLoading ? (
        <Loader
          color={"#0E76A8"}
          loading={isLoading}
          css={override}
          size={75}
        />
      ) : null}
    </main>
  );
}

export default RegisterAsVoter;
