import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { showPartyHistoryById } from "../../../redux/adminone/action";
import Loader from "../../../components/loaders/loader";
import LayOutTwo from "../../../components/layOut/layOutTwo";

import { cApiUrlPrefix } from "../../../config/envConfig";
import { formatDate } from "../../../utilities/common";
import Aside1 from "../../../components/user/history/aside1";
import Aside2 from "../../../components/user/history/aside2";
import Aside3 from "../../../components/user/history/aside3";

import CardFive from "../../../components/cards/cardFive";
const override = `
    display: block;
    margin: 0 auto;
    border-color: red;
`;
const PartiesHistoryPage = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { id } = location.state || {};

    useEffect(() => {
        dispatch(showPartyHistoryById(id));
    }, [dispatch, id]);
    const showGetAllPartyHistoryByPartyIdData = useSelector(
        (state) => state.adminone.showGetAllPartyHistoryByPartyIdData?.data
    ); const histories = showGetAllPartyHistoryByPartyIdData?.histories || [];

    const loading = useSelector((state) => state.adminone.loading);
    const articleRefs = useRef({});
    const scrollToArticle = (id) => {

        if (articleRefs.current[id]) {
            articleRefs.current[id].scrollIntoView({ behavior: "smooth", block: "center" });
        }
    };
    const cardProps = {
        images: {
            image1: cApiUrlPrefix + showGetAllPartyHistoryByPartyIdData?.party.logo
        },
        titles: {
            title1: showGetAllPartyHistoryByPartyIdData?.party.party_abbreviation,
            title2: "Party details",
        },
        subHeadings: {
            subheading1: "Foundation Date",
            subheading2: "headquarters",
            subheading3: "ideology",
            subheading4: "website",

        },
        subDetails: {
            subDetail1: formatDate(showGetAllPartyHistoryByPartyIdData?.party.founding_date),
            subDetail2: showGetAllPartyHistoryByPartyIdData?.party.headquarters,
            subDetail3: showGetAllPartyHistoryByPartyIdData?.party.ideology,
            subDetail4: showGetAllPartyHistoryByPartyIdData?.party.website,
        },
    };
    const aside = {
        aside1: "",
        aside2: {
            heading: showGetAllPartyHistoryByPartyIdData?.party.party_name,
            para: showGetAllPartyHistoryByPartyIdData?.party.bio,
        },
        aside3: <CardFive {...cardProps} />,
        histories,
        scrollToArticle,
        articleRefs,
    };
    const props = {
        components: {
            component1: <Aside1 {...aside} />,
            component2: <Aside2 {...aside} />,
            component3: <Aside3 {...aside} />,
        },
    };

    return (
        <main className="party-history-page">
            <LayOutTwo {...props} />
            {loading ? (
                <Loader color={"#0E76A8"} loading={loading} css={override} size={75} />
            ) : null}
        </main>
    );
};

export default PartiesHistoryPage;
