import React, { useEffect } from "react";
import disImg from "../../../assets/images/sreelamkaImg.png";
import { useDispatch, useSelector } from "react-redux";
import { showAllPollingDivisionsByDistrictById } from "../../../redux/adminone/action";
import { cApiUrlPrefix } from "../../../config/envConfig";
import LayOutOne from "../../../components/layOut/layOutOne";
import Loader from "../../../components/loaders/loader";
import { useLocation, useNavigate } from "react-router-dom";

const override = `
    display: block;
    margin: 0 auto;
    border-color: red;
`;

const PollingDivisionsPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { district_id, title } = location.state || {};

    useEffect(() => {
        dispatch(showAllPollingDivisionsByDistrictById(district_id));
    }, [dispatch, district_id]);
    const showsAllPollingDivisionsByDistrictIdData = useSelector(
        (state) => state.adminone.showsAllPollingDivisionsByDistrictIdData?.data
    );
    const loading = useSelector((state) => state.adminone.loading);


    const data =
        showsAllPollingDivisionsByDistrictIdData?.map((pollingDivision) => ({
            id: pollingDivision.id,
            name: pollingDivision.polling_division_name,
            image:
                pollingDivision.map_image === "0"
                    ? disImg
                    : cApiUrlPrefix + pollingDivision.map_image,
        })) || [];

        console.log("title",title);
        

    const handleClick = (polling_division_id, polling_division_name) => {
        const { electionTypeId, title, district_id, district_name } = location.state || {};
        navigate("/presidential-election-hero-section", {
            state: { electionTypeId, title, district_id, district_name, polling_division_id, polling_division_name },
        });
    };
    const user = JSON.parse(localStorage.getItem("user"));
    const activeId = user?.polling_division_id;
    const props = {
        headings: {
            heading1: "POLLING DIVISIONS",
        },

        children: {
            children1: "Enter into O'Democracy Dashboard",
        },
        data,
        handleClick,
        activeId
    };

    return (
        <>
            <LayOutOne {...props} />;
            {loading ? (
                <Loader color={"#0E76A8"} loading={loading} css={override} size={75} />
            ) : null}
        </>
    );
};

export default PollingDivisionsPage;
