import React, { useEffect, useState } from "react";
import ManageBlk from "../../../components/admin/manageBlk";
import { useDispatch, useSelector } from "react-redux";
import DropDown from "../../../components/dropdown/dropDown";
import DeleteCModal from "../../../components/modals/deleteCModal";
import { delDistrictById, indexDistrict } from "../../../redux/adminone/action";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "react-simple-snackbar";
const options = [
  { value: 10, label: 10 },
  { value: 20, label: 20 },
  { value: 30, label: 30 },
];
const cstomStyle = {
  menu: (provided) => ({
    ...provided,
    backgroundColor: "white",
    color: "#65676B",
    maxWidth: "97px",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#efefef" : "white",
    color: state.isFocused ? "#65676B" : "#65676B",
    padding: 5,
  }),
};

const ManageDistricts = () => {
  const dispatch = useDispatch();
  const [openSnackbar, closeSnackbar] = useSnackbar();
  useEffect(() => {
    dispatch(indexDistrict());
  }, [dispatch]);
  const navigate = useNavigate();
  const [isSearchable] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [districts, setDistricts] = useState(null);
  const indexDistrictsData = useSelector((state) => state.adminone.indexDistrictsData?.data);
  const loading = useSelector((state) => state.adminone.loading);
  const error = useSelector((state) => state.adminone.error);
  const dropdownItems = (data) => [
    { label: "Edit", href: "#/edit", onClick: () => handleEdit(data) },
    {
      label: "Delete",
      href: "#/delete",

      onClick: () => {
        handleOpenModal(data);
      },
    },
    {
      label: "Manage Polling Devision",
      href: "#/action-3",
      onClick: () => handlePolingDivision(data),
    },
  ];

  const handleEdit = (data) => {
    navigate("/edit-districts", {
      state: {
        id: data.id,
        district_name: data.district_name,
      }
    });
    // navigate(
    //   `/edit-districts?id=${data.id}&district_name=${encodeURIComponent(
    //     data.district_name
    //   )}`
    // );
  };

  const handleOpenModal = (data) => {
    setDistricts(data);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setDistricts(null);
  };

  const handleConfirmDelete = () => {
    if (districts) {
      const id = districts.id;

      dispatch(
        delDistrictById(id, (error, response) => {
          if (error) {


          } else {


            if (response.status === 200) {
              dispatch(indexDistrict());
              openSnackbar(response.data.message);
            }
          }
        })
      );
      handleCloseModal();
    }
  };

  const handlePolingDivision = (data) => {
    navigate("/polling-division", {
      state: {
        id: data.id,
        district_name: data.district_name,
      }
    });
    // navigate(
    //   `/polling-division?id=${data.id}&district_name=${encodeURIComponent(
    //     data.district_name
    //   )}`
    // );
  };

  const labels = [
    "District name",
    "Province",
    "District Seats",
    "Province Seats",
    "Polling Devision",
    "Populations",
    "Registered Voters",
    "Action",
  ];

  const data = Array.isArray(indexDistrictsData)
    ? indexDistrictsData.map((districts) => ({
      "District name": districts.district_name,
      Province: districts.province_name,
      "District Seats": districts.district_seats,
      "Province Seats": districts.province_seats,
      "Polling Devision": districts.polling_division,
      Populations: districts.total_population,
      "Registered Voters": districts.total_registered_voters,
      Action: (
        <DropDown
          className="sequritiesPartyAction ms-auto"
          Children={"Action"}
          items={dropdownItems(districts)}
        />
      ),
    }))
    : [];
  const districtsBlkProps = {
    title: "Districts",
    children: "Add Electoral Districts",
    to: "/add-districts",
    style: { padding: "1.3rem 2.8rem" },
    options,
    isSearchable,
    className: {
      cselectpageSize: "cselectpageSize",
      minputSearch: "minputSearch",
      deleteModal: "deleteModal",
    },
    cstomStyle,
    Placeholder: "Search",
    labels,
    data,
    error,
  };
  return (
    <main className="districts">
      <aside className="districtsaside">
        <ManageBlk {...districtsBlkProps} />
      </aside>
      <DeleteCModal
        show={showModal}
        handleClose={handleCloseModal}
        handleConfirm={handleConfirmDelete}
        className={districtsBlkProps.className.deleteModal}
      />
    </main>
  );
};

export default ManageDistricts;
