import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import OrangeSolidBtn from "../../components/buttons/orangeSolidBtn";
import CardOne from "../../components/cards/cardOne";

const LayOutOne = (props) => {
  return (
    <main className="main-layOut-one">
      <Container fluid className="layOut-one-container">
        <Row className="layOut-one-row">
          <Col className="layOut-one-col">
            <h2 className="layOut-one-heading">{props.headings.heading1}</h2>
          </Col>
        </Row>
        <Row className="layOut-one-row" md={6}>
          {props.data.map((data) => (
            <Col key={data.id} className="layOut-one-col">
              <CardOne
                className={`card-one ${data.id === props.activeId ? "active" : ""
                  }`}
                image={data.image}
                title={data.name}
                onClick={() => props.handleClick(data.id, data.name)}
              />
            </Col>
          ))}
        </Row>
      </Container>
      {/* <div className="layOut-one-container">
        <OrangeSolidBtn children={props.children.children1} />
      </div> */}
    </main>
  );
};

export default LayOutOne;
