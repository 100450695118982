import React from "react";
import Root from "./routes/root";

import { Provider } from "react-redux";
import { history } from "./utilities/navigation";
import { ToastContainer } from "react-toastify";
import SnackbarProvider from 'react-simple-snackbar'
import Store from "./redux/store";



function App() {

  return (
    <Provider store={Store}>
      <div className="App" history={history}>
        <SnackbarProvider>
          <Root />
          <ToastContainer position="top-right" />
        </SnackbarProvider>
      </div>
    </Provider>
  );
}

export default App;
