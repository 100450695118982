import React, { useEffect, useState, useMemo, useRef } from "react";
import LayOutThree from "../../../components/layOut/layOutThree";
import Aside4 from "../../../components/user/history/aside4";
import Aside5 from "../../../components/user/history/aside5";
import CustomSelectwithLabel from "../../../components/select/customSelectwithLabel";
import { useDispatch, useSelector } from "react-redux";
import { indexGetAllManageElections } from "../../../redux/adminone/action";
import {
  addParliamentSimulatorByUidEid,
  getallpresidentialsimulators,
  getAllSimulators,
  getParliamentSimulatorBySimulatorId,
  updateParliamentSimulatorByUidEid,
} from "../../../redux/user/action";

import Loader from "../../../components/loaders/loader";
import Tabss from "../../../components/tabs/tabs";
import ContentTab from "../../../components/contents/contentTab";
import Accordians from "../../../components/accordions/accordian";
import { Col, Container, Form, Image, Row, Table } from "react-bootstrap";
import CustomRangeSlider from "../../../components/range-slider/rangeSlider";
import { calculateVotingPercentage } from "../../../utilities/common";
import SolidBlueBtn from "../../../components/buttons/solidBlueBtn";
import { useLocation, useNavigate } from "react-router-dom";
import srilanka from "../../../assets/images/srilanka.png";
import IImage from "../../../components/image/iImage";
import { CiSquareMinus, CiSquarePlus } from "react-icons/ci";
import TransparentBtn from "../../../components/buttons/transparentBtn";
import { useSnackbar } from "react-simple-snackbar";
const override = `
    display: block;
    margin: 0 auto;
    border-color: red;
`;

const SimulatorPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { election_type_id } = location.state || {};

  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [openSnackbar] = useSnackbar();
  const [options1, setOptions1] = useState([]);
  const [selectedOption1, setSelectedOption1] = useState(null);
  const [options2, setOptions2] = useState([]);
  const [selectedOption2, setSelectedOption2] = useState(null);

  const [percentageValues, setPercentageValues] = useState({});
  const [percentageValues1, setPercentageValues1] = useState({});
  const user = JSON.parse(localStorage.getItem("user"));

  const sliderRefs = useRef([]);
  useEffect(() => {
    dispatch(indexGetAllManageElections());
    dispatch(getAllSimulators());
  }, [dispatch]);

  useEffect(() => {
    if (selectedOption?.value === 2) {
      dispatch(getAllSimulators());
    }
  }, [dispatch, selectedOption]);

  useEffect(() => {
    if (selectedOption2) {
      dispatch(getParliamentSimulatorBySimulatorId(selectedOption2.value));
    }
  }, [dispatch, selectedOption2]);

  const indexAllManageElectionData = useSelector(
    (state) => state.adminone.indexAllManageElectionData?.data
  );

  const getAllPresedentialSimulatorsData = useSelector(
    (state) => state.user.getAllPresedentialSimulatorsData?.data
  );

  const getAllSimulatorsData = useSelector(
    (state) => state.user.getAllSimulatorsData?.data
  );

  const getParliamentSimulatorBySidData = useSelector(
    (state) => state.user.getParliamentSimulatorBySidData?.data
  );

  const loadingA = useSelector((state) => state.adminone.loading);
  const loadingU = useSelector((state) => state.user.loading);

  const isLoading = loadingA || loadingU;

  const data = useMemo(
    () => ({
      ...getAllPresedentialSimulatorsData,
      all_district_total_votes: 0,
      simulators:
        getAllPresedentialSimulatorsData &&
          getAllPresedentialSimulatorsData.simulators
          ? Object.keys(getAllPresedentialSimulatorsData.simulators).reduce(
            (acc, key) => {
              acc[key] = {
                ...getAllPresedentialSimulatorsData.simulators[key],
                district_total_vote: 0,
                simulators: getAllPresedentialSimulatorsData.simulators[
                  key
                ].simulators.map((candidate) => ({
                  ...candidate,
                  candidate_vote: 0,
                })),
              };
              return acc;
            },
            {}
          )
          : {},
    }),
    [getAllPresedentialSimulatorsData]
  );
  const data1 = useMemo(() => {
    const simulatorsData = getAllPresedentialSimulatorsData?.simulators;
    if (!simulatorsData || typeof simulatorsData !== "object") {
      return {
        simulators: {},
        totalRegisteredVoters: 0,
        totalPollingDivisions: 0,
        totalDistrictsCount: 0,
        all_district_total_votes: 0,
        total_seats: 0,
      };
    }

    const transformedSimulators = {};
    let totalSeats = 0; // Initialize a variable to keep track of total seats

    Object.keys(simulatorsData).forEach((districtKey) => {
      const district = simulatorsData[districtKey];
      const partyData = {};
      let districtTotalVotes = 0;

      district.simulators.forEach((candidate) => {
        const partyId = candidate.party_id;
        if (!partyData[partyId]) {
          partyData[partyId] = {
            party_name: candidate.party_name,
            party_logo: candidate.party_logo,
            party_total_votes: 0,
            party_seats: 0,
            simulators: [],
          };
        }
        partyData[partyId].simulators.push({
          id: candidate.id,
          candidate_id: candidate.candidate_id,
          candidate_name: candidate.candidate_name,
          candidate_image: candidate.candidate_image,
          candidate_vote: 0,
          candidate_seats: 0,
        });
      });

      Object.keys(partyData).forEach((partyId) => {
        districtTotalVotes += partyData[partyId].party_total_votes;
      });

      // Add the district seats to the total seats
      totalSeats += district.seats;

      transformedSimulators[districtKey] = {
        district_name: district.district_name,
        total_registered_voters: district.total_registered_voters,
        district_total_vote: districtTotalVotes,
        district_total_seats: district.seats, // Include district total seats
        simulators: partyData,
      };
    });

    const updatedSimulators = { ...transformedSimulators };

    if (getParliamentSimulatorBySidData) {
      Object.keys(getParliamentSimulatorBySidData.simulators).forEach(
        (districtKey) => {
          const districtData =
            getParliamentSimulatorBySidData.simulators[districtKey];
          if (updatedSimulators[districtKey]) {
            Object.keys(districtData.simulators).forEach((partyId) => {
              const partyVotes =
                districtData.simulators[partyId].party_total_votes;
              if (updatedSimulators[districtKey].simulators[partyId]) {
                updatedSimulators[districtKey].simulators[
                  partyId
                ].party_total_votes = partyVotes;
                districtData.simulators[partyId].candidates.forEach(
                  (candidate) => {
                    const candidateId = candidate.candidate_id;
                    const candidateVotes = candidate.candidate_vote;
                    updatedSimulators[districtKey].simulators[
                      partyId
                    ].simulators.forEach((simulatorCandidate) => {
                      if (simulatorCandidate.candidate_id === candidateId) {
                        simulatorCandidate.candidate_vote = candidateVotes;
                      }
                    });
                  }
                );
              }
            });
            // Recalculate district total vote
            updatedSimulators[districtKey].district_total_vote = Object.values(
              updatedSimulators[districtKey].simulators
            ).reduce((acc, party) => acc + party.party_total_votes, 0);
          }
        }
      );
    }

    return {
      simulators: updatedSimulators,
      totalRegisteredVoters:
        getAllPresedentialSimulatorsData.totalRegisteredVoters || 0,
      totalPollingDivisions:
        getAllPresedentialSimulatorsData.totalPollingDivisions || 0,
      totalDistrictsCount:
        getAllPresedentialSimulatorsData.totalDistrictsCount || 0,
      all_district_total_votes: Object.values(updatedSimulators).reduce(
        (acc, district) => acc + district.district_total_vote,
        0
      ),
      total_seats: totalSeats, // Sum of all district total seats
      total_national_seats: 29,
    };
  }, [getAllPresedentialSimulatorsData, getParliamentSimulatorBySidData]);

  const [updateData, setUpdatedData] = useState(data);

  useEffect(() => {
    setUpdatedData(data);
  }, [data]);
  const [updateData1, setUpdatedData1] = useState(data1);

  useEffect(() => {
    setUpdatedData1(data1);
  }, [data1]);

  useEffect(() => {
    if (indexAllManageElectionData) {
      const uniqueData = indexAllManageElectionData.filter(
        (item, index, self) =>
          index ===
          self.findIndex((i) => i.election_type_id === item.election_type_id)
      );
      const newOptions = uniqueData.map((item) => ({
        value: item.election_type_id,
        label: item.type_of_election_name,
      }));

      setOptions(newOptions);

      if (newOptions.length > 0) {
        const initialOption =
          newOptions.find((option) => option.value === election_type_id) ||
          newOptions[0];
        setSelectedOption(initialOption);
      }
    }
  }, [indexAllManageElectionData, election_type_id]);

  useEffect(() => {
    if (selectedOption && indexAllManageElectionData) {
      const filteredData = indexAllManageElectionData.filter(
        (data) => data.election_type_id === selectedOption.value
      );

      const newOptions = filteredData.map((data) => ({
        value: data.id,
        label: data.election_title,
      }));

      setOptions1(newOptions);
      if (newOptions.length > 0) {
        setSelectedOption1(newOptions[0]);
      }
    }
  }, [selectedOption, indexAllManageElectionData]);

  useEffect(() => {
    if (getAllSimulatorsData) {
      const newOptions = getAllSimulatorsData.map((item) => ({
        value: item.simulater_id,
        label: item.simulater_title,
      }));

      setOptions2(newOptions);
    }
  }, [getAllSimulatorsData]);

  useEffect(() => {
    if (selectedOption1) {
      dispatch(getallpresidentialsimulators(selectedOption1.value));
    }
  }, [selectedOption1, dispatch]);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };
  const handleOptionSelect1 = (option) => {
    setSelectedOption1(option);
  };
  const handleOptionSelect2 = (option) => {
    setSelectedOption2(option);
  };

  // const handleSliderChange = (districtKey, candidateIndex, value) => {
  //   setUpdatedData((prevData) => {
  //     const updatedSimulators = { ...prevData.simulators };
  //     const district = updatedSimulators[districtKey];

  //     const newTotalVotes = district.simulators.reduce(
  //       (total, candidate, index) =>
  //         index === candidateIndex
  //           ? total + value
  //           : total + candidate.candidate_vote,
  //       0
  //     );

  //     if (newTotalVotes > district.total_registered_voters) {
  //       window.alert("Total votes cannot exceed the total registered voters.");
  //       return prevData;
  //     }

  //     district.simulators[candidateIndex].candidate_vote = value;

  //     const newSimulators = {
  //       ...updatedSimulators,
  //       [districtKey]: {
  //         ...district,
  //         district_total_vote: newTotalVotes,
  //       },
  //     };

  //     const allDistrictTotalVotes = Object.keys(newSimulators).reduce(
  //       (total, key) => total + newSimulators[key].district_total_vote,
  //       0
  //     );

  //     return {
  //       ...prevData,
  //       simulators: newSimulators,
  //       all_district_total_votes: allDistrictTotalVotes,
  //     };
  //   });
  // };
  const handleSliderChange = (districtKey, candidateIndex, value) => {
    setUpdatedData((prevData) => {
      const updatedSimulators = { ...prevData.simulators };
      const district = updatedSimulators[districtKey];

      // Calculate new total votes
      const newTotalVotes = district.simulators.reduce(
        (total, candidate, index) =>
          index === candidateIndex
            ? total + value
            : total + candidate.candidate_vote,
        0
      );

      // Validate total votes against registered voters
      if (newTotalVotes > district.total_registered_voters) {
        // Instead of alerting, you could set a state to show an error message
        console.warn("Total votes cannot exceed the total registered voters.");
        return {
          ...prevData,
          error: `Total votes exceed registered voters in district "${district.district_name}".`
        };
      }

      // Update candidate vote
      district.simulators[candidateIndex].candidate_vote = value;

      // Update district totals
      const newSimulators = {
        ...updatedSimulators,
        [districtKey]: {
          ...district,
          district_total_vote: newTotalVotes,
        },
      };

      // Calculate overall votes
      const allDistrictTotalVotes = Object.keys(newSimulators).reduce(
        (total, key) => total + newSimulators[key].district_total_vote,
        0
      );

      return {
        ...prevData,
        simulators: newSimulators,
        all_district_total_votes: allDistrictTotalVotes,
      };
    });
  };

  const handlePercentageChange = (
    districtKey,
    candidateIndex,
    percentage,
    totalRegisteredVoters
  ) => {
    if (percentage === "") {
      setPercentageValues((prev) => ({
        ...prev,
        [`${districtKey}-${candidateIndex}`]: "",
      }));
      handleSliderChange(districtKey, candidateIndex, 0);
    } else {
      setPercentageValues((prev) => ({
        ...prev,
        [`${districtKey}-${candidateIndex}`]: percentage,
      }));
      const newVoteValue = Math.round(
        (percentage / 100) * totalRegisteredVoters
      );
      handleSliderChange(districtKey, candidateIndex, newVoteValue);
    }
  };

  // const handleSliderChange1 = (districtKey, partyKey, candidateId, vote) => {
  //   setUpdatedData1((prevData) => {
  //     const newData = { ...prevData };
  //     const district = newData.simulators[districtKey];
  //     const party = district.simulators[partyKey];

  //     if (candidateId !== "") {
  //       const candidate = party.simulators.find(
  //         (candidate) => candidate.candidate_id === candidateId
  //       );
  //       if (candidate) {
  //         candidate.candidate_vote = vote;
  //       }
  //     } else {
  //       party.party_total_votes = vote;
  //     }

  //     district.district_total_vote = Object.values(district.simulators).reduce(
  //       (total, party) => total + party.party_total_votes,
  //       0
  //     );

  //     newData.all_district_total_votes = Object.values(
  //       newData.simulators
  //     ).reduce((total, district) => total + district.district_total_vote, 0);

  //     if (district.district_total_vote > district.total_registered_voters) {
  //       alert(
  //         `Total votes in district "${district.district_name}" cannot exceed registered voters (${district.total_registered_voters}).`
  //       );

  //       party.party_total_votes = 0;
  //       district.district_total_vote = Object.values(
  //         district.simulators
  //       ).reduce((total, party) => total + party.party_total_votes, 0);

  //       return newData;
  //     }

  //     newData.total_seats = Object.values(newData.simulators).reduce(
  //       (total, district) => total + district.district_total_seats,
  //       0
  //     );

  //     return newData;
  //   });
  // };

  // const handleSliderChange1 = (districtKey, partyKey, candidateId, vote) => {
  //   setUpdatedData1((prevData) => {
  //     const newData = { ...prevData };
  //     const district = newData.simulators[districtKey];
  //     const party = district.simulators[partyKey];

  //     // Update votes based on candidate or party
  //     if (candidateId !== "") {
  //       const candidate = party.simulators.find(
  //         (candidate) => candidate.candidate_id === candidateId
  //       );
  //       if (candidate) {
  //         candidate.candidate_vote = vote;
  //       }
  //     } else {
  //       party.party_total_votes = vote;
  //     }

  //     // Calculate total votes in district
  //     district.district_total_vote = Object.values(district.simulators).reduce(
  //       (total, party) => total + party.party_total_votes,
  //       0
  //     );

  //     // Calculate overall votes
  //     newData.all_district_total_votes = Object.values(
  //       newData.simulators
  //     ).reduce((total, district) => total + district.district_total_vote, 0);

  //     // Validation check
  //     if (district.district_total_vote > district.total_registered_voters) {
  //       // Optional: Provide UI feedback instead of alert
  //       console.warn(
  //         `Total votes in district "${district.district_name}" exceed registered voters (${district.total_registered_voters}).`
  //       );

  //       // Reset party votes
  //       party.party_total_votes = 0;
  //       // Recalculate district total
  //       district.district_total_vote = Object.values(
  //         district.simulators
  //       ).reduce((total, party) => total + party.party_total_votes, 0);

  //       // Optionally, set a flag to indicate an error state
  //       newData.error = `Total votes exceed registered voters in "${district.district_name}".`;

  //       return newData;
  //     }

  //     // Calculate total seats
  //     newData.total_seats = Object.values(newData.simulators).reduce(
  //       (total, district) => total + district.district_total_seats,
  //       0
  //     );

  //     return newData;
  //   });
  // };

  const handleSliderChange1 = (districtKey, partyKey, candidateId, vote) => {
    setUpdatedData1((prevData) => {
      const newData = { ...prevData };
      const district = newData.simulators[districtKey];
      const party = district.simulators[partyKey];

      let previousCandidateVote = 0; // To store the previous vote of the candidate in case of validation failure

      // Update votes based on candidate or party
      if (candidateId !== "") {
        const candidate = party.simulators.find(
          (candidate) => candidate.candidate_id === candidateId
        );
        if (candidate) {
          previousCandidateVote = candidate.candidate_vote; // Store the previous candidate vote
          candidate.candidate_vote = vote;

          // Validation: Candidate's votes should not exceed 3 times the party's total votes
          if (candidate.candidate_vote > 3 * party.party_total_votes) {
            console.warn(
              `Candidate's votes for "${candidate.candidate_name}" exceed 3 times the party's total votes for party "${party.party_name}".`
            );

            // Reset the candidate vote to previous value
            candidate.candidate_vote = previousCandidateVote;

            // Optionally, set a flag or error message
            newData.error = `Candidate's votes cannot exceed 3 times the party's total votes in "${district.district_name}" for party "${party.party_name}".`;

            return newData;
          }
        }
      } else {
        party.party_total_votes = vote;
      }

      // Recalculate district total votes
      district.district_total_vote = Object.values(district.simulators).reduce(
        (total, party) => total + party.party_total_votes,
        0
      );

      // Calculate overall votes
      newData.all_district_total_votes = Object.values(
        newData.simulators
      ).reduce((total, district) => total + district.district_total_vote, 0);

      // Validation check for total votes in district exceeding registered voters
      if (district.district_total_vote > district.total_registered_voters) {
        console.warn(
          `Total votes in district "${district.district_name}" exceed registered voters (${district.total_registered_voters}).`
        );

        // Reset party votes
        party.party_total_votes = 0;
        district.district_total_vote = Object.values(district.simulators).reduce(
          (total, party) => total + party.party_total_votes,
          0
        );

        newData.error = `Total votes exceed registered voters in "${district.district_name}".`;

        return newData;
      }

      // Calculate total seats
      newData.total_seats = Object.values(newData.simulators).reduce(
        (total, district) => total + district.district_total_seats,
        0
      );

      return newData;
    });
  };


  const handlePercentageChange1 = (
    districtKey,
    partyKey,
    percentage,
    totalRegisteredVoters
  ) => {
    if (percentage === "") {
      setPercentageValues1((prev) => ({
        ...prev,
        [`${districtKey}-${partyKey}`]: percentage,
      }));
    } else {
      setPercentageValues1((prev) => ({
        ...prev,
        [`${districtKey}-${partyKey}`]: percentage,
      }));
      const newVoteValue = Math.round(
        (percentage / 100) * totalRegisteredVoters
      );
      handleSliderChange1(districtKey, partyKey, "", newVoteValue);
    }
  };
  const aside4 = {
    pageTitle: `${selectedOption1?.label} (Simulator)`,
    location: `Sri Lanka ,${user.district_name}, ${user.polling_division_name}`,
    selectBlk1: (
      <CustomSelectwithLabel
        options={options}
        onSelect={handleOptionSelect}
        placeholder="Select an option"
        mainlabel="Election Name"
        selectedOption={selectedOption}
      />
    ),

    selectBlk2: (
      <CustomSelectwithLabel
        options={options1}
        onSelect={handleOptionSelect1}
        placeholder="Select an option"
        mainlabel="Election Title"
        selectedOption={selectedOption1}
      />
    ),
    selectBlk3:
      selectedOption?.value === 2 ? (
        <CustomSelectwithLabel
          options={options2}
          onSelect={handleOptionSelect2}
          placeholder="Select an option"
          mainlabel="Simulator"
          selectedOption={selectedOption2}
        />
      ) : null,
  };
  const [expandedParties, setExpandedParties] = useState({});
  const [accordianData, setAccordianData] = useState([]);
  const [accordianData1, setAccordianData1] = useState([]);

  useEffect(() => {
    if (updateData.simulators) {
      const newAccordianData = Object.keys(updateData.simulators).map((key) => {
        const district = updateData.simulators[key];
        const tableData = district.simulators;
        const labels = [
          "No.",
          "candidate_image",
          "candidate_name",
          "party_logo",
          "party_name",
          "candidate_vote",
          "Per %",
        ];

        const rows = tableData.map((simulator, index) => (
          <tr key={index}>
            <td>{index + 1}</td>
            <td>
              <div className="mcLogo">
                <IImage src={simulator.candidate_image} className="img-fluid" />
              </div>
            </td>
            <td>{simulator.candidate_name}</td>
            <td>
              <div className="mcLogo">
                <IImage src={simulator.party_logo} className="img-fluid" />
              </div>
            </td>
            <td>{simulator.party_name}</td>
            <td>
              <CustomRangeSlider
                ref={(el) => (sliderRefs.current[`${key}-${index}`] = el)}
                value={simulator.candidate_vote}
                onChange={(value) => handleSliderChange(key, index, value)}
                total_registered_voters={district.total_registered_voters}
              />
            </td>
            <td>
              <Form.Control
                className="label"
                aria-label="Text input with dropdown button"
                value={
                  percentageValues[`${key}-${index}`] ||
                  calculateVotingPercentage(
                    simulator.candidate_vote,
                    district.total_registered_voters
                  ) ||
                  ""
                }
                onChange={(e) =>
                  handlePercentageChange(
                    key,
                    index,
                    e.target.value,
                    district.total_registered_voters
                  )
                }
              />
            </td>
          </tr>
        ));

        return {
          header: (
            <>
              <div className="voteCalculations">
                <Container fluid>
                  <Row>
                    <Col md={1}>
                      <div>
                        <p className="vote-calc-p">{district.district_name}</p>
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="d-flex right-border">
                        <span className="sqr-box2"></span>
                        <div className="registered-e-div">
                          <h6 className="provincial-vote">
                            {district.total_registered_voters}
                          </h6>
                          <p className="registered-electors">
                            Total Registered Voters
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="d-flex right-border">
                        <span className="sqr-box3"></span>
                        <div className="registered-e-div">
                          <h6 className="provincial-vote">
                            {calculateVotingPercentage(
                              district.district_total_vote,
                              district.total_registered_voters
                            )}
                          </h6>
                          <p className="registered-electors">
                            Total Voting Percentage
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="d-flex right-border">
                        <span className="sqr-box4"></span>
                        <div className="registered-e-div">
                          <h6 className="provincial-vote">
                            {district.district_total_vote}
                          </h6>
                          <p className="registered-electors">
                            Total Voting Registered in simulation
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </>
          ),
          body: (
            <>
              <Table responsive className="si-table">
                <thead>
                  <tr>
                    {labels.map((label) => (
                      <th key={label}>{label.replace(/_/g, " ")}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>{rows}</tbody>
              </Table>
            </>
          ),
        };
      });
      setAccordianData(newAccordianData);
    }
  }, [updateData]);

  const handleToggle = (districtKey, partyKey) => {
    setExpandedParties((prevExpandedParties) => ({
      ...prevExpandedParties,
      [districtKey]: {
        ...prevExpandedParties[districtKey],
        [partyKey]: !prevExpandedParties[districtKey]?.[partyKey],
      },
    }));
  };

  useEffect(() => {
    if (updateData1?.simulators) {
      const newAccordionData = Object.keys(updateData1.simulators).map(
        (districtKey) => {
          const district = updateData1.simulators[districtKey];
          const labels = [
            "Party",
            "Candidate",
            "Total Votes",
            "Total Percentage",
            "",
          ];
          const partyRows = Object.keys(district.simulators).map((partyKey) => {
            const party = district.simulators[partyKey];
            const isExpanded = expandedParties[districtKey]?.[partyKey];

            return (
              <React.Fragment key={partyKey}>
                <tr className="si-table1-party-row">
                  <td>
                    <div className="d-flex justify-content-start align-items-center">
                      <div className="mcLogo1">
                        <IImage src={party.party_logo} className="img-fluid" />
                      </div>
                      <div>{party.party_name}</div>
                    </div>
                  </td>
                  <td></td>
                  <td className="align-bottom">
                    <CustomRangeSlider
                      ref={(el) =>
                        (sliderRefs.current[`${districtKey}-${partyKey}`] = el)
                      }
                      value={party.party_total_votes}
                      onChange={(value) =>
                        handleSliderChange1(districtKey, partyKey, "", value)
                      }
                      total_registered_voters={district.total_registered_voters}
                    />
                  </td>
                  <td>
                    <Form.Control
                      className="label1 mx-auto"
                      aria-label="Text input with dropdown button"
                      value={
                        percentageValues1[`${districtKey}-${partyKey}`] ||
                        calculateVotingPercentage(
                          party.party_total_votes,
                          district.total_registered_voters
                        ) ||
                        ""
                      }
                      onChange={(e) =>
                        handlePercentageChange1(
                          districtKey,
                          partyKey,
                          e.target.value,
                          district.total_registered_voters
                        )
                      }
                    />
                  </td>
                  <td>
                    <TransparentBtn
                      Children={
                        expandedParties[districtKey]?.[partyKey] ? (
                          <CiSquareMinus />
                        ) : (
                          <CiSquarePlus />
                        )
                      }
                      onClick={() => handleToggle(districtKey, partyKey)}
                    />
                  </td>
                </tr>

                {isExpanded &&
                  party.simulators.map((candidate, index) => (
                    <tr key={candidate.id}>
                      <td></td>
                      <td>
                        <div className="d-flex justify-content-start align-items-center">
                          <div className="mcLogo1">
                            <IImage
                              src={candidate.candidate_image}
                              className="img-fluid"
                            />
                          </div>
                          <div>{candidate.candidate_name}</div>
                        </div>
                      </td>
                      <td className="align-bottom">
                        <CustomRangeSlider
                          ref={(el) =>
                            (sliderRefs.current[`${districtKey}-${index}`] = el)
                          }
                          value={candidate.candidate_vote}
                          onChange={(value) =>
                            handleSliderChange1(
                              districtKey,
                              partyKey,
                              candidate.candidate_id,
                              value
                            )
                          }
                          total_registered_voters={
                            district.total_registered_voters
                          }
                        />
                      </td>
                      <td></td>
                    </tr>
                  ))}
              </React.Fragment>
            );
          });
          return {
            header: (
              <div className="voteCalculations">
                <Container fluid>
                  <Row>
                    <Col md={1}>
                      <p className="vote-calc-p">{district.district_name}</p>
                    </Col>
                    <Col md={3}>
                      <div className="d-flex right-border">
                        <span className="sqr-box2"></span>
                        <div className="registered-e-div">
                          <h6 className="provincial-vote">
                            {district.total_registered_voters}
                          </h6>
                          <p className="registered-electors">
                            Total Registered Voters
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="d-flex right-border">
                        <span className="sqr-box3"></span>
                        <div className="registered-e-div">
                          <h6 className="provincial-vote">
                            {calculateVotingPercentage(
                              district.district_total_vote,
                              district.total_registered_voters
                            )}
                          </h6>
                          <p className="registered-electors">
                            Total Voting Percentage
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="d-flex right-border">
                        <span className="sqr-box4"></span>
                        <div className="registered-e-div">
                          <h6 className="provincial-vote">
                            {district.district_total_vote}
                          </h6>
                          <p className="registered-electors">
                            Total Voting simulation
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col md={2}>
                      <div className="d-flex right-border">
                        <span className="sqr-box0"></span>
                        <div className="registered-e-div">
                          <h6 className="provincial-vote">
                            {district.district_total_seats}
                          </h6>
                          <p className="registered-electors">Total Seats</p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            ),
            body: (
              <Table responsive className="si-table1">
                <thead>
                  <tr>
                    {labels.map((label) => (
                      <th key={label} className="si-table1-th">
                        {label}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>{partyRows}</tbody>
              </Table>
            ),
          };
        }
      );
      setAccordianData1(newAccordionData);
    }
  }, [updateData1, expandedParties]);

  const onSimulatorSave = () => {
    const jsonData = {
      simulator_title:
        selectedOption2 !== null
          ? selectedOption2.label
          : user.username.substring(0, 3) +
          "S" +
          (getAllSimulatorsData?.length + 1) +
          " - " +
          selectedOption1?.label,
      simulators: Object.keys(updateData1.simulators || {}).map(
        (districtKey) => {
          const district = updateData1.simulators[districtKey];

          const partySimulators = district.simulators
            ? Object.keys(district.simulators).map((partyKey) => {
              const party = district.simulators[partyKey];
              return {
                party_id: partyKey,
                party_total_votes: party.party_total_votes,
                simulators: Object.values(party.simulators || {}).map(
                  (candidateKey) => ({
                    candidate_id: candidateKey.candidate_id,
                    candidate_vote: candidateKey.candidate_vote,
                  })
                ),
              };
            })
            : [];

          return {
            district_id: districtKey,
            simulators: partySimulators,
          };
        }
      ),
    };

    const matchingSimulator = getAllSimulatorsData.find(
      (simulator) => simulator.simulater_title === jsonData.simulator_title
    );
    if (matchingSimulator) {
      dispatch(
        updateParliamentSimulatorByUidEid(
          jsonData,
          user.id,
          selectedOption1?.value,
          (error, response) => {
            if (error) {
              console.log("error", error);
              if (error.status === 400) {
                alert(error.data.message);
                openSnackbar(error.data.message);
              } else if (error.status === 401) {
                openSnackbar(
                  error.data.message || "Unauthorized: Please log in."
                );
              } else {
                openSnackbar(
                  error.data?.message || "An unexpected error occurred."
                );
              }
            } else if (response) {
              if (response.status === 200) {
                openSnackbar(
                  jsonData.simulator_title + " " + response.data.message
                );
              } else {
                openSnackbar("An unexpected error occurred.");
              }
            } else {
              console.log("fsjdfsdfshdgfhsdf sbdfsdf udfgsd ");
            }
          }
        )
      );
    } else {
      dispatch(
        addParliamentSimulatorByUidEid(
          jsonData,
          user.id,
          selectedOption1?.value,
          (error, response) => {
            if (error) {
              console.log("error", error);
              if (error.status === 400) {
                alert(error.data.message);
                openSnackbar(error.data.message);
              } else if (error.status === 401) {
                openSnackbar(
                  error.data.message || "Unauthorized: Please log in."
                );
              } else {
                openSnackbar(
                  error.data?.message || "An unexpected error occurred."
                );
              }
            } else if (response) {
              if (response.status === 200) {
                openSnackbar(
                  jsonData.simulator_title + " " + response.data.message
                );
              } else {
                openSnackbar("An unexpected error occurred.");
              }
            } else {
              console.log("fsjdfsdfshdgfhsdf sbdfsdf udfgsd ");
            }
          }
        )
      );
    }
  };

  const onCheckSimulatorResult = () => {
    if (selectedOption?.value === 2) {
      if (updateData1.all_district_total_votes !== 0) {
        navigate("/parliament-election-simulator-result", {
          state: {
            updateData1,
            selectedOption: selectedOption1?.label,
          },
        });
      } else {
        alert("No votes have been recorded. Please check the voting data.");
      }
    } else {
      if (updateData.all_district_total_votes !== 0) {
        navigate("/see-exit-poll-result", {
          state: { updateData, selectedOption: selectedOption?.label },
        });
      } else {
        alert("No votes have been recorded. Please check the voting data.");
      }
    }
  };

  const contentTabs = {
    contentTitle:
      selectedOption?.value === 2
        ? "Total provincial council election vote 2024"
        : "Total Parliament Election vote 2024",
    provincialDetailsData: [
      {
        label: "Registered Electors",
        value:
          selectedOption?.value === 2
            ? updateData1?.totalRegisteredVoters
            : updateData.totalRegisteredVoters,
      },
      {
        label: "Total Votes",
        value:
          selectedOption?.value === 2
            ? updateData1?.all_district_total_votes
            : updateData.all_district_total_votes,
      },
      {
        label: "Total Voting Percentage",
        value: calculateVotingPercentage(
          selectedOption?.value === 2
            ? updateData1?.all_district_total_votes
            : updateData.all_district_total_votes,
          selectedOption?.value === 2
            ? updateData1?.totalRegisteredVoters
            : updateData.totalRegisteredVoters
        ),
      },
      {
        label: "Total Districts",
        value:
          selectedOption?.value === 2
            ? updateData1?.totalDistrictsCount
            : updateData.totalDistrictsCount,
      },
      {
        label: "Total Polling Division",
        value:
          selectedOption?.value === 2
            ? updateData1?.totalPollingDivisions
            : updateData.totalPollingDivisions,
      },
      ...(selectedOption?.value === 2
        ? [
          {
            label: "Total Seats",
            value: updateData1?.total_seats,
          },
          {
            label: "National Seats",
            value: updateData1?.total_national_seats,
          },
        ]
        : []),
    ],
    accordianData: selectedOption?.value === 2 ? accordianData1 : accordianData,
  };

  const Accordion = (
    <Accordians
      accordianData={contentTabs.accordianData}
      className="district-accordian"
    />
  );
  const buttons = (
    <div className="btnblk">
      {selectedOption?.value === 2 ? (
        <SolidBlueBtn children="Save" onClick={onSimulatorSave} />
      ) : (
        ""
      )}

      <SolidBlueBtn
        children="Check Simulator Result"
        onClick={onCheckSimulatorResult}
      />
    </div>
  );
  const tabContents = [
    {
      eventKey: "sri-lanka",
      title: (
        <div className="nav-tb-blk">
          <Image src={srilanka} className="nav-tb-img" />
          <span>Sri Lanka</span>
        </div>
      ),
      content: (
        <ContentTab
          contentTabs={contentTabs}
          Accordion={Accordion}
          buttons={buttons}
        />
      ),
    },
  ];

  const aside5 = {
    tabs: <Tabss tabContents={tabContents} />,
  };

  const propsMain = {
    components: {
      component1: <Aside4 {...aside4} />,
      component2: <Aside5 {...aside5} />,
    },
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <div className="simulatorPage">
      <LayOutThree {...propsMain} />
      {isLoading ? (
        <Loader
          color={"#0E76A8"}
          loading={isLoading}
          css={override}
          size={75}
        />
      ) : null}
    </div>
  );
};

export default SimulatorPage;
