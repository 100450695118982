import React from "react";
import OrangeSolidBtn from "../../buttons/orangeSolidBtn";

const ElectionCard = ({
    title,
    electionStartDate,
    electionEndDate,
    pollStartDate,
    pollEndDate,
    onClickExpired,
    children,
    icons,
    images,
    onClick,
    headings,
    paras,
    pe
}) => {
    const today = new Date();
    const pollEndDateObj = new Date(pe);
    const isExpired = pollEndDateObj < today;
    return (
        <div className="election-card card">
            <div className="row g-0">
                <div className="col-md-2">
                    <img src={images.image1.heroImg2} className="img-fluid rounded-start" alt="..." />
                </div>
                <div className="col-md-10">
                    <div className="card-body">
                        <div className="card-title-blk">
                            <h3 className="card-title">{title}</h3>
                            <p className="card-text">
                                {paras.para1}
                            </p>
                        </div>
                        <div className="card-election-dates">
                            <aside className="card-aside">
                                <h4 className="aside-heading">{headings.heading1}</h4>
                                <p className="aside-date"> <span className="aside-icon">{icons.icon1}</span> {electionStartDate} </p>
                            </aside>
                            <aside className="card-aside">
                                <h4 className="aside-heading">{headings.heading2}</h4>
                                <p className="aside-date"><span className="aside-icon">{icons.icon1}</span>{electionEndDate}</p>
                            </aside>
                        </div>
                        <div className="card-poll-dates">
                            <aside className="card-aside">
                                <h4 className="aside-heading">{headings.heading3}</h4>
                                <p className="aside-date"> {pollStartDate} </p>
                            </aside>
                            <aside className="card-aside">
                                <h4 className="aside-heading">{headings.heading4}</h4>
                                <p className="aside-date">{pollEndDate}</p>
                            </aside>
                            <aside className="card-aside">

                                <OrangeSolidBtn children={isExpired ? children.children2 : children.children1}
                                    onClick={isExpired ? onClickExpired : onClick} />
                            </aside>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ElectionCard;
