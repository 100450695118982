import React from "react";
import PropTypes from "prop-types";
import Table from "react-bootstrap/Table";
import Ploader from "../loaders/pLoader";
import { useSelector } from "react-redux";
const override = `
    display: block;
    margin: 0 auto;
  `;
const ManageTable = ({ data = [], error, labels = [] }) => {
  const loading = useSelector((state) => state.adminone.loading);

  return (
    <>
      <Table responsive className="manageTable">
        <thead className="align-middle">
          <tr>
            {labels.map((label) => (
              <td key={label}>{label}</td>
            ))}
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <Ploader
              color={"#0E76A8"}
              override={override}
              loading={loading}
              size={10}
            />
          ) : data.length === 0 ? (
            <tr>
              <td colSpan={labels.length} className="notFoundError">
                <span className="notFoundErrorTxt">Data Not Available</span>
              </td>
            </tr>
          ) : (
            data.map((item, index) => (
              <tr key={item.key || index}>
                {labels.map((label, i) => (
                  <td key={i}>{item[label]}</td>
                ))}
              </tr>
            ))
          )}
        </tbody>
      </Table>
      {loading ? (
        <Ploader
          color={"#0E76A8"}
          override={override}
          loading={loading}
          size={10}
        />
      ) : null}
      {/* {error ? (
        <p>
          <span className="notFoundErrorTxt">Data Not found</span>{" "}
        </p>
      ) : null} */}
    </>
  );
};

ManageTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  error: PropTypes.string,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ManageTable;
