import React, { useEffect } from "react";
import WinnerMain from "./winnerMain";
import {
  getWinnerCalculateVotes,
  filterElectionResults,
} from "../../../redux/user/action";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Loader from "../../../components/loaders/loader";
import { FiShare2 } from "react-icons/fi";
import { calculateVotingPercentage } from "../../../utilities/common";
const override = `
    display: block;
    margin: 0 auto;
    border-color: red;
`;

const WinnerPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const dispatch = useDispatch();
  const location = useLocation();

  const {
    electionTypeId,
    title,
    district_id,
    district_name,
    polling_division_id,
    polling_division_name,
  } = location.state || {};

  useEffect(() => {
    dispatch(getWinnerCalculateVotes());
    dispatch(filterElectionResults(electionTypeId));
  }, [dispatch, electionTypeId]);

  const getWinnerCalculateVote = useSelector(
    (state) => state.user.getWinnerCalculateVotes?.data
  );
  const loading = useSelector((state) => state.user.loading);

  const indexCandidateData = useSelector(
    (state) => state.user.indexCandidateData?.data
  );

  const labels = [
    "No.",
    "Candidate Name",
    "Party Name",
    "1st Priority",
    "2nd Prioprity",
    "3rd Prioprity",
  ];

  const data = Array.isArray(indexCandidateData?.data)
    ? indexCandidateData?.data.map((candidate, index) => ({
        "No.": index + 1,
        "Candidate Name": candidate.candidate_name,
        "Party Name": candidate.party_name,
        "1st Priority": candidate.preference1,
        "2nd Prioprity": candidate.preference2,
        "3rd Prioprity": candidate.preference3,
      }))
    : [];

  const provincialDetailsData = [
    { label: "Total Users", value: indexCandidateData?.total_users_count },
    {
      label: "Total Registred Voters",
      value: indexCandidateData?.total_registered_voters,
    },
    {
      label: "Total Submitted Votes",
      value: indexCandidateData?.total_submitted_votes,
    },
    {
      label: "Total Voting Percentage",
      value: calculateVotingPercentage(
        indexCandidateData?.total_submitted_votes,
        indexCandidateData?.total_registered_voters
      ),
    },
  ];
  const props = {
    electionTypeId,
    title,
    district_id,
    district_name,
    polling_division_id,
    polling_division_name,
    headings: {
      heading1: "Winner",
    },
    locations: {
      location1: `Sri Lanka, ${district_name}, ${polling_division_name}`,
    },
    Childrens: {
      children1: "Result with map view",
      children2: "View detailed result",
      children3: (
        <>
          Share Result <FiShare2 />
        </>
      ),
    },
    getWinnerCalculateVote,
    indexCandidateData,
    provincialDetailsData,
    labels,
    data,
  };
  return (
    <>
      <WinnerMain {...props} />
      {loading ? (
        <Loader color={"#0E76A8"} loading={loading} css={override} size={75} />
      ) : null}
    </>
  );
};

export default WinnerPage;
