import React, { Component } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";

const ParliamentElectionAllianceMain = (props) => {
  const { title, Components, provincialDetailsData } = props;

  return (
    <main className="parlament-alliance-main">
      <Container fluid className="parlament-alliance-container">
        <Row className="parlament-alliance-row">
          <Col className="parlament-alliance-col">
            <p className="parlament-alliance-title">{title}</p>
          </Col>
        </Row>
        <Row className="parlament-alliance-row">
          <Col className="parlament-alliance-col">
            <div className="parlament-ex-voting-blk">
              {provincialDetailsData.map((item, index) => (
                <div className="d-flex right-border" key={index}>
                  <span className={`sqr-box${index}`}></span>
                  <div className="registered-e-div">
                    <h6 className="provincial-vote">{item.value}</h6>
                    <p className="registered-electors mt-1">{item.label}</p>
                  </div>
                </div>
              ))}
            </div>
          </Col>
        </Row>
        <Row className="parlament-alliance-row">
          <Col className="parlament-alliance-col">{Components.Component1}</Col>
          <Col className="parlament-alliance-col"> {Components.Component2}</Col>
        </Row>

        <Row md={2}>
          <Col>{Components.Component3}</Col>
        </Row>
      </Container>
    </main>
  );
};

export default ParliamentElectionAllianceMain;
