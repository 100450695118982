import React, { useState, useEffect, useRef } from "react";

const CustomSelectwithLabel = ({
  options,
  placeholder,
  mainlabel,
  onSelect,
  selectedOption: initialSelectedOption,
}) => {
  const [opened, setOpened] = useState(false);
  const [selectedOption, setSelectedOption] = useState(
    initialSelectedOption || null
  );
  const selectRef = useRef(null);

  const handleClickOutside = (event) => {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      setOpened(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (initialSelectedOption) {
      setSelectedOption(initialSelectedOption);
    }
  }, [initialSelectedOption]);

  const handleSelect = (option) => {
    setSelectedOption(option);
    setOpened(false);
    onSelect(option);
  };

  return (
    <div
      className={`custom-select-label ${opened ? "opened" : ""}`}
      ref={selectRef}
    >
      <span
        className="custom-select-trigger"
        onClick={() => setOpened(!opened)}
      >
        {selectedOption ? selectedOption.label : placeholder}
      </span>
      <label className="customS-label" htmlFor="">
        {mainlabel}
      </label>
      <div className={`custom-options ${opened ? "visible" : ""}`}>
        {options.map((option) => (
          <span
            key={option.value}
            className={`custom-option ${selectedOption === option ? "selection" : ""
              }`}
            onClick={() => handleSelect(option)}
          >
            {option.label}
          </span>
        ))}
      </div>
    </div>
  );
};

export default CustomSelectwithLabel;
