import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

const LayOutThree = (propsMain) => {
    return (
        <main className='main-layOut-three'>
            <Container fluid className='layOut-three-container'>
                <Row className="layOut-three-row">
                    <Col className="layOut-three-col">
                        {propsMain.components.component1}
                    </Col>
                </Row>
                <Row className="layOut-three-row">
                    <Col className="layOut-three-col">
                        {propsMain.components.component2}
                    </Col>
                </Row>
            </Container>
        </main>
    )
}

export default LayOutThree;
