import React from 'react'
import { Col, Row } from 'react-bootstrap'
import DatePickerCustom from './datePicker'

const DateRangePicker = (props) => {
    const { className, label, startName, endName, startValue, endValue, onChangeStart, onChangeEnd, placeholderStart, placeholderEnd } = props
    return (
        <Row className="mb-3">
            <Col
                column
                sm={2}
                className="d-flex justify-content-start align-items-center"
            >
                <label className={className.cselectpageSizeLable}>
                    {label}
                </label>
            </Col>
            <Col column sm={2}>
                <DatePickerCustom name={startName} value={startValue} onChange={onChangeStart}  placeholder={placeholderStart}/>
            </Col>
            <Col sm={2} className="rangeto">To</Col>
            <Col column sm={2}>
                <DatePickerCustom name={endName} value={endValue} onChange={onChangeEnd} placeholder={placeholderEnd} />
            </Col>
        </Row>
    )
}

export default DateRangePicker
