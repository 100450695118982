import React from "react";
// import BlackBtn from "../../../components/buttons/blackBtn";
import BlackBorderBtn from "../../../components/buttons/blackBorderBtn";
import RedBorderBtn from "../../../components/buttons/redBorderBtn";
import TextForm from "../../../components/form/textForm";
import UploadImage from "../../../components/form/uploadImage";
import { v4 as uuidv4 } from "uuid";
import Cselect from "../../../components/select/cselect";
import { Col, Row } from "react-bootstrap";
import DatePickerSingle from "../../../components/datePicker/datePickerSingle";
import TextArea from "../../../components/form/textArea";

const AddCandidateHero = ({
  title1,
  blackbtnChild,
  blackBtnTo,
  blackBtnStyle,
  blackBorderBtnChild,
  blackBorderBtnTo,
  blackBorderBtnStyle,
  redBorderBtnChild,
  redBorderBtnStyle,
  textFormLabel,
  textFormPlaceholder,
  uploadLabel,
  uploadStyle,
  onChange,
  onUpload,
  handleClick,
  handleSaveAndNext,
  candidate_name_value,
  handleCancel,
  options,
  isSearchable,
  cstomStyle,
  className,
  selectChange,

  dateLable1,
  startName1,
  startValue1,
  onChangeStart1,
  placeholderStart1,

  textFormStyle1,
  textFormLabel1,
  textFormPlaceholder1,
  position_value,

  dateLable2,
  startName2,
  startValue2,
  onChangeStart2,
  placeholderStart2,

  dateLable3,
  startName3,
  startValue3,
  onChangeStart3,
  placeholderStart3,

  textFormStyle3,
  textFormLabel3,
  textFormPlaceholder3,
  bio_value,
  image,
  disabled,
  value,
  // onSubmit
}) => {
  const uniqueId1 = uuidv4();
  const uniqueId2 = uuidv4();
  const uniqueId3 = uuidv4();
  return (
    <div className="addpartyBlock">
      <div className="addpartyBlockChild">
        <h2 className="addpartyBlockTitle">{title1}</h2>
        <div className="">
          {/* {!disabled && (
            <BlackBtn
              children1={blackbtnChild}
              to1={blackBtnTo}
              style1={blackBtnStyle}
              onClick={handleSaveAndNext}
            />
          )} */}
          <BlackBorderBtn
            children1={blackBorderBtnChild}
            to1={blackBorderBtnTo}
            style1={blackBorderBtnStyle}
            onClick={handleClick}
          />
          <RedBorderBtn
            children1={redBorderBtnChild}
            onClick={handleCancel}
            style1={redBorderBtnStyle}
          />
        </div>
      </div>
      <div className="mt-5">
        <TextForm
          formLabel={textFormLabel}
          placeholder={textFormPlaceholder}
          name="candidate_name"
          value={candidate_name_value}
          onChange={onChange}
          uniqueId={uniqueId1}
          Lsm={2}
          Ism={6}
        />
        <Row className="mt-5">
          <Col
            sm={2}
            className="d-flex justify-content-start align-items-center"
          >
            <label className={className.cselectpageSizeLable}>
              Associate Party Name
            </label>
          </Col>
          <Col sm={6}>
            <Cselect
              options={options}
              isSearchable={isSearchable}
              className={className.cselectpageSize}
              styles={cstomStyle}
              onChange={selectChange}
              value={value}
            />
          </Col>
        </Row>
        <DatePickerSingle
          label={dateLable1}
          startName={startName1}
          startValue={startValue1}
          onChangeStart={onChangeStart1}
          className={className}
          placeholderStart={placeholderStart1}
        />
        <TextForm
          style={textFormStyle1}
          formLabel={textFormLabel1}
          placeholder={textFormPlaceholder1}
          name="position"
          value={position_value}
          onChange={onChange}
          uniqueId={uniqueId2}
          Lsm={2}
          Ism={6}
        />
        <DatePickerSingle
          label={dateLable3}
          startName={startName3}
          startValue={startValue3}
          onChangeStart={onChangeStart3}
          className={className}
          placeholderStart={placeholderStart3}
        />
        <DatePickerSingle
          label={dateLable2}
          startName={startName2}
          startValue={startValue2}
          onChangeStart={onChangeStart2}
          className={className}
          placeholderStart={placeholderStart2}
        />
        <TextArea
          style={textFormStyle3}
          formLabel={textFormLabel3}
          placeholder={textFormPlaceholder3}
          name="bio"
          value={bio_value}
          onChange={onChange}
          uniqueId={uniqueId3}
        />
        <UploadImage
          label={uploadLabel}
          style={uploadStyle}
          onUpload={onUpload}
          initialImageUrl={image}
        />
      </div>
    </div>
  );
};

export default AddCandidateHero;
