import React, { useEffect, useRef, useState } from "react";
import OrangeSolidBtn from "../../buttons/orangeSolidBtn";
import { Carousel, Image } from "react-bootstrap";

const SectionHero = (props) => {
  const { titles, images, children, onClick } = props;

  const titleList = useRef([
    "Empowering Democracy for All",
    "Your Voice, Your Future",
    "Innovating Democratic Participation",
    "Uniting Citizens for Change",
    "Building a Stronger Democracy Together",
  ]);

  const [currentTitleIndex, setCurrentTitleIndex] = useState(0);
  const [currentText, setCurrentText] = useState("");
  const [letterIndex, setLetterIndex] = useState(0);
  const [isRemoving, setIsRemoving] = useState(false);

  useEffect(() => {
    const currentTitle = titleList.current[currentTitleIndex];

    if (!isRemoving) {
      if (letterIndex < currentTitle.length) {
        const timeoutId = setTimeout(() => {
          setCurrentText((prev) => prev + currentTitle[letterIndex]);
          setLetterIndex(letterIndex + 1);
        }, 100);
        return () => clearTimeout(timeoutId);
      } else {
        const removeTimeout = setTimeout(() => {
          setIsRemoving(true);
        }, 2000);
        return () => clearTimeout(removeTimeout);
      }
    } else {
      if (letterIndex > 0) {
        const timeoutId = setTimeout(() => {
          setCurrentText((prev) => prev.slice(0, -1));
          setLetterIndex(letterIndex - 1);
        }, 100);
        return () => clearTimeout(timeoutId);
      } else {
        setIsRemoving(false);
        setCurrentTitleIndex(
          (prevIndex) => (prevIndex + 1) % titleList.current.length
        );
      }
    }
  }, [letterIndex, isRemoving, currentTitleIndex]);

  return (
    <section className="section-landing">
      <aside className="section-aside">
        <h2 className="section-heading">{titles.title1}</h2>
        <h1 className="section-heading">{currentText}</h1>
        <div className="section-btn-grp">
          <OrangeSolidBtn
            children={children.children7}
            onClick={onClick.handleNavigate}
          />
          <OrangeSolidBtn
            children={children.children8}
            onClick={onClick.handleNavigate2}
          />
        </div>
        <div className="section-btn-grp2 mt-4">
          <OrangeSolidBtn
            children={children.children1}
            onClick={onClick.handleNavigate}
          />
          <OrangeSolidBtn
            children={children.children6}
            onClick={onClick.handleNavigate2}
          />
        </div>

        {/* <Link to="/simulator" className="text-decoration-none">
            <Button
              onClick={handleClick}
              className="d-flex justify-content-between align-items-center w-100 vote-simulator-btn"
            >
              <aside className="d-flex align-items-center">
                <MdOutlineHowToVote className="vote-icon-3" />
                <span className="ps-2">Vote Simulator</span>
              </aside>
              <aside>
                <MdKeyboardDoubleArrowRight className="swipe-arrow" />
              </aside>
            </Button>
          </Link> */}
      </aside>
      <aside className="section-aside">
        {/* <Image src={images.image1.heroImg} fluid className='section-img' /> */}
        <Carousel controls={false} indicators={true}>
          <Carousel.Item interval={1500}>
            <Image src={images.image1.heroImg} fluid className="section-img" />
          </Carousel.Item>
          <Carousel.Item interval={1500}>
            <Image src={images.image1.heroImg} fluid className="section-img" />
          </Carousel.Item>
          <Carousel.Item interval={1500}>
            <Image src={images.image1.heroImg} fluid className="section-img" />
          </Carousel.Item>
        </Carousel>
      </aside>
    </section>
  );
};

export default SectionHero;
