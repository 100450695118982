// ReusableCheckbox.js
import React from 'react';
import { Form } from 'react-bootstrap';

const Checkbox = ({ label, checked, onChange }) => {
    return (
        <Form.Check
            type='checkbox'
            label={label}
            checked={checked}
            onChange={onChange}
            className='chebx'
        />
    );
};

export default Checkbox;
