import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";

const TextForm = ({
  placeholder,
  formLabel,
  style,
  onChange,
  name,
  value,
  uniqueId,
  error,
  disabled,
  Lsm,
  Ism
}) => {
  return (
    <Container fluid className="px-0">
      <Form.Group
        as={Row}
        className="mb-5 d-flex align-items-center textForm"
        style={style}
      >
        <Form.Label htmlFor={uniqueId} className="textformlabel" column sm={Lsm}>
          {formLabel}
        </Form.Label>
        <Col sm={Ism}>
          <Form.Control
            className="textformtext"
            type="text"
            placeholder={placeholder}
            onChange={onChange}
            name={name}
            value={value}
            id={uniqueId}
            disabled={disabled}
          />
          {error && (
            <Form.Control.Feedback type="invalid">
              {error}
            </Form.Control.Feedback>
          )}
        </Col>
      </Form.Group>
    </Container>
  );
};

export default TextForm;
