import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import HtmlEditor from "./htmlEditor";

const HtmlEditorWithoutBtn = (props) => {
  const { label, value, onChange } = props;
  return (
    <Container fluid className="px-0 mb-5">
      <Row className="htmlEditiorRow">
        <Col sm={2}>
          <label htmlFor="" className="htmlEditiorLable">
            {label}
          </label>
        </Col>
        <Col sm={10} className="htmlEditiorCol">
          <HtmlEditor initialValue={value} onChange={onChange} />
        </Col>
      </Row>
    </Container>
  );
};

export default HtmlEditorWithoutBtn;
