import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Active from "../../../components/labels/active";
import InActive from "../../../components/labels/inActive";
// import { UserDetailsById, UserList } from '../../../redux/admin/action';
import {
    showUserDetailsById,
    indexGetAllUser,
} from "../../../redux/adminone/action";
import DropDown from "../../../components/dropdown/dropDown";
import ManageBlk from "../../../components/admin/manageBlk";
import DeleteCModal from "../../../components/modals/deleteCModal";
import Check from "../../../components/labels/check";
import Cancel from "../../../components/labels/cancel";
import { useNavigate } from "react-router-dom";

const options = [
    { value: 10, label: 10 },
    { value: 20, label: 20 },
    { value: 30, label: 30 },
];

const cstomStyle = {
    menu: (provided) => ({
        ...provided,
        backgroundColor: "white",
        color: "#65676B",
        maxWidth: "97px",
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused ? "#efefef" : "white",
        color: state.isFocused ? "#65676B" : "#65676B",
        padding: 5,
    }),
};

const ManageUserList = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(indexGetAllUser());
    }, [dispatch]);
    const [showModal, setShowModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const navigate = useNavigate();
    const indexGetAllUserData = useSelector(
        (state) => state.adminone.indexGetAllUserData?.data
    );
    const error = useSelector((state) => state.adminone.error);

    const statusMap = {
        0: <InActive />,
        1: <Active />,
    };

    const activeStatusMap = {
        0: <Cancel />,
        1: <Check />,
    };

    const dropdownItems = (data) => [
        { label: "Edit", href: "#/edit", onClick: () => handleEditUser(data) },
        {
            label: "Delete",
            href: "#/delete",

            onClick: () => {
                handleOpenModal(data);
            },
        },
    ];
    const handleOpenModal = (data) => {
        setSelectedUser(data);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedUser(null);
    };
    const handleEditUser = (data) => {
        dispatch(showUserDetailsById(data.id));

        navigate("/edit-user-by-id", {
            state: {
                id: data.id,
                username: data.username,
                status: data.status
            }
        });


        // navigate(
        //     `/edit-user-by-id?id=${data.id}&username=${encodeURIComponent(
        //         data.username
        //     )}&status=${data.status}`
        // );
    };

    const handleConfirmDelete = () => {
        if (selectedUser) {
            dispatch(indexGetAllUser());
            handleCloseModal();
        }
    };

    const labels = [
        "User Name",
        "User ID",
        "Mobile Number",
        "Email Address",
        "Voter Id",
        "District Name",
        "Polling Division",
        "Province Name",
        "Status",
        "Action",
    ];

    const data = Array.isArray(indexGetAllUserData)
        ? indexGetAllUserData.map((userList) => ({
            "User Name": userList.username,
            "User ID": userList.username,
            "Mobile Number": (
                <>
                    {userList.mobileNumber}{" "}
                    {activeStatusMap[userList.isMobileNumberverified]}
                </>
            ),
            "Email Address": (
                <>
                    {userList.email} {activeStatusMap[userList.isEmailverified]}
                </>
            ),
            "Voter Id": userList.voter_id,
            "District Name": userList.district_name,
            "Polling Division": userList.polling_divisions,
            "Province Name": userList.province_name,
            Status: statusMap[userList.activated],
            Action: (
                <DropDown
                    Children={"Action"}
                    items={dropdownItems(userList)}
                    className="userListAction ms-auto"
                />
            ),
        }))
        : [];

    const props = {
        title: "User List",
        children: "Add User",
        style: { padding: "1.3rem 2.8rem" },
        options,
        // isSearchable,
        className: {
            cselectpageSize: "cselectpageSize",
            minputSearch: "minputSearch",
            deleteModal: "deleteModal",
        },
        cstomStyle,
        Placeholder: "Search",
        labels,
        data,
        error,
    };

    return (
        <main className="manageUserList">
            <aside className="manageUserListaside">
                <ManageBlk {...props} />
            </aside>
            <DeleteCModal
                show={showModal}
                handleClose={handleCloseModal}
                handleConfirm={handleConfirmDelete}
                className={props.className.deleteModal}
            />
        </main>
    );
};

export default ManageUserList;
