import React from "react";
import BlackBtn from "../buttons/blackBtn";
import BlackBorderBtn from "../buttons/blackBorderBtn";
import RedBorderBtn from "../buttons/redBorderBtn";
import Checkbox from "../inputs/checkBox";
import { Col, Row } from "react-bootstrap";
import CMultiSelect from "../select/cMultiSelect";


const PostBlockThree = (props) => {
    const {

        title,
        saveNextBtn,
        addSeqBtnstyle,
        save,
        cancel,

        handleSave,
        handleCancel,

        className,
        selectLable1,
        options1,
        selectedValue1,
        onSelect1,
        onRemove1,
        name1,
        disable,
        selectLable2,
        options2,
        selectedValue2,
        onSelect2,
        onRemove2,
        name2,
        label,
        handleCheck
    } = props;
    return (
        <div className="PostBlock-three">
            <div className="PostBlockChild">
                <h2 className="PostBlockTitle">{title}</h2>
                <div className="">
                    <BlackBorderBtn
                        children1={save}
                        style1={addSeqBtnstyle}
                        onClick={handleSave}
                    />
                    <RedBorderBtn
                        children1={cancel}
                        style1={addSeqBtnstyle}
                        onClick={handleCancel}
                    />
                </div>
            </div>
            <div className="mt-5">
                <Row className="mb-3">
                    <Col
                        column
                        sm={2}
                        className="d-flex justify-content-start align-items-center"
                    >
                        <label className={className.cselectpageSizeLable}>
                            {selectLable1}
                        </label>
                    </Col>
                    <Col column sm={6}>
                        <CMultiSelect
                            options={options1}
                            selectedValue={selectedValue1}
                            onSelect={onSelect1}
                            onRemove={onRemove1}

                            disable={disable}
                        />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col
                        column
                        sm={2}
                        className="d-flex justify-content-start align-items-center"
                    >
                        <label className={className.cselectpageSizeLable}>
                            {selectLable2}
                        </label>
                    </Col>
                    <Col column sm={6}>
                        <CMultiSelect
                            options={options2}
                            selectedValue={selectedValue2}
                            onSelect={onSelect2}
                            onRemove={onRemove2}

                            disable={disable}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={{ span: 3, offset: 2 }}>
                        <Checkbox
                            label={label}
                            onChange={handleCheck}
                        />
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default PostBlockThree;
