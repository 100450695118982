import React from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import { IoIosArrowDown } from "react-icons/io";
const DropDown = ({ Children, items, className }) => {
    return (
        <Dropdown className={className} >
            <Dropdown.Toggle id="dropdown-basic">
                {Children} <IoIosArrowDown />
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {items.map((item, index) => (
                    <Dropdown.Item key={index} href={item.href} onClick={item.onClick ? (e) => {
                        e.preventDefault();

                        item.onClick();
                    } : null}>
                        {item.label}
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default DropDown;
