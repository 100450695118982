import React, { useEffect, useState } from "react";
import ManageBlk from "../../../components/admin/manageBlk";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../components/loaders/loader";
import DropDown from "../../../components/dropdown/dropDown";

import { indexPermission } from "../../../redux/adminone/action";
import { useNavigate } from "react-router-dom";
const override = `
    display: block;
    margin: 0 auto;
    border-color: red;
`;
const options = [
  { value: 10, label: 10 },
  { value: 20, label: 20 },
  { value: 30, label: 30 },
];

const cstomStyle = {
  menu: (provided) => ({
    ...provided,
    backgroundColor: "white",
    color: "#65676B",
    maxWidth: "97px",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#efefef" : "white",
    color: state.isFocused ? "#65676B" : "#65676B",
    padding: 5,
  }),
};

const Permissions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(indexPermission());
  }, [dispatch]);

  const [isSearchable] = useState(false);

  const loading = useSelector((state) => state.adminone.loading);
  const indexPermissionData = useSelector((state) => state.adminone.indexPermissionData?.data);
  const error = useSelector((state) => state.adminone.error);

  const dropdownItems = (data) => [
    { label: "Edit", href: "#/edit", onClick: () => handleEdit(data), },

    // { label: "Manage Permission", href: "#/edit" },
  ];
  const handleEdit = (data) => {

    navigate("/edit-permission", {
      state: {
        id: data.id,
        name: data.name,
      }
    });

    // navigate(
    //   `/edit-permission?id=${data.id}&name=${encodeURIComponent(
    //     data.name
    //   )}`
    // );
  };




  const labels = ["Permission", "Action"];
  const data = Array.isArray(indexPermissionData)
    ? indexPermissionData.map((permission) => ({
      Permission: permission.name,
      Action: (
        <DropDown
          className="permisstionAction ms-auto"
          Children={"Action"}
          items={dropdownItems(permission)}
        />
      ),
    }))
    : [];
  const permissionProps = {
    title: "Permissions",
    children: "Add Permission",
    to: "/add-permission",
    style: { padding: "1.3rem 2.8rem" },
    options,
    isSearchable,
    className: {
      cselectpageSize: "cselectpageSize",
      minputSearch: "minputSearch",
      deleteModal: "deleteModal",
    },
    cstomStyle,
    Placeholder: "Search",

    labels,
    data,
    error
  };
  return (
    <main className="permission">
      <aside className="permissionaside">
        <ManageBlk {...permissionProps} />
      </aside>


      {loading ? (
        <Loader color={"#0E76A8"} loading={loading} css={override} size={75} />
      ) : null}
    </main>
  );
};

export default Permissions;
