import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../../components/loaders/loader";
import { useSnackbar } from "react-simple-snackbar";
import {
  addBlogCategory,
  showBlogCategoryById,
  updateBlogCategoryById,
} from "../../../redux/adminone/action";
import PostBlockWithBtn from "../../../components/admin/postBlockWithBtn";

const override = `
    display: block;
    margin: 0 auto;
    border-color: red;
`;

// const cstomStyle = {
//   menu: (provided) => ({
//     ...provided,
//     backgroundColor: "white",
//     color: "#65676B",
//     Width: "fit-content",
//   }),
//   option: (provided, state) => ({
//     ...provided,
//     backgroundColor: state.isFocused ? "#efefef" : "white",
//     color: state.isFocused ? "#65676B" : "#65676B",
//     padding: 5,
//   }),
// };

const AddBlogCategory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector((state) => state.adminone.loading);
  const location = useLocation();
  const { id, username } = location.state || {};
  // const params = new URLSearchParams(location.search);
  // const id = params.get("id");
  const [openSnackbar, closeSnackbar] = useSnackbar();
  useEffect(() => {
    if (id) {
      dispatch(showBlogCategoryById(id));
    }
  }, [dispatch, id]);
  const showBlogCategoryByIdData = useSelector(
    (state) => state.adminone.showBlogCategoryByIdData?.data
  );

  const [formData, setFormData] = useState({
    category_name: "",
    is_active: "",
  });

  const [errors, setErrors] = useState({
    category_name: "",
    is_active: "",
  });
  useEffect(() => {
    if (id && showBlogCategoryByIdData) {
      setFormData({
        category_name: showBlogCategoryByIdData.category_name ?? null,
        is_active: showBlogCategoryByIdData.is_active ?? null,
      });
    }
  }, [id, showBlogCategoryByIdData]);

  const handleToggle = () => {
    setFormData((prevState) => ({
      ...prevState,
      is_active: prevState.is_active === 1 ? 0 : 1,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: "",
    });
  };
  const textInput = [
    {
      label: "Category Name",
      name: "category_name",
      value: formData.category_name,
      error: errors.category_name,
    },
  ];

  const handleSave = async (event) => {
    event.preventDefault();
    const { category_name } = formData;

    let valid = true;
    if (!category_name || category_name.length < 2) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        category_name: "Enter Category Name",
      }));
      valid = false;
    }

    if (!valid) return;

    const formDataCategory = new FormData();
    formDataCategory.append("category_name", formData.category_name);
    formDataCategory.append("is_active", formData.is_active);

    if (id) {
      dispatch(
        updateBlogCategoryById(formDataCategory, id, (error, response) => {
          if (error) {
            // handle the error
          } else {
            // handle the response

            if (response.status === 200) {
              openSnackbar(response.data.message);
              navigate("/manage-blog-category");
            }
          }
        })
      );
    } else {
      dispatch(
        addBlogCategory(formDataCategory, (error, response) => {
          if (error) {
            openSnackbar("Error occurred while adding party");
          } else {
            if (response.status === 200) {
              openSnackbar(response.data.message);
              navigate("/manage-blog-category");
            }
          }
        })
      );
    }
    navigate(-1);
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const props = {
    title: id ? "Edit Blog Category" : "Add Blog Category",
    addSeqBtnstyle: { padding: "1.3rem 2.8rem", marginLeft: "1.5rem" },
    save: "save",
    cancel: "cancel",
    textInput,
    formLabel: "Status",
    handleChange,
    handleSave,
    handleCancel,
    handleToggle,
    formData,
  };

  return (
    <main className="aDistrict">
      <aside className="addbock">
        <PostBlockWithBtn {...props} />
      </aside>
      {loading ? (
        <Loader color={"#0E76A8"} loading={loading} css={override} size={75} />
      ) : null}
    </main>
  );
};

export default AddBlogCategory;
