import React from 'react'
import ManageBlkFive from '../../../components/admin/manageBlkFive'

const PollResultsMain = (props) => {
    return (
        <mian className="poll-results-main">
            <ManageBlkFive {...props} />
        </mian>
    )
}

export default PollResultsMain
