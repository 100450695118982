import React from "react";
import { Button } from "react-bootstrap";

const RedBorderBtn = ({ children1, style1, onClick }) => {
  return (
    <Button className="linkRedBorderbtn" style={style1} onClick={onClick}>
      {children1}
    </Button>
  );
};

export default RedBorderBtn;
