import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const ReportsPage = (props) => {
  const { titles, sections } = props;
  return (
    <section className="section-reportsPage">
      <Container fluid>
        <h2 className="section-heading">{titles.title1}</h2>
        <Row lg={4} md={3} sm={1} xs={1}>
          {sections.map((section, index) => (
            <Col key={index} className="mb-5 px-0 section-block">
              <Link className="text-decoration-none" to={section.link}>
                <aside className="section-aside">
                  <div className="sa-imgblk">
                    <Image src={section.icon} fluid />
                  </div>
                  <h4 className="aside-heading">{section.heading}</h4>
                  <p className="aside-para">{section.para}</p>
                </aside>
              </Link>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default ReportsPage;
