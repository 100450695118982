import React from "react";
import BlackBorderBtn from "../buttons/blackBorderBtn";
import RedBorderBtn from "../buttons/redBorderBtn";
import TextForm from "../form/textForm";
import UploadImage from "../form/uploadImage";
import { Col, Container, Row } from "react-bootstrap";
import Cselect from "../select/cselect";
import HtmlEditorWithoutBtn from "../editor/htmlEditorWithoutBtn";

const PostBlockSeven = ({
  title,
  addSeqBtnstyle,
  save,
  handleSave,
  cancel,
  handleCancel,
  blockLabel,
  blockName,
  blockValue,
  blockPlaceholder,
  blockError,
  blockDisabled,
  handleChange,
  handleChanges,
  textFormLabel,
  textFormPlaceholder,
  // onChange,
  overviewValue,
  overviewName,
  uploadLabel,
  uploadStyle,
  onUpload,
  map_image,
  blocks,
  blockId,
  selectLabel,
  className,
  options,
  isSearchable,
  cstomStyle,
  selectChange,
  value,
  uniqueId1,
  uniqueId2,
  handleToggle,
  formData,
  formLabel,
}) => {
  return (
    <div className="postBlock-five">
      <div className="PostBlockChild">
        <h2 className="PostBlockTitle">{title}</h2>
        <div className="">
          <BlackBorderBtn
            children1={save}
            style1={addSeqBtnstyle}
            onClick={handleSave}
          />
          <RedBorderBtn
            children1={cancel}
            style1={addSeqBtnstyle}
            onClick={handleCancel}
          />
        </div>
      </div>
      <div id={blockId !== "null" ? blockId : "null"}>
        <Container fluid className="px-0 mb-5">
          <Row className="mt-5">
            <Col
              sm={2}
              className="d-flex justify-content-start align-items-center"
            >
              <label className={className.cselectpageSizeLable}>
                {selectLabel}
              </label>
            </Col>
            <Col sm={6}>
              <Cselect
                options={options}
                isSearchable={isSearchable}
                className={className.cselectpageSize}
                styles={cstomStyle}
                onChange={selectChange}
                value={value}
              />
            </Col>
          </Row>
        </Container>
        <TextForm
          formLabel={blockLabel}
          placeholder={blockPlaceholder}
          name={blockName}
          value={blockValue}
          onChange={handleChange}
          error={blockError}
          disabled={blockDisabled}
          uniqueId={uniqueId2}
          Lsm={2}
          Ism={6}
        />

        <TextForm
          formLabel={textFormLabel}
          placeholder={textFormPlaceholder}
          name={overviewName}
          value={overviewValue}
          onChange={handleChange}
          uniqueId={uniqueId1}
          Lsm={2}
          Ism={6}
        />
        <HtmlEditorWithoutBtn
          label="Content"
          value={blocks}
          onChange={handleChanges}
        />
        <UploadImage
          label={uploadLabel}
          style={uploadStyle}
          onUpload={onUpload}
          initialImageUrl={map_image}
        />
        <Container fluid className="my-5 px-0">
          <Row className="row-sub-profile d-flex align-items-center textForm">
            <Col>
              <label
                htmlFor="labelForButton"
                className="textformlabel"
                column
                sm={2}
              >
                {formLabel}
              </label>
            </Col>
            <Col md={10} className="col-sub-profile">
              <div className="form-check form-switch activeSwitch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="flexSwitchCheckChecked"
                  checked={formData.isactive === 1}
                  onChange={handleToggle}
                />
                <label
                  className="form-check-label"
                  htmlFor="flexSwitchCheckChecked"
                >
                  {formData.isactive === 1 ? "Active" : "In Active"}
                </label>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default PostBlockSeven;
