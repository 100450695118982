import React, { useState } from "react";
import Image from "react-bootstrap/Image";
import { Container } from "react-bootstrap";
import Logo from "../../../assets/images/logo.png";
import Form from "react-bootstrap/Form";
import SolidBlueBtn from "../../../components/buttons/solidBlueBtn";
import { useDispatch, useSelector } from "react-redux";
import CustomTooltip from "../../../components/tooltips/ctoolTip";
import { FloatingLabel } from "react-bootstrap";
import { toast } from "react-toastify";
import { forgotPasswordSendEmail } from "../../../redux/user/action";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/loaders/loader";
const override = `
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

const EsendOtp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const [credentials, setCredentials] = useState({ email: "" });
  const loading = useSelector((state) => state.user.loading);
  const handleChange = (event) => {
    event.preventDefault();

    const { name, value } = event.target;

    setCredentials({
      ...credentials,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    const { email } = credentials;

    if (/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/.test(email)) {
    } else {
      toast.error("Invalid email");
      return;
    }
    const formData = new FormData();
    formData.append("email", email);
    dispatch(
      forgotPasswordSendEmail(formData, (error, response) => {
        if (error && error.status) {
          toast.error(error.data.message, {
            position: "top-center",
            style: {
              fontSize: "1.6rem",
            },
          });
        } else {
          if (response && response.status === 200) {
            toast.success(response.message, {
              position: "top-center",
              style: {
                fontSize: "1.6rem",
              },
            });
          }
        }
      })
    );
  };
  return (
    <main className="main-auth">
      <Container fluid="xxl">
        <div className="esoMain">
          <figure>
            <Image src={Logo} fluid />
          </figure>
          <div className="authLastBlk">
            <h2 className="authHead">Email Address</h2>
            <p className="authPara">Please enter your email address</p>
            <Form
              noValidate
              validated={validated}
              onSubmit={handleSubmit}
              className="emailSendOtp"
            >
              <CustomTooltip text="The user's email address.">
                <FloatingLabel
                  controlId="idEmail"
                  label="Email"
                  className={
                    validated
                      ? credentials.email
                        ? "is-valid"
                        : "is-invalid"
                      : ""
                  }
                >
                  <Form.Control
                    required
                    type="email"
                    placeholder="Enter your email"
                    name="email"
                    value={credentials.email}
                    onChange={handleChange}
                    isValid={validated && credentials.email}
                    isInvalid={
                      validated &&
                      (!credentials.email ||
                        credentials.email.trim().length === 0 ||
                        !/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/.test(
                          credentials.email
                        ))
                    }
                  />
                </FloatingLabel>
              </CustomTooltip>

              <SolidBlueBtn
                type="submit"
                style={{
                  padding: "1.3rem 6.8rem",
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginTop: "7.9rem",
                }}
              >
                Send
              </SolidBlueBtn>
              {/* {loading ? (
                <Loader
                  color={"#0E76A8"}
                  loading={loading}
                  css={override}
                  size={75}
                />
              ) : null} */}
            </Form>
          </div>
        </div>
      </Container>
      {loading ? (
        <Loader color={"#0E76A8"} loading={loading} css={override} size={75} />
      ) : null}
    </main>
  );
};

export default EsendOtp;
