import React from "react";
// import OrangeSolidBtn from "../../buttons/orangeSolidBtn";
import { Image } from "react-bootstrap";
import WhiteBtnWithBorder from "../../buttons/whiteBtnWithBorder";

const SectionVoterEdu = (props) => {
  const { paras, children, titles, icons } = props;
  return (
    <section className="section-landing">
      <aside className="section-aside">
        <div className="eduvoter-block">
          {/* <div className="eduvoterimgblk">
                        <Image src={images.image4.heroImg3} className="userside-img" />
                    </div> */}
          <h2 className="eduvotertitle">{titles.title8}</h2>
          {/* <Image className="userside-img" /> */}
          <Image src={icons.icons8} className="eduvoterwavyline" />
          <p className="eduvoterpara">{paras.para9}</p>
          {/* <OrangeSolidBtn children={children.children3} /> */}
          <WhiteBtnWithBorder
            type="submit"
            style={{ width: "calc(100% - 85%)", padding: "1.3rem" }}
          >
            {children.children3}
          </WhiteBtnWithBorder>
        </div>
      </aside>
    </section>
  );
};

export default SectionVoterEdu;
