import React from 'react';
import { IoIosArrowDown } from 'react-icons/io';
// import { Link } from 'react-router-dom';

const Aside1 = (aside) => {
  return (
    <section className='asideone'>
      <ul className="nav flex-column">
        {aside.histories?.map((history) => (
          <li className="nav-item" key={history.id}>
            <div className="nav-link active" aria-current="page" onClick={() => aside.scrollToArticle(history.id)}><div><IoIosArrowDown className='arw-history' /></div> <div>{history.title}</div>  </div>
          </li>
        ))}
      </ul>
    </section>
  )
}

export default Aside1;
