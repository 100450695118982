import React from "react";
// import BlackBtn from "../buttons/blackBtn";
import BlackBorderBtn from "../buttons/blackBorderBtn";
import RedBorderBtn from "../buttons/redBorderBtn";
import TextForm from "../form/textForm";
import Cselect from "../select/cselect";
import { Col, Row } from "react-bootstrap";
import DateRangePicker from "../datePicker/dateRangePicker";

const PostBlockTwo = (props) => {
    const {
        selectChange,
        title,
        // saveNextBtn,
        addSeqBtnstyle,
        save,
        cancel,
        textInput,
        handleChange,
        // handleSaveAndNext,
        handleSave,
        handleCancel,
        options,
        isSearchable,
        cstomStyle,
        className,
        selectLable,
        dateLable1,
        startName1,
        startValue1,
        onChangeStart1,
        endName1,
        endValue1,
        onChangeEnd1,
        placeholderStart1,
        placeholderEnd1,
        dateLable2,
        startName2,
        startValue2,
        onChangeStart2,
        endName2,
        endValue2,
        onChangeEnd2,
        placeholderStart2,
        placeholderEnd2,
        value
    } = props;
    return (
        <div>
            <div className="PostBlock">
                <div className="PostBlockChild">
                    <h2 className="PostBlockTitle">{title}</h2>
                    <div className="">
                        {/* <BlackBtn
                            children1={saveNextBtn}
                            style1={addSeqBtnstyle}
                            onClick={handleSaveAndNext}
                        /> */}
                        <BlackBorderBtn
                            children1={save}
                            style1={addSeqBtnstyle}
                            onClick={handleSave}
                        />
                        <RedBorderBtn
                            children1={cancel}
                            style1={addSeqBtnstyle}
                            onClick={handleCancel}
                        />
                    </div>
                </div>
                <div className="mt-5">
                    <Row className="mb-3">
                        <Col
                            column
                            sm={2}
                            className="d-flex justify-content-start align-items-center"
                        >
                            <label className={className.cselectpageSizeLable}>
                                {selectLable}
                            </label>
                        </Col>
                        <Col column sm={6}>
                            <Cselect
                                options={options}
                                isSearchable={isSearchable}
                                className={className.cselectpageSize}
                                styles={cstomStyle}
                                onChange={selectChange}
                                value={value}
                            />
                        </Col>
                    </Row>
                    {textInput.map((input, index) => (
                        <TextForm
                            key={index}
                            formLabel={input.label}
                            name={input.name}
                            value={input.value}
                            placeholder={input.placeholder}
                            onChange={handleChange}
                            error={input.error}
                            Lsm={2}
                            Ism={6}
                        />
                    ))}
                    <DateRangePicker
                        label={dateLable1}
                        startName={startName1}
                        startValue={startValue1}
                        onChangeStart={onChangeStart1}
                        endName={endName1}
                        endValue={endValue1}
                        onChangeEnd={onChangeEnd1}
                        className={className}
                        placeholderStart={placeholderStart1}
                        placeholderEnd={placeholderEnd1}
                    />
                    <DateRangePicker
                        label={dateLable2}
                        startName={startName2}
                        startValue={startValue2}
                        onChangeStart={onChangeStart2}
                        endName={endName2}
                        endValue={endValue2}
                        onChangeEnd={onChangeEnd2}
                        className={className}
                        placeholderStart={placeholderStart2}
                        placeholderEnd={placeholderEnd2}
                    />
                </div>
            </div>
        </div>
    );
};

export default PostBlockTwo;
