import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FaLocationArrow } from "react-icons/fa";
import { GoArrowUpRight } from "react-icons/go";
import ManageTable from "../../../components/table/manageTable";
import CustomChart from "../../../components/charts/customChart";
import SolidBlueBtn from "../../../components/buttons/solidBlueBtn";
import IImage from "../../../components/image/iImage";

const ParliamentSimulatorResultMain = (props) => {
    const {
        selectedOption,
        locationDistrict,
        locationPollingDivision,
        provincialDetailsData,
        labels,
        data,
        leadingParty,
        partySeatsOptions,
        parliamentChartOptions,
        parliamentpieOptions,
        partyDistrictDataOptions,
        childrens,
        handleClick,
        components,
        isChecked,
        handleSwitchChange,
    } = props;
    return (
        <main className="parlament-ex-main">
            <Container fluid className="parlament-ex-container">
                <Row className="parlament-ex-row">
                    <Col className="parlament-ex-col">
                        <div>
                            <h2 className="parlament-ex-heading">
                                {selectedOption}{" "}
                                <span className="parlament-ex-heading-lbl">(Simulator)</span>
                            </h2>
                            <p className="parlament-ex-location">
                                Sri Lanka ,{locationDistrict}, {locationPollingDivision}{" "}
                                <FaLocationArrow className="location-i" />
                            </p>
                        </div>
                    </Col>
                    <Col className="parlament-ex-col" md={4}>
                        <div className="parlament-ex-subBlk">
                            <div className="mpLogo">
                                <IImage
                                    src={leadingParty.Logo.props.children.props.src}
                                    className="mpLogoImg"
                                />
                            </div>
                            <div className="svsrp-details">
                                <h4 className="svsrp-deta-head">
                                    <span className="text-decoration-underline">
                                        {leadingParty.Name || "N/A"}
                                    </span>
                                    <span>
                                        <GoArrowUpRight className="svsrp-arw" />
                                    </span>
                                </h4>
                                <div className="svsrp-deta-votes">
                                    Leading Party Votes - {leadingParty.Votes}
                                </div>
                                <div className="svsrp-deta-votes">
                                    Leading Seats - {leadingParty.Seats}
                                </div>
                                <div className="svsrp-deta-votes">
                                    National Seats - {leadingParty["National Seats"]}
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="parlament-ex-row">
                    <Col className="parlament-ex-col">
                        <section className="parlament-ex-section">
                            <h5 className="parlament-ex-voting-heading">
                                Simulator voting details
                            </h5>
                            <div className="parlament-ex-voting-blk">
                                {provincialDetailsData.map((item, index) => (
                                    <div className="d-flex right-border" key={index}>
                                        <span className={`sqr-box${index}`}></span>
                                        <div className="registered-e-div">
                                            <h6 className="provincial-vote">{item.value}</h6>
                                            <p className="registered-electors mt-1">{item.label}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </section>
                    </Col>
                </Row>
                <Row className="parlament-ex-row">
                    <Col className="parlament-ex-col" md={12}>
                        <h3 className="parlament-ex-heading">
                            Allocation Of Parlament Seats
                        </h3>
                        <div className="form-check form-switch activeSwitch">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id="flexSwitchCheckChecked"
                                checked={isChecked}
                                onChange={handleSwitchChange}
                            />
                            <label
                                className="form-check-label"
                                htmlFor="flexSwitchCheckChecked"
                            >
                                {isChecked ? "table" : "chart"}
                            </label>
                        </div>
                    </Col>
                    <Col className="parlament-ex-col" md={6}>
                        <ManageTable
                            data={data}
                            // error={error}
                            labels={labels}
                        />
                    </Col>
                    <Col className="parlament-ex-col" md={6}>
                        <div className="parlament-ex-bc">
                            <CustomChart options={parliamentChartOptions} />
                        </div>
                    </Col>
                    <Col className="parlament-ex-col" md={6}>
                        <div className="parlament-ex-bc">
                            <CustomChart options={partySeatsOptions} />
                        </div>
                    </Col>
                    <Col className="parlament-ex-col" md={6}>
                        <div className="parlament-ex-bc">
                            <CustomChart options={parliamentpieOptions} />
                        </div>
                    </Col>
                </Row>
                <Row className="parlament-ex-row">
                    <Col className="parlament-ex-col">
                        <span className="allianceText">
                            What happens when parties alliance with each other?
                        </span>
                        <SolidBlueBtn
                            children={childrens.children1}
                            onClick={handleClick}
                        />
                        <span className="allianceText">to see the result</span>
                    </Col>
                </Row>
                <Row className="parlament-ex-row" md={1}>
                    <Col className="parlament-ex-col">
                        <h3 className="parlament-ex-heading">
                            Party and District wise seats
                        </h3>
                    </Col>
                    <Col className="parlament-ex-col">
                        <div className="parlament-ex-bc">
                            <CustomChart options={partyDistrictDataOptions} />
                        </div>
                    </Col>
                    <Col className="parlament-ex-col">{components.component1}</Col>
                </Row>
            </Container>
        </main>
    );
};

export default ParliamentSimulatorResultMain;
