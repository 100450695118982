import React from "react";
import { Dropdown, DropdownButton, Image } from "react-bootstrap";
import NavDropdown from "react-bootstrap/NavDropdown";
import Logo from "../../assets/images/logo.png";
import LoginImg from "../../assets/images/photo.png";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { FaBars, FaRegUser } from "react-icons/fa6";
import { IoIosArrowDown } from "react-icons/io";
import { HiOutlineDocumentCheck } from "react-icons/hi2";
import { MdLogout } from "react-icons/md";
import { TbSwitch3 } from "react-icons/tb";

import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { logoutAdmin } from "../../redux/adminone/action";

import BorderBlueBtn from "../buttons/borderBlueBtn";
import Loader from "../loaders/loader";
import { cApiUrlPrefix } from "../../config/envConfig";
const override = `
    display: block;
    margin: 0 auto;
    border-color: red;
  `;
const electionTypes = [
  { name: "Presidential Election", path: "/simulator", election_type_id: 1 },
  { name: "Parliament Election", path: "/simulator", election_type_id: 2 }
];
const Aheader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector((state) => state.adminone.loading);
  const user = JSON.parse(localStorage.getItem("user"));
  const userImg = user?.user_image;
  const handleLogout = () => {
    dispatch(logoutAdmin());
    navigate("/admin/login");
    localStorage.removeItem("token");
  };
  return (
    <header className="aheadermain">
      <Navbar expand="xl">
        <Container fluid>
          <Navbar.Brand
            as={Link}
            to="/dashboard"
            href="#home"
            className="logoBlk"
          >
            <Image src={Logo} fluid />
          </Navbar.Brand>
          <div className="apsettingBtnblk">
            <DropdownButton
              className="AprofileBtn d-block d-xl-none"
              id="dropdown-basic-button"
              title={
                <div className="aloginbtn">
                  {" "}
                  <div className="loginBlk">
                    <Image
                      src={cApiUrlPrefix + userImg}
                      fluid
                      className="plogo"
                    />
                  </div>{" "}
                  <FaBars className="loginbar" />
                </div>
              }
            >
              <Dropdown.Item className="mUlist" as={Link} to="/profile">
                <FaRegUser className="pm" /> Profile Manage
              </Dropdown.Item>
              <Dropdown.Item className="mUlist" href="#/action-2">
                <HiOutlineDocumentCheck className="sf" /> Save Files
              </Dropdown.Item>
              <Dropdown.Item
                className="mUlist"
                href="#/action-3"
                onClick={handleLogout}
              >
                <MdLogout className="lo" /> Logout
              </Dropdown.Item>

              <BorderBlueBtn className="saveWorkBtn">Save Work</BorderBlueBtn>
            </DropdownButton>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
          </div>

          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="aNav">
              <NavDropdown
                title={
                  <>
                    Admin <IoIosArrowDown />
                  </>
                }
                id="basic-nav-dropdown"
              >
                <NavDropdown.Item
                  as={Link}
                  to="/manage-party"
                  className="mUlist"
                >
                  Manage party
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/manage-candidate"
                  className="mUlist"
                >
                  Manage Candidate
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/manage-province"
                  className="mUlist"
                >
                  Manage Province
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/manage-districts"
                  className="mUlist"
                >
                  Manage Districts
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/security-level"
                  className="mUlist"
                >
                  Manage Security Level
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/permission" className="mUlist">
                  Manage Permissions
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/manage-blog-category"
                  className="mUlist"
                >
                  Manage Blog Category
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/manage-blogs-content"
                  className="mUlist"
                >
                  Manage Blogs
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown
                title={
                  <>
                    Election <IoIosArrowDown />
                  </>
                }
                id="basic-nav-dropdown"
              >
                <NavDropdown.Item
                  as={Link}
                  to="/type-of-election"
                  className="mUlist"
                >
                  Manage Election
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/election-history"
                  className="mUlist"
                >
                  Presidential Election History
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/election-history"
                  className="mUlist"
                >
                  Parliamentary Election History
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/election-history"
                  className="mUlist"
                >
                  Provincial Council Election History
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/election-history"
                  className="mUlist"
                >
                  Local Authorities Election
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown
                title={
                  <>
                    Vote Simulator <IoIosArrowDown />
                  </>
                }
                id="basic-nav-dropdown"
              >
                {electionTypes.map((election) => (
                  <NavDropdown.Item
                    key={election.election_type_id}
                    onClick={() => {
                      navigate(election.path, { state: { election_type_id: election.election_type_id } });
                    }}

                    className="mUlist"
                  >
                    {election.name}
                  </NavDropdown.Item>
                ))}
                {/* <NavDropdown.Item
                  as={Link}
                  to={{
                    pathname: "/simulator",
                    hash: "",
                  }}
                  className="mUlist"
                >
                  Presidential Simulator
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to={{
                    pathname: "/simulator",
                    hash: "",
                  }}
                  className="mUlist"
                >
                  Parliament Simulator
                </NavDropdown.Item> */}
              </NavDropdown>
              <Nav.Link as={Link} to="/user-list" className="anavLink">
                Manage Users
              </Nav.Link>

              <DropdownButton
                className="AprofileBtn d-none d-xl-block"
                id="dropdown-basic-button"
                title={
                  <div className="aloginbtn">
                    {" "}
                    <div className="loginBlk">
                      <Image
                        src={cApiUrlPrefix + userImg}
                        fluid
                        className="plogo"
                      />
                    </div>{" "}
                    <FaBars className="loginbar" />
                  </div>
                }
              >
                <Dropdown.Item className="mUlist" as={Link} to="/">
                  <TbSwitch3 className="pm" /> Switch to user
                </Dropdown.Item>
                <Dropdown.Item className="mUlist" as={Link} to="/profile">
                  <FaRegUser className="pm" /> Profile Manage
                </Dropdown.Item>
                <Dropdown.Item className="mUlist" href="#/action-2">
                  <HiOutlineDocumentCheck className="sf" /> Save Files
                </Dropdown.Item>
                <Dropdown.Item
                  className="mUlist"
                  href="#/action-3"
                  onClick={handleLogout}
                >
                  <MdLogout className="lo" /> Logout
                </Dropdown.Item>

                <BorderBlueBtn className="saveWorkBtn">Save Work</BorderBlueBtn>
              </DropdownButton>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {loading ? (
        <Loader color={"#0E76A8"} loading={loading} css={override} size={75} />
      ) : null}
    </header>
  );
};

export default Aheader;
