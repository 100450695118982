import React from 'react';
import { Button } from 'react-bootstrap';

const TransparentBtn = ({ Children, icon, onClick }) => {
    return (
        <Button className='transparentbtn' onClick={onClick}>
            {Children} {icon}
        </Button>
    )
}

export default TransparentBtn;
