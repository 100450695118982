import React from "react";
import DOMPurify from 'dompurify';

const Aside2 = (aside) => {
  return (
    <section className="aside-two">
      <h2 className="aside-two-heading">{aside.aside2.heading}</h2>
      <p className="aside-two-para">{aside.aside2.para}</p>
      {aside.histories?.map((history) => (
        <article key={history.id} className="aside-article" ref={el => aside.articleRefs.current[history.id] = el}>
          <h5 className="article-subheading">{history.title}</h5>
          <p className="article-subpara" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(history.content) }}></p>
        </article>))}
    </section>
  );
};

export default Aside2;
