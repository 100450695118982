import React from 'react';
import { Button } from 'react-bootstrap';

const RedSolidBtn = ({ children, onClick }) => {
    return (
        <Button onClick={onClick}  className='redsolidbtn'>
            {children}
        </Button>
    )
}

export default RedSolidBtn;
