import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import InActive from "../../../components/labels/inActive";
import Active from "../../../components/labels/active";
// import ManageBlkOne from "../../../components/admin/manageBlk";
import DeleteCModal from "../../../components/modals/deleteCModal";
import ManageBlk from "../../../components/admin/manageBlk";
// import { getManageAssociatedCandidateById } from "../../../redux/admin/action";
import { showManageAssociatedCandidateById } from "../../../redux/adminone/action";
import IImage from "../../../components/image/iImage";
import DropDown from "../../../components/dropdown/dropDown";
const options = [
  { value: 10, label: 10 },
  { value: 20, label: 20 },
  { value: 30, label: 30 },
];

const cstomStyle = {
  menu: (provided) => ({
    ...provided,
    backgroundColor: "white",
    color: "#65676B",
    maxWidth: "97px",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#efefef" : "white",
    color: state.isFocused ? "#65676B" : "#65676B",
    padding: 5,
  }),
};

const ManageAssiciatedCandidateById = () => {
  const location = useLocation();
  const { id, party_name, status } = location.state || {};


  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(showManageAssociatedCandidateById(id));
  }, [dispatch, id]);

  const [isSearchable] = useState(false);
  const showMaCandidateByIdData = useSelector(
    (state) => state.adminone.showMaCandidateByIdData?.data
  );
  const error = useSelector((state) => state.adminone.error);

  const statusMap = {
    0: <InActive />,
    1: <Active />,
  };

  const dropdownItems = (data, apiEndpoint, httpMethod) => [
    {
      label: "Edit",
      href: "#/edit",
      // onClick: () => handleEditClick(data, apiEndpoint, httpMethod),
    },
    { label: "Delete", href: "#/delete" },
  ];

  const labels = [
    "Candidate Image",
    "Candidate Name",
    "Party Name",
    "Status",
    "Action",
  ];

  const data = Array.isArray(showMaCandidateByIdData)
    ? showMaCandidateByIdData.map((data) => ({
      "Candidate Image": (
        <figure className="mpLogo">
          <IImage src={data.candidate_image} className="mpLogoImg" />
        </figure>
      ),
      "Candidate Name": data.candidate_name,
      "Party Name": data.party_name,
      Status: statusMap[data.status],
      Action: (

        <DropDown
          Children={"Action"}
          items={dropdownItems(data)}
          className="managPartyAction ms-auto"
        />
      ),
    }))
    : [];

  const props = {
    title: `${party_name} >> Candidates`,
    children: "Add Candidate",
    to: "/add-associated-candidate-by-id",
    style: { padding: "1.3rem 2.8rem" },
    options,
    isSearchable,
    className: {
      cselectpageSize: "cselectpageSize",
      minputSearch: "minputSearch",
      deleteModal: "deleteModal",
    },
    cstomStyle,
    Placeholder: "Search",
    labels,
    data,
  };

  return (
    <main className="macbid">
      <aside className="macbidParty">
        <ManageBlk {...props} />
        {error ? <p> 404 Not found</p> : null}
      </aside>
      <DeleteCModal
        // show={showModal}
        // handleClose={handleCloseModal}
        // handleConfirm={handleConfirmDelete}
        className={props.className.deleteModal}
      />
    </main>
  );
};

export default ManageAssiciatedCandidateById;
