import React, { forwardRef } from "react";
import Slider from "react-slider";

const CustomRangeSlider = forwardRef(
  ({ value, onChange, disabled, total_registered_voters }, ref) => {
    const handleInputChange = (e) => {
      const newValue = Math.max(0, Math.min(total_registered_voters, Number(e.target.value)));
      onChange(newValue);
    };

    return (
      <div className="rangeSlider" ref={ref}>
        <Slider
          min={0}
          max={total_registered_voters}
          value={value}
          onChange={onChange}
          ariaLabelledby="continuous-slider"
          className="slider"
          disabled={disabled}
          renderThumb={(thumbProps) => {
            const { key, ...restProps } = thumbProps;
            return <div {...restProps} className="thumb" key={key} />;
          }}
        />

        <input
          type="text"
          className="label"
          value={value}
          onChange={handleInputChange}
          disabled={disabled}
          min={0}
          max={total_registered_voters}
        />

      </div>
    );
  }
);

export default CustomRangeSlider;
