import React from "react";
import { Col, Row } from "react-bootstrap";
import DatePickerCustom from "./datePicker";

const DatePickerSingle = (props) => {
  const {
    className,
    label,
    startName,
    startValue,
    onChangeStart,
    placeholderStart,
  } = props;
  return (
    <Row className="mt-5">
      <Col sm={2} className="d-flex justify-content-start align-items-center">
        <label className={className.cselectpageSizeLable}>{label}</label>
      </Col>
      <Col sm={2}>
        <DatePickerCustom
          name={startName}
          value={startValue}
          onChange={onChangeStart}
          placeholder={placeholderStart}
        />
      </Col>
    </Row>
  );
};

export default DatePickerSingle;
