import React, { useEffect, useState } from "react";
import ManageBlk from "../../../components/admin/manageBlk";
import { useDispatch, useSelector } from "react-redux";
import DropDown from "../../../components/dropdown/dropDown";
import DeleteCModal from "../../../components/modals/deleteCModal";

import { delProvinceById, indexProvince } from "../../../redux/adminone/action";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "react-simple-snackbar";
const options = [
  { value: 10, label: 10 },
  { value: 20, label: 20 },
  { value: 30, label: 30 },
];

const cstomStyle = {
  menu: (provided) => ({
    ...provided,
    backgroundColor: "white",
    color: "#65676B",
    maxWidth: "97px",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#efefef" : "white",
    color: state.isFocused ? "#65676B" : "#65676B",
    padding: 5,
  }),
};
const ManageProvince = () => {
  const dispatch = useDispatch();
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(indexProvince());
  }, [dispatch]);

  const [isSearchable] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [province, setProvince] = useState(null);
  const indexProvinceData = useSelector((state) => state.adminone.indexProvinceData?.data);
  const error = useSelector((state) => state.adminone.error);
  const dropdownItems = (data) => [
    { label: "Edit", href: "#/edit", onClick: () => handleEdit(data) },
    {
      label: "Delete",
      href: "#/delete",

      onClick: () => {
        handleOpenModal(data);
      },
    },
    {
      label: "Manage Districts",
      href: "#/action-3",
      onClick: () => handleDistrictsById(data),
    },
  ];
  const handleEdit = (data) => {
    navigate("/edit-province", {
      state: {
        id: data.id,
        province_name: data.province_name,

      }
    });
    // navigate(
    //   `/edit-province?id=${data.id}&province_name=${encodeURIComponent(
    //     data.province_name
    //   )}`
    // );
  };
  const handleOpenModal = (data) => {
    setProvince(data);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setProvince(null);
  };

  const handleConfirmDelete = () => {
    if (province) {
      const id = province.id;

      dispatch(
        delProvinceById(id, (error, response) => {
          if (error) {
            // handle the error

          } else {
            // handle the response

            if (response.status === 200) {
              dispatch(indexProvince());
              openSnackbar(response.data.message);
            }
          }
        })
      );
      handleCloseModal();
    }
  };

  const handleDistrictsById = (data) => {

    navigate("/manage-associated-district-by-id", {
      state: {
        id: data.id,
        province_name: data.province_name,
      }
    });
    // navigate(
    //   `/manage-associated-district-by-id?id=${data.id
    //   }&province_name=${encodeURIComponent(data.province_name)}`
    // );
  };
  const labels = [
    "Province name",
    "Total Districts",
    "Total Polling Devision",
    "Seats",
    "Total Population",
    "Registered voter",
    "Action",
  ];

  const data = Array.isArray(indexProvinceData)
    ? indexProvinceData.map((province) => ({
      "Province name": province.province_name,
      "Total Districts": province.province_districts,
      "Total Polling Devision": province.province_polling_divisions,
      Seats: province.province_seats,
      "Total Population": province.province_population,
      "Registered voter": province.province_registered_voters,
      Action: (
        <DropDown
          className="sequritiesPartyAction ms-auto"
          Children={"Action"}
          items={dropdownItems(province)}
        />
      ),
    }))
    : [];

  const provinceBlkProps = {
    title: "Province",
    children: "Add Electoral Province",
    to: "/add-province",
    style: { padding: "1.3rem 2.8rem" },
    options,
    isSearchable,
    className: {
      cselectpageSize: "cselectpageSize",
      minputSearch: "minputSearch",
      deleteModal: "deleteModal",
    },
    cstomStyle,
    Placeholder: "Search",
    labels,
    data,
    error,
  };

  return (
    <main className="province">
      <aside className="provinceaside">
        <ManageBlk {...provinceBlkProps} />
      </aside>
      <DeleteCModal
        show={showModal}
        handleClose={handleCloseModal}
        handleConfirm={handleConfirmDelete}
        className={provinceBlkProps.className.deleteModal}
      />
    </main>
  );
};

export default ManageProvince;
