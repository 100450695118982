import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import IImage from "../../../components/image/iImage";
import TransparentBtn from "../../../components/buttons/transparentBtn";
import SearchBtnForm from "../../../components/form/searchBtnForm";
import Loader from "../../../components/loaders/loader";
import { useSelector } from "react-redux";

const override = `
    display: block;
    margin: 0 auto;
    border-color: red;
`;

const Blogs = (props) => {
  const { blogData, titles } = props;
  const loading = useSelector((state) => state.user.loading);

  return (
    <main className="blogs-main">
      <Container fluid className="container-candidate">
        <Row className="row-candidate">
          <Col className="col-candidate">
            <h2 className="candidate-heading">{titles}</h2>
          </Col>
          <Col className="col-candidate">
            <SearchBtnForm
              className="minputSearch"
              placeholder="search blog..."
            />
          </Col>
        </Row>
        <Row md={3} xs={1} className="section-row gy-5">
          {blogData.map((blog, index) => (
            <Col key={index} className="section-col">
              <div className="card col-card">
                <IImage src={blog.images} className="card-img-top mb-2" />
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <div className="date">{blog.dates}</div>
                    <span className="card-cName">{blog.categoryName}</span>
                  </div>
                  <h5 className="card-title">{blog.subtitles}</h5>
                  <p className="card-text">{blog.parag}</p>
                  <TransparentBtn Children={blog.children} icon={blog.icons} onClick={blog.onClick} />
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
      {loading ? (
        <Loader color={"#0E76A8"} loading={loading} css={override} size={75} />
      ) : null}
    </main>
  );
};

export default Blogs;
