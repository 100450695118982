import React from 'react';
import Image from 'react-bootstrap/Image';
import Logo from "../../../assets/images/logo.png"
import { Link } from 'react-router-dom';
import SolidBlueBtn from '../../../components/buttons/solidBlueBtn';
import { useNavigate } from "react-router-dom";
import { Container } from 'react-bootstrap';

const LoginPerefrance = () => {
  const navigate = useNavigate();
  const handleClick1 = () => {
    navigate("/login")
  }
  const handleClick2 = () => {
    navigate("/register")
  }
  return (
    <main className="main-auth">
      <Container fluid="xxl">
        <div className="loginPre">

          <figure>

            <Image src={Logo} fluid />

          </figure>
          <div className='authCenterBlk'>
            <h2 className='authHead'>Welcome</h2>
            <p className='authPara'>Please select your login perefrance</p>

            <SolidBlueBtn onClick={handleClick1} style={{ padding: '1.5rem', width: '100%', marginTop: '7rem', marginBottom: '2rem', }}>Login</SolidBlueBtn>
            <SolidBlueBtn onClick={handleClick2} style={{ padding: '1.5rem', width: '100%', }}>New user</SolidBlueBtn>
            <SolidBlueBtn style={{ padding: '1.5rem', width: '100%', marginTop: '2rem' }}>Login with Oorraar</SolidBlueBtn>
          </div>
          <div className='lp-linkBlk'><Link className='lp-link'>Login as a guest</Link></div>

        </div>
      </Container>
    </main>
  )
}

export default LoginPerefrance;
