import React from 'react';
import { css } from '@emotion/react';
import ClipLoader from "react-spinners/ClipLoader";
const Loader = ({ loading, size, color }) => {

    const override = css`
        display: block;
        margin: 0 auto;
    `;
    return (
        <div className="spinner-container">
            <ClipLoader color={color} loading={loading} css={override} size={size} />
        </div>
    )
}

export default Loader;