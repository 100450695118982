import React, { useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import ItemSeries from 'highcharts/modules/item-series';
import Exporting from 'highcharts/modules/exporting';
import Fullscreen from 'highcharts/modules/full-screen';


if (typeof Highcharts === 'object') {
    ItemSeries(Highcharts);
    Exporting(Highcharts);
    Fullscreen(Highcharts);
}

const CustomChart = ({ options }) => {
    return (
        <div className="custom-chart">
            <HighchartsReact highcharts={Highcharts} options={options} /> ;
        </div>
    )
};

export default CustomChart;
