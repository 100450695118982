import React, { useEffect, useState } from 'react';
import PollResultsMain from './pollResultsMain';
import { useDispatch, useSelector } from 'react-redux';
import { getAlloDemocarcyPollResultById, indexDistrict, showAllPollingDivisionsByDistrictById } from '../../../redux/adminone/action';
import { useLocation, useNavigate } from 'react-router-dom';
const options = [
    { value: 10, label: 10 },
    { value: 20, label: 20 },
    { value: 30, label: 30 },
];
const cstomStyle = {
    menu: (provided) => ({
        ...provided,
        backgroundColor: "white",
        color: "#65676B",
        maxWidth: "97px",
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused ? "#efefef" : "white",
        color: state.isFocused ? "#65676B" : "#65676B",
        padding: 5,
    }),
};
const cstomStyle1 = {
    menu: (provided) => ({
        ...provided,
        backgroundColor: "white",
        color: "#65676B",
        maxWidth: "250px",
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused ? "#efefef" : "white",
        color: state.isFocused ? "#65676B" : "#65676B",
        padding: 5,
    }),
};
const cstomStyle2 = {
    menu: (provided) => ({
        ...provided,
        backgroundColor: "white",
        color: "#65676B",
        maxWidth: "97px",
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused ? "#efefef" : "white",
        color: state.isFocused ? "#65676B" : "#65676B",
        padding: 5,
    }),
};
const PollResultsPage = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const [isSearchable] = useState(false);
    const [options1, setOptions1] = useState([]);
    const [options2, setOptions2] = useState([]);
    const [selectedDistrictId, setSelectedDistrictId] = useState(null);
    const [selectedPollingDivId, setSelectedPollingDivId] = useState(null);
    const { electionTypeId } = location.state || {};


    useEffect(() => {
        dispatch(getAlloDemocarcyPollResultById(electionTypeId));
        dispatch(indexDistrict());
    }, [dispatch]);

    const getAllOdemocracyPollResultsByIdData = useSelector((state) => state.adminone.getAllOdemocracyPollResultsByIdData?.data); 

    const indexDistrictsData = useSelector((state) => state.adminone.indexDistrictsData?.data);
    useEffect(() => {
        if (indexDistrictsData) {
            const newOptions = [
                ...indexDistrictsData.map((item) => ({
                    value: item.id,
                    label: item.district_name,
                })),
            ];
            setOptions1(newOptions);
        }
    }, [indexDistrictsData]);
    const selectChange = (selectedOption) => {
        setSelectedDistrictId(selectedOption.value);
        dispatch(showAllPollingDivisionsByDistrictById(selectedOption.value));
    };
    const showsAllPollingDivisionsByDistrictIdData = useSelector(
        (state) => state.adminone.showsAllPollingDivisionsByDistrictIdData?.data
    );
    useEffect(() => {
        if (showsAllPollingDivisionsByDistrictIdData) {
            const newOptions = [
                ...showsAllPollingDivisionsByDistrictIdData.map((item) => ({
                    value: item.id,
                    label: item.polling_division_name,
                })),
            ];
            setOptions2(newOptions);
        }
    }, [showsAllPollingDivisionsByDistrictIdData]);

    const selectChange1 = (selectedOption) => {
        setSelectedPollingDivId(selectedOption.value);
    };


    const loading = useSelector((state) => state.adminone.loading);
    const error = useSelector((state) => state.adminone.error);

    const labels = [
        "Candidate",
        "Party",
        "1st Preferance",
        "2nd Preferance",
        "3rd Preferance",
        "Total Votes",
        "Vote %",
    ];

    const data = Array.isArray(getAllOdemocracyPollResultsByIdData?.topCandidates)
        ? getAllOdemocracyPollResultsByIdData.topCandidates.map((topCand) => ({
            "Candidate": topCand.candidate_name,
            "Party": topCand.party_name,
            "1st Preferance": topCand.preference1,
            "2nd Preferance": topCand.preference2,
            "3rd Preferance": topCand.preference3,
            "Total Votes": topCand.total_votes,
            "Vote %": "0",
        }))
        : [];


    const handleClick = () => {

    };
    const props = {
        pageTitles:

        {
            pageTitle1: "Presidential Election Poll Result Details",
        },
        className: {
            cselectpageSize: "cselectpageSize",
            cselectpageSize2: "cselectpageSize2",
            minputSearch: "minputSearch",
            deleteModal: "deleteModal",
        },

        label: {
            lable1: "",
            lable2: "",
            lable3: "",
        },
        details: {
            detail1: "",
            detail2: "",
            detail3: "",
        },
        Placeholder: {
            Placeholder1: "Search",
            Placeholder2: "Districts",
            Placeholder3: "Polling Divisions",
        },
        options,
        options1,
        options2,
        isSearchable,
        cstomStyle,
        cstomStyle1,
        cstomStyle2,
        handleClick,
        labels,
        data,
        loading,
        error,
        selectChange,
        selectChange1
    }
    return (
        <PollResultsMain {...props} />
    )
}

export default PollResultsPage;
