import React from "react";
import SectionHero from "../../../components/user/landingPage/sectionHero";
import SectionAbout from "../../../components/user/landingPage/sectionAbout";
import SectionVisionMission from "../../../components/user/landingPage/sectionVisionMission";
import SectionHowItWork from "../../../components/user/landingPage/sectionHowItWork";
import SectionAnnouncements from "../../../components/user/landingPage/sectionAnnouncements";
import SectionVoterEdu from "../../../components/user/landingPage/sectionVoterEdu";
import SectionSimulator from "../../../components/user/landingPage/sectionSimulator";
import SectionNews from "../../../components/user/landingPage/sectionNews";

const LandingMain = (props) => {
  return (
    <main className="main-landing">
      <SectionHero {...props} />
      <SectionAbout {...props} />
      <SectionVisionMission {...props} />
      <SectionVoterEdu {...props} />
      <SectionHowItWork {...props} />
      <SectionAnnouncements {...props} />
      <SectionSimulator {...props} />
      <SectionNews {...props} />
    </main>
  );
};

export default LandingMain;
