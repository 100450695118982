import React from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import Cselect from "../select/cselect";

function DropdownWithModal({
  show,
  onHide,
  title,
  voteText,
  options,
  isSearchable,
  cstomStyle,
  className,

  selectChange,
  value,
  handleClick,
  placeholder,

  options1,
  isSearchable1,
  value1,
  placeholder1,
  selectChange1,
  options2,
  value2,
  placeholder2,
  isSearchable2,
}) {
  return (
    <div className="vyc-modal">
      <Modal size="md" show={show} onHide={onHide} centered backdrop="static">
        <Modal.Header className="justify-content-center vyc-header">
          <Modal.Title className="position-relative">
            <h3>{title}</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="vyc-body my-5">
          <div className="mb-5">
            <Cselect
              options={options2}
              isSearchable={isSearchable2}
              className={className.cselectpageSize1}
              styles={cstomStyle}
              value={value2}
              placeholder={placeholder2}
            />
          </div>
          <div className="mb-5">
            <Cselect
              options={options}
              isSearchable={isSearchable}
              className={className.cselectpageSize1}
              styles={cstomStyle}
              onChange={selectChange}
              value={value}
              placeholder={placeholder}
            />
          </div>
          <div className="">
            <Cselect
              options={options1}
              isSearchable={isSearchable1}
              className={className.cselectpageSize1}
              styles={cstomStyle}
              onChange={selectChange1}
              value={value1}
              placeholder={placeholder1}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="d-block">
          <div className="d-flex justify-content-around align-items-center my-4">
            <Button
              className="vyc-btn2"
              onClick={handleClick}
              disabled={!value || !value1}
            >
              {voteText}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default DropdownWithModal;
