import React from "react";
import { Image } from "react-bootstrap";
import OrangeSolidBtn from "../../buttons/orangeSolidBtn";

const SectionHowItWork = (props) => {
  const { titles, icons, subtitles, paras, onClick, children } = props;

  return (
    <section className="section-landing">
      <div className="ourApprochHead">
        <aside className="section-aside">
          <span className="aside-lable">
            {icons.icons6}
            {titles.title5}
          </span>
          <h2 className="aside-heading">{titles.title7}</h2>
          <Image src={icons.icons7} className="float-end" />
        </aside>
        <aside className="section-aside">
          <p className="aside-para">{paras.para17}</p>
          <OrangeSolidBtn onClick={onClick} children={children.children4} />
        </aside>
      </div>
      {/* <h1 className="section-heading">{titles.title5} </h1> */}
      {/* <div className="section-block"> */}
      <aside className="section-aside1">
        <div className="sa-imgblk">
          {/* <Image src={icons.icons1} /> */}
          <span className="numSpan">1</span>
        </div>
        <h4 className="aside-heading">{subtitles.heading1}</h4>
        <p className="aside-para">{paras.para3}</p>
      </aside>
      <aside className="section-aside1">
        <div className="sa-imgblk">
          {/* <Image src={icons.icons2} /> */}
          <span className="numSpan">2</span>
        </div>
        <h4 className="aside-heading">{subtitles.heading2}</h4>
        <p className="aside-para">{paras.para4}</p>
      </aside>
      <aside className="section-aside1">
        <div className="sa-imgblk">
          {/* <Image src={icons.icons3} /> */}
          <span className="numSpan">3</span>
        </div>
        <h4 className="aside-heading">{subtitles.heading3}</h4>
        <p className="aside-para">{paras.para5}</p>
      </aside>
      <aside className="section-aside1">
        <div className="sa-imgblk">
          <span className="numSpan">4</span>
          {/* <Image src={icons.icons4} /> */}
        </div>
        <h4 className="aside-heading">{subtitles.heading4}</h4>
        <p className="aside-para">{paras.para6}</p>
      </aside>
      <aside className="section-aside1">
        <div className="sa-imgblk">
          <span className="numSpan">5</span>
          {/* <Image src={icons.icons4} /> */}
        </div>
        <h4 className="aside-heading">{subtitles.heading11}</h4>
        <p className="aside-para">{paras.para20}</p>
      </aside>
      {/* <aside className="section-aside">
          <div className="sa-imgblk">
          </div>
          <h4 className="aside-heading">{subtitles.heading12}</h4>
          <p className="aside-para">{paras.para25}</p>
        </aside> */}
      {/* <aside className='section-aside'>
                    <div className='sa-imgblk'>
                        <Image src={icons.icons2} />
                    </div>
                    <h4 className="aside-heading">{subtitles.heading2}</h4>
                    <p className='aside-para'>{paras.para4}</p>
                </aside>
                <aside className='section-aside'>
                    <div className='sa-imgblk'>
                        <Image src={icons.icons3} />
                    </div>
                    <h4 className="aside-heading"> {subtitles.heading3}</h4>
                    <p className='aside-para'>{paras.para5}</p>
                </aside>
                <aside className='section-aside'>
                    <div className='sa-imgblk'>
                        <Image src={icons.icons4} />
                    </div>
                    <h4 className="aside-heading">{subtitles.heading4}</h4>
                    <p className='aside-para'>{paras.para6}</p>
                </aside>
                <aside className='section-aside'>
                    <div className='sa-imgblk'>
                        <Image src={icons.icons4} />
                    </div>
                    <h4 className="aside-heading">{subtitles.heading11}</h4>
                    <p className='aside-para'>{paras.para20}</p>
                </aside> */}
      {/* </div> */}
    </section>
  );
};

export default SectionHowItWork;
