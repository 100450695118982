import React from "react";
import Card from "react-bootstrap/Card";
import { FaCircleCheck } from "react-icons/fa6";

const CardSeven = ({ image, title, onClick, className, isActive }) => {
  return (
    <Card className={className} onClick={onClick}>
      <Card.Img variant="top" src={image} />
      <Card.Body>
        <Card.Title>{title}</Card.Title>
      </Card.Body>
      {isActive && (
        <span className="checkSign">
          <FaCircleCheck />
        </span>
      )}
    </Card>
  );
};

export default CardSeven;
