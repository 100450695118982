import React from "react";

const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("en-us", { month: "short" });
    const suffix = getSuffix(day);
    return `${day}${suffix} ${month}`;
};

const getSuffix = (day) => {
    if (day === 1 || day === 21 || day === 31) return "st";
    if (day === 2 || day === 22) return "nd";
    if (day === 3 || day === 23) return "rd";
    return "th";
};
const DateFormatter = ({ startDate, endDate }) => {
    const formattedStartDate = formatDate(startDate);
    const formattedEndDate = formatDate(endDate);
    return (
        <span>
            {formattedStartDate} - {formattedEndDate}
        </span>
    )
}

export default DateFormatter;
