import React, { useState } from 'react';


const CustomTooltip = ({ text, children }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const toggleTooltip = () => {
    setShowTooltip(!showTooltip);
  };

  return (
    <div className="tooltip-container" onMouseEnter={toggleTooltip} onMouseLeave={toggleTooltip}>
      {children}
      {showTooltip && <div className="tooltip">{text}</div>}
    </div>
  );
};

export default CustomTooltip;