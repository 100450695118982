import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../../components/loaders/loader";
import PostBlockOne from "../../../components/admin/postBlockOne";
import {
  indexProvince,
  addDistricts,
  showElectroalDistrictById,
  updateElectroalDistrictById,
} from "../../../redux/adminone/action";
import { useSnackbar } from "react-simple-snackbar";

const override = `
    display: block;
    margin: 0 auto;
    border-color: red;
`;

const cstomStyle = {
  menu: (provided) => ({
    ...provided,
    backgroundColor: "white",
    color: "#65676B",
    Width: "fit-content",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#efefef" : "white",
    color: state.isFocused ? "#65676B" : "#65676B",
    padding: 5,
  }),
};

const AddManageDistricts = () => {
  const dispatch = useDispatch();
  const [openSnackbar] = useSnackbar();
  const location = useLocation();
  const { id, district_name, status } = location.state || {};
  // const params = new URLSearchParams(location.search);
  // const id = params.get("id");

  useEffect(() => {
    dispatch(indexProvince());
    if (id) {
      dispatch(showElectroalDistrictById(id));
    }
  }, [dispatch, id]);

  const navigate = useNavigate();
  const loading = useSelector((state) => state.adminone.loading);
  const indexProvinceData = useSelector(
    (state) => state.adminone.indexProvinceData?.data
  );
  const showDistrictByIdData = useSelector(
    (state) => state.adminone.showDistrictByIdData?.data
  );

  const [options, setOptions] = useState([]);
  const [selectedProvinceId, setSelectedProvinceId] = useState(null);
  const [defaultProvinceOption, setDefaultProvinceOption] = useState(null);
  const [formData, setFormData] = useState({
    district_name: "",
    districts_seats: "",
    province_seats: "",
    // total_population: "",
    // total_registered_voters: "",
    map_image: null,
  });

  const [errors, setErrors] = useState({
    district_name: "",
    districts_seats: "",
    province_seats: "",
    // total_population: "",
    // total_registered_voters: "",
    map_image: null,
  });

  useEffect(() => {
    if (indexProvinceData) {
      const newOptions = indexProvinceData.map((item) => ({
        value: item.id,
        label: item.province_name,
      }));
      setOptions(newOptions);
    }
  }, [indexProvinceData]);

  useEffect(() => {
    if (id && showDistrictByIdData) {
      setFormData({
        district_name: showDistrictByIdData.district_name,
        districts_seats: showDistrictByIdData.district_seats,
        province_seats: showDistrictByIdData.province_seats,
        total_population: showDistrictByIdData.total_population,
        total_registered_voters: showDistrictByIdData.total_registered_voters,
        map_image: showDistrictByIdData.map_image,
      });

      setDefaultProvinceOption({
        value: showDistrictByIdData.province_id,
        label: showDistrictByIdData.province_name,
      });

      setSelectedProvinceId(showDistrictByIdData.province_id);
    }
  }, [id, showDistrictByIdData]);

  const selectChange = (selectedOption) => {
    setSelectedProvinceId(selectedOption.value);
    setDefaultProvinceOption(selectedOption);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const handleUpload = (imageList) => {
    if (imageList.length > 0) {
      setFormData((prevData) => ({
        ...prevData,
        map_image: imageList[0].file,
      }));
    } else {
      setFormData((prevData) => ({ ...prevData, map_image: null }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        map_image: "Map image is required.",
      }));
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    const {
      district_name,
      districts_seats,
      province_seats,
      // total_population,
      // total_registered_voters,
      map_image,
    } = formData;

    let valid = true;
    let newErrors = {};

    if (!district_name || district_name.length < 2) {
      newErrors.district_name =
        "District name must be at least 2 characters long.";
      valid = false;
    }

    if (!districts_seats || isNaN(districts_seats) || districts_seats <= 0) {
      newErrors.districts_seats = "Districts seats must be a positive number.";
      valid = false;
    }

    if (!province_seats || isNaN(province_seats) || province_seats <= 0) {
      newErrors.province_seats = "Province seats must be a positive number.";
      valid = false;
    }

    // if (!total_population || isNaN(total_population) || total_population <= 0) {
    //   newErrors.total_population =
    //     "Total population must be a positive number.";
    //   valid = false;
    // }

    // if (
    //   !total_registered_voters ||
    //   isNaN(total_registered_voters) ||
    //   total_registered_voters <= 0
    // ) {
    //   newErrors.total_registered_voters =
    //     "Total registered voters must be a positive number.";
    //   valid = false;
    // }

    setErrors(newErrors);

    if (!valid) return;

    const formDataDistrict = new FormData();
    formDataDistrict.append("district_name", district_name);
    formDataDistrict.append("seats", districts_seats);
    formDataDistrict.append("province_seats", province_seats);
    formDataDistrict.append("province_id", selectedProvinceId);

    if (showDistrictByIdData?.map_image !== map_image) {
      formDataDistrict.append("map_image", map_image);
    }

    if (id) {
      dispatch(
        updateElectroalDistrictById(formDataDistrict, id, (error, response) => {
          if (error) {
            openSnackbar("Error occurred while updating the district");
          } else {
            if (response.status === 200) {
              openSnackbar(response.data.message);
              navigate("/manage-districts");
            }
          }
        })
      );
    } else {
      dispatch(
        addDistricts(
          formDataDistrict,
          (error, response) => {
            if (error) {
              openSnackbar("Error occurred while adding the district");
            } else {
              if (response.status === 200) {
                openSnackbar(response.data.message);
                navigate("/manage-districts");
              }
            }
          }
        )
      );
    }
  };

  const handleCancel = () => {
    navigate("/manage-districts");
  };

  const textInput = id
    ? [
      {
        label: "District name",
        name: "district_name",
        value: formData.district_name,
        error: errors.district_name,
        placeholder: "Enter district name",
        onChange: handleChange,
      },
      {
        label: "Districts Seats",
        name: "districts_seats",
        value: formData.districts_seats,
        error: errors.districts_seats,
        placeholder: "Enter districts seats",
        onChange: handleChange,
      },
      {
        label: "Province Seats",
        name: "province_seats",
        value: formData.province_seats,
        error: errors.province_seats,
        placeholder: "Enter Province Seats",
        onChange: handleChange,
      },
      {
        label: "Total Population",
        name: "total_population",
        value: formData.total_population,
        error: errors.total_population,
        placeholder: "Enter total population",
        onChange: handleChange,
        disabled: true,
      },
      {
        label: "Total Registered Voters",
        name: "total_registered_voters",
        value: formData.total_registered_voters,
        error: errors.total_registered_voters,
        placeholder: "Enter total registered voters",
        onChange: handleChange,
        disabled: true,
      },
    ]
    : [
      {
        label: "District name",
        name: "district_name",
        value: formData.district_name,
        error: errors.district_name,
        placeholder: "Enter district name",
        onChange: handleChange,
      },
      {
        label: "Districts Seats",
        name: "districts_seats",
        value: formData.districts_seats,
        error: errors.districts_seats,
        placeholder: "Enter districts seats",
        onChange: handleChange,
      },
      {
        label: "Province Seats",
        name: "province_seats",
        value: formData.province_seats,
        error: errors.province_seats,
        placeholder: "Enter Province Seats",
        onChange: handleChange,
      },
    ];

  const props = {
    to1: "/security-level",
    title: id ? "Edit District" : "Add Districts",
    selectLable: "Add Province",
    saveNextBtn: "Save and Next",
    addSeqBtnstyle: { padding: "1.3rem 2.8rem", marginLeft: "1.5rem" },
    save: "save",
    cancel: "cancel",
    textInput,
    className: {
      cselectpageSize: "cselectpageSize",
      cselectpageSizeLable: "cselectpageSizeLable",
    },
    options,
    cstomStyle,
    uploadLabel: "Upload Image",
    uploadStyle: {
      marginTop: "3rem",
    },
    onUpload: handleUpload,
    selectChange,
    handleChange,
    handleSave,
    handleCancel,
    value: defaultProvinceOption,
    map_image: id ? showDistrictByIdData?.map_image : formData.map_image,
  };

  return (
    <main className="aDistrict">
      <aside className="addbock">
        <PostBlockOne {...props} />
      </aside>
      {loading ? (
        <Loader color={"#0E76A8"} loading={loading} css={override} size={75} />
      ) : null}
    </main>
  );
};

export default AddManageDistricts;
