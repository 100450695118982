import React from "react";
import BlackBorderBtn from "../../../components/buttons/blackBorderBtn";
import RedBorderBtn from "../../../components/buttons/redBorderBtn";
import TextForm from "../../../components/form/textForm";
import { v4 as uuidv4 } from "uuid";
import Cselect from "../../../components/select/cselect";
import { Col, Row } from "react-bootstrap";
// import TextArea from "../../../components/form/textArea";

const EditUserHero = ({
  title1,
  blackBorderBtnChild,
  blackBorderBtnTo,
  blackBorderBtnStyle,
  redBorderBtnChild,
  redBorderBtnStyle,
  textFormLabel,
  textFormPlaceholder,
  onChange,
  handleClick,
  handleCancel,
  options,
  // isSearchable,
  cstomStyle,
  className,
  selectChange,

  textFormStyle1,
  textFormLabel1,
  textFormPlaceholder1,

  textFormStyle3,
  textFormLabel3,
  textFormPlaceholder3,

  user_name_value,
  user_id_value,
  mobileNumber_value,
  textFormStyle4,
  textFormLabel4,
  textFormPlaceholder4,
  email_value,
  textFormStyle5,
  textFormLabel5,
  textFormPlaceholder5,
  district_name_value,
  textFormStyle6,
  textFormLabel6,
  textFormPlaceholder6,
  polling_divisions_value,
  textFormStyle7,
  textFormLabel7,
  textFormPlaceholder7,
  province_name_value,
  textFormStyle8,
  textFormLabel8,
  textFormPlaceholder8,
  voter_id_value,

  value,
}) => {
  const uniqueId1 = uuidv4();
  const uniqueId2 = uuidv4();
  const uniqueId3 = uuidv4();
  const uniqueId4 = uuidv4();
  const uniqueId5 = uuidv4();
  const uniqueId6 = uuidv4();
  const uniqueId7 = uuidv4();
  const uniqueId8 = uuidv4();
  return (
    <div className="addpartyBlock">
      <div className="addpartyBlockChild">
        <h2 className="addpartyBlockTitle">{title1}</h2>
        <div className="">
          <BlackBorderBtn
            children1={blackBorderBtnChild}
            to1={blackBorderBtnTo}
            style1={blackBorderBtnStyle}
            onClick={handleClick}
          />
          <RedBorderBtn
            children1={redBorderBtnChild}
            onClick={handleCancel}
            style1={redBorderBtnStyle}
          />
        </div>
      </div>
      <div className="mt-5">
        <TextForm
          formLabel={textFormLabel}
          placeholder={textFormPlaceholder}
          name="name"
          value={user_name_value}
          onChange={onChange}
          uniqueId={uniqueId1}
          Lsm={2}
          Ism={6}
        />
        <TextForm
          style={textFormStyle1}
          formLabel={textFormLabel1}
          placeholder={textFormPlaceholder1}
          name="username"
          value={user_id_value}
          onChange={onChange}
          uniqueId={uniqueId2}
          Lsm={2}
          Ism={6}
        />
        <TextForm
          style={textFormStyle3}
          formLabel={textFormLabel3}
          placeholder={textFormPlaceholder3}
          name="mobileNumber"
          value={mobileNumber_value}
          onChange={onChange}
          uniqueId={uniqueId3}
          Lsm={2}
          Ism={6}
        />
        <TextForm
          style={textFormStyle4}
          formLabel={textFormLabel4}
          placeholder={textFormPlaceholder4}
          name="email"
          value={email_value}
          onChange={onChange}
          uniqueId={uniqueId4}
          Lsm={2}
          Ism={6}
        />
        <TextForm
          style={textFormStyle5}
          formLabel={textFormLabel5}
          placeholder={textFormPlaceholder5}
          name="district_name"
          value={district_name_value}
          onChange={onChange}
          uniqueId={uniqueId5}
          Lsm={2}
          Ism={6}
        />
        <TextForm
          style={textFormStyle6}
          formLabel={textFormLabel6}
          placeholder={textFormPlaceholder6}
          name="polling_divisions"
          value={polling_divisions_value}
          onChange={onChange}
          uniqueId={uniqueId6}
          Lsm={2}
          Ism={6}
        />
        <TextForm
          style={textFormStyle7}
          formLabel={textFormLabel7}
          placeholder={textFormPlaceholder7}
          name="province_name"
          value={province_name_value}
          onChange={onChange}
          uniqueId={uniqueId7}
          Lsm={2}
          Ism={6}
        />
        <TextForm
          style={textFormStyle8}
          formLabel={textFormLabel8}
          placeholder={textFormPlaceholder8}
          name="voter_id"
          value={voter_id_value}
          onChange={onChange}
          uniqueId={uniqueId8}
          Lsm={2}
          Ism={6}
        />
        <Row className="mt-5">
          <Col
            sm={2}
            className="d-flex justify-content-start align-items-center"
          >
            <label className={className.cselectpageSizeLable}>
              Security Level
            </label>
          </Col>
          <Col sm={6}>
            <Cselect
              options={options}
              // isSearchable={isSearchable}
              className={className.cselectpageSize}
              styles={cstomStyle}
              onChange={selectChange}
              value={value}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default EditUserHero;
