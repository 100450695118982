import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import PostBlock from "../../../components/admin/postBlock";
// import {
//     GetSecurityLevelDetailsById,
//     // addSecurity,
//     // postUpdateSecurityLevelById,
// } from "../../../redux/admin/action";
import {
    ShowSecurityLevelById,
    addSecurity,
    UpdateSecurityLevelById,
} from "../../../redux/adminone/action";

import Loader from "../../../components/loaders/loader";
import { useSnackbar } from "react-simple-snackbar";

const override = `
    display: block;
    margin: 0 auto;
    border-color: red;
`;
const AddSecurity = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loading = useSelector((state) => state.adminone.loading);
    const [openSnackbar, closeSnackbar] = useSnackbar();
    const location = useLocation();
    const { id, securitylevel_name } = location.state || {};


    useEffect(() => {
        if (id) {
            dispatch(ShowSecurityLevelById(id));
        }
    }, [dispatch, id]);
    const ShowSecurityLevelByIdData = useSelector(
        (state) => state.adminone.ShowSecurityLevelByIdData?.data
    );

    const [formData, setFormData] = useState({
        name: "",
    });

    const [errors, setErrors] = useState({
        name: "",
    });
    useEffect(() => {
        if (
            id &&
            ShowSecurityLevelByIdData &&
            ShowSecurityLevelByIdData.security_level
        ) {
            setFormData({
                name: ShowSecurityLevelByIdData.security_level.securitylevel_name,
            });
        }
    }, [id, ShowSecurityLevelByIdData]);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });

        setErrors({
            ...errors,
            [name]: "",
        });
    };

    const handleSave = async () => {
        const { name } = formData;

        let valid = true;
        if (!name || name.length < 2) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                name: "Enter Security Name",
            }));
            valid = false;
        }

        if (!valid) return;

        const securityFormdata = new FormData();
        securityFormdata.append("name", formData.name);

        securityFormdata.append("name", formData.name);
        if (id) {
            dispatch(
                UpdateSecurityLevelById(securityFormdata, id, (error, response) => {
                    if (error) {

                    } else {


                        if (response.status === 200) {
                            openSnackbar(response.data.message);
                            navigate("/security-level");
                        }
                    }
                })
            );
        } else {
            dispatch(
                addSecurity(securityFormdata, (error, response) => {
                    if (error) {

                    } else {


                        if (response.status === 200) {
                            openSnackbar(response.data.message);
                            navigate("/security-level");
                        }
                    }
                })
            );
        }

        navigate(-1);
    };

    const handleCancel = () => {
        navigate(-1);
    };

    const props = {
        to1: "/security-level",
        title: id ? "Edit Securities" : "Add Securities",

        saveNextBtn: "Save and Next",
        addSeqBtnstyle: { padding: "1.3rem 2.8rem", marginLeft: "1.5rem" },
        save: "save",
        cancel: "cancel",
        textInput: [
            {
                label: "name",
                name: "name",
                value: formData.name,
                error: errors.name,
            },
        ],
        handleChange,

        handleSave,
        handleCancel,
    };

    return (
        <main className="aSecurity">
            <aside className="addbock">
                <PostBlock {...props} />
            </aside>
            {loading ? (
                <Loader color={"#0E76A8"} loading={loading} css={override} size={75} />
            ) : null}
        </main>
    );
};

export default AddSecurity;
