import React from "react";
import Card from "react-bootstrap/Card";
import IImage from "../image/iImage";
import { Container, Table } from "react-bootstrap";
import { Link } from "react-router-dom";

const ElectionReviewCard = ({ candidate, voteDetails, to, to1 }) => {
  return (
    <Card className="electionReviewCad pb-4">
      <Card.Title className="cardTitle text-center py-4 mb-0">
        <Link to={to} className="text-decoration-none cardTitleLink">
          {candidate.election_title_name}
        </Link>
      </Card.Title>
      <div className="text-center mt-3">
        <IImage src={candidate.candidate_image} className="mpLogoImg" />
      </div>
      <Card.Body className="text-center px-2">
        <Card.Title className="candidateTitle py-2">
          <Link to={to1} className="text-decoration-none candidateTitleLink">
            {candidate.candidate_name}
          </Link>
        </Card.Title>
        <div className="d-flex align-items-center justify-content-around">
          <IImage src={candidate.party_logo} className="pLogoImg" />
          <p className="pName">{candidate.party_name}</p>
        </div>
      </Card.Body>
      <Container fluid className="ercTotal">
        <Table striped bordered hover>
          <tbody>
            {voteDetails.map((detail, index) => (
              <tr key={index}>
                <td className="pText">{detail.label}</td>
                <td className="pText">{detail.value}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>
    </Card>
  );
};

export default ElectionReviewCard;
