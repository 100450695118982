import React from "react";

const ContentTab = ({ contentTabs, Accordion, buttons }) => {
    return (
        <div className="contentTabs-total">
            <h6 className="contentTabs-total-head">{contentTabs.contentTitle}</h6>
            <div className="provincial-details">
                {contentTabs.provincialDetailsData.map((item, index) => (
                    <div className="d-flex right-border" key={index}>
                        <span className={`sqr-box${index}`}></span>
                        <div className="registered-e-div">
                            <h6 className="provincial-vote">{item.value}</h6>
                            <p className="registered-electors mt-1">{item.label}</p>
                        </div>
                    </div>
                ))}
            </div>
            {buttons}
            <div>{Accordion}</div>
        </div>
    );
};

export default ContentTab;
