import React, { useEffect, useState } from "react";
import ElectionReviewCard from "../../../components/cards/electionReviewCard";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { allPresidentialElectionResults } from "../../../redux/user/action";
import { Col, Container, Row } from "react-bootstrap";
import SolidSquareBlueBtn from "../../../components/buttons/solidSquareBlueBtn";
import Cselect from "../../../components/select/cselect";
import Loader from "../../../components/loaders/loader";
import BlueBorderBtn from "../../../components/buttons/blueBorderBtn";
import { IoArrowBack } from "react-icons/io5";

const override = `
    display: block; 
    margin: 0 auto;
    border-color: red;
`;
const cstomStyle = {
  menu: (provided) => ({
    ...provided,
    backgroundColor: "white",
    color: "#65676B",
    maxWidth: "100%",
    borderColor: "#65676B",
    boxShadow: "unset",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#efefef" : "white",
    color: state.isFocused ? "#65676B" : "#65676B",
    padding: 5,
    maxHeight: 30,
  }),
};

const ParticularReport = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector((state) => state.user.loading);
  const [options, setOptions] = useState([]);
  const [currentTitle, setCurrentTitle] = useState("");
  const [defaultDistrictOption, setDefaultDistrictOption] = useState(null);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const election_title_id = searchParams.get("election_title_id");
  const getAllPresedentialElectionResultData = useSelector(
    (state) => state.user.getAllPresedentialElectionResultData
  );

  useEffect(() => {
    dispatch(allPresidentialElectionResults());
  }, [dispatch]);

  useEffect(() => {
    if (election_title_id) {
      dispatch(allPresidentialElectionResults(election_title_id));
    } else {
      dispatch(allPresidentialElectionResults());
    }
  }, [dispatch, election_title_id]);

  useEffect(() => {
    if (getAllPresedentialElectionResultData?.data) {
      setCurrentTitle(
        election_title_id
          ? getAllPresedentialElectionResultData.data.election_title_name || ""
          : "Presidential Election Sri Lanka Result"
      );
    }
  }, [getAllPresedentialElectionResultData, election_title_id]);

  console.log("election_title_id", election_title_id);

  const candidates =
    getAllPresedentialElectionResultData?.data?.data &&
    Array.isArray(getAllPresedentialElectionResultData.data.data) &&
    getAllPresedentialElectionResultData.data.data.map((item) => ({
      election_title_name: election_title_id
        ? item.district_name || ""
        : item.election_title_name || "",
      election_title_id: election_title_id
        ? item.district_id || ""
        : item.election_title_id || "",
      candidate_image: item.candidate_image || "",
      candidate_name: item.candidate_name || "",
      party_logo: item.party_logo || "",
      party_name: item.party_name || "",
      // district_id: item.district_id || "",
      voteDetails: [
        {
          label: "Total Received Votes",
          value: item.total_received_votes || "N/A",
        },
        {
          label: "Total Postal Votes",
          value: item.total_postal_votes || "N/A",
        },
        { label: "Total Votes", value: item.total_votes || "N/A" },
        {
          label: "Percentage Vote",
          value: `${
            parseFloat(item.percentage_votes).toFixed(
              item.percentage_votes % 1 === 0 ? 0 : 2
            ) || "N/A"
          }%`,
        },
      ],
    }));

  const selectChange = (selectedOption) => {
    //   setSelectedFilter(selectedOption);
    //   setIsElectionSelected(true);
    //   const allOption = options.find((option) => option.value === "all");
    //   setSelectedDistrictId(allOption.value);
    //   setDefaultDistrictOption(allOption);
    //   setSelectedPollingDivisionId(null);
    //   setDefaultPollingDivisionOption(null);
    //   setOptions1([]);
  };

  const handleClick = () => {
    navigate("/");
  };

  const onClick = () => {
    navigate(-1);
  };

  return (
    <main className="pReports">
      <Container className="pReports-header" fluid>
        <Row className="align-items-center" md={2} sm={1} xs={1}>
          <Col className="d-flex align-items-center">
            <BlueBorderBtn onClick={onClick}>
              <IoArrowBack />
            </BlueBorderBtn>
            <h2 className="section-heading ps-2">{currentTitle}</h2>
          </Col>
          <Col>
            {election_title_id && (
              <Cselect
                id="districtSelect"
                options={options}
                className="cselectpageSize"
                styles={cstomStyle}
                onChange={selectChange}
                value={defaultDistrictOption}
                placeholder="Select Option"
              />
            )}
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <Row>
          {loading ? (
            <Loader
              color={"#0E76A8"}
              loading={loading}
              css={override}
              size={75}
            />
          ) : candidates && candidates.length > 0 ? (
            candidates.map((candidate, index) => (
              <Col className="erCard" key={index} lg={4} md={6} sm={12}>
                <ElectionReviewCard
                  candidate={candidate}
                  voteDetails={candidate.voteDetails}
                  to={`/presidentialelection-report?election_title_id=${candidate.election_title_id}`}
                />
              </Col>
            ))
          ) : (
            <div className="erCard notFoundError">
              <span className="notFoundErrorTxt mb-2">Data Not Available</span>
              <SolidSquareBlueBtn
                style={{ padding: "1rem 2.5rem" }}
                onClick={handleClick}
              >
                Go To Home Page
              </SolidSquareBlueBtn>
            </div>
          )}
        </Row>
      </Container>
    </main>
  );
};

export default ParticularReport;
