import React, { useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import SeeVoteSimulatorResultMain from "./seeVoteSimulatorResultMain";
import { calculateVotingPercentage } from "../../../utilities/common";
import SolidBlueBtn from "../../../components/buttons/solidBlueBtn";
import IImage from "../../../components/image/iImage";
import { cApiUrlPrefix } from "../../../config/envConfig";

const SeeVoteSimulatorResultPage = () => {
    const location = useLocation();
    const user = JSON.parse(localStorage.getItem("user"));
    const { updateData, selectedOption } = location.state || {};
    const [isChecked, setIsChecked] = useState(false);

    const handleSwitchChange = () => {
        setIsChecked(!isChecked);
    };
    const pdfRef = useRef();

    if (!updateData) return null;

    const voteAggregation = {};
    for (const district of Object.values(updateData.simulators)) {
        for (const simulator of district.simulators) {
            const {
                candidate_id,
                candidate_name,
                candidate_image,
                party_id,
                party_name,
                party_logo,
                candidate_vote,
            } = simulator;

            if (!voteAggregation[candidate_id]) {
                voteAggregation[candidate_id] = {
                    candidate_id,
                    candidate_name,
                    candidate_image,
                    party_id,
                    party_name,
                    party_logo,
                    total_votes: 0,
                };
            }

            voteAggregation[candidate_id].total_votes += candidate_vote;
        }
    }

    const pieArray = Object.values(voteAggregation).map((candidate) => {
        const percentageString = calculateVotingPercentage(
            candidate.total_votes,
            updateData.all_district_total_votes
        );
        const percentageValue = parseFloat(percentageString.replace("%", ""));
        return {
            name: candidate.candidate_name,
            y: percentageValue,
        };
    });

    console.log("pieArray", pieArray);


    const cNameArray = Object.values(voteAggregation).map(
        (candidate) => candidate.candidate_name
    );
    const cImgArray = Object.values(voteAggregation).map(
        (candidate) => candidate.candidate_image
    );
    const voteArray = Object.values(voteAggregation).map(
        (candidate) => candidate.total_votes
    );
    const partyArray = Object.values(voteAggregation).map(
        (candidate) => candidate.party_name
    );

    const aggregatedList = Object.values(voteAggregation).sort(
        (a, b) => b.total_votes - a.total_votes
    );

    const topCandidate = aggregatedList[0] || {};

    const partyList = aggregatedList.filter(
        (candidate) => candidate.party_id === topCandidate.party_id
    );

    const totalPartyVotes = partyList.reduce(
        (sum, candidate) => sum + candidate.total_votes,
        0
    );

    const tableLabels = [
        "Candidate Image",
        "Candidate Name",
        "Party Logo",
        "Party Name",
        "Total Votes",
        "Per %",
    ];

    const data = aggregatedList.map((candidate) => ({
        "Candidate Image": (
            <figure className="mpLogo">
                <IImage src={candidate.candidate_image} className="mpLogoImg" />
            </figure>
        ),
        "Candidate Name": candidate.candidate_name,

        "Party Logo": (
            <figure className="mpLogo">
                <IImage src={candidate.party_logo} className="mpLogoImg" />
            </figure>
        ),
        "Party Name": candidate.party_name,
        "Total Votes": candidate.total_votes,
        "Per %": calculateVotingPercentage(
            candidate.total_votes,
            updateData.all_district_total_votes
        ),
    }));

    const provincialDetailsData = [
        { label: "Registered Electors", value: updateData.totalRegisteredVoters },
        { label: "Total Votes", value: updateData.all_district_total_votes },
        {
            label: "Total Voting Percentage",
            value: calculateVotingPercentage(
                updateData.all_district_total_votes,
                updateData.totalRegisteredVoters
            ),
        },
        { label: "Total Districts", value: updateData.totalDistrictsCount },
        {
            label: "Total Polling Divisions",
            value: updateData.totalPollingDivisions,
        },
    ];

    const handleDownloadPdf = () => {
        const input = pdfRef.current;
        html2canvas(input, {
            useCORS: true,
            allowTaint: false,
            scrollY: -window.scrollY,
        })
            .then((canvas) => {
                const imgData = canvas.toDataURL("image/png");
                const pdf = new jsPDF("p", "mm", "a4");
                const imgProps = pdf.getImageProperties(imgData);
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
                pdf.save("vote-simulator-result.pdf");
            })
            .catch((error) => {
                console.error("Error generating PDF:", error);
            });
    };

    const buttons = (
        <section className="svsrp-section">
            <SolidBlueBtn onClick={handleDownloadPdf}>Download as PDF</SolidBlueBtn>
        </section>
    );

    const columnOption = {
        chart: {
            type: "column",
            backgroundColor: "transparent",
        },
        title: {
            text: "Voting Distribution",
            style: {
                fontSize: "1.4rem",
            },
        },
        xAxis: {
            categories: cNameArray,
            labels: {
                useHTML: true,
                formatter: function () {
                    const index = this.pos;
                    const imageUrl = cImgArray[index];
                    return `<img src="${cApiUrlPrefix}${imageUrl}" alt="${this.value}" class="highChart-img" />`;
                },
            },
        },
        yAxis: {
            labels: {
                style: {
                    fontSize: "1.4rem",
                },
            },
        },
        series: [
            {
                name: "Votes",
                data: voteArray,
                colorByPoint: true,
                // colors: voteArray.map(getColorWithOpacity),
            },
            // {
            //   name: "Per%",
            //   data: perArray,
            //   colorByPoint: true,
            //   colors: perArray.map(getColorWithOpacityone),
            // },
        ],
        tooltip: {
            style: {
                fontSize: "1.4rem",
            },

            colorByPoint: true,
            formatter: function () {
                const pointIndex = this.point.index;
                const votes = voteArray[pointIndex];
                // const candidate = cNameArray[pointIndex];
                const partyName = partyArray[pointIndex];
                return `<b>${this.x}</b><br/>Votes: ${votes}<br/>Party: ${partyName}`;
            },
        },
        // exporting: {
        //     buttons: {
        //         contextButton: {
        //             menuItems: [
        //                 'viewFullscreen',
        //             ],
        //         },
        //     },
        // },
    };
    console.log("cNameArray", cNameArray);

    const pieOption = {
        chart: {
            type: "pie",
            backgroundColor: "transparent",
        },
        title: {
            text: "Percentage Distribution",
            style: {
                fontSize: "1.4rem",
            },
        },
        tooltip: {
            valueSuffix: "%",
            style: {
                fontSize: "1.4rem",
            },
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: "pointer",
                dataLabels: {
                    categories: cNameArray,
                    enabled: true,
                    // distance: 20,
                    format: "{point.name}: {point.percentage:.1f}%",
                    style: {
                        fontSize: "1.4rem",
                        textOutline: "none",
                    },
                    // filter: {
                    //     operator: ">",
                    //     property: "percentage",
                    //     value: 10,
                    // },
                },
            },
        },
        series: [
            {
                name: "Percentage",
                colorByPoint: true,
                data: pieArray,
            },
        ],
        exporting: {
            buttons: {
                contextButton: {
                    menuItems: [
                        'viewFullscreen',
                    ],
                },
            },
        },
    };

    const props = {
        columnOption,
        pieOption,
        selectedOption,
        provincialDetailsData,
        data,
        tableLabels,
        topCandidate,
        totalPartyVotes,
        buttons,
        handleSwitchChange,
        isChecked,
        locationDistrict: user.district_name,
        locationPollingDivision: user.polling_division_name
    };

    return (
        <div ref={pdfRef}>
            <SeeVoteSimulatorResultMain {...props} />
        </div>
    );
};

export default SeeVoteSimulatorResultPage;
