export const REGISTER_USER_REQUEST = "REGISTER_USER_REQUEST";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_FAILURE = "REGISTER_USER_FAILURE";

export const VERIFY_EMAIL_REQUEST = "VERIFY_EMAIL_REQUEST";
export const VERIFY_EMAIL_SUCCESS = "VERIFY_EMAIL_SUCCESS";
export const VERIFY_EMAIL_FAILURE = "VERIFY_EMAIL_FAILURE";

export const RESEND_OTP_REQUEST = "RESEND_OTP_REQUEST";
export const RESEND_OTP_SUCCESS = "RESEND_OTP_SUCCESS";
export const RESEND_OTP_FAILURE = "RESEND_OTP_FAILURE";

export const LOGIN_USER_REQUEST = "LOGIN_USER_REQUEST";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAILURE = "LOGIN_USER_FAILURE";

export const FORGOT_PASSWORD_SEND_EMAIL_REQUEST = "SEND_EMAIL_REQUEST";
export const FORGOT_PASSWORD_SEND_EMAIL_SUCCESS = "SEND_EMAIL_SUCCESS";
export const FORGOT_PASSWORD_SEND_EMAIL_FAILURE = "SEND_EMAIL_FAILURE";

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";

export const ADD_USERS_LOCATION_REQUEST = "ADD_USERS_LOCATION_REQUEST";
export const ADD_USERS_LOCATION_SUCCESS = "ADD_USERS_LOCATION_SUCCESS";
export const ADD_USERS_LOCATION_FAILURE = "UPDATE_USERS_LOCATION_FAILURE";

export const UPDATE_USERS_LOCATION_REQUEST = "UPDATE_USERS_LOCATION_REQUEST";
export const UPDATE_USERS_LOCATION_SUCCESS = "UPDATE_USERS_LOCATION_SUCCESS";
export const UPDATE_USERS_LOCATION_FAILURE = "UPDATE_USERS_LOCATION_FAILURE";

export const POST_REGISTRTVOTERID_REQUEST = "POST_REGISTRTVOTERID_REQUEST";
export const POST_REGISTRTVOTERID_SUCCESS = "POST_REGISTRTVOTERID_SUCCESS";
export const POST_REGISTRTVOTERID_FAILURE = "POST_REGISTRTVOTERID_FAILURE";

export const GET_USERDASHBOARD_REQUEST = "GET_USERDASHBOARD_REQUEST";
export const GET_USERDASHBOARD_SUCCESS = "GET_USERDASHBOARD_SUCCESS";
export const GET_USERDASHBOARD_FAILURE = "GET_USERDASHBOARD_FAILURE";

export const GET_FILTERELECTIONRESULTS_REQUEST =
  "GET_FILTERELECTIONRESULTS_REQUEST";
export const GET_FILTERELECTIONRESULTS_SUCCESS = "POST_REGISTRTVOTERID_SUCCESS";
export const GET_FILTERELECTIONRESULTS_FAILURE = "POST_REGISTRTVOTERID_FAILURE";

export const GET_ALL_CANDAIDATE_BY_TITLE_ID_REQUEST =
  "GET_ALL_CANDAIDATE_BY_TITLE_ID_REQUEST";
export const GET_ALL_CANDAIDATE_BY_TITLE_ID_SUCCESS =
  "GET_ALL_CANDAIDATE_BY_TITLE_ID_SUCCESS";
export const GET_ALL_CANDAIDATE_BY_TITLE_ID_FAILURE =
  "GET_ALL_CANDAIDATE_BY_TITLE_ID_FAILURE";

export const ADD_PRESIDENTIAL_ELECTION_RESULTS_BY_DID_UID_REQUEST =
  "ADD_PRESIDENTIAL_ELECTION_RESULTS_BY_DID_UID_REQUEST";
export const ADD_PRESIDENTIAL_ELECTION_RESULTS_BY_DID_UID_SUCCESS =
  "ADD_PRESIDENTIAL_ELECTION_RESULTS_BY_DID_UID_SUCCESS";
export const ADD_PRESIDENTIAL_ELECTION_RESULTS_BY_DID_UID_FAILURE =
  "ADD_PRESIDENTIAL_ELECTION_RESULTS_BY_DID_UID_FAILURE";

export const UPDATE_PRESIDENTIAL_ELECTION_RESULTS_BY_DID_UID_REQUEST =
  "UPDATE_PRESIDENTIAL_ELECTION_RESULTS_BY_DID_UID_REQUEST";
export const UPDATE_PRESIDENTIAL_ELECTION_RESULTS_BY_DID_UID_SUCCESS =
  "UPDATE_PRESIDENTIAL_ELECTION_RESULTS_BY_DID_UID_SUCCESS";
export const UPDATE_PRESIDENTIAL_ELECTION_RESULTS_BY_DID_UID_FAILURE =
  "UPDATE_PRESIDENTIAL_ELECTION_RESULTS_BY_DID_UID_FAILURE";

export const GET_ELECTION_USERID_ELECTIONID_REQUEST =
  "GET_ELECTION_USERID_ELECTIONID_REQUEST";
export const GET_ELECTION_USERID_ELECTIONID_SUCCESS =
  "GET_ELECTION_USERID_ELECTIONID_SUCCESS";
export const GET_ELECTION_USERID_ELECTIONID_FAILURE =
  "GET_ELECTION_USERID_ELECTIONID_FAILURE";

export const GET_WINNER_CALCULATE_VOTES_REQUEST =
  "GET_WINNER_CALCULATE_VOTES_REQUEST";
export const GET_WINNER_CALCULATE_VOTES_SUCCESS =
  "GET_WINNER_CALCULATE_VOTES_SUCCESS";
export const GET_WINNER_CALCULATE_VOTES_FAILURE =
  "GET_WINNER_CALCULATE_VOTES_FAILURE";

export const UPDATE_EDIT_USER_BY_USER_ID_REQUEST =
  "UPDATE_EDIT_USER_BY_USER_ID_REQUEST";
export const UPDATE_EDIT_USER_BY_USER_ID_SUCCESS =
  "UPDATE_EDIT_USER_BY_USER_ID_SUCCESS";
export const UPDATE_EDIT_USER_BY_USER_ID_FAILURE =
  "UPDATE_EDIT_USER_BY_USER_ID_FAILURE";

export const UPDATE_EMAIL_BY_USER_ID_REQUEST =
  "UPDATE_EMAIL_BY_USER_ID_REQUEST";
export const UPDATE_EMAIL_BY_USER_ID_SUCCESS =
  "UPDATE_EMAIL_BY_USER_ID_SUCCESS";
export const UPDATE_EMAIL_BY_USER_ID_FAILURE =
  "UPDATE_EMAIL_BY_USER_ID_FAILURE";

export const GET_ALL_PRESIDENTIAL_SIMULATORS_REQUEST =
  "GET_ALL_PRESIDENTIAL_SIMULATORS_REQUEST";
export const GET_ALL_PRESIDENTIAL_SIMULATORS_SUCCESS =
  "GET_ALL_PRESIDENTIAL_SIMULATORS_SUCCESS";
export const GET_ALL_PRESIDENTIAL_SIMULATORS_FAILURE =
  "GET_ALL_PRESIDENTIAL_SIMULATORS_FAILURE";

export const GET_ALLPRESIDENTIALELECTIONRESULTS_BYID_REQUEST =
  "GET_ALLPRESIDENTIALELECTIONRESULTS_BYID_REQUEST";
export const GET_ALLPRESIDENTIALELECTIONRESULTS_BYID_SUCCESS =
  "GET_ALLPRESIDENTIALELECTIONRESULTS_BYID_SUCCESS";
export const GET_ALLPRESIDENTIALELECTIONRESULTS_BYID_FAILURE =
  "GET_ALLPRESIDENTIALELECTIONRESULTS_BYID_FAILURE";

export const GET_ALLCANDIDATERESULTS_BYID_REQUEST =
  "GET_ALLCANDIDATERESULTS_BYID_REQUEST";
export const GET_ALLCANDIDATERESULTS_BYID_SUCCESS =
  "GET_ALLCANDIDATERESULTS_BYID_SUCCESS";
export const GET_ALLCANDIDATERESULTS_BYID_FAILURE =
  "GET_ALLCANDIDATERESULTS_BYID_FAILURE";

export const ADD_PARLIAMENT_ELECTION_RESULTS_BY_PID_CID_REQUEST =
  "ADD_PARLIAMENT_ELECTION_RESULTS_BY_PID_CID_REQUEST";
export const ADD_PARLIAMENT_ELECTION_RESULTS_BY_PID_CID_SUCCESS =
  "ADD_PARLIAMENT_ELECTION_RESULTS_BY_PID_CID_SUCCESS";
export const ADD_PARLIAMENT_ELECTION_RESULTS_BY_PID_CID_FAILURE =
  "ADD_PARLIAMENT_ELECTION_RESULTS_BY_PID_CID_FAILURE";

export const GET_PARLIAMENT_ELECTION_CANDIDATERESULTS_BYID_REQUEST =
  "GET_PARLIAMENT_ELECTION_CANDIDATERESULTS_BYID_REQUEST";
export const GET_PARLIAMENT_ELECTION_CANDIDATERESULTS_BYID_SUCCESS =
  "GET_PARLIAMENT_ELECTION_CANDIDATERESULTS_BYID_SUCCESS";
export const GET_PARLIAMENT_ELECTION_CANDIDATERESULTS_BYID_FAILURE =
  "GET_PARLIAMENT_ELECTION_CANDIDATERESULTS_BYID_FAILURE";

export const UPDATE_PARLIAMENT_ELECTION_RESULTS_BY_PID_CID_REQUEST =
  "UPDATE_PARLIAMENT_ELECTION_RESULTS_BY_PID_CID_REQUEST";
export const UPDATE_PARLIAMENT_ELECTION_RESULTS_BY_PID_CID_SUCCESS =
  "UPDATE_PARLIAMENT_ELECTION_RESULTS_BY_PID_CID_SUCCESS";
export const UPDATE_PARLIAMENT_ELECTION_RESULTS_BY_PID_CID_FAILURE =
  "UPDATE_PARLIAMENT_ELECTION_RESULTS_BY_PID_CID_FAILURE";

export const POST_PARLIAMENT_SIMULATOR_BY_USER_ID_ELECTION_TITLE_ID_REQUEST =
  "POST_PARLIAMENT_SIMULATOR_BY_USER_ID_ELECTION_TITLE_ID_REQUEST";
export const POST_PARLIAMENT_SIMULATOR_BY_USER_ID_ELECTION_TITLE_ID_SUCCESS =
  "POST_PARLIAMENT_SIMULATOR_BY_USER_ID_ELECTION_TITLE_ID_SUCCESS";
export const POST_PARLIAMENT_SIMULATOR_BY_USER_ID_ELECTION_TITLE_ID_FAILURE =
  "POST_PARLIAMENT_SIMULATOR_BY_USER_ID_ELECTION_TITLE_ID_FAILURE";

export const GET_PARLIAMENT_SIMULATOR_BY_SIMULATOR_ID_REQUEST =
  "GET_PARLIAMENT_SIMULATOR_BY_SIMULATOR_ID_REQUEST";
export const GET_PARLIAMENT_SIMULATOR_BY_SIMULATOR_ID_SUCCESS =
  "GET_PARLIAMENT_SIMULATOR_BY_SIMULATOR_ID_SUCCESS";
export const GET_PARLIAMENT_SIMULATOR_BY_SIMULATOR_ID_FAILURE =
  "GET_PARLIAMENT_SIMULATOR_BY_SIMULATOR_ID_FAILURE";

export const POST_UPDATE_PARLIAMENT_SIMULATOR_BY_USER_ID_ELECTION_TITLE_ID_REQUEST =
  "POST_UPDATE_PARLIAMENT_SIMULATOR_BY_USER_ID_ELECTION_TITLE_ID_REQUEST";
export const POST_UPDATE_PARLIAMENT_SIMULATOR_BY_USER_ID_ELECTION_TITLE_ID_SUCCESS =
  "POST_UPDATE_PARLIAMENT_SIMULATOR_BY_USER_ID_ELECTION_TITLE_ID_SUCCESS";
export const POST_UPDATE_PARLIAMENT_SIMULATOR_BY_USER_ID_ELECTION_TITLE_ID_FAILURE =
  "POST_UPDATE_PARLIAMENT_SIMULATOR_BY_USER_ID_ELECTION_TITLE_ID_FAILURE";

export const GET_PARLIAMENT_SEAT_ALLOCATION_BY_ELECTION_TITLE_ID_REQUEST =
  "GET_PARLIAMENT_SEAT_ALLOCATION_BY_ELECTION_TITLE_ID_REQUEST";
export const GET_PARLIAMENT_SEAT_ALLOCATION_BY_ELECTION_TITLE_ID_SUCCESS =
  "GET_PARLIAMENT_SEAT_ALLOCATION_BY_ELECTION_TITLE_ID_SUCCESS";
export const GET_PARLIAMENT_SEAT_ALLOCATION_BY_ELECTION_TITLE_ID_FAILURE =
  "GET_PARLIAMENT_SEAT_ALLOCATION_BY_ELECTION_TITLE_ID_FAILURE";

export const GET_PARLIAMENT_SEAT_CANDIATE_ALLOCATION_BY_ELECTION_TITLE_ID_REQUEST =
  "GET_PARLIAMENT_SEAT_CANDIATE_ALLOCATION_BY_ELECTION_TITLE_ID_REQUEST";
export const GET_PARLIAMENT_SEAT_CANDIATE_ALLOCATION_BY_ELECTION_TITLE_ID_SUCCESS =
  "GET_PARLIAMENT_SEAT_CANDIATE_ALLOCATION_BY_ELECTION_TITLE_ID_SUCCESS";
export const GET_PARLIAMENT_SEAT_CANDIATE_ALLOCATION_BY_ELECTION_TITLE_ID_FAILURE =
  "GET_PARLIAMENT_SEAT_CANDIATE_ALLOCATION_BY_ELECTION_TITLE_ID_FAILURE";

export const GET_ALL_SIMULATORS_REQUEST = "GET_ALL_SIMULATORS_REQUEST";
export const GET_ALL_SIMULATORS_SUCCESS = "GET_ALL_SIMULATORS_SUCCESS";
export const GET_ALL_SIMULATORS_FAILURE = "GET_ALL_SIMULATORS_FAILURE";

export const LOGOUT_USER = "LOGOUT_USER";
