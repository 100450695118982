import React from 'react';
import { Button } from 'react-bootstrap';

const BlueBorderBtn = ({ children, onClick, style }) => {
    return (
        <Button onClick={onClick} className="blueborderbtn" style={style}>
            {children}
        </Button>
    )
}

export default BlueBorderBtn;
