import React, { useState, useEffect } from "react";
import IImage from "../../../components/image/iImage";
import ManageBlk from "../../../components/admin/manageBlk";
import { useDispatch, useSelector } from "react-redux";
import InActive from "../../../components/labels/inActive";
import Active from "../../../components/labels/active";
import DropDown from "../../../components/dropdown/dropDown";
import DeleteCModal from "../../../components/modals/deleteCModal";

import { delCandidateById, ShowCandidateById, indexCandidate } from "../../../redux/adminone/action";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "react-simple-snackbar";

const options = [
  { value: 10, label: 10 },
  { value: 20, label: 20 },
  { value: 30, label: 30 },
];

const cstomStyle = {
  menu: (provided) => ({
    ...provided,
    backgroundColor: "white",
    color: "#65676B",
    maxWidth: "97px",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#efefef" : "white",
    color: state.isFocused ? "#65676B" : "#65676B",
    padding: 5,
  }),
};

const ManageCandidate = () => {
  const dispatch = useDispatch();


  useEffect(() => {

    dispatch(indexCandidate());


  }, [dispatch]);

  const [isSearchable] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedParty, setSelectedParty] = useState(null);
  const [openSnackbar, closeSnackbar] = useSnackbar();

  const indexCandidateData = useSelector((state) => state.adminone.indexCandidateData?.data);
  const navigate = useNavigate();
  const loading = useSelector((state) => state.adminone.loading);
  const error = useSelector((state) => state.adminone.error);

  const statusMap = {
    0: <InActive />,
    1: <Active />,
  };

  const dropdownItems = (data) => [
    { label: "Edit", href: "#/edit", onClick: () => handleEditCandidate(data), },
    {
      label: "Delete",
      href: "#/delete",
      onClick: () => handleOpenModal(data),
    },
    { label: "Manage Candidate History", href: "#/action-3", onClick: () => handleHistory(data) },
  ];

  const handleOpenModal = (data) => {
    setSelectedParty(data);
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedParty(null);
  };

  const handleEditCandidate = (data) => {
    dispatch(ShowCandidateById(data.id));
    navigate("/edit-candidate-by-id", {
      state: {
        id: data.id,
        candidate_name: data.candidate_name,
        status: data.status
      }
    });
    // navigate(
    //   `/edit-candidate-by-id?id=${data.id}&candidate_name=${encodeURIComponent(
    //     data.candidate_name
    //   )}&status=${data.status}`
    // );
  };

  const handleConfirmDelete = () => {
    if (selectedParty) {
      const id = selectedParty.id;
      dispatch(delCandidateById(id, (error, response) => {
        if (error) {
          // handle the error

        } else {
          // handle the response

          if (response.status === 200) {
            dispatch(indexCandidate());
            openSnackbar(response.data.message);
          }
        }
      }));
      handleCloseModal();
    }
  };

  const handleHistory = (data) => {

    navigate("/candidate-history", {
      state: {
        id: data.id,
        candidate_name: data.candidate_name,

      }
    });

    // navigate(`/candidate-history?id=${data.id}&candidate_name=${encodeURIComponent(data.candidate_name)}`);
  }

  const labels = [
    "Candidate Image",
    "Candidate Name",
    "Assosiated Party Name",
    "Status",
    "Action",
  ];
  const data = Array.isArray(indexCandidateData)
    ? indexCandidateData.map((candidate) => ({
      "Candidate Image": (
        <figure className="mcLogo">
          <IImage src={candidate.candidate_image} />
        </figure>
      ),

      "Candidate Name": candidate.candidate_name,
      "Assosiated Party Name": candidate.party_name,
      Status: statusMap[candidate.status],
      Action: (
        <DropDown
          className="managCandidateAction ms-auto"
          Children={"Action"}
          items={dropdownItems(candidate)}
        />
      ),
    }))
    : [];

  const manageCandidateBlkProps = {
    title: "Candidates",
    children: "Add Candidate",
    to: "/add-candidate",
    style: { padding: "1.3rem 2.8rem" },
    options,
    isSearchable,
    className: {
      cselectpageSize: "cselectpageSize",
      minputSearch: "minputSearch",
      deleteModal: "deleteModal",
    },
    cstomStyle,
    Placeholder: "Search",
    labels,
    data,
    loading,
    error,
  };

  return (
    <main className="mCandidate">
      <aside className="pmCandidate">
        <ManageBlk {...manageCandidateBlkProps} />
      </aside>
      <DeleteCModal
        show={showModal}
        handleClose={handleCloseModal}
        handleConfirm={handleConfirmDelete}
        className={manageCandidateBlkProps.className.deleteModal}
      />
    </main>
  );
};

export default ManageCandidate;
