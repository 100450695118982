import React from "react";
import Button from "react-bootstrap/Button";
const SolidBlueBtn = ({ children, style, onClick, disabled, type }) => {
  return (
    <Button
      type={type}
      disabled={disabled}
      style={style}
      onClick={onClick}
      className="solidBlueBtn"
    >
      {children}
    </Button>
  );
};

export default SolidBlueBtn;
