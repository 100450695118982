import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

const LayOutTwo = (props) => {
    return (
        <main className='main-layout-two position-relative'>
            <Container className='container-layout-two' fluid>
                <Row className='row-layout-two'>
                    <Col className='col-layout-two' md={2}>
                        {props.components.component1}
                    </Col>
                    <Col className='col-layout-two' md={6}>
                        {props.components.component2}
                    </Col>
                    <Col className='col-layout-two' md={4}>
                        {props.components.component3}
                    </Col>
                </Row>
            </Container>

        </main>
    )
}

export default LayOutTwo;
