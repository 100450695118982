import React from "react";
import { Modal } from "react-bootstrap";
import { IoMdCheckmark } from "react-icons/io";
// import { Link } from 'react-router-dom';
import GreySolidBtn from "../buttons/greySolidBtn";
import TransparentBtn from "../buttons/transparentBtn";

const VoteSubmited = ({
  show,
  handleChangeClick,
  handleChangeClick1,
  handleClose,
  district,
  pd,
}) => {
  return (
    <Modal
      size="md"
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className="votersubmited"
    >
      <Modal.Header>
        <div className="checkblk">
          <IoMdCheckmark className="modalcheck" />
        </div>
      </Modal.Header>
      <Modal.Body>
        <p className="ays">
          Your vote has been recorded for{" "}
          <span className="high">{district}</span> Districts,{" "}
          <span className="high">{pd}</span> polling division
        </p>
      </Modal.Body>
      <Modal.Footer>
        <GreySolidBtn onClick={handleChangeClick}>Change Vote</GreySolidBtn>
        <TransparentBtn
          className="sepr-link"
          Children=" See Exit Poll Result"
          onClick={handleChangeClick1}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default VoteSubmited;
