import React from "react";
import Form from "react-bootstrap/Form";
import Input from "../inputs/input";
import SolidSquareBlueBtn from "../buttons/solidSquareBlueBtn";

const SearchBtnForm = (props) => {
    const { className, placeholder, style } = props;
    return (
        <Form className="searchbtnform">
            <Input className={className} placeholder={placeholder}  id="unique-input-id" name= "unique-input-name" />
            <SolidSquareBlueBtn style={style}> Search </SolidSquareBlueBtn>
        </Form>
    );
};

export default SearchBtnForm;
