import React, { useState, useRef, useEffect } from "react";
import Image from "react-bootstrap/Image";
import Logo from "../../../assets/images/logo.png";
import Form from "react-bootstrap/Form";
import SolidBlueBtn from "../../../components/buttons/solidBlueBtn";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Container } from "react-bootstrap";
import { resendOTP, verfyEmailByOtp } from "../../../redux/user/action";
import { toast } from "react-toastify";
import Loader from "../../../components/loaders/loader";

const override = `
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

const OTP = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [focusedInput, setFocusedInput] = useState(null);
  const [timer, setTimer] = useState(60); // Initial timer value in seconds
  const [timerExpired, setTimerExpired] = useState(false); // Track if timer has expired
  const [storedEmail, setStoredEmail] = useState(""); // State to hold email from local storage
  const inputRefs = useRef([]);
  const loading = useSelector((state) => state.user.loading);
  let countdown; // Define countdown outside useEffect

  useEffect(() => {
    const email =
      localStorage.getItem("new_email") || localStorage.getItem("email");
    setStoredEmail(email);

    const countdown = setInterval(() => {
      // Define countdown inside the useEffect
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    return () => clearInterval(countdown);
  }, []);

  useEffect(() => {
    if (timer === 0) {
      // Timer expired, perform any action you want
      clearInterval(countdown);
      setTimerExpired(true);
    }
  }, [timer]);

  useEffect(() => {
    const handleBackButton = (event) => {
      event.preventDefault();
      window.history.pushState(null, null, window.location.pathname);
    };

    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, []);

  const handleChange = (e, index) => {
    const { value } = e.target;
    if (value.length <= 1) {
      // Allow only numeric input
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Move focus to the next input field if available
      if (value === "" && index > 0) {
        inputRefs.current[index - 1].focus();
      } else if (index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleFocus = (index) => {
    setFocusedInput(index);
  };

  const handleBlur = () => {
    setFocusedInput(null);
  };

  const handleClick = async (event) => {
    event.preventDefault();
    const Otp = otp.join("");
    const email = localStorage.getItem("new_email") || localStorage.getItem("email");
    const formData = new FormData();
    formData.append("email", email);
    formData.append("otp", Otp);

    dispatch(
      verfyEmailByOtp(formData, (error, response) => {
        if (error && error.status === 422) {
          // handle the error

          toast.error(error.data.message, {
            position: "top-center",
            style: {
              fontSize: "1.6rem", // Adjust the font size as needed
            },
          });
        } else {
          // handle the response

          if (response && response.status === 200) {
            toast.success(response.data.message, {
              position: "top-center",
              style: {
                fontSize: "1.6rem", // Adjust the font size as needed
              },
            });
            navigate("/login");
            localStorage.removeItem("email");
            localStorage.clear()
          }
        }
      })
    );
  };
  const handleResendOTP = async () => {
    const email = localStorage.getItem("new_email") || localStorage.getItem("email");
    const formData = new FormData();
    formData.append("email", email);
    dispatch(
      resendOTP(formData, (error, response) => {
        if (error && error.status === 422) {
          // handle the error

          toast.error(error.data.message, {
            position: "top-center",
            style: {
              fontSize: "1.6rem", // Adjust the font size as needed
            },
          });
        } else {
          // handle the response

          if (response && response.status === 200) {
            toast.success(response.data.message, {
              position: "top-center",
              style: {
                fontSize: "1.6rem", // Adjust the font size as needed
              },
            });
          }
        }
      })
    );
    setTimer(60);
  };
  // Check if all OTP fields are filled
  const isOtpFilled = otp.every((value) => value.length === 1);

  return (
    <main className="main-auth">
      <Container fluid="xxl">
        <div className="enterOTPMain">
          <figure>
            <Image src={Logo} fluid />
          </figure>
          <div className="authLastBlk">
            <h2 className="authHead">Verify Email address</h2>
            <p className="authPara">
              Please check your registered email address. We sent OTP to{" "}
              {storedEmail && `${storedEmail.substring(0, 2)}******@g****.com`}
            </p>
            <Form className="eoForm">
              <Form.Group className="eoFieldsetBlk" controlId="formBasicEmail">
                {Array.from({ length: 6 }, (_, index) => (
                  <Form.Control
                    key={index}
                    className="eoFieldset"
                    inputMode="numeric"
                    maxLength="1" // Limit input to single digit
                    type="text"
                    value={otp[index]}
                    onChange={(e) => handleChange(e, index)}
                    onFocus={() => handleFocus(index)}
                    onBlur={handleBlur}
                    ref={(input) => (inputRefs.current[index] = input)}
                  />
                ))}
              </Form.Group>
              <div className="otptimer">
                {timerExpired ? (
                  <button
                    type="button"
                    onClick={handleResendOTP}
                    className="resetBtn"
                  >
                    Resend OTP
                  </button>
                ) : timer > 0 ? (
                  `${timer} sec`
                ) : (
                  "resend otp"
                )}
              </div>
              <div className="text-center seoFormBtn">
                <SolidBlueBtn
                  onClick={handleClick}
                  style={{ padding: "1.3rem 7.6rem" }}
                  disabled={!isOtpFilled} // Disable button if OTP is not fully entered
                >
                  Send OTP
                </SolidBlueBtn>
              </div>
            </Form>
          </div>
        </div>
      </Container>
      {loading ? (
        <Loader color={"#0E76A8"} loading={loading} css={override} size={75} />
      ) : null}
    </main>
  );
};

export default OTP;
