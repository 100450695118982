import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../components/loaders/loader";
import disImg from "../../../assets/images/sreelamkaImg.png";
import { indexCandidate } from "../../../redux/adminone/action";
import { Col, Container, Row } from "react-bootstrap";
import SearchBtnForm from "../../../components/form/searchBtnForm";
// import CardTwo from "../../../components/cards/cardTwo";
import { cApiUrlPrefix } from "../../../config/envConfig";
// import { formatDate } from "../../../utilities/common";
import { useNavigate } from "react-router-dom";
import LayOutOne from "../../../components/layOut/layOutOne";

const override = `
    display: block;
    margin: 0 auto;
    border-color: red;
`;

const CandidatesPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(indexCandidate());
  }, [dispatch]);

  const indexCandidateData = useSelector(
    (state) => state.adminone.indexCandidateData?.data
  );
  const loading = useSelector((state) => state.adminone.loading);

  const data =
    indexCandidateData?.map((candidate) => ({
      id: candidate.id,
      name: candidate.candidate_name,
      image:
        candidate.candidate_image === "0"
          ? disImg
          : cApiUrlPrefix + candidate.candidate_image,
    })) || [];
  // const user = JSON.parse(localStorage.getItem("user"));
  // const activeId = user?.district_id;

  const handleClick = (id) => {
    console.log("id", id);
    navigate("/candidates-history", { state: { id } });
  };
  
  const props = {
    titles: {
      title1: "CANDIDATES",
    },
    // childrens: {
    //   children1: "View More",
    // },
    headings: {
      heading1: "",
      // heading2: "Party Name",
      // heading3: "Date of Birth",
    },
    handleClick,
    data,
    // activeId,
  };

  return (
    <main className="candidate-main">
      <Container fluid className="container-candidate">
        <Row className="row-candidate">
          <Col className="col-candidate">
            <h2 className="candidate-heading">{props.titles.title1}</h2>
          </Col>
          <Col className="col-candidate">
            <SearchBtnForm
              className="minputSearch"
              placeholder="search candidate..."
            />
          </Col>
        </Row>
        {/* <Row className="row-candidate" xs={1}>
          {indexCandidateData &&
            indexCandidateData.map((candidate) => (
              <Col key={candidate.id} className="col-candidate">
                <CardTwo
                  src={cApiUrlPrefix + candidate.candidate_image}
                  title={candidate.candidate_name}
                  bio={
                    candidate.bio.length > 150
                      ? candidate.bio.substring(0, 150) + "..."
                      : candidate.bio
                  }
                  position={candidate.position}
                  party_name={candidate.party_name}
                  date_of_birth={formatDate(candidate.date_of_birth)}
                  childrens={props.childrens}
                  headings={props.headings}
                  onClick={() => props.handleClick(candidate.id)}
                />
              </Col>
            ))}
        </Row> */}
      </Container>
      <LayOutOne {...props} />
      {loading ? (
        <Loader color={"#0E76A8"} loading={loading} css={override} size={75} />
      ) : null}
    </main>
  );
};

export default CandidatesPage;
