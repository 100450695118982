import React from "react";
import { Col, Row } from "react-bootstrap";
import HtmlEditor from "./htmlEditor";
import MinusButton from "../buttons/minusButton";

const HtmlEditorRow = (props) => {
    const { label, value, onChange, removeBlock } = props;

    return (
        <Row className="htmlEditiorRow">
            <Col sm={1}>
                <label htmlFor="" className="htmlEditiorLable" >{label}</label>
            </Col>
            <Col sm={10} className="htmlEditiorCol">
                <HtmlEditor
                    initialValue={value}
                    onChange={onChange}
                />
            </Col>
            <Col sm={1} className="htmlEditiorCol1">
                <MinusButton onClick={removeBlock} />
            </Col>
        </Row>
    );
};

export default HtmlEditorRow;
