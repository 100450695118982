const envs = {
    DEV: "DEV",
    TEST: "TEST",
    PROD: "PROD",
};

const cEnvironment = process.env.NODE_ENV === "development" ? envs.DEV :
    process.env.NODE_ENV === "test" ? envs.TEST : envs.PROD;

const cApiUrlPrefix = process.env[`REACT_APP_API_URL_${cEnvironment}`];

export {
    envs,
    cEnvironment,
    cApiUrlPrefix,
};
