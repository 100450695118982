import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../components/loaders/loader";
import PostBlockSeven from "../../../components/admin/postBlockSeven";
import { useLocation, useNavigate } from "react-router-dom";
import { useSnackbar } from "react-simple-snackbar";
import {
  addBlogContent,
  indexGetAllBlogCategory,
  showBlogContentById,
  updateBlogContentById,
} from "../../../redux/adminone/action";

const override = `
    display: block;
    margin: 0 auto;
    border-color: red;
`;
const cstomStyle = {
  menu: (provided) => ({
    ...provided,
    backgroundColor: "white",
    color: "#65676B",
    Width: "fit-content",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#efefef" : "white",
    color: state.isFocused ? "#65676B" : "#65676B",
    padding: 5,
  }),
};

const AddBlogs = ({ initialValues }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector((state) => state.adminone.loading);
  const location = useLocation();
  const { id } = location.state || {};
  // const params = new URLSearchParams(location.search);
  // const id = params.get("id");
  const [openSnackbar] = useSnackbar();
  const [options, setOptions] = useState([]);
  const [selectedCandidateId, setSelectedCandidateId] = useState(null);
  const [defaultPartyoptions, setDefaultPartyoptions] = useState(null);

  const [formData, setFormData] = useState({
    category_name: initialValues?.category_name ?? "",
    blog_image: initialValues?.blog_image ?? null,
    isactive: initialValues?.isactive ?? 1,
    overview: initialValues?.overview ?? "",
    content: initialValues?.content ?? "",
    blog_title: initialValues?.blog_title ?? "",
  });

  const [blocks, setBlocks] = useState("");

  const showBlogContentByIdData = useSelector(
    (state) => state.adminone.showBlogContentByIdData?.data
  );

  const getAllBlogCategory = useSelector(
    (state) => state.adminone.getAllBlogCategory?.data
  );

  const [errors, setErrors] = useState({
    category_name: "",
    blog_image: "",
    isactive: "",
    overview: "",
    content: "",
    blog_title: "",
  });

  useEffect(() => {
    if (id) {
      dispatch(showBlogContentById(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (location.pathname === "/edit-blogs-content") {
      dispatch(indexGetAllBlogCategory());
    }
  }, [dispatch, location.pathname]);

  useEffect(() => {
    if (id && showBlogContentByIdData) {
      setFormData({
        overview: showBlogContentByIdData.overview ?? "",
        content: showBlogContentByIdData.content ?? "",
        blog_title: showBlogContentByIdData.blog_title ?? "",
        blog_image: showBlogContentByIdData.blog_image ?? null,
        isactive: showBlogContentByIdData.isactive ?? "",
        category_name: showBlogContentByIdData.category_name ?? "",
      });
      setDefaultPartyoptions({
        value: showBlogContentByIdData.categoryid,
        label: showBlogContentByIdData.category_name,
      });
      setSelectedCandidateId(showBlogContentByIdData.categoryid);

      if (Array.isArray(showBlogContentByIdData.blocks)) {
        setBlocks(
          showBlogContentByIdData.blocks
            .map((block) => block.content)
            .join("\n")
        );
      }
    }
  }, [id, showBlogContentByIdData]);

  useEffect(() => {
    if (getAllBlogCategory) {
      const newOptions = getAllBlogCategory.map((item) => ({
        value: item.id,
        label: item.category_name,
      }));
      setOptions(newOptions);
    }
  }, [getAllBlogCategory]);

  useEffect(() => {

    if (location.pathname === "/add-blogs-content") {
      setFormData({
        category_name: "",
        blog_image: null,
        isactive: 1,
        overview: "",
        content: "",
        blog_title: "",
        categoryid: "",
      });
      setBlocks("");
      setDefaultPartyoptions(null);
      setSelectedCandidateId(null);
    }
  }, [location.pathname]);

  const handleToggle = () => {
    setFormData((prevState) => ({
      ...prevState,
      isactive: prevState.isactive === 1 ? 0 : 1,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: "",
    });
  };

  useEffect(() => {
    if (
      Array.isArray(showBlogContentByIdData) &&
      showBlogContentByIdData.length > 0
    ) {
      const initialBlocks = showBlogContentByIdData.map((item) => ({
        content: item.content,
      }));
      setBlocks(initialBlocks);
    }
  }, [showBlogContentByIdData]);

  useEffect(() => {
    if (initialValues?.content) {
      setBlocks(initialValues.content);
    }
  }, [initialValues]);

  const handleChanges = (content) => {
    const stripHtmlTags = (html) => {
      const doc = new DOMParser().parseFromString(html, "text/html");
      return doc.body.textContent || "";
    };

    const plainText = stripHtmlTags(content);
    setBlocks(plainText);
  };

  const handleUpload = (imageList) => {
    if (imageList.length > 0) {
      setFormData((prevData) => ({
        ...prevData,
        blog_image: imageList[0].file,
      }));
      setErrors((prevErrors) => ({ ...prevErrors, blog_image: "" }));
    } else {
      setFormData((prevData) => ({ ...prevData, blog_image: null }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        blog_image: "Candidate image is required.",
      }));
    }
  };

  const handleSave = async (event) => {
    event.preventDefault();

    const { category_name, blog_image, blog_title, overview } = formData;

    const isLogoUpdated = showBlogContentByIdData?.blog_image !== blog_image;

    let valid = true;

    if (!category_name || category_name.length < 2) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        category_name: "Enter Category Name",
      }));
      valid = false;
    }

    if (!blog_title || blog_title.length < 2) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        blog_title: "Enter Blog Title",
      }));
      valid = false;
    }

    if (!overview || overview.length < 2) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        overview: "Enter Overview",
      }));
      valid = false;
    }

    if (!blog_image) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        blog_image: "Upload a Blog Image",
      }));
      valid = false;
    }

    if (!valid) return;

    const formDataContent = new FormData();
    formDataContent.append("category_name", defaultPartyoptions.label);
    formDataContent.append("categoryid", selectedCandidateId);
    formDataContent.append("blog_title", formData.blog_title);
    formDataContent.append("overview", formData.overview);
    formDataContent.append("content", blocks);
    formDataContent.append("isactive", formData.isactive);

    if (isLogoUpdated) {
      formDataContent.append("blog_image", blog_image);
    }

    if (id) {
      formDataContent.append("id", id);
      dispatch(
        updateBlogContentById(formDataContent, id, (error, response) => {
          if (response && response.status === 200) {
            openSnackbar("Blog updated successfully");
            navigate("/manage-blogs-content");
          } else {
            openSnackbar("Failed to update the blog");
          }
        })
      );
    } else {
      dispatch(
        addBlogContent(formDataContent, (error, response) => {
          if (error) {
            openSnackbar("Error occurred while adding party");
          } else {
            if (response.status === 200) {
              openSnackbar(response.data.message);
              navigate("/manage-blogs-content");
            }
          }
        })
      );
    }

    if (!id) {
      setFormData({
        category_name: "",
        blog_image: null,
        overview: "",
        content: "",
        isactive: 1,
        blog_title: "",
        categoryid: "",
      });
    }
  };

  const selectChange = (selectedOption) => {
    setSelectedCandidateId(selectedOption.value);
    setDefaultPartyoptions(selectedOption);
    setFormData((prevData) => ({
      ...prevData,
      category_name: selectedOption.label,
    }));
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const props = {
    title: id ? "Edit Blog Category" : "Add Blog Category",
    addSeqBtnstyle: { padding: "1.3rem 2.8rem", marginLeft: "1.5rem" },
    save: "save",
    cancel: "cancel",
    formLabel: "Status",
    handleChange,
    handleSave,
    handleCancel,
    handleToggle,
    formData,
    map_image: id ? showBlogContentByIdData?.blog_image : formData.blog_image,
    onUpload: handleUpload,
    uploadLabel: "Upload Image",
    uploadStyle: {
      marginTop: "3rem",
    },
    blockPlaceholder: "Enter Blog Title",
    blockLabel: "Blog Title",
    blockName: "blog_title",
    blockValue: formData.blog_title,
    uniqueId2: "blog-title",
    textFormLabel: "Overview",
    textFormPlaceholder: "Enter Overview",
    overviewValue: formData.overview,
    uniqueId1: "overview-field",
    overviewName: "overview",
    blocks: id ? showBlogContentByIdData?.content : formData.content,
    handleChanges,
    selectLabel: "Category Name",
    className: {
      cselectpageSize: "cselectpageSize",
      cselectpageSizeLable: "cselectpageSizeLable",
    },
    options,
    cstomStyle,
    selectChange,
    value: defaultPartyoptions,
  };

  return (
    <main className="aDistrict">
      <aside className="addbock">
        <PostBlockSeven {...props} />
      </aside>
      {loading ? (
        <Loader color={"#0E76A8"} loading={loading} css={override} size={75} />
      ) : null}
    </main>
  );
};

export default AddBlogs;
