import React from "react";
import { Container, Image, Table } from "react-bootstrap";
import ProgressBar from "../../../components/progressBar";
import { cApiUrlPrefix } from "../../../config/envConfig";

const ElectionTotalVotes = ({
  totalVotes,
  votingPercentage,
  candidatesData,
}) => {
  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     votingPercentage.set(70);
  //   }, 2000);

  //   return () => clearTimeout(timer);
  // }, [votingPercentage]);

  const labels = [
    "3 Leading candidates as a first preference",
    "1st Preference",
    "2nd Preference",
    "3rd Preference",
    "Total Votes",
    "Votes Percentage",
  ];

  return (
    <Container fluid className="px-0">
      <div className="total-votesmain">
        <div className="total-votes">
          <div className="total-votes-row">
            <div className="left-side">
              <h5>Total Submitted Votes</h5>
              <h6>Based on Platform</h6>
            </div>
            <div className="right-side">
              <span>{totalVotes}</span>
            </div>
          </div>
          <div className="total-votes-row2">
            <div className="left-side">
              <h5>Total voting Percetage</h5>
              <h6>Based on Platform</h6>
            </div>
            <div>
              <ProgressBar
                percentage={Number(votingPercentage.value)}
                color={votingPercentage.color}
                size={90}
              />
            </div>
          </div>
        </div>
        <div className="leadingCandidates px-4">
          <Table responsive className="leadingCandidates-Table mb-0">
            <thead>
              <tr>
                {labels.map((label) => (
                  <td key={label}>{label}</td>
                ))}
              </tr>
            </thead>
            <tbody>
              {candidatesData.map((item, index) => (
                <tr key={index} className="align-middle">
                  {labels.map((label, i) => (
                    <td key={i}>
                      {i === 0 ? (
                        <span>
                          <Image
                            src={`${cApiUrlPrefix}${item.CandidateImage}`}
                            alt="Candidate"
                            width={30}
                            height={30}
                            className="me-3"
                          />
                          {item[label]}
                        </span>
                      ) : i === 1 ? (
                        <span className="second-child">{item[label]}</span>
                      ) : i === 2 ? (
                        <span className="third-child">{item[label]}</span>
                      ) : i === 3 ? (
                        <span className="fourth-child">{item[label]}</span>
                      ) : i === 4 ? (
                        <span className="fifth-child">{item[label]}</span>
                      ) : i === 5 ? (
                        <span className="sixth-child">{item[label]} %</span>
                      ) : (
                        item[label]
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </Container>
  );
};

export default ElectionTotalVotes;
