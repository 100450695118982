import React from 'react'

const Aside5 = (aside5) => {
    return (
        <div className='tabs'>
            {aside5.tabs}
        </div>
    )
}

export default Aside5
