import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { FaLocationArrow } from "react-icons/fa6";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { getAllCandidateByTitleId } from "../../../redux/adminone/action";
import Loader from "../../loaders/loader";
import SolidBlueBtn from "../../buttons/solidBlueBtn";
import { HiArrowNarrowRight } from "react-icons/hi";
import { IoArrowBack } from "react-icons/io5";
import BlueBorderBtn from "../../buttons/blueBorderBtn";
import { CgPlayButtonO } from "react-icons/cg";
import IImage from "../../image/iImage";
import VoteSubmited from "../../modals/voteSubmited";
import { addParliamentElectionResultsByPIdCid } from "../../../redux/user/action";

const override = `
    display: block;
    margin: 0 auto;
    border-color: red;
`;

const ChoosePartyCandidate = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [selectedCandidates, setSelectedCandidates] = useState([]);
  const maxSelection = 3;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [userData, setUserData] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const { party_image, party_name, electionTitleId, id } = location.state || {};
  // const electionTitleId = 10;

  console.log("electionTitleId: ", electionTitleId);

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      setUserData(parsedUser);
    }
  }, []);

  useEffect(() => {
    dispatch(getAllCandidateByTitleId(electionTitleId));
  }, [dispatch, electionTitleId]);

  const getAllCandidateByTitleIdData = useSelector(
    (state) => state.adminone.getAllCandidateByTitleIdData?.data
  );

  const districtIdFromStorage = userData?.district_id;

  const filteredCandidates = Array.isArray(getAllCandidateByTitleIdData)
    ? getAllCandidateByTitleIdData.filter(
        (candidate) =>
          candidate.district_id === districtIdFromStorage &&
          candidate.party_id === id
      )
    : [];

  const onClick = () => {
    navigate(-1);
  };

  const handleOpenModal = () => {
    document.body.classList.add("blur-background");
    setShowModal(true);
  };

  const handleCloseModal = () => {
    document.body.classList.remove("blur-background");
    setShowModal(false);
  };

  const handleChangeClick = () => {
    setShowModal(false);
    navigate("/update-party-candidate", {
      state: {
        id,
        party_image,
        party_name,
        electionTitleId,
        title: location.state?.title || "Election Title",
      },
    });
  };

  const loading = useSelector((state) => state.adminone.loading);

  const partyData = filteredCandidates.map((data) => ({
    id: data.candidate_id,
    candidate_image: (
      <figure className="mpLogo">
        <IImage src={data.candidate_image} className="mpLogoImg" />
      </figure>
    ),
    candidate_name: data.candidate_name,
  }));

  console.log("partyData:", partyData);

  const handleCandidateSelection = (id) => {
    setSelectedCandidates((prevSelected) => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter((candidateId) => candidateId !== id);
      } else if (prevSelected.length < maxSelection) {
        return [...prevSelected, id];
      } else {
        return prevSelected;
      }
    });
  };

  const handleChangeClick1 = () => {
    setShowModal(false);
  };

  useEffect(() => {
    console.log("Location State:", location.state);
  }, [location]);

  const Eid = electionTitleId || null;
  const Uid = userData ? userData.id : null;

  console.log("Election ID (Eid):", Eid);
  console.log("User ID (Uid):", Uid);

  const handleSubmit = () => {
    if (selectedCandidates.length === maxSelection) {
      const formData = {
        results: [
          {
            party_id: id,
            candidate_id: selectedCandidates,
          },
        ],
      };

      console.log("Form Data before submit:", formData);
      console.log("Eid:", Eid, "Uid:", Uid);

      dispatch(
        addParliamentElectionResultsByPIdCid(
          formData,
          Eid,
          Uid,
          (error, response) => {
            if (!error) {
              setShowModal(true);
              handleOpenModal();
            } else {
              console.error("Error submitting vote:", error);
            }
          }
        )
      );
    } else {
      alert(`Please select exactly ${maxSelection} candidates.`);
    }
  };

  return (
    <div className="choose-party-candidate">
      {showModal && <div className="backdrop" />}
      <div className="d-lg-flex d-block justify-content-between align-items-center first-div">
        <div className="hero-h5">
          <aside className="backButton">
            <BlueBorderBtn onClick={onClick}>
              <IoArrowBack />
            </BlueBorderBtn>
          </aside>
          <aside className="ps-3">
            <h5>
              Choose party candidate for{" "}
              {location.state?.title || "Election Title"}
              <span className="ps-2">(Poll)</span>
            </h5>
            <p>
              Sri Lanka, {userData?.district_name},{" "}
              {userData?.polling_division_name}{" "}
              <FaLocationArrow
                className="location-i"
                onClick={() => {
                  navigate(`/select-election`);
                }}
              />
            </p>
          </aside>
        </div>
        <div className="button-wrapper text-end">
          <SolidBlueBtn
            type="submit"
            className="solidBlueBtn"
            onClick={handleSubmit}
          >
            Submit your vote
            <span className="arrowBtn">
              <HiArrowNarrowRight />
            </span>
          </SolidBlueBtn>
          <p className="selectThree">Select Only three*</p>
        </div>
      </div>
      <Container fluid className="layOut-one-container">
        <Row className="layOut-one-row">
          <Col md={6} className="d-flex flex-column">
            <div className="d-flex align-items-center mt-md-3 mt-0">
              <span className="orange-span"></span>
              <figure className="mcLogo">
                <Image
                  fluid
                  src={party_image}
                  className="card-img-top"
                  alt="image"
                />
              </figure>
              <h5 className="ps-4">{party_name}</h5>
              <figure className="playBtn">
                <CgPlayButtonO />
              </figure>
            </div>
          </Col>
          <Col className="cDetails">
            <div className="party-list">
              {partyData.map((party) => (
                <div key={party.id} className="party-item">
                  <aside className="d-flex align-items-center">
                    <span>{party.candidate_image}</span>
                    <h5>{party.candidate_name}</h5>
                  </aside>
                  <aside>
                    <Button
                      className={`blank-button ${
                        selectedCandidates.includes(party.id) ? "selected" : ""
                      }`}
                      onClick={() => handleCandidateSelection(party.id)}
                    ></Button>
                  </aside>
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </Container>
      <VoteSubmited
        show={showModal}
        handleClose={handleCloseModal}
        handleChangeClick={handleChangeClick}
        handleChangeClick1={handleChangeClick1}
        district={userData?.district_name}
        pd={userData?.polling_division_name}
      />
      {loading ? (
        <Loader color={"#0E76A8"} loading={loading} css={override} size={75} />
      ) : null}
    </div>
  );
};

export default ChoosePartyCandidate;
