import React, { useEffect, useState } from "react";
import ManageBlk from "../../../components/admin/manageBlk";
import DeleteCModal from "../../../components/modals/deleteCModal";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import InActive from "../../../components/labels/inActive";
import Active from "../../../components/labels/active";
import DropDown from "../../../components/dropdown/dropDown";
import { useSnackbar } from "react-simple-snackbar";
import {
  delBlogContentById,
  indexGetAllBlogCategory,
  indexGetAllBlogContent,
  showBlogContentById,
} from "../../../redux/adminone/action";

const options = [
  { value: 10, label: 10 },
  { value: 20, label: 20 },
  { value: 30, label: 30 },
];

const cstomStyle = {
  menu: (provided) => ({
    ...provided,
    backgroundColor: "white",
    color: "#65676B",
    maxWidth: "97px",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#efefef" : "white",
    color: state.isFocused ? "#65676B" : "#65676B",
    padding: 5,
  }),
};

const ManageBlogsmain = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(indexGetAllBlogContent());
    dispatch(indexGetAllBlogCategory());
  }, [dispatch]);
  const [showModal, setShowModal] = useState(false);
  const [openSnackbar] = useSnackbar();
  const [selectedUser, setSelectedUser] = useState(null);
  const navigate = useNavigate();
  const getAllBlogContent = useSelector(
    (state) => state.adminone.getAllBlogContent?.data
  );
  const error = useSelector((state) => state.adminone.error);

  const statusMap = {
    0: <InActive />,
    1: <Active />,
  };

  const dropdownItems = (data) => [
    { label: "Edit", href: "#/edit", onClick: () => handleEditUser(data) },
    {
      label: "Delete",
      href: "#/delete",

      onClick: () => {
        handleOpenModal(data);
      },
    },
  ];
  const handleOpenModal = (data) => {
    setSelectedUser(data);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedUser(null);
  };
  const handleEditUser = (data) => {

    navigate("/edit-blogs-content", {
      state: {
        id: data.id,
      }
    });

    // navigate(`/edit-blogs-content?id=${data.id}`);
    dispatch(showBlogContentById(data.id));
  };

  const handleConfirmDelete = () => {
    if (selectedUser) {
      const id = selectedUser.id;
      dispatch(
        delBlogContentById(id, (error, response) => {
          if (error) {
            // handle the error
          } else {
            // handle the response

            if (response.status === 200) {
              dispatch(indexGetAllBlogContent());
              openSnackbar(response.data.message);
            }
          }
        })
      );
      handleCloseModal();
    }
  };

  const labels = ["Category Name", "Blog Title", "Status", "Action"];

  const data = Array.isArray(getAllBlogContent)
    ? getAllBlogContent.map((categoryList) => {
      return {
        "Category Name": categoryList.category_name,
        "Blog Title": categoryList.blog_title,
        Status: statusMap[categoryList.isactive],
        Action: (
          <DropDown
            Children={"Action"}
            items={dropdownItems(categoryList)}
            className="userListAction ms-auto"
          />
        ),
      };
    })
    : [];

  const props = {
    title: "Blogs Content",
    children: "Add Blog Content",
    style: { padding: "1.3rem 2.8rem" },
    to: "/add-blogs-content",
    options,
    // isSearchable,
    className: {
      cselectpageSize: "cselectpageSize",
      minputSearch: "minputSearch",
      deleteModal: "deleteModal",
    },
    cstomStyle,
    Placeholder: "Search",
    labels,
    data,
    error,
  };
  return (
    <main className="manageBlogCategory">
      <aside className="manageUserListaside">
        <ManageBlk {...props} />
      </aside>
      <DeleteCModal
        show={showModal}
        handleClose={handleCloseModal}
        handleConfirm={handleConfirmDelete}
        className={props.className.deleteModal}
      />
    </main>
  );
};

export default ManageBlogsmain;
