import React from 'react';
import { Modal } from 'react-bootstrap';
import { ImCancelCircle } from "react-icons/im";
import RedSolidBtn from '../buttons/redSolidBtn';
import RedBorderBtnn from '../buttons/redBorderBtnn';


const DeleteCModal = ({ show, handleClose, handleConfirm, className }) => {
  
    return (
        <Modal show={show} onHide={handleClose} centered className={className}>
            <Modal.Header >
                {/* <Modal.Title>Confirm Delete</Modal.Title> */}
                <ImCancelCircle className='deleteIcon' />
            </Modal.Header>
            <Modal.Body>
                <p className='ays'>Are you sure?</p>
                <p className='ywtdti'>you want to delete this item !</p>
            </Modal.Body>
            <Modal.Footer>
                <RedBorderBtnn onClick={handleClose}>Cancel</RedBorderBtnn>
                <RedSolidBtn onClick={handleConfirm}>Confirm Delete</RedSolidBtn>
            </Modal.Footer>
        </Modal>
    )
}

export default DeleteCModal;
