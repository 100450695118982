import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import PostBlock from "../../../components/admin/postBlock";
import {
  addPermision,
  showPermissionByID,
  updatePermissionsById,
} from "../../../redux/adminone/action";
import Loader from "../../../components/loaders/loader";
import { useSnackbar } from "react-simple-snackbar";
const override = `
    display: block; 
    margin: 0 auto;
    border-color: red;
`;
const AddPermission = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector((state) => state.adminone.loading);
  const location = useLocation();
  const { id, district_name, status } = location.state || {};
  const [openSnackbar, closeSnackbar] = useSnackbar();
  useEffect(() => {
    if (id) {
      dispatch(showPermissionByID(id));
    }
  }, [dispatch, id]);
  const ShowPermissionData = useSelector(
    (state) => state.adminone.ShowPermissionData?.data
  );

  const [formData, setFormData] = useState({
    name: "",
  });

  const [errors, setErrors] = useState({
    name: "",
  });
  useEffect(() => {
    if (id && ShowPermissionData) {
      setFormData({
        name: ShowPermissionData.name,
      });
    }
  }, [id, ShowPermissionData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const handleSave = async () => {
    const { name } = formData;

    let valid = true;
    if (!name || name.length < 2) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        name: "Enter Security Name",
      }));
      valid = false;
    }

    if (!valid) return;

    const formDataPermission = new FormData();
    formDataPermission.append("name", formData.name);

    if (id) {
      dispatch(
        updatePermissionsById(formDataPermission, id, (error, response) => {
          if (error) {
            // handle the error
          } else {
            // handle the response

            if (response.status === 200) {
              openSnackbar(response.data.message);
              navigate("/manage-party");
            }
          }
        })
      );
    } else {
      dispatch(
        addPermision(formDataPermission, (error, response) => {
          if (error) {
            openSnackbar("Error occurred while adding party");
          } else {
            if (response.status === 200) {
              openSnackbar(response.data.message);
              navigate("/manage-party");
            }
          }
        })
      );
    }
    navigate(-1);
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const props = {
    title: id ? "Edit Permissions" : "Add Permissions",
    saveNextBtn: "Save and Next",
    addSeqBtnstyle: { padding: "1.3rem 2.8rem", marginLeft: "1.5rem" },
    save: "save",
    cancel: "cancel",
    textInput: [
      {
        label: "name",
        name: "name",
        value: formData.name,
        error: errors.name,
      },
    ],
    handleChange,
    handleSave,
    handleCancel,
  };

  return (
    <main className="aSecurity">
      <aside className="addbock">
        <PostBlock {...props} />
      </aside>
      {loading ? (
        <Loader color={"#0E76A8"} loading={loading} css={override} size={75} />
      ) : null}
    </main>
  );
};

export default AddPermission;
