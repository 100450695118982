import React, { useEffect, useState } from "react";
import ManageBlk from "../../../components/admin/manageBlk";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../components/loaders/loader";
import DropDown from "../../../components/dropdown/dropDown";
import DeleteCModal from "../../../components/modals/deleteCModal";
// import { DelSecurityById } from "../../../redux/admin/action";
import { indexSecurityLevel } from "../../../redux/adminone/action";
import { useNavigate } from "react-router-dom";

const override = `
    display: block;
    margin: 0 auto;
    border-color: red;
  `;
const options = [
  { value: 10, label: 10 },
  { value: 20, label: 20 },
  { value: 30, label: 30 },
];
const cstomStyle = {
  menu: (provided) => ({
    ...provided,
    backgroundColor: "white",
    color: "#65676B",
    maxWidth: "97px",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#efefef" : "white",
    color: state.isFocused ? "#65676B" : "#65676B",
    padding: 5,
  }),
};

const SecurityLevel = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();


  useEffect(() => {

    dispatch(indexSecurityLevel());


  }, [dispatch]);

  const [isSearchable] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [Sequrities, setSequrities] = useState(null);
  const loading = useSelector((state) => state.adminone.loading);
  const indexSecurityData = useSelector((state) => state.adminone.indexSecurityData?.data);
  const error = useSelector((state) => state.adminone.error);


  const dropdownItems = (data) => [
    { label: "Edit", href: "#/edit", onClick: () => handleEdit(data), },
    // {
    //   label: "Delete",
    //   href: "#/delete",

    //   onClick: () => {
    //     handleOpenModal(data);
    //   },
    // },
    {
      label: "Manage Permissions",
      href: "#/edit",
      onClick: () => handleHistory(data),
    },
  ];
  const handleEdit = (data) => {
    navigate("/edit-securities", {
      state: {
        id: data.id,
        securitylevel_name: data.securitylevel_name,
      }
    });
    // navigate(
    //   `/edit-securities?id=${data.id}&securitylevel_name=${encodeURIComponent(
    //     data.securitylevel_name
    //   )}`
    // );
  };
  //   const handleOpenModal = (data) => {
  //     setSequrities(data);
  //     setShowModal(true);
  //   };

  const handleCloseModal = () => {
    setShowModal(false);
    setSequrities(null);
  };

  const handleConfirmDelete = () => {
    if (Sequrities) {
      const id = Sequrities.id;

      // dispatch(DelSecurityById(id));
      handleCloseModal();
    }
  };

  const labels = ["Security Level", "Action"];

  const data = Array.isArray(indexSecurityData)
    ? indexSecurityData.map((securities) => ({
      "Security Level": securities.securitylevel_name,
      Action: (
        <DropDown
          className="sequritiesPartyAction ms-auto"
          Children={"Action"}
          items={dropdownItems(securities)}
        />
      ),
    }))
    : [];

  const handleHistory = (data) => {
    navigate(
      `/manage-permission?id=${data.id}&securitylevel_name=${encodeURIComponent(
        data.securitylevel_name
      )}`
    );
  };

  const SecurityLevelProps = {
    title: "Securities Level",
    children: "Add Security",
    to: "/add-securities",
    style: { padding: "1.3rem 2.8rem" },
    options,
    isSearchable,
    className: {
      cselectpageSize: "cselectpageSize",
      minputSearch: "minputSearch",
      deleteModal: "deleteModal",
    },
    cstomStyle,
    Placeholder: "Search",
    handleCloseModal,
    handleConfirmDelete,
    labels,
    data,
    error,
  };
  return (
    <main className="slevel">
      <aside className="slevelaside">
        <ManageBlk {...SecurityLevelProps} />
      </aside>
      <DeleteCModal
        show={showModal}
        handleClose={SecurityLevelProps.handleCloseModal}
        handleConfirm={SecurityLevelProps.handleConfirmDelete}
        className={SecurityLevelProps.className.deleteModal}
      ></DeleteCModal>
      {loading ? (
        <Loader color={"#0E76A8"} loading={loading} css={override} size={75} />
      ) : null}
    </main>
  );
};

export default SecurityLevel;
