import React from "react";
// import BlackBtn from "../../../components/buttons/blackBtn";
import BlackBorderBtn from "../../../components/buttons/blackBorderBtn";
import RedBorderBtn from "../../../components/buttons/redBorderBtn";
import TextForm from "../../../components/form/textForm";
import UploadImage from "../../../components/form/uploadImage";
import { v4 as uuidv4 } from "uuid";
import DatePickerSingle from "../../../components/datePicker/datePickerSingle";
import TextArea from "../../../components/form/textArea";

const AddPartyHero = ({
  title1,
  blackbtnChild,
  blackBtnTo,
  blackBtnStyle,
  blackBorderBtnChild,
  blackBorderBtnTo,
  blackBorderBtnStyle,
  redBorderBtnChild,
  redBorderBtnStyle,
  textFormLabel,
  textFormPlaceholder,
  textFormStyle1,
  textFormLabel1,
  textFormPlaceholder1,
  uploadLabel,
  uploadStyle,
  onChange,
  onUpload,
  onClick,
  party_name_value,
  party_abbreviation_value,
  handleCancel,
  dateLable1,
  startName1,
  startValue1,
  onChangeStart1,
  className,
  placeholderStart1,

  textFormPlaceholder2,
  textFormLabel2,
  textFormStyle2,
  leader_value,

  textFormStyle3,
  textFormLabel3,
  textFormPlaceholder3,
  headquarters_value,

  textFormStyle4,
  textFormLabel4,
  textFormPlaceholder4,
  website_value,

  textFormStyle5,
  textFormLabel5,
  textFormPlaceholder5,
  ideology_value,

  handleSaveAndNext,

  disabled,
  logo,
  textFormLabel6
  // onSubmit
}) => {
  const uniqueId1 = uuidv4();
  const uniqueId2 = uuidv4();
  const uniqueId3 = uuidv4();
  const uniqueId4 = uuidv4();
  const uniqueId5 = uuidv4();
  const uniqueId6 = uuidv4();

  return (
    <div className="addpartyBlock">
      <div className="addpartyBlockChild">
        <h2 className="addpartyBlockTitle">{title1}</h2>
        <div className="">
          {/* {!disabled && (
            <BlackBtn
              children1={blackbtnChild}
              to1={blackBtnTo}
              style1={blackBtnStyle}
              onClick={handleSaveAndNext}
            />
          )} */}
          <BlackBorderBtn
            children1={blackBorderBtnChild}
            to1={blackBorderBtnTo}
            style1={blackBorderBtnStyle}
            onClick={onClick}
          />
          <RedBorderBtn
            children1={redBorderBtnChild}
            onClick={handleCancel}
            style1={redBorderBtnStyle}
          />
        </div>
      </div>
      <div className="mt-5">
        <TextForm
          formLabel={textFormLabel}
          placeholder={textFormPlaceholder}
          name="party_name"
          value={party_name_value}
          onChange={onChange}
          uniqueId={uniqueId1}
          Lsm={2}
          Ism={6}
        />
        {/* {errors.party_name && <p>{errors.party_name}</p>} */}
        <TextForm
          style={textFormStyle1}
          formLabel={textFormLabel1}
          placeholder={textFormPlaceholder1}
          name="party_abbreviation"
          value={party_abbreviation_value}
          onChange={onChange}
          uniqueId={uniqueId2}
          Lsm={2}
          Ism={6}
        />
        {/* {errors.party_abbreviation && <p>{errors.party_abbreviation}</p>} */}
        <DatePickerSingle
          label={dateLable1}
          startName={startName1}
          startValue={startValue1}
          onChangeStart={onChangeStart1}
          className={className}
          placeholderStart={placeholderStart1}
        />
        {/* {errors.founding_date && <p>{errors.founding_date}</p>} */}
        <TextForm
          style={textFormStyle2}
          formLabel={textFormLabel2}
          placeholder={textFormPlaceholder2}
          name="leader"
          value={leader_value}
          onChange={onChange}
          uniqueId={uniqueId3}
          Lsm={2}
          Ism={6}
        />
        {/* {errors.leader && <p>{errors.leader}</p>} */}
        <TextForm
          style={textFormStyle3}
          formLabel={textFormLabel3}
          placeholder={textFormPlaceholder3}
          name="headquarters"
          value={headquarters_value}
          onChange={onChange}
          uniqueId={uniqueId4}
          Lsm={2}
          Ism={6}
        />
        {/* {errors.headquarters && <p>{errors.headquarters}</p>} */}
        <TextArea
          style={textFormStyle5}
          formLabel={textFormLabel5}
          placeholder={textFormPlaceholder5}
          name="ideology"
          value={ideology_value}
          onChange={onChange}
          uniqueId={uniqueId6}
        />
        {/* {errors.ideology && <p>{errors.ideology}</p>} */}
        <TextForm
          style={textFormStyle4}
          formLabel={textFormLabel4}
          placeholder={textFormPlaceholder4}
          name="website"
          value={website_value}
          onChange={onChange}
          uniqueId={uniqueId5}
          Lsm={2}
          Ism={6}
        />

        <UploadImage
          label={uploadLabel}
          style={uploadStyle}
          onUpload={onUpload}
          initialImageUrl={logo}
        />
      </div>
    </div>
  );
};

export default AddPartyHero;
