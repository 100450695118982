import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Cselect from "../select/cselect";
import SolidSquareBlueBtn from "../buttons/solidSquareBlueBtn";
import IImage from "../image/iImage";
import { GoArrowUpRight, GoTrophy } from "react-icons/go";

const PastPresidentElection = (props) => {
  const {
    title,
    headings,
    cstomStyle,
    options0,
    selectChange0,
    value0,
    placeholder0,
    options,
    options1,
    className,
    selectChange,
    selectChange1,
    value,
    value1,
    style,
    placeholder1,
    isFirstSelectSelected,
    handleClick1,
    showEditButton,
    selectedElectionLabel,
    topCandidate,
    showWonButton,
    isElectionSelected,
    updatedWinner,
  } = props;

  const updatedTitle = selectedElectionLabel
    ? `${selectedElectionLabel}`
    : title;
  return (
    <div className="pastpresidentialElection">
      <div className="manageHeadTitle d-lg-flex justify-content-between align-items-center">
        <h2>{updatedTitle}</h2>
        {showEditButton && (
          <SolidSquareBlueBtn style={style} onClick={handleClick1}>
            Edit
          </SolidSquareBlueBtn>
        )}
        {showWonButton && topCandidate && (
          <div>
            <h3 className="pb-2 leadingIn">Leading In - {updatedWinner}</h3>
            <div className="svsrp-wcv-Blk">
              <div className="svsrp-wcv-subBlk">
                <div className="svsrp-trophy">
                  <GoTrophy className="trophy" />
                </div>
                <div className="svsrp-details">
                  <h4 className="svsrp-deta-head">
                    <span className="text-decoration-underline">
                      {topCandidate.candidate_name || "N/A"}
                    </span>
                    <span>
                      <GoArrowUpRight className="svsrp-arw" />
                    </span>
                  </h4>
                  <div className="svsrp-deta-votes">
                    Winning Candidate Votes -{" "}
                    {topCandidate.combined_votes || "N/A"}
                  </div>
                </div>
              </div>
              <div className="svsrp-wcv-subBlk">
                <div className="mpLogo">
                  <IImage
                    src={topCandidate.party_image}
                    className="mpLogoImg"
                  />
                </div>
                <div className="svsrp-details">
                  <h4 className="svsrp-deta-head">
                    <span className="text-decoration-underline">
                      {topCandidate.party_name || "N/A"}
                    </span>
                    <span>
                      <GoArrowUpRight className="svsrp-arw" />
                    </span>
                  </h4>
                  {/* <div className="svsrp-deta-votes">
                  Winning Party Votes - {totalPartyVotes}
                </div> */}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="ppe-selects">
        <Container fluid className="elections-container">
          <Row md={3} xs={1} className="elections-row">
            <Col className="mb-3">
              <Row className="d-flex align-items-center g-3">
                <Col xs={12} className="ps-0 elections-col">
                  <h2 className="elections-heading">{headings.heading0}</h2>
                </Col>
                <Col xs={12} className="ps-0 elections-col">
                  <Cselect
                    id="districtSelect"
                    options={options0}
                    className={className.cselectpageSize}
                    styles={cstomStyle}
                    onChange={selectChange0}
                    value={value0}
                    placeholder={placeholder0}
                  />
                </Col>
              </Row>
            </Col>
            {isElectionSelected && (
              <Col className="ppeCol mb-3">
                <Row className="d-flex align-items-center g-3">
                  <Col xs={12} className="elections-col">
                    <h2 className="elections-heading">{headings.heading1}</h2>
                  </Col>
                  <Col xs={12} className="elections-col">
                    <Cselect
                      id="districtSelect"
                      options={options}
                      className={className.cselectpageSize}
                      styles={cstomStyle}
                      onChange={selectChange}
                      value={value}
                      placeholder={placeholder1}
                    />
                  </Col>
                </Row>
              </Col>
            )}
            {isFirstSelectSelected && (
              <Col className="ppeCol">
                <Row className="d-flex align-items-center g-3">
                  <Col xs={12} className=" elections-col">
                    <h2 className="elections-heading">{headings.heading2}</h2>
                  </Col>
                  <Col xs={12} className="elections-col">
                    <Cselect
                      id="districtSelect"
                      options={options1}
                      className={className.cselectpageSize}
                      styles={cstomStyle}
                      onChange={selectChange1}
                      value={value1}
                    />
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default PastPresidentElection;
