import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  indexSecurityLevel,
  updateUserSecurityLeveLByUserId,
  showUserDetailsById,
} from "../../../redux/adminone/action";
import Loader from "../../../components/loaders/loader";
import EditUserHero from "./editUserHero";
import { useSnackbar } from "react-simple-snackbar";

const cstomStyle = {
  menu: (provided) => ({
    ...provided,
    backgroundColor: "white",
    color: "#65676B",
    Width: "fit-content",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#efefef" : "white",
    color: state.isFocused ? "#65676B" : "#65676B",
    padding: 5,
  }),
};

const EditUser = () => {
  const dispatch = useDispatch();
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const location = useLocation();
  const navigate = useNavigate();
  const { id, username, status } = location.state || {};
  // const queryParams = new URLSearchParams(location.search);
  // const id = queryParams.get("id");

  const [formData, setFormData] = useState({
    name: "",
    username: "",
    email: "",
    mobileNumber: "",
    district_name: "",
    polling_divisions: "",
    province_name: "",
    voter_id: "",
    status: "",
    security_level_id: "",
  });

  const loading = useSelector((state) => state.adminone.loading);
  const showUserDetailsByIdData = useSelector(
    (state) => state.adminone.showUserDetailsByIdData?.data
  );

  const indexSecurityData = useSelector(
    (state) => state.adminone.indexSecurityData?.data
  );

  const [options, setOptions] = useState([]);
  const [selectedUserId, setselectedUserId] = useState(null);
  const [defaultUseroptions, setDefaultUseroptions] = useState(null);

  useEffect(() => {
    if (id) {
      dispatch(showUserDetailsById(id));
      dispatch(indexSecurityLevel());
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (showUserDetailsByIdData && indexSecurityData) {
      const defaultSecurityLevel = indexSecurityData.find(
        (level) => level.id === showUserDetailsByIdData.security_level_id
      );
      setFormData({
        name: showUserDetailsByIdData.name ?? "",
        username: showUserDetailsByIdData.username ?? "",
        email: showUserDetailsByIdData.email ?? "",
        mobileNumber: showUserDetailsByIdData.mobileNumber ?? "",
        district_name: showUserDetailsByIdData.district_name ?? "",
        polling_divisions: showUserDetailsByIdData.polling_divisions ?? "",
        province_name: showUserDetailsByIdData.province_name ?? "",
        voter_id: showUserDetailsByIdData.voter_id ?? "",
        status: showUserDetailsByIdData.status ?? "",
        security_level_id: showUserDetailsByIdData.security_level_id ?? "",
      });
      setDefaultUseroptions({
        value: defaultSecurityLevel?.id,
        label: defaultSecurityLevel?.securitylevel_name,
      });
      setselectedUserId(defaultSecurityLevel?.id);
    }
  }, [showUserDetailsByIdData, indexSecurityData]);

  useEffect(() => {
    if (indexSecurityData) {
      const newOptions = indexSecurityData.map((item) => ({
        value: item.id,
        label: item.securitylevel_name,
      }));
      setOptions(newOptions);
    }
  }, [indexSecurityData]);

  const handleCancel = () => {
    navigate(-1);
  };

  const selectChange = (selectedOption) => {
    setselectedUserId(selectedOption.value);
    setDefaultUseroptions(selectedOption);
  };

  const handleClick = async (e) => {
    e.preventDefault();

    let valid = true;
    if (!selectedUserId) {
      alert("Please select a security level.");
      return;
    }

    if (!valid) return;

    const formDataUser = new FormData();
    formDataUser.append("security_level_id", selectedUserId);

    formDataUser.append("id", id);

    dispatch(
      updateUserSecurityLeveLByUserId(formDataUser, id, (error, response) => {
        if (error) {
        } else {
          if (response.status === 200) {
            openSnackbar(response.data.message);
            navigate("/user-list");
          }
        }
      })
    );

    navigate(-1);
  };

  const props = {
    title1: "Edit User",
    blackBorderBtnChild: "Save",
    blackBorderBtnTo: "",
    blackBorderBtnStyle: {
      padding: "1.3rem 2.8rem",
      marginLeft: "1.5rem",
    },
    redBorderBtnChild: "Cancel",
    redBorderBtnStyle: {
      padding: "1.3rem 2.8rem",
      marginLeft: "1.5rem",
    },
    textFormLabel: "Edit User Name",
    textFormPlaceholder: "Mayur Saksena",
    className: {
      cselectpageSize: "cselectpageSize",
      cselectpageSizeLable: "cselectpageSizeLable",
    },
    options,
    cstomStyle,

    user_name_value: formData.name,

    textFormStyle1: {
      marginTop: "3rem",
    },
    textFormLabel1: "Edit User Id",
    textFormPlaceholder1: "Mayur Saksena",
    user_id_value: formData.username,

    textFormStyle3: {
      marginTop: "3rem",
    },
    textFormLabel3: "Mobile Number",
    textFormPlaceholder3: "Mobile Number",
    mobileNumber_value: formData.mobileNumber,

    textFormStyle4: {
      marginTop: "3rem",
    },
    textFormLabel4: "Email Address",
    textFormPlaceholder4: "Enter Email Address",
    email_value: formData.email,

    textFormStyle5: {
      marginTop: "3rem",
    },
    textFormLabel5: "District Name",
    textFormPlaceholder5: "Enter District Name",
    district_name_value: formData.district_name,

    textFormStyle6: {
      marginTop: "3rem",
    },
    textFormLabel6: "Polling Division",
    textFormPlaceholder6: "Enter Polling Division",
    polling_divisions_value: formData.polling_divisions,

    textFormStyle7: {
      marginTop: "3rem",
    },
    textFormLabel7: "Province Name",
    textFormPlaceholder7: "Enter Province Name",
    province_name_value: formData.province_name,

    textFormStyle8: {
      marginTop: "3rem",
    },
    textFormLabel8: "Voter Id",
    textFormPlaceholder8: "Voter Id",
    voter_id_value: formData.voter_id,

    onChange: () => { },
    handleClick,
    handleCancel,
    selectChange,
    value: defaultUseroptions,
  };

  return (
    <main className="aParty cCandidate">
      <form className="aParty-hero aCandidate-hero">
        <EditUserHero {...props} />{" "}
      </form>
      {loading ? (
        <Loader color={"#0E76A8"} loading={loading} size={75} />
      ) : null}
    </main>
  );
};

export default EditUser;
