import React from "react";
// import BlackBtn from "../buttons/blackBtn";
import BlackBorderBtn from "../buttons/blackBorderBtn";
import RedBorderBtn from "../buttons/redBorderBtn";
import TextForm from "../form/textForm";
import Cselect from "../select/cselect";
import { Col, Row } from "react-bootstrap";
import UploadImage from "../form/uploadImage";
// import { Form } from "react-router-dom";

const PostBlockOne = (props) => {
  const {
    selectChange,
    title,
    // saveNextBtn,
    addSeqBtnstyle,
    save,
    cancel,
    textInput,
    handleChange,
    handleSave,
    handleCancel,
    options,
    isSearchable,
    cstomStyle,
    className,
    selectLable,
    value,
    uploadLabel,
    uploadStyle,
    onUpload,
    map_image,
  } = props;
  return (
    <div>
      <div className="PostBlock">
        <div className="PostBlockChild">
          <h2 className="PostBlockTitle">{title}</h2>
          <div className="">
            <BlackBorderBtn
              children1={save}
              style1={addSeqBtnstyle}
              onClick={handleSave}
            />
            <RedBorderBtn
              children1={cancel}
              style1={addSeqBtnstyle}
              onClick={handleCancel}
            />
          </div>
        </div>
        <div className="mt-5">
          <Row className="mb-3">
            <Col
              column
              sm={2}
              className="d-flex justify-content-start align-items-center"
            >
              <label className={className.cselectpageSizeLable}>
                {selectLable}
              </label>
            </Col>
            <Col column sm={6}>
              <Cselect
                options={options}
                isSearchable={isSearchable}
                className={className.cselectpageSize}
                styles={cstomStyle}
                onChange={selectChange}
                value={value}
              />
            </Col>
          </Row>
          {textInput.map((input, index) => (
            <TextForm
              key={index}
              formLabel={input.label}
              name={input.name}
              value={input.value}
              placeholder={input.placeholder}
              onChange={handleChange}
              error={input.error}
              Lsm={2}
              Ism={6}
              disabled={input.disabled || false}
            />
          ))}
        </div>
        <UploadImage
          label={uploadLabel}
          style={uploadStyle}
          onUpload={onUpload}
          initialImageUrl={map_image}
        />
      </div>
    </div>
  );
};

export default PostBlockOne;
