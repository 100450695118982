import React from "react";
import Modal from "react-bootstrap/Modal";
// import TextInputWithLabel from "../form-inputs/textInputWithLabel";
import { IoClose } from "react-icons/io5";
// import TextInput from "../form-inputs/textInput";
import { Button, FloatingLabel, Form } from "react-bootstrap";

function VoteForYourCandidate({
  show,
  onHide,
  title,
  voteText,
  formValue,
  handleChange,
  handleSubmit,
  formValue1,
  handleClick,
}) {
  return (
    <div className="vyc-modal">
      <Modal size="md" show={show} onHide={onHide} centered>
        <Modal.Header className="justify-content-center vyc-header">
          <Modal.Title className="position-relative">
            <h3>{title}</h3>
          </Modal.Title>
          <span className="close-icon" onClick={onHide}>
            <IoClose />
          </span>
        </Modal.Header>
        <Modal.Body className="vyc-body my-4">
          <Form className="lForm" onSubmit={handleSubmit}>
            <FloatingLabel controlId="idEmail" label="Voter name">
              <Form.Control
                required
                type="text"
                placeholder="Enter ooraar profile name"
                name="username"
                value={formValue}
                onChange={handleChange}
              />
            </FloatingLabel>
            <Form.Control
              required
              type="text"
              placeholder="Voter ID number"
              name="voter_id"
              value={formValue1}
              onChange={handleChange}
              className="mt-5"
            />
          </Form>
        </Modal.Body>
        <Modal.Footer className="d-block">
          <div className="text-center my-4">
            {/* <BlueBordeBtn text="System tour" />
            <BlueBtn text="Go for vote" /> */}
            {/* <Button className="vyc-btn1">{systemTourText}</Button> */}

            <Button
              className="vyc-btn2"
              onClick={handleClick}
              disabled={!formValue || !formValue1}
            >
              {voteText}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default VoteForYourCandidate;
