import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DropDown from "../../../components/dropdown/dropDown";

import { useLocation, useNavigate } from "react-router-dom";
import ManageBlkFour from "../../../components/admin/ManageBlkFour";
// import { getManageCandidateToeById } from "../../../redux/admin/action";
import { getAllCandidateByTitleId } from "../../../redux/adminone/action";
import InActive from "../../../components/labels/inActive";
import Active from "../../../components/labels/active";
const options1 = [
    { value: 10, label: 10 },
    { value: 20, label: 20 },
    { value: 30, label: 30 },
];
const cstomStyle1 = {
    menu: (provided) => ({
        ...provided,
        backgroundColor: "white",
        color: "#65676B",
        maxWidth: "97px",
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused ? "#efefef" : "white",
        color: state.isFocused ? "#65676B" : "#65676B",
        padding: 5,
    }),
};

const cstomStyle2 = {
    menu: (provided) => ({
        ...provided,
        backgroundColor: "white",
        color: "#65676B",
        maxWidth: "150px",
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused ? "#efefef" : "white",
        color: state.isFocused ? "#65676B" : "#65676B",
        padding: 5,
    }),
};

const ManageCandidateToe = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const location = useLocation();
    const { id, type_of_election_name, election_title } = location.state || {};
    // const params = new URLSearchParams(location.search);
    // const id = params.get("id");
    // const type_of_election_name = params.get("type_of_election_name");
    // const election_title = params.get("election_title");

    useEffect(() => {
        if (id) {
            dispatch(getAllCandidateByTitleId(id));
        }
    }, [dispatch, id]);
    const getAllCandidateByTitleIdData = useSelector((state) => state.adminone.getAllCandidateByTitleIdData?.data);
    const loading = useSelector((state) => state.adminone.loading);
    const error = useSelector((state) => state.adminone.error);
    const [isSearchable] = useState(false);
    const [options2, setOptions2] = useState([]);
    const [defaultDistrictsOption2, setDefaultDistrictsOption2] = useState(null);
    const [selectedFilter, setSelectedFilter] = useState(null);

    useEffect(() => {
        if (getAllCandidateByTitleIdData) {
            const newOptions = [
                { value: "all", label: "All" },
                ...getAllCandidateByTitleIdData.map((item) => ({
                    value: item.district_id,
                    label: item.district_name,
                })),
            ];
            setOptions2(newOptions);
            if (getAllCandidateByTitleIdData.length > 0) {
                setDefaultDistrictsOption2({
                    value: getAllCandidateByTitleIdData[0].district_id,
                    label: getAllCandidateByTitleIdData[0].district_name,
                });
                setSelectedFilter({
                    value: getAllCandidateByTitleIdData[0].district_id,
                    label: getAllCandidateByTitleIdData[0].district_name,
                });
            }
        }
    }, [getAllCandidateByTitleIdData]);

    const handleFilterChange = (selectedOption) => {
        setSelectedFilter(selectedOption);
    };

    const filteredData =
        selectedFilter && selectedFilter.value !== "all"
            ? getAllCandidateByTitleIdData.filter(
                (item) => item.district_id === selectedFilter.value
            )
            : getAllCandidateByTitleIdData;

    const statusMap = {
        0: <InActive />,
        1: <Active />,
    };
    const handleEdit = () => {
        navigate(
            `/type-of-election-edit-candidate?id=${id}&type_of_election_name=${encodeURIComponent(
                type_of_election_name
            )}&edit=true`
        );
    };

    const handleAddManageCandidate = () => {
        navigate(
            `/type-of-election-add-candidate?id=${id}&type_of_election_name=${encodeURIComponent(
                type_of_election_name
            )}&add=true`
        );
    };

    const labels = ["Candidate Name", "Party Name", "Status"];
    const data = Array.isArray(filteredData)
        ? filteredData.map((candidateToe) => ({
            "Candidate Name": candidateToe.candidate_name,
            "Party Name": candidateToe.party_name,
            Status: statusMap[candidateToe.status],
        }))
        : [];

    const Props = {
        title: `${type_of_election_name}>> ${election_title} >> Manage Candidate`,
        children: "Add Candidate",
        children1: "Edit Candidate",
        options1,
        options2,
        isSearchable,
        className: {
            cselectpageSize: "cselectpageSize",
            cselectpageSize2: "cselectpageSize2",
            minputSearch: "minputSearch",
            deleteModal: "deleteModal",
        },
        handleAddManageCandidate,
        handleEdit,
        cstomStyle1,
        cstomStyle2,
        Placeholder: "Search",
        labels,
        data,
        error,
        loading,
        handleFilterChange,
        selectedFilter,
        value: selectedFilter || defaultDistrictsOption2,
    };
    return (
        <main className="candidateToe">
            <aside className="candidateToeaside">
                <ManageBlkFour {...Props} />
            </aside>
        </main>
    );
};

export default ManageCandidateToe;
